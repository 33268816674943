import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from "react";
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import Draggable, { DraggableCore } from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import FlipIcon from '@mui/icons-material/Flip';
import { Popover } from "@mui/material";
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import Group from '@mui/icons-material/CallMerge';
import Ungroup from '@mui/icons-material/CallSplit';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CloseIcon from '@mui/icons-material/Close';
import OpacityIcon from '@mui/icons-material/Opacity';


const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));

const GeneralProperties = ({clearAll,lockmovementActiveobject,editor,fabric,canvasHeight,canvasWidth}) => {

    const classes = useStyles();
    //draggable
    const [state, setState] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    });

    const onStart = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags + 1 });
    };
    const onStop = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags - 1 });
    };

    const dragHandlers = { onStart, onStop };


    const Flipselection = () => {
        const fliphorizontal = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    if (editor.canvas.getActiveObject().flipX === true) {
                        editor.canvas.getActiveObject().set('flipX', false)
                        editor.canvas.renderAll();

                    }
                    else {
                        editor.canvas.getActiveObject().set('flipX', true)
                        editor.canvas.renderAll();
                    }
                }
            }

        }
        const flipvertical = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    if (editor.canvas.getActiveObject().flipY === true) {
                        editor.canvas.getActiveObject().set('flipY', false)
                        editor.canvas.renderAll();

                    }
                    else {
                        editor.canvas.getActiveObject().set('flipY', true)
                        editor.canvas.renderAll();
                    }
                }
            }

        }

        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);
            }
        }
        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div className="flipContainer">
                <FlipIcon className={anchorEl ? "toolbar-icons-active" : "toolbar-icons"} onClick={handleCloseAndOpen} />
                <Popover
                    id={id}
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="div-flipSection">
                        <Button color="white" className="btn" variant='outline' onClick={fliphorizontal}><ThreeSixtyIcon className="postition-icon" /> <span className="postition-icon-text"></span>Horizontal</Button>
                        <Button color="white" className="btn" variant='outline' onClick={flipvertical} ><ThreeSixtyIcon className="postition-icon " style={{ transform: "rotate(90deg)" }} /> <span>Vertical</span></Button>
                    </div>
                </Popover>
            </div>
        );
    }

    const Positionselction = ({ dragHandlers }) => {
        const alignpositionleft = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.getActiveObject().set('left', 0)
                    editor.canvas.renderAll()
                }
            }
        }
        const alignpositionright = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.getActiveObject().set('left', canvasWidth - (editor.canvas.getActiveObject().width * editor.canvas.getActiveObject().scaleX))
                    editor.canvas.renderAll()
                }
            }
        }
        const alignpositiontop = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.getActiveObject().set('top', 0)
                    editor.canvas.renderAll()
                }
            }
        }
        const alignpositionbottom = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.getActiveObject().set('top', canvasHeight - (editor.canvas.getActiveObject().height * editor.canvas.getActiveObject().scaleY))
                    editor.canvas.renderAll()
                }
            }
        }

        const alignpositionhorizontal = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.getActiveObject().set('left', (canvasWidth / 2) - ((editor.canvas.getActiveObject().width * editor.canvas.getActiveObject().scaleX) / 2))
                    editor.canvas.renderAll()
                }
            }
        }
        const alignpositionvertical = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.getActiveObject().set('top', (canvasHeight / 2) - ((editor.canvas.getActiveObject().height * editor.canvas.getActiveObject().scaleY) / 2))
                    editor.canvas.renderAll()
                }
            }
        }
        const alignpositionmiddle = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.getActiveObject().set('top', (canvasHeight / 2) - ((editor.canvas.getActiveObject().height * editor.canvas.getActiveObject().scaleY) / 2))
                    editor.canvas.getActiveObject().set('left', (canvasWidth / 2) - ((editor.canvas.getActiveObject().width * editor.canvas.getActiveObject().scaleX) / 2))
                    editor.canvas.renderAll()
                }
            }
        }

        const forward = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.bringForward(editor.canvas.getActiveObject())
                    editor.canvas.renderAll();
                }
            }
        }
        const backward = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.sendBackwards(editor.canvas.getActiveObject())
                    editor.canvas.renderAll();
                }
            }
        }
        const front = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.bringToFront(editor.canvas.getActiveObject())
                    editor.canvas.renderAll();
                }
            }
        }
    
        const back = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.sendToBack(editor.canvas.getActiveObject())
                    editor.canvas.renderAll();
                }
            }
        }





        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);
            }
        }
        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div className="positioncontainer">
                <Button aria-describedby={id} color="white" className="positionbtn" size="sm" variant="outline" onClick={handleCloseAndOpen}>
                    <PictureInPictureIcon className={anchorEl ? "toolbar-icons-active" : "toolbar-icons"} />
                </Button>
                <Draggable handle="strong" {...dragHandlers}>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className={classes.popover}
                        classes={{
                            paper: classes.popoverContent,
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className="div-positionsection  box no-cursor">
                            <strong className='cursor d-flex align-items-center  gap-2 mb-3'>
                                <div className="drag-me ">
                                    Drag
                                </div>
                                <CloseIcon className="close-icon-pop" onClick={handleClose} />

                            </strong>
                            <div className="fbposition">
                                <label className="postion-header">Layer</label>
                                <Button color="#fff" onClick={front} className="btn"><ShortcutIcon className="postition-icon" style={{ transform: 'rotate(270deg)' }} /> <span className="postition-icon-text">Front</span></Button>
                                <Button color="#fff" onClick={forward} className="btn"><ShortcutIcon className="postition-icon" style={{ transform: 'rotate(270deg)' }} /> <span className="postition-icon-text">Forward</span></Button>
                                <Button color="#fff" onClick={backward} className="btn"> <ShortcutIcon className="postition-icon" style={{ transform: 'rotate(90deg)' }} /> <span className="postition-icon-text">Backward</span></Button>
                                <Button color="#fff" onClick={back} className="btn"> <ShortcutIcon className="postition-icon" style={{ transform: 'rotate(90deg)' }} /> <span className="postition-icon-text">Back</span></Button>
                            </div>
                            <div className="alignposition">
                                <label className="postion-header">Position</label>
                                <Button className="btn " color="#fff" onClick={alignpositiontop} ><AlignVerticalTopIcon className="postition-icon" /> <span className="postition-icon-text">Top</span> </Button>
                                <Button className="btn" color="#fff" onClick={alignpositionbottom}><AlignVerticalBottomIcon className="postition-icon" /> <span className="postition-icon-text">Bottom</span></Button>
                                <Button className="btn" color="#fff" onClick={alignpositionleft} ><AlignHorizontalLeftIcon className="postition-icon" /> <span className="postition-icon-text">Left</span></Button>
                                <Button className=" btn" color="#fff" onClick={alignpositionright}><AlignHorizontalRightIcon className="postition-icon" />  <span className="postition-icon-text">Right</span></Button>
                                <Button className=" btn" color="#fff" onClick={alignpositionvertical} ><AlignHorizontalCenterIcon className="postition-icon" /> <span className="postition-icon-text">V-Center</span></Button>
                                <Button className=" btn" color="#fff" onClick={alignpositionhorizontal} ><AlignVerticalCenterIcon className="postition-icon" /> <span className="postition-icon-text">H-Center</span></Button>
                                {/* </div> */}
                                {/* <div className="alignpositionmiddleButton"> */}
                                <Button className="btn" color="#fff" onClick={alignpositionmiddle} ><VerticalAlignCenterIcon className="postition-icon" /><span className="postition-icon-text">Page Center</span></Button>
                            </div>
                        </div>
                    </Popover>
                </Draggable>
            </div>
        );
    }

    const Groupsection = () => {

        const group = () => {
            if (!editor.canvas.getActiveObject()) {
                return;
            }
            if (editor.canvas.getActiveObject().type !== 'activeSelection') {
                return;
            }
            editor.canvas.getActiveObject().toGroup();
            editor.canvas.requestRenderAll();
        }
        const ungroup = () => {
            if (!editor.canvas.getActiveObject()) {
                return;
            }
            if (editor.canvas.getActiveObject().type !== 'group') {
                return;
            }
            editor.canvas.getActiveObject().toActiveSelection();
            editor.canvas.requestRenderAll();
        }

        const [anchorEl, setAnchorEl] = React.useState(null);

        // const handleClick = (event) => {
        //     setAnchorEl(event.currentTarget);
        // };

        // const handleClose = () => {
        //     setAnchorEl(null);
        // };

        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);

            }
        }
        const handleClose = () => {
            setAnchorEl(null);
        };
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div className="groupcontainer" style={{ display: (editor?.canvas.getActiveObject()?.type == '') || (editor?.canvas.getActiveObject()?.type == 'path') || (editor?.canvas.getActiveObject()?.type == 'circle') || (editor?.canvas.getActiveObject()?.type == 'pologon') || (editor?.canvas.getActiveObject()?.type == 'rect') ? 'none' : 'block' }}>
                <Group className={anchorEl ? "toolbar-icons-active" : "toolbar-icons"} onClick={handleCloseAndOpen} />
                <Popover
                    id={id}
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="div-groupsection" >
                        <Button className="groupsection-btn" onClick={group} color="#fff"> <Group /> Group</Button>
                        <Button className="groupsection-btn" onClick={ungroup} color="#fff"><Ungroup />Ungroup</Button>
                    </div>
                </Popover>
            </div>
        );
    }

    const Opacity = () => {

        const [opacity, setOpacity] = useState(100)
        const opacityinputer = useRef(null)





        useEffect(() => {

            if (editor) {

                if (editor.canvas.getActiveObjects()) {
                    let temp = 0
                    editor.canvas.getActiveObjects().forEach((object) => {
                        if (object.opacity > temp) {
                            // console.log('object',object.opacity);
                            temp = object.opacity
                            // console.log('temp',temp);
                        }
                    })
                    // console.log('final temp',temp);
                    setOpacity(temp * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])


        // if(editor){
        //     if(editor.canvas.getActiveObject()){
        //         console.log(editor.canvas.getActiveObject().opacity);
        //         // setOpacity(editor.canvas.getActiveObject().opacity)
        //         editor.canvas.renderAll()
        //     }
        // }


        const changeopacityvalue = () => {
            setOpacity(opacityinputer.current.value)
        }


        const opacitytemp = useRef(true)
        useEffect(() => {
            if (opacitytemp.current) {
                opacitytemp.current = false
                return;
            }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    // editor.canvas.getActiveObject().set({opacity : opacity/100})
                    // editor.canvas.renderAll()

                    try {
                        editor.canvas.getActiveObject().set({ opacity: opacity / 100 })
                        editor.canvas.renderAll()
                        // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                        let activeObjects = editor.canvas.getActiveObjects();
                        if (activeObjects.length) {
                            activeObjects.forEach(function (object) {
                                object.set({ opacity: opacity / 100 })
                                editor.canvas.renderAll()
                            });
                        }
                    }
                    catch {
                    }
                }
            }
        })

        // ##########################skewx#########################################

        const [skewx, setSkewx] = useState(0)
        const skewxinputer = useRef(null)





        useEffect(() => {

            if (editor) {

                if (!editor.canvas.getActiveObjects()) {
                    return;
                }
                else {

                    if (editor.canvas.getActiveObject()) {
                        setSkewx(editor.canvas.getActiveObject().skewX)
                        editor.canvas.renderAll()
                    }
                }
            }
        }, [editor])


        // if(editor){
        //     if(editor.canvas.getActiveObject()){
        //         console.log(editor.canvas.getActiveObject().opacity);
        //         // setOpacity(editor.canvas.getActiveObject().opacity)
        //         editor.canvas.renderAll()
        //     }
        // }


        const changeskewxvalue = () => {
            setSkewx(skewxinputer.current.value)
        }


        const skewxtemp = useRef(true)
        useEffect(() => {
            if (skewxtemp.current) {
                skewxtemp.current = false
                return;
            }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    // editor.canvas.getActiveObject().set({opacity : opacity/100})
                    // editor.canvas.renderAll()

                    try {
                        editor.canvas.getActiveObject().set({ skewX: skewx })
                        editor.canvas.renderAll()
                        // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                        // let activeObjects = editor.canvas.getActiveObjects();
                        // if (activeObjects.length) {
                        //     activeObjects.forEach(function (object) {
                        //         object.set({ skewX: skewx })
                        //         editor.canvas.renderAll()
                        //     });
                        // }
                    }
                    catch {
                    }
                }
            }
        })
        // ##################################skewy#############################################

        const [skewy, setSkewy] = useState(0)
        const skewyinputer = useRef(null)





        useEffect(() => {

            if (editor) {

                if (!editor.canvas.getActiveObjects()) {
                    return;
                }
                else {
                    if (editor.canvas.getActiveObject()) {
                        setSkewy(editor.canvas.getActiveObject().skewY)
                        editor.canvas.renderAll()
                    }
                }
            }
        }, [editor])


        // if(editor){
        //     if(editor.canvas.getActiveObject()){
        //         console.log(editor.canvas.getActiveObject().opacity);
        //         // setOpacity(editor.canvas.getActiveObject().opacity)
        //         editor.canvas.renderAll()
        //     }
        // }


        const changeskewyvalue = () => {
            setSkewy(skewyinputer.current.value)
        }


        const skewytemp = useRef(true)
        useEffect(() => {
            if (skewytemp.current) {
                skewytemp.current = false
                return;
            }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    // editor.canvas.getActiveObject().set({opacity : opacity/100})
                    // editor.canvas.renderAll()

                    try {
                        editor.canvas.getActiveObject().set({ skewY: skewy })
                        editor.canvas.renderAll()
                        // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                        // let activeObjects = editor.canvas.getActiveObjects();
                        // if (activeObjects.length) {
                        //     activeObjects.forEach(function (object) {
                        //         object.set({ skewX: skewx })
                        //         editor.canvas.renderAll()
                        //     });
                        // }
                    }
                    catch {
                    }
                }
            }
        })


        const [anchorEl, setAnchorEl] = React.useState(null);

        // const handleClick = (event) => {
        //     setAnchorEl(event.currentTarget);
        // };

        // const handleClose = () => {
        //     setAnchorEl(null);
        // };

        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);

            }
        }

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div className="opacitycontainer">
                <OpacityIcon className={anchorEl ? "toolbar-icons-active" : "toolbar-icons"} onClick={handleCloseAndOpen} />
                <Popover
                    id={id}
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="div-opacitysection">
                        <label for="customRange1" className="form-label">Opacity</label>
                        <div className="opacity-inputflex">
                            <input type="range" className="form-range" value={opacity} min={0} max={100} ref={opacityinputer} onChange={() => {
                                changeopacityvalue();
                            }} id="customRange1" />
                            <span className='spanOpacity'>{opacity}</span>
                        </div>
                        <label for="customRange2" className="form-label">SkewX</label>
                        <div className="opacity-inputflex">
                            <input type="range" className="form-range" value={skewx} min={-25} max={25} ref={skewxinputer} onChange={() => {
                                changeskewxvalue();
                            }} id="customRange2" />
                            <span className='spanOpacity'>{skewx}</span>
                        </div>
                        <label for="customRange3" className="form-label">SkewY</label>
                        <div className="opacity-inputflex">
                            <input type="range" className="form-range" value={skewy} min={-25} max={25} ref={skewyinputer} onChange={() => {
                                changeskewyvalue();
                            }} id="customRange3" />
                            <span className='spanOpacity'>{skewy}</span>
                        </div>
                    </div>

                </Popover>
            </div>
        );
    }

    

    return(
        <div className="font-formater-clear-tools gap-2" style={{ visibility: editor?.canvas.getActiveObject() ? 'visible' : 'hidden', zIndex: 2 }}>
                                    <Flipselection editor={editor} fabric={fabric} />
                                    <Positionselction editor={editor} fabric={fabric} />
                                    <Groupsection editor={editor} fabric={fabric} />
                                    <Opacity editor={editor} fabric={fabric} />
                                    <LockOpenOutlinedIcon onClick={lockmovementActiveobject} className="toolbar-icons lockiconbtn" />
                                    <DeleteOutlinedIcon className="toolbar-icons delecticonbtn" onClick={clearAll} />
                                </div>
    )


}
export default GeneralProperties;