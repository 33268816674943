export const langFilter = [
 
    // {
    //     "label": "Afrikaans",
    //     "value": 1
    // },
    // {
    //     "label": "Albanian",
    //     "value": 2
    // },
    // {
    //     "label": "Amharic",
    //     "value": 3
    // },
    // {
    //     "label": "Arabic",
    //     "value": 4
    // },
    // {
    //     "label": "Armenian",
    //     "value": 5
    // },
    // {
    //     "label": "Azerbaijani",
    //     "value": 6
    // },
    // {
    //     "label": "Basque",
    //     "value": 7
    // },
    // {
    //     "label": "Belarusian",
    //     "value": 8
    // },
    // {
    //     "label": "Bosnian",
    //     "value": 9
    // },
    // {
    //     "label": "Bulgarian",
    //     "value": 10
    // },
    // {
    //     "label": "Catalan",
    //     "value": 11
    // },
    // {
    //     "label": "Croatian",
    //     "value": 13
    // },
    // {
    //     "label": "Czech",
    //     "value": 14
    // },
    // {
    //     "label": "Danish",
    //     "value": 15
    // },
    // {
    //     "label": "Dutch",
    //     "value": 16
    // },
    // {
    //     "label": "English",
    //     "value": 17
    // },
    // {
    //     "label": "Estonian",
    //     "value": 19
    // },
    // {
    //     "label": "Filipino",
    //     "value": 20
    // },
    // {
    //     "label": "Finnish",
    //     "value": 21
    // },
    // {
    //     "label": "French",
    //     "value": 22
    // },
    // {
    //     "label": "Georgian",
    //     "value": 25
    // },
    // {
    //     "label": "German",
    //     "value": 26
    // },
    // {
    //     "label": "Greek",
    //     "value": 27
    // },
    // {
    //     "label": "Gujarati",
    //     "value": 28
    // },
    // {
    //     "label": "Hausa",
    //     "value": 29
    // },
    // {
    //     "label": "Hawaiian",
    //     "value": 30
    // },
    // {
    //     "label": "Hebrew",
    //     "value": 31
    // },
    // {
    //     "label": "Hindi",
    //     "value": 32
    // },
    // {
    //     "label": "Hungarian",
    //     "value": 33
    // },
    // {
    //     "label": "Icelandic",
    //     "value": 34
    // },
    // {
    //     "label": "Igbo",
    //     "value": 35
    // },
    // {
    //     "label": "Indonesian",
    //     "value": 36
    // },
    // {
    //     "label": "Irish",
    //     "value": 37
    // },
    // {
    //     "label": "Italian",
    //     "value": 38
    // },
    // {
    //     "label": "Japanese",
    //     "value": 39
    // },
    // {
    //     "label": "Kannada",
    //     "value": 40
    // },
    // {
    //     "label": "Kazakh",
    //     "value": 41
    // },
    // {
    //     "label": "Khmer",
    //     "value": 42
    // },
    // {
    //     "label": "Kinyarwanda",
    //     "value": 43
    // },
    // {
    //     "label": "Korean",
    //     "value": 44
    // },
    // {
    //     "label": "Kyrgyz",
    //     "value": 45
    // },
    // {
    //     "label": "Lao",
    //     "value": 46
    // },
    // {
    //     "label": "Latvian",
    //     "value": 47
    // },
    // {
    //     "label": "Lithuanian",
    //     "value": 48
    // },
    // {
    //     "label": "Macedonian",
    //     "value": 50
    // },
    // {
    //     "label": "Malagasy",
    //     "value": 51
    // },
    // {
    //     "label": "Malay",
    //     "value": 52
    // },
    // {
    //     "label": "Malayalam",
    //     "value": 53
    // },
    // {
    //     "label": "Maltese",
    //     "value": 54
    // },
    // {
    //     "label": "Marathi",
    //     "value": 55
    // },
    // {
    //     "label": "Mongolian",
    //     "value": 56
    // },
    // {
    //     "label": "Myanmar (Burmese)",
    //     "value": 57
    // },
    // {
    //     "label": "Nepali",
    //     "value": 58
    // },
    // {
    //     "label": "Odia",
    //     "value": 59
    // },
    // {
    //     "label": "Pashto",
    //     "value": 60
    // },
    // {
    //     "label": "Polish",
    //     "value": 62
    // },
    // {
    //     "label": "Portuguese",
    //     "value": 63
    // },
    // {
    //     "label": "Punjabi",
    //     "value": 64
    // },
    // {
    //     "label": "Romanian",
    //     "value": 65
    // },
    // {
    //     "label": "Russian",
    //     "value": 66
    // },
    // {
    //     "label": "Serbian",
    //     "value": 67
    // },
    // {
    //     "label": "Shona",
    //     "value": 68
    // },
    // {
    //     "label": "Sinhala",
    //     "value": 69
    // },
    // {
    //     "label": "Slovak",
    //     "value": 70
    // },
    // {
    //     "label": "Slovenian",
    //     "value": 71
    // },
    // {
    //     "label": "Somali",
    //     "value": 72
    // },
    // {
    //     "label": "Spanish",
    //     "value": 73
    // },
    // {
    //     "label": "Swahili",
    //     "value": 74
    // },
    // {
    //     "label": "Swedish",
    //     "value": 75
    // },
    // {
    //     "label": "Tajik",
    //     "value": 76
    // },
    // {
    //     "label": "Tamil",
    //     "value": 77
    // },
    // {
    //     "label": "Tatar",
    //     "value": 78
    // },
    // {
    //     "label": "Telugu",
    //     "value": 79
    // },
    // {
    //     "label": "Thai",
    //     "value": 80
    // },
    // {
    //     "label": "Turkish",
    //     "value": 81
    // },
    // {
    //     "label": "Ukrainian",
    //     "value": 82
    // },
    // {
    //     "label": "Urdu",
    //     "value": 83
    // },
    // {
    //     "label": "Uyghur",
    //     "value": 84
    // },
    // {
    //     "label": "Uzbek",
    //     "value": 85
    // },
    // {
    //     "label": "Vietnamese",
    //     "value": 86
    // },
    // {
    //     "label": "Welsh",
    //     "value": 87
    // },
    // {
    //     "label": "Yiddish",
    //     "value": 88
    // },
    // {
    //     "label": "Yoruba",
    //     "value": 89
    // },
    // {
    //     "label": "Zulu",
    //     "value": 90
    // },
    // {
    //     "label": "Bengali",
    //     "value": 91
    // },
    // {
    //     "label": "Cebuano",
    //     "value": 92
    // },
    // {
    //     "label": "Haitian Creole",
    //     "value": 94
    // },
    // {
    //     "label": "Hmong",
    //     "value": 95
    // },
    // {
    //     "label": "Javanese",
    //     "value": 96
    // },
    // {
    //     "label": "Maori",
    //     "value": 98
    // },
    // {
    //     "label": "Norwegian",
    //     "value": 99
    // },
    // {
    //     "label": "Samoan",
    //     "value": 100
    // },
    // {
    //     "label": "Sindhi",
    //     "value": 101
    // },
    // {
    //     "label": "Sundanese",
    //     "value": 102
    // },
    // {
    //     "label": "Turkmen",
    //     "value": 103
    // },
    // {
    //     "label": "Xhosa",
    //     "value": 104
    // },
    // {
    //     "label": "Farsi / Persian",
    //     "value": 106
    // },
    // {
    //     "label": "Chinese (Traditional)",
    //     "value": 107
    // },
    // {
    //     "label": "Chinese (Simplified)",
    //     "value": 108
    // }
]