import squaresvg from '../../../assets/elements/squaresvg.svg';
import rectsvg from '../../../assets/elements/rectsvg.svg';
import circlesvg from '../../../assets/elements/circlesvg.svg';
import ovalSvg1 from '../../../assets/elements/ovalSvg1.svg';
import triangkesvg from '../../../assets/elements/triangkesvg.svg';
import invtriangkesvg from '../../../assets/elements/invtriangkesvg.svg';
import polygonsvg from '../../../assets/elements/polygonsvg.svg';
import starsvg from '../../../assets/elements/starsvg.svg';
import Draggable, { DraggableCore } from 'react-draggable';
import React, { useEffect, useLayoutEffect, useState, useRef ,useCallback} from "react";
import { Popover } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';


const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));


const Shapes = ({ editor,fabric }) => {

    const classes = useStyles();
    //draggable
    const [state, setState] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    });

    const onStart = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags + 1 });
    };
    const onStop = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags - 1 });
    };

    const dragHandlers = { onStart, onStop };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [squareid, setSquareid] = useState(1)

    const addSquare = () => {

        setSquareid(squareid + 1)

        var square = new fabric.Rect({
            id: 'square-' + squareid,
            left: 50,
            top: 50,
            width: 100,
            height: 100,
            fill: '#b7c1ac',
            minScaleLimit:0.1


        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }

    const [rectid, setRectid] = useState(1)

    const addRectangle = () => {

        setRectid(rectid + 1)

        var square = new fabric.Rect({
            id: "rectangle-" + rectid,
            left: 50,
            top: 50,
            width: 200,
            height: 100,
            fill: '#b7c1ac',
            minScaleLimit:0.1




        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }

    const [circleid, setCircleid] = useState(1)

    const addCircle = () => {

        setCircleid(circleid + 1)

        var square = new fabric.Circle({
            id: "circle-" + circleid,
            left: 50,
            top: 50,
            radius: 75,
            fill: '#b7c1ac',
            minScaleLimit:0.1


        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()



    }

    const [ovalid, setOvalid] = useState(1)

    const addOval = () => {
        setOvalid(ovalid + 1)
        var square = new fabric.Ellipse({
            id: "oval-" + ovalid,
            left: 50,
            top: 50,
            rx: 200,
            ry: 100,
            fill: '#b7c1ac',
            minScaleLimit:0.1


        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }

    const [triangleid, setTriangleid] = useState(1)

    const addTriangle = () => {

        setTriangleid(triangleid + 1)

        var square = new fabric.Triangle({
            id: 'triangle-' + triangleid,
            left: 50,
            top: 50,
            width: 100,
            height: 100,
            fill: '#b7c1ac',
            minScaleLimit:0.1


        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }
    const [itriangleid, setItriangleid] = useState(1)

    const addInvertedTriangle = () => {

        setItriangleid(itriangleid + 1)
        var square = new fabric.Triangle({
            id: "itriangle-" + itriangleid,
            left: 50,
            top: 50,
            flipY: true,
            width: 100,
            height: 100,
            fill: '#b7c1ac',
            minScaleLimit:0.1


        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }

    const [diamondid, setDiamondid] = useState(1)

    const addDaimond = () => {
        setDiamondid(diamondid + 1)
        var square = new fabric.Rect({
            id: "diamond-" + diamondid,
            left: 50,
            top: 50,
            width: 100,
            height: 100,
            angle: 45,
            fill: '#b7c1ac',
            minScaleLimit:0.1
            
            



        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }


    const [polygonid, setPolygonid] = useState(1)

    const addPoloygon = () => {
        setPolygonid(polygonid + 1)
        var emerald = [{ x: 850, y: 75 },
        { x: 958, y: 137.5 },
        { x: 958, y: 262.5 },
        { x: 850, y: 325 },
        { x: 742, y: 262.5 },
        { x: 742, y: 137.5 },
        ];


        var square = new fabric.Polygon(emerald, {
            id: 'polygon-' + polygonid,
            left: 50,
            top: 50,
            fill: '#b7c1ac',
            minScaleLimit:0.1


        });
        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()
    }


    const [starid, setStarid] = useState(1)

    const addStar = () => {

        setStarid(starid + 1)

        var star5 = [{ x: 350, y: 75 },
        { x: 380, y: 160 },
        { x: 470, y: 160 },
        { x: 400, y: 215 },
        { x: 423, y: 301 },
        { x: 350, y: 250 },
        { x: 277, y: 301 },
        { x: 303, y: 215 },
        { x: 231, y: 161 },
        { x: 321, y: 161 },];

        var square = new fabric.Polygon(star5, {
            id: 'star-' + starid,
            left: 50,
            top: 50,
            fill: '#b7c1ac',
            minScaleLimit:0.1

        });

        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }


    const [trapezoidid, setTrapezoidid] = useState(1)

    const addTrapezoid = () => {

        setTrapezoidid(trapezoidid + 1)

        var trapezoid = [{ x: -100, y: -50 }, { x: 100, y: -50 }, { x: 150, y: 50 }, { x: -150, y: 50 }];
        var square = new fabric.Polygon(trapezoid, {
            id: 'trapezoid-' + trapezoidid,
            left: 50,
            top: 50,
            fill: '#b7c1ac',
            minScaleLimit:0.1


        });

        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }


    const [fstar, setFstar] = useState(1)

    const addStar2 = () => {

        setFstar(fstar + 1)

        var star4 = [
            { x: 0, y: 0 },
            { x: 100, y: 50 },
            { x: 200, y: 0 },
            { x: 150, y: 100 },
            { x: 200, y: 200 },
            { x: 100, y: 150 },
            { x: 0, y: 200 },
            { x: 50, y: 100 },
            { x: 0, y: 0 }
        ];
        var square = new fabric.Polygon(star4, {
            id: 'Fstar-' + fstar,
            left: 50,
            top: 50,
            fill: '#b7c1ac',
            minScaleLimit:0.1

        });

        editor.canvas.add(square);
        editor.canvas.centerObject(square);

        editor.canvas.renderAll()


    }




    return (
        <div className="filter-container" >
            <button aria-describedby={id} className={anchorEl ? "toolbar-icons-active shapes-button " : "toolbar-icons shapes-button"} style={{ display: (editor?.canvas.getActiveObject()?.type == 'textbox') || (editor?.canvas.getActiveObject()?.type == 'images') ? 'none' : 'block' }} onClick={handleClick}>
                Shapes
            </button>
            <Draggable handle="strong" {...dragHandlers}>

                <Popover
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="shapes-div-wrap box no-cursor">
                        <strong className='cursor d-flex align-items-center  gap-2 mb-3'>
                            <div className="drag-me ">
                                Drag
                            </div>
                            <CloseIcon className="close-icon-pop" onClick={handleClose} />

                        </strong>

                        <div className="shapes-div">
                            <div className="shapes-gride-items" onClick={addSquare}>
                                <img id='shapes' src={squaresvg} onDragEnd={addSquare} />
                            </div>
                            <div className="shapes-gride-items" onClick={addRectangle}>
                                <img id='shapes' src={rectsvg} onDragEnd={addRectangle} />
                            </div>
                            <div className="shapes-gride-items" onClick={addCircle}>
                                <img id='shapes' src={circlesvg} onDragEnd={addCircle} />
                            </div>
                            <div className="shapes-gride-items" onClick={addOval}>
                                <img id='shapes' src={ovalSvg1} onDragEnd={addOval} />
                            </div>
                            <div className="shapes-gride-items" onClick={addTriangle}>
                                <img id='shapes' src={triangkesvg} onDragEnd={addTriangle} />
                            </div>
                            <div className="shapes-gride-items" onClick={addInvertedTriangle}>
                                <img id='shapes' src={invtriangkesvg} onDragEnd={addInvertedTriangle} />
                            </div>
                            <div className="shapes-gride-items" onClick={addPoloygon}>
                                <img id='poly-shapes' src={polygonsvg} onDragEnd={addPoloygon} />

                            </div>
                            <div className="shapes-gride-items" onClick={addStar}>
                                <img id='shapes' src={starsvg} onDragEnd={addStar} />

                            </div>
                            <div className="shapes-gride-items" onClick={addStar2}>
                                <div id='square'></div>
                            </div>
                            <div className="shapes-gride-items" onClick={addTrapezoid}>
                                <div id='square'></div>
                            </div>
                            <div className="shapes-gride-items" onClick={addDaimond}>
                                <div id='square'></div>
                            </div>
                            <div className="shapes-gride-items" >
                                <div id='square'></div>
                            </div>
                        </div>
                    </div>

                </Popover>
            </Draggable>

        </div>

    )


}

export default Shapes;