import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import Config from "../config/Config";
import Cookies from "js-cookie";
import axios from 'axios';

const Dashboard = ({setNavbar,id,isLoading}) => {
    // const [id, setId] = useState(8)
//   const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate();
    const [showLoginLoader, setShowLoginLoader] = useState(false);
  const [ token , setToken] = useState(null)


    // const getAssistant = async () => {
    //     Config.axios({
    //         url: Config.LABS_API + "openai/ai_assistant/",
    //         method: "GET",
    //         auth: true,
    //         success: (response) => {
    //             console.log(response)


    //         },
    //         error: (err) => {
    //             console.log(err)
    //             Config.toast('Something went wrong', 'error')
    //         },
    //     });
    // };


    // useEffect(() => {
    //     getAssistant()
    // },[])

    // useEffect(() => {
    //     if(id == 7){
    //         if(!window.location.pathname.includes('elearning')){
    //             navigate('/dashboard')
    //         }
    //     }
    // },[])



    // useEffect(() => {
    //     getUser()
    // },[])

    return (
        <React.Fragment>
            <section className="home-section-main-wrapper">
                <div className="container">
                    <div className="home-section-wrap">
                        <h1>Welcome to the Ailaysa Lab!</h1>
                        {/* <p>This is a graphical interface to easily try all our models without writing a single line of code. For more information about the API, please see the documentation, and if you're new please first register and get a free API token.</p> */}
                      {!isLoading &&   <div className="home-lab-services-row">
                            <div onClick={() => navigate("/elearning-general") } className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">E-learning General</p>
                            </div>
                            <div onClick={() => navigate("/elearning-question-based")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">E-learning Question based</p>
                            </div>
                             <div onClick={() => navigate("/elearning-knowledge-based")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">E-learning Book based</p>
                            </div>
                            {/* <div onClick={() => navigate("/elearning") } className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">E-learning</p>
                            </div> */}
                            {/* <div onClick={() => navigate("/testing")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">Testing</p>
                            </div>
                            <div onClick={() => navigate("/testing")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">Testing_V2</p>
                            </div> */}
                            {id != 7 && <>
                                {/* <div onClick={() => navigate("/elearning") } className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">E-learning</p>
                            </div> */}
                              <div onClick={() => navigate("/digitalization")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">Digitalization</p>
                            </div>
                             
                            <div onClick={() => navigate("/prompt")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">Prompt creation</p>
                            </div>
                            <div onClick={() => navigate("/meet")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">Meet</p>
                            </div>
                            <div onClick={() => navigate("/text-to-speech")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/text-to-speech.svg"} />
                                <p className="tile-text">Text to Speech</p>
                            </div>
                            <div onClick={() => navigate("/social-media-localization")}  className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/social-media-localization.svg"} />
                                <p className="tile-text">Social Media Localization</p>
                            </div>
                            <div onClick={() => navigate("/ai-writing")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/ai-writing.svg"} />
                                <p className="tile-text">AI Writing</p>
                            </div>
                            <div onClick={() => navigate("/ner-and-keyword")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/keyword.svg"} />
                                <p className="tile-text">NER and Keyword</p>
                            </div>
                            <div onClick={() => navigate("/social-post-localization")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/social-post-localization.svg"} />
                                <p className="tile-text">Image Localization</p>
                            </div>
                            <div onClick={() => navigate("/subtitle-localization")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/subtitle-localization.svg"} />
                                <p className="tile-text">Subtitle Localization</p>
                            </div>
                            <div onClick={() => navigate("/trans-generation")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/user-male-circle.svg"} />
                                <p className="tile-text">Trans-generation</p>
                            </div>
                            <div onClick={() => navigate("/canvas-Workspace")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/user-male-circle.svg"} />
                                <p className="tile-text">Canvas Workspace</p>
                            </div>
                            <div onClick={() => {
                                navigate("/ailaysa-canvas")
                                setNavbar(true)
                        }} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/user-male-circle.svg"} />
                                <p className="tile-text">Ailaysa Canvas</p>
                            </div>
                            <div className="tile-box-lists lock-wrapper">
                                <img src={window.location.origin + "/media/icons/multilingual-template.svg"} />
                                <p className="tile-text">Multilingual Templates</p>
                                <div className="lock-area">
                                    <LockIcon className="lock-icon" />
                                </div>
                            </div>
                            <div className="tile-box-lists lock-wrapper">
                                <img src={window.location.origin + "/media/icons/ailaysa-glossaries.svg"} />
                                <p className="tile-text">Ailaysa Glossaries</p>
                                <div className="lock-area">
                                    <LockIcon className="lock-icon" />
                                </div>
                            </div>
                            <div onClick={() => navigate("/convert")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/file-convertion.svg"} />
                                <p className="tile-text">File Convertion</p>
                            </div>
                            <div onClick={() => navigate("/inpaint")} className="tile-box-lists">
                                <img src={window.location.origin + "/media/icons/keyword.svg"} />
                                <p className="tile-text">Inpaint</p>
                            </div>
                            <div onClick={() => navigate("/designer-assets")} className="tile-box-lists">
                             <img src={window.location.origin + "/media/icons/social-post-localization.svg"} />
                                <p className="tile-text">Designer assets</p>
                            </div>
                            <div onClick={() => navigate("/transbuilder")} className="tile-box-lists">
                            <img src={window.location.origin + "/media/icons/ai-writing.svg"} />
                                <p className="tile-text">Transbuilder</p>
                            </div>
                            <div onClick={() => navigate("/pdf-chat")} className="tile-box-lists">
                            <img src={window.location.origin + "/media/icons/ai-writing.svg"} />
                                <p className="tile-text">PDF Chat</p>
                            </div>
                            </>}
                        </div>}
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Dashboard;
