import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ButtonBase from '@mui/material/ButtonBase';
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import Select, { components } from "react-select";
import Config from '../../config/Config';
import { debounce } from 'lodash';
import { Tooltip } from '@material-ui/core';


const drawerWidth = 400;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function TransbuilderComponent() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [ token , setToken] = useState(null)
  const [email, setEmail] = useState("ailaysademo@gmail.com");
  const [password, setPassword] = useState("Ai_mt#4321");
  const [pwdVisibility, setPwdVisibility] = useState(false);
  const [pwdSignVisibility, setPwdSignVisibility] = useState(false);

  const [showLoginLoader, setShowLoginLoader] = useState(false);
  const [show, setShow] = useState(false)
  const [ csvFile , setCsvFile] = useState(null)
 
 

  console.log(csvFile)
const postImage = () => {
  // setIsSaved(false)
 
  var formdata = new FormData();
  formdata.append("file", csvFile);


  Config.axios({
      url: Config.MT_URL + "/dataset/file-upload/",
      method: "POST",
      data: formdata,
      auth: true,
      success: (response) => {
        console.log(response)
      },
      error: (err) => {
          console.log(err)

          // Config.toast('Something went wrong', 'error')
      }
  })


}

const handleFileUpload = (e) => {
  console.log(e.target.files[0])
  setCsvFile(e.target.files[0])
}

const handleFormData = (formData) => {
  formData.append("email", email);
  formData.append("password", password);
  return formData;
};

  const handleSubmit = async(e) => {

    var requestOptions = {
      method: 'POST',
      body: handleFormData(new FormData()),
      redirect: 'follow',
     
    };
    setShowLoginLoader(true)
    let data = await fetch(Config.MT_URL + "/users/login/", requestOptions)
    if(data.status === 200){
        let response = await data.json()
        console.log(response.access_token)
        setToken(response.access_token)
        console.log(response)
        setTimeout(() => {
            setShowLoginLoader(false)
            // window.location.href = "/";
            navigate('/transbuilder/?step=1')
            setShow(true)
        }, 500);
    }else{
        setShowLoginLoader(false)
    }
};

useEffect(() => {
  
},[token])



  const handleSubmitFile = async(e) => {
    var formdata = new FormData();
  formdata.append("file", csvFile);
  var header = new Headers();
  header.append("Authorization", `Bearer ${token}`)


  var requestOptions = {
    method: 'POST',
    body: formdata,
    headers: header,
    redirect: 'follow',
   
  };
  setShowLoginLoader(true)
  let data = await fetch(Config.MT_URL + "/dataset/file-upload/", requestOptions)
  if(data.status === 200){
      // let response = await data.json()
      console.log(data)
      setTimeout(() => {
          setShowLoginLoader(false)
      navigate(`/transbuilder/?step=${parseInt(URL_SEARCH_PARAMS.get('step'))+1}`)
      }, 500);
  }else{
      setShowLoginLoader(false)
  }
 

  }


  const handlePwdVisibilityChange = () => {
    setPwdVisibility(!pwdVisibility);
  };









  useEffect(() => {
    if(window.location.pathname.includes('/transbuilder')){
      navigate('/transbuilder?step=1')
    }
  },[])





  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleTranslate = (e) => {
    console.log(document.querySelectorAll('.left-textarea')[e.currentTarget.dataset.id].value)

  }


  const handleConfirm = (e) => {
    console.log(document.querySelectorAll('.left-textarea')[e.currentTarget.dataset.id].value)
    console.log(document.querySelectorAll('.right-textarea')[e.currentTarget.dataset.id].value)

  }

  const backToAiCanvas = () => {
    navigate('/')
  }

  const customProjectTypeSelectStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      color: "#3C4043",
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "24px",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: "6px 0px 0px 0px",
      boxShadow: "0px 3px 6px #00000029",
      border: "1px solid #DADADA",
      borderRadius: "4px",

    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #CED4DA",
      borderLeft: "2px solid transparent",
      color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
      background: state.isSelected ? "#F4F5F7" : "#ffffff",
      display: "flex",
      marginBottom: "0.2rem",
      padding: "4px 6px",
      color: "#292929",
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "24px",
      "&:hover": {
        background: "#F4F5F7",
        borderLeft: "2px solid #0074D3",
        cursor: "pointer",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? "1px solid #0071D4" : "1px solid #ADB5BD",
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      transtion: 0.3,
      color: "#3C4043",
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "24px",
      boxShadow: 0,
      padding: "0px 10px",
      width: '100%',
      height: 40,
      "&:hover": {
        cursor: "pointer",
      },
    }),
    menuList: (base) => ({
      ...base,

      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1"
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888"
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555"
      }
    })
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <span id="triangle-up"></span> : <span id="triangle-down"></span>}

      </components.DropdownIndicator>
    );
  };
  const languages = [
    { label: 'English', value: 1 }, { label: 'Tamil', value: 2 }
  ]
  const [sourceLanguage, setSourceLanguage] = useState({ label: 'English', value: 1 })
  const [targetLanguage, setTargetLanguage] = useState({ label: 'Tamil', value: 2 })


  const handleTargetLanguage = (selected) => {
    setTargetLanguage(selected);
  }

  const handleSourceLanguage = (selected) => {
    setSourceLanguage(selected);
  }
  const URL_SEARCH_PARAMS = new URLSearchParams(window.location.search);

  const [translatedText, setTranslatedText] = useState('')
  const [text, setText] = useState('')

  const handleTranslateMt = async() => {
  setShowLoginLoader(true)

  var header = new Headers();
  let token = Config.userStateSub != null ? Config.userStateSub.token : "";
  header.append("Authorization", `Bearer ${token}`)


  var requestOptions = {
    method: 'GET',
    headers: header,
    redirect: 'follow',
   
  };
  let data = await fetch(Config.MT_URL + `/dataset/ailaysa-mt-en-ta?sentence=${text}`, requestOptions)
  if(data.status === 200){
      let response = await data.json()
      console.log(response.result)
      setTranslatedText(response.result)
  setShowLoginLoader(false)
     
  }else{
    setShowLoginLoader(false)

  }
 

  }
  const debouncedAPICall = debounce(handleTranslateMt, 1000); // Adjust the delay time as needed

  // Event handler for onChange
  const handleTextareaChange = (event) => {
    const newValue = event.target.value;
    
    // Call the debounced API function
    debouncedAPICall(newValue);
  };
  
  const handleUpadateText = (e) => {
    setText(e.target.value)
  }

  useEffect(() => {

  },[text])
 
  return (
    <div>

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <ButtonBase
              style={{ marginRight: '20px' }}
              color="inherit"
              onClick={backToAiCanvas}
            >
              <ArrowBackIosIcon />
            </ButtonBase>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
              Transbuilder
            </Typography>
            {show && <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={{ ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>}
          </Toolbar>
        </AppBar>
        <Main style={{ display: 'grid', height: 'calc(100vh - 64px)'}} open={open}>
         {/* {!show &&  <div className="login-bg" style={{ justifyContent: 'center' }}>
            <div className="login-form">
              <div className="form-area-wrapper img-swap-active-anim">

                <h1 className="title">Log in</h1>
                <div className="w-100">
                  <div className="ai-login-form-group">
                    <lable htmlFor="email-id">Email *</lable>
                    <input
                      type="email"
                      id="email-id"
                      className="ai-textbox"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="ai-login-form-group">
                    <div className="login-forget-pwd">
                      <lable htmlFor="password">Password *</lable>
                    </div>
                    <input
                      type={pwdVisibility ? "text" : "password"}
                      id="password"
                      className="ai-textbox"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="pwd-visibility-btn" onClick={handlePwdVisibilityChange}>
                      <img
                        src={
                          pwdVisibility
                            ? window.location.origin + "/media/images/auth/pwd_visibility.svg"
                            : window.location.origin + "/media/images/auth/pwd_visibility_off.svg"
                        }
                        alt="pwdvisibility"
                      />
                    </span>
                  </div>
                  <div className="ai-mt-2">
                    <button
                      type="submit"
                      onClick={(e) => {
                        handleSubmit(e)
                      }}
                      disabled={showLoginLoader ? true : false}
                      className="ai-login-submit-btn-base ai-login-submit-btn"
                    >
                      <span className="ai-login-submit-btn">
                        {showLoginLoader && (
                          <div className="save-btn-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                        {showLoginLoader ? "Logging in" : "Log in"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>} */}
          {/* {show && <DrawerHeader />} */}
          {<div className='segment-wrapper'>
            {/* <h1 style={{ textAlign: 'center', marginBottom: '50px'}}>Build your MT</h1> */}
          {/* {(URL_SEARCH_PARAMS.get('step') == 1) && 
            <>
            <div className="segment-view gap-2">
              <div class="mb-3">
            <label for="formFile" class="form-label">Upload Data</label>
           <input class="form-control" type="file" id="formFile"  accept=".csv"  onChange={(e) => handleFileUpload(e)} />
            </div>
            
            </div>
              <div className="segment-view gap-2 justify-content-center">
              <div className="">
                    <button
                      type="submit"
                      onClick={(e) => {
                        csvFile != null &&  handleSubmitFile(e)
                      }}
                      disabled={showLoginLoader ? true : false}
                      className="ai-login-submit-btn-base ai-login-submit-btn"
                    >
                      <span className="ai-login-submit-btn">
                        {showLoginLoader && (
                          <div className="save-btn-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                        {showLoginLoader ? "Uploading..." : "Upload"}
                      </span>
                    </button>
                  </div>
                </div>
                </>
            } */}
             {(show && URL_SEARCH_PARAMS.get('step') == 2) && 
            <>
            <div className="segment-view gap-2">
              <div class="mb-3 w-100">
            <label for="formFile" class="form-label" >Set project</label>
            <>
             <div className="segment-view gap-2">
              <div style={{ width: '50%' }}>
                <Select
                  styles={customProjectTypeSelectStyles}
                  options={languages}
                  value={sourceLanguage}
                  isSearchable={true}
                  onChange={handleSourceLanguage}
                  components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                />

              </div>
            
              <div style={{ width: '50%', position: 'relative' }}>
                <Select
                  styles={customProjectTypeSelectStyles}
                  options={languages}
                  value={targetLanguage}
                  isSearchable={true}
                  onChange={handleTargetLanguage}
                  components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                />
            
              </div>
            </div>
            </>
            </div>
            
            </div>
              <div className="segment-view gap-2 justify-content-center">
              <div className="">
                    <button
                      type="submit"
                      onClick={(e) => {
                        handleSubmitFile(e)
                      }}
                      disabled={showLoginLoader ? true : false}
                      className="ai-login-submit-btn-base ai-login-submit-btn"
                    >
                      <span className="ai-login-submit-btn">
                        {showLoginLoader && (
                          <div className="save-btn-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                        {showLoginLoader ? "Uploading..." : "Upload"}
                      </span>
                    </button>
                  </div>
                </div>
                </>
            }
            {( URL_SEARCH_PARAMS.get('step') == 1) && 
            <>
            <div className="segment-view gap-2">
              <div style={{ width: '50%' }}>
                <Select
                  styles={customProjectTypeSelectStyles}
                  options={languages}
                  value={sourceLanguage}
                  isSearchable={true}
                  isDisabled={true}
                  onChange={handleSourceLanguage}
                  components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                />

              </div>
              {/* <ButtonBase className='translate-button-for-segnment' data-id="0" onClick={(e) => {handleTranslate(e)}}>
                    <ArrowForwardIcon className='translate-icon-segment' />
                </ButtonBase> */}
              <div style={{ width: '50%', position: 'relative' }}>
                <Select
                  styles={customProjectTypeSelectStyles}
                  options={languages}
                  value={targetLanguage}
                  isSearchable={true}
                  isDisabled={true}
                  onChange={handleTargetLanguage}
                  components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                />
                {/* <ButtonBase className='confirm-button-for-segnment' data-id="0" onClick={(e) => {handleConfirm(e)}}>
                    <DoneIcon className='confirm-icon-segment' />
                  </ButtonBase> */}
              </div>
            </div>
            <div className="segment-view gap-2">
              <div style={{ width: '50%' }}>
                <textarea className="left-textarea" value={text} onChange={handleUpadateText}  placeholder='Source segment' disabled={showLoginLoader} />

              </div>
              <Tooltip title='Click to translate' >
              <ButtonBase className='translate-button-for-segnment' data-id="0" onClick={(e) => {handleTranslateMt()}}>
                    <ArrowForwardIcon className='translate-icon-segment' style={{ fill: '#FFFFFF'}} />
                </ButtonBase>
              </Tooltip>
              <div style={{ width: '50%', position: 'relative' }}>
                <textarea className="right-textarea" value={translatedText} placeholder='Translated segment' disabled/>
                {/* <ButtonBase className='confirm-button-for-segnment' data-id="0" onClick={(e) => {handleConfirm(e)}}>
                    <DoneIcon className='confirm-icon-segment' />
                  </ButtonBase> */}
              </div>
            </div>
            </>
            }
            
            
            {/* <div className="segment-view">
            <div style={{ width: '45%'}}>
                  <textarea className="left-textarea" placeholder='Source segment' />
                </div>
                <ButtonBase className='translate-button-for-segnment' data-id="1" onClick={(e) => {handleTranslate(e)}}>
                    <ArrowForwardIcon className='translate-icon-segment' />
                </ButtonBase>
                <div style={{ width: '45%', position: 'relative'}}>
                <textarea className="right-textarea" placeholder='Translated segment' />
                <ButtonBase className='confirm-button-for-segnment' data-id="1" onClick={(e) => {handleConfirm(e)}}>
                    <DoneIcon className='confirm-icon-segment' />
                  </ButtonBase>
                </div>
            </div> */}
          </div>}
        </Main>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
              Keywords
            </Typography>
          </DrawerHeader>
          <Divider />
          <List>
            <div style={{ textAlign: 'center' }}>
              None

            </div>
          </List>
        </Drawer>
      </Box>
    </div>

  );
}