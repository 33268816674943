import React, { useEffect, useState } from "react";
// import FileSaver from "file-saver";
import { EditorState, convertToRaw,ContentState  } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from "draftjs-to-html";
import 'draft-js/dist/Draft.css';



import VoiceEditor from "./VoiceEditor";
import { Toolbar } from "./Toolbar";
import ClearButton from "./ClearButton";



const DraftEditor = (props) => {

  let{speechSourceLanguageOption,setTranslateContent, setSpeechTargetLanguageOption, setTranslated, setAudioBlob} = props
  const [editorState, setEditorState] = useState(EditorState.createEmpty());



  
  const down = editorState.getCurrentContent().getPlainText()

  setTranslateContent(down);

  
  

  // const getHTMLFromState = () => {
  //   return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  // };

  // const handleSave = () => {
  //     const down = editorState.getCurrentContent().getPlainText()

  //     var blob = new Blob([down], {
  //       type: "text/plain;charset=utf-8"
  //     });
  //     FileSaver.saveAs(blob, "YourSpeech.txt");

  // };


 

  return (
    <div className="ai-text-translate-work-container">
      <Editor
        initialEditorState={editorState}
        wrapperClassName="editor-wrapper1 "
        editorClassName="editor"
        onEditorStateChange={setEditorState}
        toolbar={Toolbar}
        placeholder={"Type Your Text here..."}
        toolbarCustomButtons={[
            <VoiceEditor className="voice-button" speechSourceLanguageOption={speechSourceLanguageOption} />
          ,

          <ClearButton className ="clear-button" down={down}  />
         
        ]}
      />
      {/* <button className="btn btn-primary width" onClick={handleSave} type="button">Export Text</button> */}
      
    </div>
  );
};

export default DraftEditor;