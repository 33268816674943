import React, { useEffect, useState, useRef } from "react";
import Peer from 'peerjs';
import InputMuiComponent from "./InputMuiComponent";
import { ButtonBase } from "@mui/material";
import Config from "../config/Config";
import CallEndIcon from '@mui/icons-material/CallEnd';
import { fabric } from "fabric";
import { invokeSaveAsDialog } from 'recordrtc';
import RecordRTC from 'recordrtc';

const AilaysaMeet = () => {



    const [myId, setMyId] = useState('');
    const [friendId, setFriendId] = useState('');
    const [peer, setPeer] = useState({});
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [name, setName] = useState('')
    const [isCallActive, setIsCallActive] = useState(false)
    const myVideoRef = useRef(null);
    const friendVideoRef = useRef(null);
    var webcamEl = document.getElementById('webcam');
    const peerIntanceRef = useRef(null)

    useEffect(() => {
        const peerInstance = new Peer();
        peerIntanceRef.current = peerInstance
        console.log(friendVideoRef.current.srcObject)
        console.log(myVideoRef.current.srcObject)


        peerInstance.on('open', (id) => {
            setMyId(id);
            setPeer(peerInstance);
        });

        peerInstance.on('connection', (conn) => {
            conn.on('data', (data) => {
                setMessages((prevMessages) => [...prevMessages, data]);
            });
        });

        peerInstance.on('call', (call) => {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then((stream) => {
                    myVideoRef.current.srcObject = stream;
                  
                    myVideoRef.current.play();
                    call.answer(stream);
                    call.on('stream', (remoteStream) => {
                        friendVideoRef.current.srcObject = remoteStream;
                        friendVideoRef.current.play();
                        setIsCallActive(true)
                    });
                })
                .catch((err) => {
                    console.error('Error accessing media devices: ', err);
                });
        });

        return () => {
            peerInstance.destroy();
        };
    }, []);

    const send = (e) => {
        if (e.keyCode == 13) {
            const conn = peer.connect(friendId);
            conn.on('open', () => {
                const msgObj = {
                    senderName: name,
                    sender: myId,
                    message: message
                };
                conn.send(msgObj);
                setMessages((prevMessages) => [...prevMessages, msgObj]);
                setMessage('');
            });
        }

    };



    function record(length,stream) {
        var recorder = new window.MediaRecorder(stream);
    
        recorder.ondataavailable = function(event) {
            if (recorder.state == 'recording') {
                var blob = new window.Blob([event.data], {
                    type: 'audio/ogg'
                });
                console.log(blob)
                // use the created blob
    
                recorder.stop();
            }
        };
    
        recorder.onstop = function() {
            recorder = null;
        };
    
        recorder.start(length);
    }

    var webcam = new fabric.Image(webcamEl, {
        left: 0,
        top: 0,
        angle: 94.5,
        originX: 'center',
        originY: 'center',
        objectCaching: false,
        });
        var recorder
    

    const videoCall = () => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then((stream) => {
                myVideoRef.current.srcObject = stream;
                myVideoRef.current.play();
                setIsCallActive(true)
                const call = peer.call(friendId, stream);
           
                // call.on('stream', (remoteStream) => {
                //      recorder = new RecordRTCPromisesHandler(remoteStream, {
                //         type: 'video'
                //     });
                //     recorder.startRecording();
                //     friendVideoRef.current.srcObject = remoteStream;
                //     friendVideoRef.current.play();
                //     setIsCallActive(true)
                    
                // });
            })
            .catch((err) => {
                console.error('Error accessing media devices: ', err);
            });
    };

    console.log(messages)

    const leaveCall = async() => {
        setMessage([])
        setMessage('')
        setFriendId('')
		setIsCallActive(false)
		peerIntanceRef.current.destroy()
        myVideoRef.current.srcObject = null
        friendVideoRef.current.srcObject = null
        let blob = await recorder.getBlob();
        invokeSaveAsDialog(blob);

    }




  
  

  
    return (
        <React.Fragment>
            <section className="ac-section-wrapper">
                <div className="container-fluid" style={{ padding: 0 }}>
                    <div className="ac-wrap">
                        <div className="meet-total-wrapper">
                        <div className="meet-start-box">
                                    <div>
                                        <InputMuiComponent 
                                            customClass={`custom-input-box `}
                                            value={myId}
                                            onKeyUp={null}
                                            disable={true}
                                            autoFocus={false}
                                            autoComplete={false}
                                            required={false}
                                            type={'text'}
                                            label={'My id'}
                                        /> 
                                    </div>
                                    <div className="divider"></div>
                                    <InputMuiComponent 
                                            customClass={`custom-input-box `}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            onKeyUp={null}
                                            disable={isCallActive}
                                            autoFocus={false}
                                            autoComplete={false}
                                            required={false}
                                            type={'text'}
                                            label={'Name'}
                                        /> 
                                         <div className="divider"></div>
                                         <InputMuiComponent 
                                            customClass={`custom-input-box `}
                                            value={friendId}
                                            onChange={(e) => setFriendId(e.target.value)}
                                            onKeyUp={null}
                                            disable={isCallActive}
                                            autoFocus={false}
                                            autoComplete={false}
                                            required={false}
                                            type={'password'}
                                            label={'Friend-Id'}
                                        /> 
                                  
                                    {/* {friendVideoRef.current?.srcObject == null &&
                                    <button onClick={videoCall}>Call
                                    </button>
                                    } */}
                                    {!isCallActive  && <ButtonBase  onClick={videoCall}>
                                        <span className="main-btn">Call</span>
                                    </ButtonBase>}
                                </div>
                        <div className="meet-main-wrapper">
                            <div className="meet-screen-outer-wrap" >
                                <div className="meet-screen-wrapper">
                                    <div className="meet-stream">
                                        <video ref={myVideoRef} />
                                        {/* {isCallActive &&  <span  onClick={leaveCall} className="end-call">
                                            <CallEndIcon fontSize="large" style={{ color:'#FFFFFF'}} />    
                                        </span>} */}
                                    </div>
                                    <div className="divider"></div>
                                    <div className="meet-stream">
                                    
                                        <video ref={friendVideoRef} />
                                        {isCallActive && <span className="end-call" onClick={leaveCall}>
                                            <CallEndIcon fontSize="large" style={{ color:'#FFFFFF'}} />
                                        </span>}

                                    </div>
                                </div>
                                <div className="transcription">
                                    <p>

                                    </p>
                                </div>
                            </div>
                          
                           
                            <div className="divider"></div>
                            <div className="meet-chat">
                                <div className="chat-inner-wrap">
                                    <div className="chat-list">
                                        {messages.map((message, i) => (
                                             <div className='main-q-and-a-section question-section'>
                                             <span  className='avatar-icon avatar-outer-ring'>
                                               {message?.avatar != null ? (
                                                 <>
                                                   <img
                                                     className="img-align-radius"
                                                     src={
                                                        message?.avatar?.charAt(0) === "/"
                                                         ? Config?.BASE_URL + message?.avatar
                                                         : message?.avatar
                                                     }
                                                     alt="prof-pic"
                                                   />
                                                 </>
                                               ) : (
                                                 <>
                                                   <div className="ailays-wp-avatar ailays-wp-avatar-bg-blue-color" >
                                                   {message?.senderName == null  ? message?.senderName?.charAt(0)?.toUpperCase() : message?.senderName?.charAt(0)?.toUpperCase()}
                                                   </div>
                                                 </>
                                               )}
                                             </span>
                                             <div className='q-and-a-section'>
                                               <span className={`primary-text-color name-wrapper`}>
                                               {message?.senderName == null  ? message?.senderName?.split('@')[0] : message?.senderName}
                                                 </span>
                                               <span className={`primary-text-color  answer-wrapper`}>
                                                 {message?.message}
                                               </span>
                                             </div>
                                           </div>
                                        ))}
                                    </div>
                                    <video id="webcam"></video>
                                    <div className="chat-box">
                                        <input value={message}
                                            onChange={(e) => setMessage(e.target.value)} onKeyDown={send} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default AilaysaMeet;