import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFound from "../pages/PageNotFound";
import Dashboard from "../pages/Dashboard";
import TextToSpeech from "../pages/TextToSpeech";
import SocialMediaLocalisation from "../pages/SocialMediaLocalisation";
import AiWriting from "../pages/AiWriting";
import TransGeneration from "../pages/TransGeneration";
import NERKeyword from "../pages/NERKeyword";
import SocialPostLocalisation from "../pages/SocialPostLocalisation";
import SubtitleLocalization from "../pages/SubtitleLocalization";
import CanvasWorkspace from "../pages/CanvasWorkspace";
import MultilingualTemplates from "../pages/MultilingualTemplates";
import AilaysaGlossaries from "../pages/AilaysaGlossaries";
import Login from "../authentication/Login"
import Config from "../config/Config";
import AilaysaCanvas from "../pages/AilaysaCanvas";
import AilaysaCanvasWorkspace from "../components/ailaysa-canvas/AilaysaCanvasWorkspace";
import Convert from "../pages/Conver";
import { useState } from "react";
// import Inpaint from "../pages/Inpaint";
import DesignerAssets from "../pages/DesignerAssets";
import Transbuilder from "../pages/Transbuilder";
import PromptForm from "../pages/PromptForm";
import AilaysaMeet from "../pages/AilaysaMeet";
import Elearning from "../pages/Elearning";
import ElearningCopy from "../pages/ElearningCopy";
import ElearningversionTwo from "../pages/ElearningversionTwo";
import { useNavigate } from "react-router-dom";
import ElearningversionThree from "../pages/ElearningversionThree";
import ElearningversionGeneral from "../pages/ElearningversionGeneral";
import ElearningversionQuestion from "../pages/ElearningversionQuestion";
import New from "../pages/New";
import NewTwo from "../pages/NewTwo";
import Digitalization from "../pages/Digitalization";


const LabRoutes = ({lastUpdated,setLastUpdated,setDownloadLangwise,setDownloadProject,downloadProject,downloadLangwise,setPostEditAccess,handleCloseAndOpen,anchorEl,opens,ids,name,setName,setDownload,setNavbar,pop,setPop,switchWorkspace,setAilaysaWorkspace,setSwitchWorkspace,download}) => {

    const [jsdata, setJsdata] = useState(null)
    const [tLanguages, setTLanguages] = useState([]);
    const [result, setResult] = useState(null);
    const [sourceLang , setSourceLang] = useState()

    const navigate = useNavigate();

    const [data, setData] = useState([])

    console.log(data)

    const getDomain= async() => {
        Config.axios({
            url: `${Config.LABS_API}prompts/domains/`,
            auth: true,
            success: (response) => {
                console.log(response)
                setData(response.data)
            },
        });
    }

    useEffect(() => {
        getDomain()
    },[])

    const [id, setId] = useState(8)
    const [isLoading, setIsLoading] = useState(true)


    const getUser = async () => {
        setIsLoading(true)
        Config.axios({
            url: Config.LABS_API + "auth/dj-rest-auth/user/",
            method: "GET",
            auth: true,
            success: (response) => {
                console.log(response)
                setId(response?.data?.pk)
                setIsLoading(false)


            },
            error: (err) => {
                console.log(err)
                Config.toast('Something went wrong', 'error')
            },
        });
    };


    useEffect(() => {
        getUser()
    },[])



    return (
            <Routes>
                {/* Lab Service pages */}
                <Route path="/" element={<Dashboard setNavbar={setNavbar} id={id} isLoading={isLoading} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/testing" element={<New />} />
                {id != 7 && <><Route path="/text-to-speech" element={<TextToSpeech />} />
                <Route path="/social-media-localization" element={<SocialMediaLocalisation/>} />
                <Route path="/ai-writing" element={<AiWriting />} />
                <Route path="/trans-generation" element={<TransGeneration />} />
                <Route path="/ner-and-keyword" element={<NERKeyword />} />
                <Route path="/social-post-localization" element={<SocialPostLocalisation />} />
                <Route path="/subtitle-localization" element={<SubtitleLocalization />} />
                <Route path="/canvas-Workspace" element={<CanvasWorkspace />} />
                <Route path="/ailaysa-canvas" element={<AilaysaCanvas lastUpdated={lastUpdated} setLastUpdated={setLastUpdated} downloadProject={downloadProject} setDownloadProject={setDownloadProject} sourceLang={sourceLang} setPostEditAccess={setPostEditAccess} setSourceLang={setSourceLang} handleCloseAndOpen={handleCloseAndOpen} anchorEl={anchorEl} opens={opens} ids={ids} setName={setName} name={name} setDownload={setDownload} pop={pop} setPop={setPop} setResult={setResult} setJsdata={setJsdata} setTLanguages={setTLanguages} tLanguages={tLanguages} jsdata={jsdata} setNavbar={setNavbar} setAilaysaWorkspace={setAilaysaWorkspace} download={download} />} />
                <Route path="/ailaysa-canvas-workspace" element={<AilaysaCanvasWorkspace setDownloadLangwise={setDownloadLangwise} setDownloadProject={setDownloadProject} downloadLangwise={downloadLangwise} downloadProject={downloadProject} sourceLang={sourceLang} setPostEditAccess={setPostEditAccess} setSourceLang={setSourceLang} anchorEl={anchorEl} opens={opens} ids={ids} setSwitchWorkspace={setSwitchWorkspace} setName={setName}  pop={pop} result={result} setJsdata={setJsdata} tLanguages={tLanguages} jsdata={jsdata} setPop={setPop} setNavbar={setNavbar} switchWorkspace={switchWorkspace} setAilaysaWorkspace={setAilaysaWorkspace} download={download} setDownload={setDownload}  />} />
                {/* <Route path="/multilingual-templates" element={<MultilingualTemplates />} /> */}
                {/* <Route path="/ailaysa-glossaries" element={<AilaysaGlossaries />} /> */}
                {/* <Route path="/inpaint" element={<Inpaint />} /> */}
                <Route path="/convert" element={<Convert />} />
                {/* <Route path="/testing" element={<New />} />
                <Route path="/testing" element={<NewTwo />} /> */}
                <Route path="/meet" element={<AilaysaMeet />} />
                <Route path="/digitalization" element={<Digitalization />} />

                <Route path="/designer-assets" element={<DesignerAssets />} />
                <Route path="/transbuilder" element={<Transbuilder />} />
                <Route path="/prompt" element={<PromptForm data={data} getDomain={getDomain}/>} />
                <Route path="/prompt:category" element={<PromptForm data={data} getDomain={getDomain} />} /></>}
                <Route path="/elearning-general" element={<ElearningversionGeneral />} />
                <Route path="/elearning-knowledge-based" element={<ElearningversionThree />} />
                <Route path="/elearning-question-based" element={<ElearningversionQuestion />} />

                <Route path="/404" element={<NotFound />} />
                <Route path="/*" element={<Navigate to="/404" />} />
            </Routes>
    )
}

export default LabRoutes;
