import React, { useEffect, useState, useRef } from "react";
import Header from "../layouts/Header";
import Teacher from '../assets/photos/Teacher.svg'
import KeyboardVoiceOutlinedIcon from '@mui/icons-material/KeyboardVoiceOutlined';
import { Tooltip } from '@material-ui/core';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import StudentGirl from '../assets/photos/Student_girl.svg'
import StudentBoy from '../assets/photos/Student_boy.svg'
import environment from '../assets/photos/aienvironment.png'
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { marked } from 'marked';
import Config from "../config/Config";
import useRecorder from "./recorder-components/RecorderHooks"
import validator from 'validator';
import StopIcon from '@mui/icons-material/Stop';
import ButtonLoader from "./ButtonLoader";
import RecordRTC, { invokeSaveAsDialog } from 'recordrtc';
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import Select, { components } from 'react-select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from "react-router-dom";


const customAssignStyles = {
    placeholder: (provided, state) => ({
        ...provided,
        color: "#3C4043",
        fontFamily: "Roboto",
        fontSize: "15px",
        fontWeight: "500",
        lineHeight: "24px",
    }),
    menu: (provided, state) => ({
        ...provided,
        padding: "6px 0px",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #DADADA",
        borderRadius: "4px",
    }),
    option: (provided, state) => ({
        ...provided,
        borderBottom: "0px solid #CED4DA",
        borderLeft: "2px solid transparent",
        color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
        background: state.isSelected ? "#F4F5F7" : "#ffffff",
        padding: "2px 8px",
        color: "#3C4043",
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "24px",
        "&:hover": {
            background: "#F4F5F7",
            borderLeft: "2px solid #0074D3",
            cursor: "pointer",
        },
    }),
    control: (base, state) => ({
        ...base,
        border: `1px solid #C0C8CE`,
        borderRadius: 4,
        transtion: 0.3,
        color: state.isFocused ? "#0074D3" : "#3C4043",
        fontFamily: "Roboto",
        fontSize: "15px",
        fontWeight: "500",
        lineHeight: "24px",
        minWidth: "200px",
        minHeight: 44,
        padding: 0,
        height: state.isFocused ? 44 : 44,
        boxShadow: 0,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#EBEBEB",
            height: 44,
        },
    }),
};


const New = (props) => {




    const genderOptions = [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' }

    ]

    const navigate = useNavigate();


    const [gender, setGender] = useState(null)
    const [studentName, setStudentName] = useState('')

    const [isSubmitting, setIsSubmitting] = useState(false)


    const [isThreadCreating, setIsThreadCreating] = useState(false)
    const [showAlertModal, setShowAlertModal] = useState(false);

    const [threadId, setThreadId] = useState(null);
    const threadIdref = useRef(null)

    const [assistantId, setAssistantId] = useState(null);
    const assistantIdref = useRef(null)

    const [chatData, setChatData] = useState([])

    const [letsStart, setLetsStart] = useState(false)

    const [isListenting, setIsListening] = useState(false)

    const [teacher, setTeacher] = useState(null)
    const [student, setStudent] = useState(null)

    const [story, setStory] = useState(1)

    const [enable, setEnable] = useState(false)
    const [audio, setAudio] = useState(null)
    const [reset, setReset] = useState(false)
    const audioRef = useRef()
    const [isDone, setIsDone] = useState(false)

    const { recorderState, ...handlers } = useRecorder();
    const { audioUrl, audioFile, tempBlob } = recorderState;
    const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
    const { startRecording, saveRecording, cancelRecording } = handlers;
    const [trigger, setTrigger] = useState(1)


    const handleCloseALertModal = () => {
        setShowAlertModal(false);
    };

    const modaloption = {
        closeMaskOnClick: false,
        width: "auto",
        onClose: handleCloseALertModal,
    };

    const [recordedUrl, setRecordedUrl] = useState('');
    const [recorderBlob, setRecorderBlob] = useState('');

    const mediaStream = useRef(null);
    const mediaRecorder = useRef(null);
    const chunks = useRef([]);
    const [stream, setStream] = useState(null);

    const [blob, setBlob] = useState(null);

    const recorderRef = useRef(null);

    const handleRecording = async () => {
      // const cameraStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
  
      setStream(mediaStream);
      recorderRef.current = new RecordRTC(mediaStream, { type: 'audio' });
      recorderRef.current.startRecording();
    };

    const handleStop = () => {
        recorderRef.current.stopRecording(() => {
          setBlob(recorderRef.current.getBlob());
          speech2text(recorderRef.current.getBlob())
        });
      };

      const [audioAuth, setAudioAuth] = React.useState(false);

      // Ensure proper permission handling
      useEffect(() => {
        if (!audioAuth) {
          navigator.permissions.query({ name: "microphone" }).then(({ state }) => {
            if (state === "granted") {
                Config.showToast('granted','sucess')
                setAudioAuth(true)
            }
          });
        }
      }, []);


    
    const startRecordingNew = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia(
                { audio: true }
            );
            mediaStream.current = stream;
            mediaRecorder.current = new MediaRecorder(stream);
            mediaRecorder.current.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    chunks.current.push(e.data);
                }
            };
            mediaRecorder.current.onstop = () => {
                const recordedBlob = new Blob(
                    chunks.current, { type: 'audio/webm' }
                );
                speech2text(recordedBlob)
                const url = URL.createObjectURL(recordedBlob);
                setRecordedUrl(url);
                chunks.current = [];
            };
            mediaRecorder.current.start();
        } catch (error) {
            Config.showToast('Error accessing microphone:', error);
        }
    };
    const stopRecordingNew = () => {
        if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
            mediaRecorder.current.stop();
        }
        if (mediaStream.current) {
            mediaStream.current.getTracks().forEach((track) => {
                track.stop();
            });
        }
    };



    const speech2text = async (file) => {
        const formdata = new FormData();
        const blob = new Blob([file], { type: file.type })
        formdata.append("mp3_file", blob);

        Config.axios({
            url: Config.LABS_API + 'openai/t2s/',
            method: "POST",
            data: formdata,
            auth: true,
            success: async (response) => {

                cancelRecording()
                if (validator.isEmpty(response.data.result.transcripted_text)) {
                    Config.showToast('Please speak again. voice is not detected', 'warning')
                } else {
                    setStudent(response.data.result.transcripted_text)
                    setTeacher(null)
                    handleFetchAudio(response.data.result.transcripted_text)
                }

            },
            error: (err) => {
                cancelRecording()
                Config.showToast('Somthing went wrong', 'error')

            }
        })
    }





    // useEffect(() => {
    //     if (story == 1 && threadId != null) {
    //         setTimeout(() => {
    //             // setTeacher('How are you, கயல்?')
    //             // setAudio(oneAudio)
    //             handleFetchAudio('read and understand the uploaded document')
    //             setEnable(true)
    //         }, 1500);
    //     }
    // }, [story, threadId])



    const [isProcessing, setIsProcessing] = useState(false)


    const handleStopPlaying = () => {
        audioRef.current.pause()
        audioRef.current.currentTime = 0;

    }

    const handleClick = () => {
        setLetsStart(true)
        handleStopPlaying()
        if (!isListenting) {
            handleRecording()
            // startRecordingNew()
            setStudent(null)
            setIsProcessing(true)
            setTimeout(() => {
                setIsProcessing(false)
                setIsListening(true)
            }, 1000);
        } else {
            // saveRecording()
            // stopRecordingNew()
            handleStop()
            // recognition.stop();
            setIsProcessing(true)
            setTimeout(() => {
                setIsProcessing(false)
                setIsListening(false)
            }, 1000);


        }


    }

    useEffect(() => {
        if (recorderState?.tempBlob?.length != 0) {
            speech2text(recorderState.audioFile)
        }
    }, [recorderState])




    const handlePlay = () => {
        audioRef.current.play()
    }

    const handleSpeechEnded = () => {
        setIsDone(true)

    }


    const createThread = async () => {
        const formdata = new FormData();
        formdata.append("assist_id", 1);

        Config.axios({
            url: Config.LABS_API + 'openai/create_thread/',
            method: "POST",
            data: formdata,
            auth: true,
            success: async (response) => {

                console.log(response.data)
                setThreadId(response.data.id);
                threadIdref.current = response.data.id
                assistantIdref.current = response.data.assistant.id
                setAssistantId(response.data.assistant.id)
                setIsThreadCreating(false)
                setShowAlertModal(true)


            },
            error: (err) => {
                Config.showToast('Somthing went wrong', 'error')

            }
        })
    }

    useEffect(() => {
        createThread()
    }, [])

    async function markdownToString(mdString) {
        // Convert Markdown to HTML
        const htmlString = marked.parse(mdString);

        // Create a temporary element to hold the HTML content
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;

        // Extract text content from the HTML
        const textString = tempElement.textContent || tempElement.innerText;

        return textString.trim(); // Return the trimmed text
    }




    async function initialMessage(message) {
        const formdata = new FormData();
        formdata.append("message", message);
        formdata.append("thread_id", threadId);

        const response = await fetch(Config.LABS_API + 'openai/thread_message/', {
            method: 'POST',
            // headers: {
            //     'Content-Type': 'application/json'
            // },
            body: formdata
        });

        const data = await response.json();
        const html = await markdownToString(data.response)
        const txt = await markdownToString(data.response)
        // const aud = await fetchData(txt)

        return { html: html, voice: Config.LABS_API + data.mp3_file }

    }


    const handleFetchAudio = async (transcript) => {
        let data = await initialMessage(transcript)
        setTeacher(null)
        setAudio(data.voice)
        setTeacher(data.html)

    }


    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownIcon className="icon" />
            </components.DropdownIndicator>
        );
    };

    const handleStudentSubmit = async() => {
            setIsSubmitting(true)
            await handleFetchAudio(`start. student name is ${studentName}`)
            setEnable(true)
            setIsSubmitting(false)
            handleCloseALertModal()
                
    }

    const back = () => {
        navigate('/')
  
      }



    return (
        <React.Fragment>

        <section className="ac-section-wrapper shool-background" style={{ top: 0 }}>
            <Header />
            <audio ref={audioRef} id="audio_tag" autoplay="true" src={audio} onEnded={handleSpeechEnded} />
            <div className="main-speach-wrapper" style={{ gap: '100px', display: 'flex', alignItems: 'end', width: '100%', height: '100%', padding: '0 50px' }}>
                <div className="teacher-section">
                    <div className="teacher">
                        <img src={Teacher} />
                    </div>
                    {/* <div className="teacher-chat"> */}
                    <div className="teacher-conversation-wrapper" style={{ minWidth: '50%', marginTop: '100px' }}>
                        {teacher != null && <div style={{ position: 'relative' }} class="talk-bubble tri-right left-top teacher-chat">
                            <Tooltip placement='top' arrow title='Click to hear again!' >
                                <span className="speak-icon" onClick={handlePlay} >
                                    <VolumeUpIcon />
                                </span>
                            </Tooltip>
                            <div class="talktext">
                                <span dangerouslySetInnerHTML={{ __html: teacher }}>
                                    {/* {teacher} */}
                                </span>
                            </div>
                        </div>}
                    </div>

                    {/* </div> */}
                </div>
                <div className="student-section">

                    {student != null && <div style={{ maxWidth: '50%' }} className="student-chat  talk-bubble tri-right  right-top">
                        <div class="talktext">
                            <span>{student}</span>
                        </div>
                    </div>}
                    {gender != null && <div className="student">
                        <img src={gender?.value == 'Male' ? StudentBoy :  StudentGirl} />
                        <div className="chat-mic" onClick={handleClick}>
                            {!isListenting ? (<span className="listening">
                                {isProcessing ? <ButtonLoader color={"#FFFFFF"} /> : <KeyboardVoiceOutlinedIcon className="icon" />}
                            </span>) : (
                                <span className="listening">
                                    {isProcessing ? <ButtonLoader color={"#FFFFFF"} /> : <StopIcon className="icon" style={{
                                        color: '#FFFFFF'
                                    }} />}

                                </span>

                            )
                            }
                        </div>
                    </div>}
                </div>
            </div>
        </section>
        <Rodal
            visible={showAlertModal}
            {...modaloption}
            showCloseButton={true}
            onClose={back}
            className="ai-mark-confirm-box"
        >
            <div className="student-data-input-box">
                <span className="welcome">
                    Welcome
                </span>
                <div className="welcome-image">
                    <img src={environment} />
                </div>
                <div className="w-100">
                    <div className="student-name">
                    <input type="text" value={studentName} onChange={(e) => setStudentName(e.target.value)} placeholder="Your name" />
                    </div>
                    <div>
                        <Select
                            classNamePrefix="language-button"
                            styles={customAssignStyles}
                            isSearchable={false}
                            options={genderOptions}
                            hideSelectedOptions={false}
                            value={gender}
                            onChange={setGender}
                            placeholder="Gender"
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}

                        />
                    </div>
                </div>
                <button disabled={gender === null || studentName === ''} onClick={!isSubmitting && handleStudentSubmit}>
                    {isSubmitting && <ButtonLoader />}
                   <span style={{marginLeft: '5px'}}>Submit</span> 
                </button>
            </div>

        </Rodal>
    </React.Fragment>
    )
}
export default New;