import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';


function ToastComponent(props) {

    const{
        content,
        type
    } = props
  
    
    
    return (
      <div className={`toast-box ${type}`}>
        <span className='content'>{content}</span>
        <span onClick={() => {toast.dismiss();toast.clearWaitingQueue();}} className='toast-icon-wrapper'>
        <CloseIcon className='toast-icon' />
        </span>
      </div>
    )
  }
  
  export default ToastComponent
  