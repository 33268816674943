import Draggable, { DraggableCore } from 'react-draggable';
import React, { useEffect, useLayoutEffect, useState, useRef ,useCallback} from "react";
import { Popover } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import samp5 from '../../../assets/photos/samp5.jpg'
import greyscale from '../../../assets/photos/greyscale.png'
import sepia from '../../../assets/photos/sepia.png'
import blackandwhite from '../../../assets/photos/blackandwhite.png'
import brownie from '../../../assets/photos/brownie.png'
import kodachrome from '../../../assets/photos/kodachrome.png'
import technicolor from '../../../assets/photos/technicolor.png'
import poloriod from '../../../assets/photos/poloriod.png'
import vintage from '../../../assets/photos/vintage.png'
import closebutton from '../../../assets/icons/closebutton.svg'
import { fabric } from "fabric";



const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));

const ImageProperties = ({editor,fabric,setAsbackgroundIm}) => {
    const classes = useStyles();

    //draggable
    const [state, setState] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    });

    const onStart = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags + 1 });
    };
    const onStop = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags - 1 });
    };

    const dragHandlers = { onStart, onStop };

    //Image Filter

    const Filter = ({ dragHandlers,editor,fabric }) => {

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const grayscaleFilter = () => {
            var filter = new fabric.Image.filters.Grayscale();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();

            // editor.canvas.getActiveObject().filters.push('grayscale')
            // console.log(editor.canvas.getActiveObject());
            // editor.canvas.getActiveObject().app
            // // something.filters['grayscale']
            // editor.canvas.renderAll();
            //    console.log(something);
        }

        const removeFilters = () => {

            editor.canvas.getActiveObject().filters.length = 7;
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();


        }

        const sepiaFilter = () => {

            var filter = new fabric.Image.filters.Sepia();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const blackwhiteFilter = () => {

            var filter = new fabric.Image.filters.BlackWhite();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const brownieFilter = () => {

            var filter = new fabric.Image.filters.Brownie();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const vintageFilter = () => {

            var filter = new fabric.Image.filters.Vintage();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const kodachromeFilter = () => {

            var filter = new fabric.Image.filters.Kodachrome();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const technicolorFilter = () => {

            var filter = new fabric.Image.filters.Technicolor();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }

        const polaroidFilter = () => {

            var filter = new fabric.Image.filters.Polaroid();
            editor.canvas.getActiveObject().filters.push(filter);
            editor.canvas.getActiveObject().applyFilters();
            editor.canvas.renderAll();
        }




        return (
            <div className="filter-container" >
                <button aria-describedby={id} className="filters-button" onClick={handleClick}>
                    Filters
                </button>
                <Draggable handle="strong" {...dragHandlers}>

                    <Popover

                        id={id}
                        className={classes.popover}
                        classes={{
                            paper: classes.popoverContent,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className="filter-div-wrapper box no-cursor">
                            <strong className='cursor d-flex align-items-center  gap-2 mb-3'>
                                <div className="drag-me ">
                                    Drag
                                </div>
                                <CloseIcon className="close-icon-pop" onClick={handleClose} />

                            </strong>
                            <div className="filter-div">
                                <div className="filter-gride-items" onClick={removeFilters}>
                                    <img className="image-filter-samp" src={samp5} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">None</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={grayscaleFilter}>
                                    <img className="image-filter-samp" src={greyscale} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Greyscale</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={sepiaFilter}>
                                    <img className="image-filter-samp" src={sepia} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Sepia</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={blackwhiteFilter}>
                                    <img className="image-filter-samp" src={blackandwhite} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Black/White</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={brownieFilter}>
                                    <img className="image-filter-samp" src={brownie} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Brownie</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={vintageFilter}>
                                    <img className="image-filter-samp" src={vintage} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Vintage</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={kodachromeFilter}>
                                    <img className="image-filter-samp" src={kodachrome} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Kodachrome</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={technicolorFilter}>
                                    <img className="image-filter-samp" src={technicolor} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Technicolor</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={polaroidFilter}>
                                    <img className="image-filter-samp" src={poloriod} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Poloroid</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popover>
                </Draggable>
            </div>

        )


    }


    const Adjust = ({ dragHandlers }) => {

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const [brightness, setBrightness] = useState(0)
        const [contrast, setContrast] = useState(0)
        const [saturation, setSaturation] = useState(0)
        const [blur, setBlur] = useState(0)
        const [vibrance, setVibrance] = useState(0)
        const [noise, setNoise] = useState(0)
        const [hue, setHue] = useState(0)

        const [pixalate, setPixalate] = useState(0)






        const handleBrightness = (e) => {
            setBrightness(e.target.value);

        }

        const brightnessTemp = useRef(true)

        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setBrightness(editor.canvas.getActiveObject().filters[0].brightness * 200)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])


        useEffect(() => {

            if (brightnessTemp.current) {
                brightnessTemp.current = false;
                return;
            }



            if (editor.canvas.getActiveObject().type == 'image') {

                editor.canvas.getActiveObject().filters[0].brightness = brightness / 200
                editor.canvas.getActiveObject().applyFilters();
                editor.canvas.renderAll();
            }

        }, [brightness])




        const handleContrast = (e) => {
            setContrast(e.target.value);

        }

        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setContrast(editor.canvas.getActiveObject().filters[1].contrast * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])

        const contrastTemp = useRef(true)
        useEffect(() => {

            if (contrastTemp.current) {
                contrastTemp.current = false;
                return;
            }

            if (editor.canvas.getActiveObject().type == 'image') {
                editor.canvas.getActiveObject().filters[1].contrast = contrast / 100
                editor.canvas.getActiveObject().applyFilters();
                editor.canvas.renderAll();
            }

        }, [contrast])

        const handleSaturation = (e) => {
            setSaturation(e.target.value);

        }

        const saturationTemp = useRef(true)
        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setSaturation(editor.canvas.getActiveObject().filters[2].saturation * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])


        useEffect(() => {

            if (saturationTemp.current) {
                saturationTemp.current = false;
                return;
            }



            if (editor.canvas.getActiveObject().type == 'image') {

                editor.canvas.getActiveObject().filters[2].saturation = saturation / 100
                editor.canvas.getActiveObject().applyFilters();
                editor.canvas.renderAll();
            }

        }, [saturation])

        const handleBlur = (e) => {
            setBlur(e.target.value);

        }

        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setBlur(editor.canvas.getActiveObject().filters[3].blur * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])

        const blurTemp = useRef(true)
        useEffect(() => {

            if (blurTemp.current) {
                blurTemp.current = false;
                return;
            }



            if (editor.canvas.getActiveObject().type == 'image') {

                editor.canvas.getActiveObject().filters[3].blur = blur / 100
                editor.canvas.getActiveObject().applyFilters();
                editor.canvas.renderAll();
            }

        }, [blur])

        const handleVibrance = (e) => {
            setVibrance(e.target.value);

        }

        const vibranceTemp = useRef(true)

        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setVibrance(editor.canvas.getActiveObject().filters[4].vibrance * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])


        useEffect(() => {

            if (vibranceTemp.current) {
                vibranceTemp.current = false;
                return;
            }



            if (editor.canvas.getActiveObject().type == 'image') {

                editor.canvas.getActiveObject().filters[4].vibrance = vibrance / 100
                editor.canvas.getActiveObject().applyFilters();
                editor.canvas.renderAll();
            }

        }, [vibrance])

        const handleNoise = (e) => {
            setNoise(e.target.value);

        }

        const noiseTemp = useRef(true)

        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setNoise(editor.canvas.getActiveObject().filters[5].noise / 10)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])



        useEffect(() => {

            if (noiseTemp.current) {
                noiseTemp.current = false;
                return;
            }



            if (editor.canvas.getActiveObject().type == 'image') {

                editor.canvas.getActiveObject().filters[5].noise = noise * 10
                editor.canvas.getActiveObject().applyFilters();
                editor.canvas.renderAll();
            }

        }, [noise])

        // const handlePixalate = (e) => {
        //     setPixalate(e.target.value);

        // }

        // useEffect(()=>{
        //     // if(opacitytemp.current){
        //     //     opacitytemp.current = false
        //     //     return;
        //     // }
        //     if(editor){
        //         if(editor.canvas.getActiveObject()){
        //             setPixalate(editor.canvas.getActiveObject().filters[7].blocksize)
        //             editor.canvas.renderAll()

        //         }
        //     }
        // },[editor])


        // const PixalateTemp = useRef(true)
        // useEffect(() => {

        //     if(PixalateTemp.current){
        //         PixalateTemp.current = false;
        //         return;
        //     }



        //     if(editor.canvas.getActiveObject().type == 'image'){

        //         editor.canvas.getActiveObject().filters[7].blocksize = pixalate
        //         editor.canvas.getActiveObject().applyFilters();
        //         editor.canvas.renderAll();
        //     }

        // },[pixalate])

        const handleHueRotation = (e) => {
            setHue(e.target.value);

        }

        const hueTemp = useRef(true)

        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setHue(editor.canvas.getActiveObject().filters[6].rotation * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])



        useEffect(() => {

            if (hueTemp.current) {
                hueTemp.current = false;
                return;
            }



            if (editor.canvas.getActiveObject().type == 'image') {

                editor.canvas.getActiveObject().filters[6].rotation = hue / 100
                editor.canvas.getActiveObject().applyFilters();
                editor.canvas.renderAll();
            }

        }, [hue])




        return (

            <div className="adjust">
                <button aria-describedby={id} className="filters-button" onClick={handleClick} style={{ display: editor?.canvas.getActiveObject().type == 'image'? 'block' : 'none' }}>
                    Adjust
                </button>
                <Draggable handle="strong" {...dragHandlers}>
                    <Popover
                        className={classes.popover}
                        classes={{
                            paper: classes.popoverContent,
                        }}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >

                        <div className="adjust-div-wrapper box no-cursor">
                            <strong className='cursor d-flex align-items-center  gap-2 mb-3'>
                                <div className="drag-me ">
                                    Drag
                                </div>
                                <CloseIcon className="close-icon-pop" onClick={handleClose} />

                            </strong>
                            <div className="adjust-div">


                                <div>
                                    <label className="form-label adjust-headers">Brightness</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={brightness} min={-100} max={100} onChange={handleBrightness} id="customRange1" />
                                        <span className="input-value">{brightness}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Contrast</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={contrast} min={-100} max={100} onChange={handleContrast} id="customRange1" />
                                        <span className="input-value">{contrast}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Saturation</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={saturation} min={-100} max={100} onChange={handleSaturation} id="customRange1" />
                                        <span className="input-value">{saturation}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Vibrance</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={vibrance} min={0} max={100} onChange={handleVibrance} id="customRange1" />
                                        <span className="input-value">{vibrance}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Blur</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={blur} min={0} max={100} onChange={handleBlur} id="customRange1" />
                                        <span className="input-value">{blur}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Noise</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={noise} min={0} max={100} onChange={handleNoise} id="customRange1" />
                                        <span className="input-value">{noise}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Hue</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={hue} min={-100} max={100} onChange={handleHueRotation} id="customRange1" />
                                        <span className="input-value">{hue}</span>
                                    </div>
                                    {/* <label className="form-label adjust-headers">Pixalate</label>
                            <div className="d-flex align-items-center gap-2">
                                <input type="range" className="form-range slider-range" value={pixalate} min={1} max={10} onChange={handlePixalate} id="customRange1" />
                                <span className="input-value">{pixalate}</span>
                            </div> */}
                                </div>
                            </div>
                        </div>

                    </Popover>
                </Draggable>
            </div>

        )
    }


    const Crop = () => {
        const alignCenter = true;

        const [imageCrop , setImageCrop] = useState()
        const addInvisibleRect = () => {
            // var pattern = new fabric.Pattern({source: editor.canvas.getActiveObject(), offsetX: -20, offsetY: -50});
            // editor.canvas.getActiveObject().set({opacity: 0.5
            //     })
            let objid = editor.canvas.getActiveObject().id;
            let Imageobj = editor.canvas.getObjects().find(Imageobj => Imageobj.id == objid);
            setImageCrop(Imageobj)
            console.log(Imageobj);
            Imageobj.set({ opacity:0.5})
            var slot1 = new fabric.Rect({
                originX: 'left', 
                originY: 'top',
                left: Imageobj.left, 
                top: Imageobj.top,
                width: Imageobj.width*Imageobj.scaleX, 
                height: Imageobj.height*Imageobj.scaleY,
                // scaleY: editor.canvas.getActiveObject().scaleY,
                // scaleX: editor.canvas.getActiveObject().scaleX,
                fill: 'transparent',
                stroke: 'white',
                strokeWidth: 0,
                selectable: true,
                id: 'crop-box'

              });

              editor.canvas.add(slot1)
              editor.canvas.setActiveObject(slot1)
              editor.canvas.renderAll();


        }



        
        // const cropImage = () => {

        //     editor.canvas.getActiveObject().set({
        //         cropX : 100,
        //         cropY : 100,
        //         scaleX : 0.5,
        //         scaleY : 0.7,
        //         opacity: 1
        //     })
        //     editor.canvas.renderAll();

        // }

        let nowClip = {
            x: 0,
            y: 0
          }

          useEffect(() => {

            if(imageCrop != ''){
                console.log(imageCrop);
            }

          },[imageCrop])

          
// canvas.remove(obj);

        function clipImage () {

            console.log('clicked');
        //      let obj = editor.canvas.getObjects().find(obj => obj.id == 'crop-box');
        //   console.log(obj);
        let obj = editor.canvas.getObjects().find(obj => obj.id == 'crop-box');

            console.log('clip!')
            const newImgCrop = obj.getBoundingRect()
            console.log(newImgCrop)
            // editor.canvas.setWidth(newImgCrop.width)
            // editor.canvas.setHeight(newImgCrop.height)
            imageCrop.set({
              cropX: newImgCrop.left ,
              cropY: newImgCrop.top ,
              width: newImgCrop.width,
              height: newImgCrop.height,
            //   scaleY:  1,
                // scaleX:  1,
                opacity: 1,
            })  
            // 校正位置
            nowClip.x += newImgCrop.left
            nowClip.y += newImgCrop.top
          
          
            imageCrop.set({
              left: obj.left,
              top: obj.top,

            })
            imageCrop.setCoords()
            editor.canvas.renderAll()
            console.log(obj);
            editor.canvas.remove(obj)
            editor.canvas.renderAll()

          }

          






        
          

        return (
            <div>
                <button onClick={addInvisibleRect}>crop</button>
                <button onClick={clipImage}>Confirm</button>

            </div>
        )
    }


    return(
        <div className="d-flex align-items-center bg-white gap-2 more-tools">
                <Filter editor={editor} fabric={fabric} />
                <Adjust editor={editor} fabric={fabric} />
                {/* <Crop editor={editor} fabric={fabric} /> */}
                <button className='set-as-background-but toolbar-icons' onClick={setAsbackgroundIm}>Set as Background</button>
        </div>
    )
}


export default ImageProperties;