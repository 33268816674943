import AilaysaCanvasEditor from '../components/ailaysa-canvas/AilaysaCanvasEditor'
import React, { useEffect, useState, useRef } from "react";
import ailaysaCanvas from '../assets/styles/ailaysaCanvas.css'
import AilaysaCanvasLanguageSelection from '../components/ailaysa-canvas/AilaysaCanvasLanguageSelection';
import { motion, AnimatePresence } from "framer-motion";


const AilaysaCanvas = ({lastUpdated,setLastUpdated,setDownloadProject,downloadProject,result,sourceLang,setSourceLang,handleCloseAndOpen,anchorEl,opens,ids,name,setName,setDownload,download,pop,setPop,setNavbar,setAilaysaWorkspace,setResult,setMultilingualCanvasData,setJsdata,jsdata,setTLanguages,tLanguages}) => {
    const [canvas, setCanvas] = useState('');
    const [sendThumb , setSendThumb] = useState()
    const [sendHeight , setSendHeight] = useState()
    const [sendWidth , setSendWidth] = useState()

    const [translate, setTranslate] = useState('Ailaysacanvas')
    const [translatedCanvases, setTranslatedCanvases] = useState(false);

    useEffect(() => {   
        setNavbar(true);
        setAilaysaWorkspace(false);
    },[])

  

    return (
        <React.Fragment>
            <section className="ac-section-wrapper">
        <div className="container-fluid">
            <div className="ac-wrap">
            <AnimatePresence exitBeforeEnter>
                {translate === "Ailaysacanvas" && <AilaysaCanvasEditor lastUpdated={lastUpdated} setLastUpdated={setLastUpdated} setDownloadProject={setDownloadProject} downloadProject={downloadProject} setSendThumb={setSendThumb} setSendHeight={setSendHeight} setSendWidth={setSendWidth}  handleCloseAndOpen={handleCloseAndOpen} anchorEl={anchorEl} opens={opens} ids={ids} name={name} setName={setName} setDownload={setDownload} download={download} setTranslate={setTranslate} setCanvas={setCanvas} canvas={canvas} setJsdata={setJsdata} jsdata={jsdata} pop={pop} translatedCanvases={translatedCanvases} setPop={setPop} /> }
                {translate === "translateLanguage" && <AilaysaCanvasLanguageSelection result={result}name={name} sendThumb={sendThumb} sendHeight={sendHeight} sendWidth={sendWidth} sourceLang={sourceLang} setSourceLang={setSourceLang} setResult={setResult} setName={setName} setTranslate={setTranslate} canvas={canvas} setCanvas={setCanvas} jsdata={jsdata} setTranslatedCanvases={setTranslatedCanvases} setMultilingualCanvasData={setMultilingualCanvasData} setTLanguages={setTLanguages} tLanguages={tLanguages} /> }
            </AnimatePresence>
            </div>
        </div>
    </section>
        </React.Fragment>
    )
}
export default AilaysaCanvas;