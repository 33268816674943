import React, { useState, useEffect } from 'react';
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { languages } from './language';
import Config from "../../config/Config";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { ButtonBase } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import samp1 from '../social-post-localisation/images/samp1.jpg'
import samp2 from '../social-post-localisation/images/samp2.jpg'
import samp3 from '../social-post-localisation/images/samp3.jpg'
import samp4 from '../social-post-localisation/images/samp4.jpg'
import samp5 from '../social-post-localisation/images/samp5.jpg'
import samp6 from '../social-post-localisation/images/samp6.jpg'
import samp7 from '../social-post-localisation/images/samp7.jpg'
import samp8 from '../social-post-localisation/images/samp8.jpg'
import Modal from 'react-modal';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalic from '@mui/icons-material/FormatItalic';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel'





const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    height: '85%',
    width: '95%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },


}






const SocialPostCanvas = () => {

  const LABS_API = Config.LABS_API
  const [clicked, setCLicked] = useState('0')

  const [access, setAccess] = useState(false)
  const [accessMulti, setAccessMulti] = useState(false)


  //Text Color
  const [color, setColor] = useState("#000000");


  //translation Source and Target set
  const [targetLang, setTargetLang] = useState(0);

  // Dynamic Translation state variable
  const [dynamicTranslatedText, setDynamicTranslatedText] = useState('');

  // importing function from FabricJS
  const { editor, onReady } = useFabricJSEditor();


  const [translatedImg, setTranslatedImg] = useState('')
  const [nonTranslatedImg, setNonTranslatedImg] = useState('')


  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [bold, setBold] = useState(false)
  const [italic, setItalic] = useState(false)
  const [underline, setUnderline] = useState(false)

  // Add Text Box Function
  function addtext() {
    editor.canvas.add(new fabric.IText('Tap and Type', {
      left: 50,
      top: 100,
      fontFamily: 'Arial',
      fill: color,
      fontSize: '32',
      fontWeight: '500'
    }));

    setAccess(true);



  }
  const addBackground = (e) => {

    const image = e.target.files[0];
    fabric.Image.fromURL(URL.createObjectURL(image), (img) => {
      editor.canvas.setBackgroundImage(img);
      editor.canvas.renderAll();

    });

  };

  // Remove selected Object
  const removeObjectFromCanvas = () => {
    editor.canvas.remove(editor.canvas.getActiveObject());
  };


  // /Handle Color Change
  useEffect(() => {


    document.querySelector('[type="color"]').addEventListener('input', function (e) {
      setColor(this.value);

    })
  }, [color])


  const handleColorChange = () => {

    editor.canvas.getActiveObject().set("fill", color);
    editor.canvas.renderAll();

  }

  //Download Canva Image
  const downloadImage = () => {
    const ext = "png";
    const base64 = editor.canvas.toDataURL({
      format: ext,
      enableRetinaScaling: true
    });
    const link = document.createElement("a");
    link.href = base64;
    link.download = `PrimarCanvas.${ext}`;
    link.click();
  };


  //Download old img
  const transGenDownloadImage = () => {
    const ext = "png";
    const base64 = nonTranslatedImg
    const link = document.createElement("a");
    link.href = base64;
    link.download = `NonTranslatedCanvas.${ext}`;
    link.click();
  };

  //Download New img
  const transGenDownloadImage2 = () => {
    const ext = "png";
    const base64 = translatedImg
    const link = document.createElement("a");
    link.href = base64;
    link.download = `TranslatedCanvas.${ext}`;
    link.click();
  };


  const handleAuthTrue = (authentication) => {
    let token = Config.userState != null ? Config.userState.token : "";
    authentication.append("Authorization", `Bearer ${token}`)
    return authentication;
  }


  //Google API call

  useEffect(() => {

    if (clicked == '1') {
      (async function () {
        var formdata = new FormData();
        formdata.append("Input", editor.canvas.getActiveObject().text);
        formdata.append("target_language", targetLang);

        var requestOptions = {
          method: 'POST',
          headers: handleAuthTrue(new Headers()),
          body: formdata,
          redirect: 'follow'
        };

        let data = await fetch(LABS_API + "/voice/translate/", requestOptions)
        if (data.status === 200) {
          let response = await data.json()
          setDynamicTranslatedText(response.text)
          setCLicked('0')

        }
        else {
          console.log('error');
        }
      })();
    }
  }, [clicked == '1']);



  const transGenerateText = () => {

    let image = new Image();
    image.crossOrigin = "anonymous";
    image.src = editor.canvas.toDataURL();
    setNonTranslatedImg(editor.canvas.toDataURL());

    editor.canvas.getActiveObject().set({ text: dynamicTranslatedText })
    editor.canvas.renderAll();

    setAccessMulti(true);
    setDynamicTranslatedText('')
    setTargetLang(0)
  };


  //Set target language and api call
  const handleChange = (e) => {
    setTargetLang(e.target.value);
    setCLicked('1');


  };




  const display = () => {

    let image = new Image();
    image.crossOrigin = "anonymous";
    image.src = editor.canvas.toDataURL();
    setTranslatedImg(editor.canvas.toDataURL());
    openModal()
  }



  //delete selected object using delete key

  useEffect(() => {
    window.addEventListener('keydown', e => {
      if (e.key === 'Delete') {

        try {
          editor.canvas.remove(editor.canvas.getActiveObject())

        }
        catch {
        }
      }

    })
  });



  (function () {
    if (typeof EventTarget !== 'undefined') {
      let supportsPassive = false;
      try {
        const opts = Object.defineProperty({}, 'passive', {
          get: () => {
            supportsPassive = true;
          },
        });
        window.addEventListener('testPassive', null, opts);
        window.removeEventListener('testPassive', null, opts);
      } catch (e) { }
      const func = EventTarget.prototype.addEventListener;
      EventTarget.prototype.addEventListener = function (type, fn) {
        this.func = func;
        this.func(type, fn, supportsPassive ? { passive: false } : false);
      };
    }
  })();

  //Text align 
  const alignCenter = () => {

    editor.canvas.getActiveObject().textAlign = "center";
    editor.canvas.renderAll();


  }

  const alignLeft = () => {

    editor.canvas.getActiveObject().textAlign = "left";
    editor.canvas.renderAll();


  }

  const alignRight = () => {

    editor.canvas.getActiveObject().textAlign = "right";
    editor.canvas.renderAll();


  }
  const hiddenFileInput = React.useRef(null);
  const hiddenColorInput = React.useRef(null);


  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleClickTwo = (event) => {
    hiddenColorInput.current.click();
  };


  //Functions for Adding Template Images in Canvas
  const onAddImage1 = () => {
    fabric.Image.fromURL(
      samp1,
      function (oImg) {
        editor.canvas.setBackgroundImage(oImg);
        editor.canvas.renderAll();

      }
    );
  };

  const onAddImage2 = () => {
    fabric.Image.fromURL(
      samp2,
      function (oImg) {
        editor.canvas.setBackgroundImage(oImg);
        editor.canvas.renderAll();

      }
    );
  };

  const onAddImage3 = () => {
    fabric.Image.fromURL(
      samp3,
      function (oImg) {
        editor.canvas.setBackgroundImage(oImg);
        editor.canvas.renderAll();

      }
    );
  };

  const onAddImage4 = () => {
    fabric.Image.fromURL(
      samp4,
      function (oImg) {
        editor.canvas.setBackgroundImage(oImg);
        editor.canvas.renderAll();

      }
    );
  };

  const onAddImage5 = () => {
    fabric.Image.fromURL(
      samp5,
      function (oImg) {
        editor.canvas.setBackgroundImage(oImg);
        editor.canvas.renderAll();

      }
    );
  };

  const onAddImage6 = () => {
    fabric.Image.fromURL(
      samp6,
      function (oImg) {
        editor.canvas.setBackgroundImage(oImg);
        editor.canvas.renderAll();

      }
    );
  };

  const onAddImage7 = () => {
    fabric.Image.fromURL(
      samp7,
      function (oImg) {
        editor.canvas.setBackgroundImage(oImg);
        editor.canvas.renderAll();

      }
    );
  };

  const onAddImage8 = () => {
    fabric.Image.fromURL(
      samp8,
      function (oImg) {
        editor.canvas.setBackgroundImage(oImg);
        editor.canvas.renderAll();

      }
    );
  };





  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }




  const onBold = () => {

    editor.canvas.getActiveObject().set("fontWeight", "bold");
    editor.canvas.renderAll();

  }

  const unBold = () => {

    editor.canvas.getActiveObject().set("fontWeight", "");
    editor.canvas.renderAll();
  }


  const handleBold = () => {
    if (!bold) {
      onBold();
      setBold(true);

    } else {
      unBold();
      setBold(false);
    }
  };

  const onItalic = () => {

    editor.canvas.getActiveObject().set("fontStyle", "italic");
    editor.canvas.renderAll();

  }

  const unItalic = () => {

    editor.canvas.getActiveObject().set("fontStyle", "");
    editor.canvas.renderAll();
  }


  const handleItalic = () => {
    if (!italic) {
      onItalic();
      setItalic(true);

    } else {
      setItalic(false);
      unItalic();

    }
  };

  const onUnderline = () => {

    editor.canvas.getActiveObject().set("underline", true);
    editor.canvas.renderAll();

  }

  const unUnderline = () => {

    editor.canvas.getActiveObject().set("underline", false);
    editor.canvas.renderAll();
  }


  const handleUnderline = () => {
    if (!underline) {
      onUnderline();
      setUnderline(true);

    } else {
      unUnderline();
      setUnderline(false);
    }
  };


  const clearAll = () => {

    editor.canvas.clear();
    setAccess(false)
    setDynamicTranslatedText('');
    setTargetLang(0);
    setTranslatedImg('');
    setNonTranslatedImg('');

  }


  return (
    <React.Fragment>
      <div className="spl-section-wrap">
        <div className="row spl-inner-wrap">
          <div className="row justify-content-center align-items-center">
            <div className='col-3 spl-templates-wrapper'>
              {/* <h5>Templates</h5> */}
              <div className="row justify-content-center gap-3">
                <div className="col-4">
                  <img src={samp1} className="size-img" onClick={onAddImage1} alt=""></img>
                </div>
                <div className="col-4">
                  <img src={samp2} className="size-img" onClick={onAddImage2} alt=""></img>
                </div>
                <div className="w-100"></div>
                <div className="col-4">
                  <img src={samp3} className="size-img" onClick={onAddImage3} alt=""></img>
                </div>
                <div className="col-4">
                  <img src={samp4} className="size-img" onClick={onAddImage4} alt=""></img>
                </div>
                <div className="w-100"></div>
                <div className="col-4">
                  <img src={samp5} className="size-img" onClick={onAddImage5} alt=""></img>
                </div>
                <div className="col-4">
                  <img src={samp6} className="size-img" onClick={onAddImage6} alt=""></img>
                </div>
                <div className="w-100"></div>
                <div className="col-4">
                  <img src={samp7} className="size-img" onClick={onAddImage7} alt=""></img>
                </div>
                <div className="col-4">
                  <img src={samp8} className="size-img" onClick={onAddImage8} alt=""></img>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex align-items-center justify-content-center mb-2 canvas-toolbar-wrapper">
              <ButtonBase className="canvas-language-target-but-wrap" disabled={access && editor.canvas.getActiveObject() ? false : true}>
              <FormControl sx={{ m: 0, minWidth: 170 }} size="small">
                <InputLabel id="demo-simple-select-helper-label">Target Language</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={targetLang}
                  label="Target Language"
                  onChange={handleChange}
                >
                  
                  {languages.map((lang) => (
                      <MenuItem value={lang.language} key={lang.code}>
                        {lang.language}
                      </MenuItem>
                    ))}
                  
                </Select>

        </FormControl>
        </ButtonBase>
                {/* <ButtonBase className="canvas-language-target-but-wrap" disabled={access && editor.canvas.getActiveObject() ? false : true}>
                  <select
                    className="camvas-language-button"
                    name="language"
                    id="language"
                    value={targetLang}
                    onChange={handleChange}>
                    <option>Target Language</option>
                    {languages.map((lang) => (
                      <option value={lang.language} key={lang.code}>
                        {lang.language}
                      </option>
                    ))}
                  </select>
                </ButtonBase> */}
                  <ButtonBase onClick={handleClick}>
                  <Tooltip TransitionComponent={Zoom} title="Image Upload" placement="top">
                    <UploadFileIcon className="icon" />
                    </Tooltip>
                  </ButtonBase>
                  <input id="file-input" type="file" multiple onChange={addBackground} ref={hiddenFileInput} style={{ display: 'none' }} />
                <ButtonBase onClick={addtext}>
                  <Tooltip TransitionComponent={Zoom} title="Add Text" placement="top">
                    <TextFieldsIcon className="icon" />
                  </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={handleClickTwo}>
                <Tooltip TransitionComponent={Zoom} title="Color" placement="top">
                  <FormatColorTextIcon className="icon" />
                  </Tooltip>
                </ButtonBase>
                <input id="color-input" type="color" onChange={handleColorChange} ref={hiddenColorInput} style={{ visibility: 'block' }} />
                <ButtonBase onClick={alignLeft}>
                  <Tooltip TransitionComponent={Zoom} title="Align left" placement="top">
                    <FormatAlignLeftIcon className="icon" />
                  </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={alignCenter}>
                <Tooltip TransitionComponent={Zoom} title="Align center" placement="top">
                  <FormatAlignCenterIcon className="icon" />
                  </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={alignRight}>
                <Tooltip TransitionComponent={Zoom} title="Align right" placement="top">
                  <FormatAlignRightIcon className="icon" />
                </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={handleBold}>
                <Tooltip TransitionComponent={Zoom} title="Bold" placement="top">
                  <FormatBoldIcon className="icon" />
                </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={handleItalic}>
                <Tooltip TransitionComponent={Zoom} title="Italic" placement="top">
                  <FormatItalic className="icon" />
                  </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={handleUnderline}>
                <Tooltip TransitionComponent={Zoom} title="Underline" placement="top">

                  <FormatUnderlinedIcon className="icon" />
                </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={removeObjectFromCanvas}>
                <Tooltip TransitionComponent={Zoom} title="Remove selection" placement="top">
                  <ClearIcon className="icon" />
                </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={downloadImage}>
                <Tooltip TransitionComponent={Zoom} title="Export" placement="top">
                  <FileDownloadIcon className="icon" />
                  </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={clearAll}>
                <Tooltip TransitionComponent={Zoom} title="Clear" placement="top">
                  <DeleteIcon className="icon" />
                </Tooltip>
                </ButtonBase>
                <ButtonBase className="canvas-translate-button" onClick={transGenerateText} disabled={dynamicTranslatedText && editor.canvas.getActiveObject() ? false : true}>
                  <span className="txt">Translate</span>
                </ButtonBase>
              </div>
              <div>
                <FabricJSCanvas className="canvas" onReady={onReady} />
              </div>
              <div className="canvas-modal-wrap">
                <ButtonBase type="button" className="canvas-Preview-button" onClick={display} disabled={accessMulti && editor.canvas.getActiveObject() ? false : true}>
                  <span className="txt">Multilingual Images</span>
                </ButtonBase>
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Example Modal">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>multilingual Images</h5>
                    <ButtonBase onClick={closeModal}>
                      <CloseIcon />
                    </ButtonBase>
                  </div>
                  <div className='row justify-content-center gap-5 mt-4'>
                    <div className='col-6' style={{ width: '800px', height: '600px' , boxShadow: '0px 1px 1px #b1b9c5' , border: '1px solid #DCE0E6', borderRadius: '5px', padding: '0' }}>
                      <img className='transgenerated-image' src={nonTranslatedImg} id='myImg' alt="" />
                      <div className="d-flex justify-content-center mt-2">
                        <ButtonBase className="canvas-translate-button" onClick={transGenDownloadImage} style={{ backgroundColor: '#0078D4', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '30px', paddingRight: '30px' }}>
                          <span className="txt" style={{ color: '#FFFFFF', fontSize: '15px', fontWeight: '500', fontStyle: 'normal' }}>Export</span>
                        </ButtonBase>
                      </div>
                    </div>
                    <div className='col-6' style={{ width: '800px', height: '600px' , boxShadow: '0px 1px 1px #b1b9c5' , border: '1px solid #DCE0E6', borderRadius: '5px', padding: '0'}}>
                      <img className='transgenerated-image' src={translatedImg} id='myImg2' alt="" />
                      <div className="d-flex justify-content-center mt-2">
                        <ButtonBase className="canvas-translate-button d-flex" onClick={transGenDownloadImage2} style={{ backgroundColor: '#0078D4', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '30px', paddingRight: '30px' }}>
                          <span className="txt" style={{ color: '#FFFFFF', fontSize: '15px', fontWeight: '500', fontStyle: 'normal' }}>Export</span>
                        </ButtonBase>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  )

}

export default SocialPostCanvas;