import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import { ButtonBase, Grid } from "@mui/material";
import BrushIcon from '@mui/icons-material/Brush';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import elements from '../../assets/icons/elements.svg'
import photos from '../../assets/icons/photos.svg'
import template from '../../assets/icons/template.svg'
import text from '../../assets/icons/text.svg'
import upload from '../../assets/icons/uploads.svg'
import singleView from '../../assets/icons/singleView.svg'
import doubleViewVertical from '../../assets/icons/doubleViewVertical.svg'
import { fabric } from "fabric";
import { Popover } from "@mui/material";
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import CircleIcon from '@mui/icons-material/Circle';
import Config from "../../config/Config";
import Shapes from './ailaysaCanvasComponents/Shapes'
import ElementsProperties from "./ailaysaCanvasComponents/ElementsProperties";
import ImageProperties from "./ailaysaCanvasComponents/ImageProperties";
import BackgroundProperties from "./ailaysaCanvasComponents/BackgroundProperties";
import GeneralProperties from "./ailaysaCanvasComponents/GeneralProperties";
import TextProperties from "./ailaysaCanvasComponents/TextProperties";
import ColorizeIcon from '@mui/icons-material/Colorize';
import useEyeDropper from 'use-eye-dropper'
import { SketchPicker, CompactPicker, TwitterPicker } from 'react-color'
import file from '../../assets/elements/file.svg';
import background from '../../assets/icons/background.svg'
import CheckIcon from '@mui/icons-material/Check';
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
import eraser from '../../assets/icons/eraser.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import { langFilter } from './ailaysaCanvasComponents/languages'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Keyboard, Mousewheel } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import {ReactComponent as Unconfirmed} from '../../assets/icons/Unconfirmed.svg'
import {ReactComponent as Transliteration} from '../../assets/icons/Transliteration.svg'
import {saveAs} from 'file-saver'
import Skeleton from '@mui/material/Skeleton';


const AilaysaCanvasWorkspace = ({setDownloadLangwise,setDownloadProject,downloadProject,downloadLangwise,setPostEditAccess, sourceLang, setSourceLang, setTranslate, result, setName, setDownload, download, translatedCanvases, pop, setPop, setJsdata, jsdata, setNavbar, switchWorkspace, setSwitchWorkspace, setAilaysaWorkspace, tLanguages, name }) => {
// const fs = require('fs');
    
    const [instaTranslate, setInstaTranslate] = useState()
    const [disableLi, setDisableLi] = useState(false);
    const LABS_API = Config.LABS_API
    const [multilingualCanvasData, setMultilingualCanvasData] = useState([]);
    const [jsonDataInfo, setJsonDatainfo] = useState([]);
    const [jsonDataInfoFinal, setJsonDatainfoFinal] = useState([]);
    const [sourcData, setSourceData] = useState([]);
    const { editor, onReady } = useFabricJSEditor();
    const [passW, setPassW] = useState(false);
    const [isdrawing, setisdrawing] = useState(false)
    const [fontColor, setFontcolor] = useState("1111")
    const [pageNo, setPageNo] = useState(1)
    const [languageData, setLanguageData] = useState()
    const [design, setDesign] = useState([])
    const [pageData, setPageData] = useState()
    const [srLanguage, setSrLanguage] = useState()
    const { state } = useLocation();
    const location = useLocation();
    const [designName, setDesignName] = useState();
    const navigate = useNavigate();
 


    const [new2, setNew2] = useState(location.state || {})

    const handleAuthTrue = (authentication) => {
        let token = Config.userState != null ? Config.userState.token : "";
        authentication.append("Authorization", `Bearer ${token}`)
        return authentication;
    }

    useEffect(() => {
        navigate(".", { replace: true });
    }, [navigate]);



    const getProjectData = () => {
        (async function () {

            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-designs/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                setDesign(response)
                setMultilingualCanvasData(response)
                setJsonDatainfoFinal(response.source_json)
                setCanvasismodified(true)
                setTranslatedCanvasModified(false)

            }
            else {

            }
        })();

    }

    const updateProjectData = () => {
        (async function () {

            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-designs/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                setDesign(response)
                

            }
            else {

            }
        })();

    }





    useEffect(() => {

        getProjectData()

    }, [])

    useEffect(() => {
        if (multilingualCanvasData != '' && new2 != '') {
            let data = multilingualCanvasData
            data = data.filter(function (item) {
                return item.id == new2.id;
            }).map(function ({ id, source_json, canvas_translation, file_name, height, width }) {
                return { id, source_json, canvas_translation, file_name, height, width };
            });
            setSourceData(data[0]);
            setLanguageData(data[0]?.canvas_translation)
            setSrLanguage(data[0]?.canvas_translation[0]?.source_language)
            setJsonDatainfo(data[0]?.source_json[0]?.json)
            setJsonDatainfoFinal(data[0]?.source_json)
            setCanvasHeight(data[0]?.source_json[0]?.json.height);
            setDesignName(data[0]?.file_name)
            setCanvaswidth(data[0]?.source_json[0]?.json.width);
            setTargetLanNo(data[0]?.canvas_translation[0]?.source_language)
            editor?.canvas.loadFromJSON(data[0]?.source_json[0].json, function () {
                editor.canvas.renderAll.bind(editor.canvas.renderAll());
            })
            setReuseddesignboolen(true)
            setCanvasismodified(true)



        }
    }, [multilingualCanvasData, new2])




    // const [canvas, setCanvas] = useState('');
    const [sideBarMyTemplateOpenClose, setSideBarMyTemplateOpenClose] = useState(false)
    const [sideBarMyDesignOpenClose, setSideBarMyDesignOpenClose] = useState(false)
    const [sideBarTemplateOpenClose, setSideBarTemplateOpenClose] = useState(false)
    const [sideBarElementOpenClose, setSideBarElementOpenClose] = useState(false)
    const [sideBarUploadOpenClose, setSideBarUploadOpenClose] = useState(false)
    const [sideBarTextOpenClose, setSideBarTextOpenClose] = useState(false)
    const [sideBarPhotosOpenClose, setSideBarPhotosOpenClose] = useState(false)
    const [sideBarBackgroundOpenClose, setSideBarBackgroundOpenClose] = useState(false)

    const [canvasHeight, setCanvasHeight] = useState(600)
    const [canvasWidth, setCanvaswidth] = useState(600)
    const [bold, setBold] = useState(false)
    const [italic, setItalic] = useState(false)
    const [underline, setUnderline] = useState(false)
    const [counter, setCounter] = useState(32)
    const [color, setColor] = useState("#000000");
    const [searchInput, setSearchInput] = useState("");
    const [canvasSecondary, setCanvasSecondary] = useState(false)
    const [Confirmed, setConfirmed] = useState(false)

    const [files, setFile] = useState([]);
    const [message, setMessage] = useState();

    const hiddenFileInput = React.useRef(null);
    const hiddenColorInput = React.useRef(null);
    const [secondaryJsdata, setSecondaryJsdata] = useState(null)


    //draggable
    const [state2, setState2] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    });

    const onStart = () => {
        const { activeDrags } = state2;
        setState2({ ...state2, activeDrags: activeDrags + 1 });
    };
    const onStop = () => {
        const { activeDrags } = state2;
        setState2({ ...state2, activeDrags: activeDrags - 1 });
    };

    const dragHandlers = { onStart, onStop };

    const getJsonData = () => {


        setSecondaryJsdata(editor.canvas.toJSON(['id', 'height', 'width', 'subTargetCheck', 'canvasimg', 'status', 'lockMovementX', 'lockMovementY', 'editable','newtext']))
        console.log(editor.canvas.toJSON(['id', 'height', 'width', 'subTargetCheck', 'canvasimg', 'status', 'lockMovementX', 'lockMovementY', 'editable','newtext']));
        // console.log(editor?.canvas.getObjects('textbox').length)
        // editor.canvas.deactivateAllWithDispatch();
        // editor.canvas.renderAll();
        // const image   = editor.canvas.toSVG();
        // const svg_image = "data:image/svg+xml,"+encodeURIComponent(image);
        // // // // console.log(svg_image);

    }

    useEffect(() => {
        setNavbar(true)
        setAilaysaWorkspace(true)
    }, [])




    useEffect(() => {
        window.addEventListener('keydown', e => {
            if (e.key === 'Delete') {

                try {
                    editor.canvas.remove(editor.canvas.getActiveObject())
                    // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                    let activeObjects = editor.canvas.getActiveObjects();
                    if (activeObjects.length) {
                        activeObjects.forEach(function (object) {
                            editor.canvas.remove(object);

                        });
                    }
                }
                catch {
                }
            }

        })
    });



    // const initCanvas = () => (

    // new fabric.Canvas('canvas', {
    //   height: canvasHeight? canvasHeight : 500 ,
    //   width: canvasWidth? canvasWidth : 500 ,
    //   backgroundColor: 'white',


    // })
    // )

    const [skeleton, setSkeleton] = useState(true)


    const handleSidebarClose = () => {
        setSideBarMyDesignOpenClose(false);
        setSideBarMyTemplateOpenClose(false);
        setSideBarTemplateOpenClose(false);
        setSideBarElementOpenClose(false);
        setSideBarUploadOpenClose(false);
        setSideBarTextOpenClose(false);
        setSideBarPhotosOpenClose(false);
        setSideBarBackgroundOpenClose(false);
    
        }

    const handleMyTemplateSidebarOpen = () => {
        if(sideBarMyTemplateOpenClose){
            setSideBarMyTemplateOpenClose(false);
        }
        else{
            setSideBarMyTemplateOpenClose(true);
            // getMyTemplate()


        }
        setSideBarMyDesignOpenClose(false);
        setSideBarTemplateOpenClose(false);
        setSideBarElementOpenClose(false);
        setSideBarUploadOpenClose(false);
        setSideBarTextOpenClose(false);
        setSideBarPhotosOpenClose(false);
        setSideBarBackgroundOpenClose(false);

    }

    const handleMyDesignSidebarOpen = () => {
        if(sideBarMyDesignOpenClose){
            setSideBarMyDesignOpenClose(false);
        }
        else{
            setSideBarMyDesignOpenClose(true);
            updateProjectData()
        setSkeleton(true)


        }
        setSideBarMyTemplateOpenClose(false);
        
        setSideBarTemplateOpenClose(false);
        setSideBarElementOpenClose(false);
        setSideBarUploadOpenClose(false);
        setSideBarTextOpenClose(false);
        setSideBarPhotosOpenClose(false);
        setSideBarBackgroundOpenClose(false);
    }

    const handleTemplateSidebarOpen = () => {
        if(sideBarTemplateOpenClose){
            setSideBarTemplateOpenClose(false);
        }
        else{
            setSideBarTemplateOpenClose(true);
        }
        setSideBarMyTemplateOpenClose(false);
        setSideBarMyDesignOpenClose(false);
        
        setSideBarElementOpenClose(false);
        setSideBarUploadOpenClose(false);
        setSideBarTextOpenClose(false);
        setSideBarPhotosOpenClose(false);
        setSideBarBackgroundOpenClose(false);

    }

    const handlePhotoSidebarOpen = () => {
        if(sideBarPhotosOpenClose){
            setSideBarPhotosOpenClose(false)
        }
        else{
            setSideBarPhotosOpenClose(true)
        api()

        }
        setSideBarMyTemplateOpenClose(false);
        setSideBarMyDesignOpenClose(false);
        setSideBarTemplateOpenClose(false);
        setSideBarElementOpenClose(false);
        setSideBarUploadOpenClose(false);
        setSideBarTextOpenClose(false);
        
        setSideBarBackgroundOpenClose(false)

    }
    
    const handleElementSidebarOpen = () => {
        if(sideBarElementOpenClose){
            setSideBarElementOpenClose(false);
        }
        else{
            setSideBarElementOpenClose(true);
        elementsimage()

        }
        setSideBarMyTemplateOpenClose(false);
        setSideBarMyDesignOpenClose(false);
        setSideBarTemplateOpenClose(false);
        
        setSideBarUploadOpenClose(false);
        setSideBarTextOpenClose(false);
        setSideBarPhotosOpenClose(false);
        setSideBarBackgroundOpenClose(false)
        

    }
    const handleUploadSidebarOpen = () => {
        if(sideBarUploadOpenClose){
            setSideBarUploadOpenClose(false)
        }
        else{
            setSideBarUploadOpenClose(true)
        // getMyUpload()

        }
        setSideBarMyTemplateOpenClose(false);
        setSideBarMyDesignOpenClose(false);
        setSideBarTemplateOpenClose(false);
        setSideBarElementOpenClose(false);
        setSideBarTextOpenClose(false);
        setSideBarPhotosOpenClose(false);
        setSideBarBackgroundOpenClose(false)

    }
    const handleTextSidebarOpen = () => {
        if(sideBarTextOpenClose){
            setSideBarTextOpenClose(false);
        }
        else{
            setSideBarTextOpenClose(true);
        }
        setSideBarMyTemplateOpenClose(false);
        setSideBarMyDesignOpenClose(false);
        setSideBarTemplateOpenClose(false);
        setSideBarElementOpenClose(false);
        setSideBarUploadOpenClose(false);
        
        setSideBarPhotosOpenClose(false);
        setSideBarBackgroundOpenClose(false)

    }
    const handleBackgroundSidebaropen = () => {
        if(sideBarBackgroundOpenClose){
            setSideBarBackgroundOpenClose(false)
        }
        else{
            setSideBarBackgroundOpenClose(true)
            bgapi()
        }
        setSideBarMyTemplateOpenClose(false);
        setSideBarMyDesignOpenClose(false);
        
        setSideBarTemplateOpenClose(false);
        setSideBarElementOpenClose(false);
        setSideBarUploadOpenClose(false);
        setSideBarTextOpenClose(false);
        setSideBarPhotosOpenClose(false);
        
    }


    const [toolbar, setToolbar] = useState(false)
    const [imgtoolbar, setImgToolbar] = useState(false)


    const [TextId, setTextId] = useState(1);
    const [imgId, setImgId] = useState(1);


    const [leftToptext, setLeftTopText] = useState(100)
    const addHeading = () => {

        setTextId(TextId + 1)
        setLeftTopText(leftToptext + 10)

        const txt = (new fabric.Textbox("Add a heading", {
            id: editor.canvas.getObjects('textbox').lenth,
            textAlign: "center",
            left: leftToptext,
            top: leftToptext,
            fontFamily: 'Ariel',
            fill: '#111',
            fontSize: 32/scaleCanvas,
            opacity: 1,
            lockMovementX: false,
            lockMovementY: false,
            lineHeight: 1,
            width: 190,
            minScaleLimit: 0.5,
            breakWords: true,
            uniformScaling: true,
            status: 'Unconfirmed',
            newtext:'new'

        }));


        editor.canvas.add(txt);
        editor.canvas.renderAll();
        setToolbar(true);








        editor.canvas.getObjects().forEach((e) => {
            if (e.id === 'text-' + TextId) {
                editor.canvas.setActiveObject(e)
            }
        })
    }

    const addHeading2 = () => {
        setTextId(TextId + 1)
        setLeftTopText(leftToptext + 10)

        const txt = (new fabric.Textbox("Add a Subheading", {
            id: editor.canvas.getObjects('textbox').lenth,
            textAlign: "center",
            left: leftToptext,
            top: leftToptext,
            fontFamily: 'Ariel',
            fill: '#111',
            fontSize: 28/scaleCanvas,
            lockMovementX: false,
            lockMovementY: false,
            lineHeight: 1,
            width: 167,
            minScaleLimit: 0.5,
            breakWords: true


        }));



        editor.canvas.add(txt);
        editor.canvas.renderAll();
        setToolbar(true);



        editor.canvas.getObjects().forEach((e) => {
            if (e.id === 'text-' + TextId) {
                editor.canvas.setActiveObject(e)
            }
        })



    }

    const addHeading3 = () => {

        setTextId(TextId + 1)
        setLeftTopText(leftToptext + 10)

        const txt = (new fabric.Textbox("Add a heading", {
            id: editor.canvas.getObjects('textbox').lenth,
            textAlign: "center",
            left: leftToptext,
            top: leftToptext,
            fontFamily: 'Ariel',
            fill: '#111',
            fontSize: 18/scaleCanvas,
            lockMovementX: false,
            lineHeight: 1,
            width: 110,
            minScaleLimit: 0.5,
            breakWords: true


        }));



        editor.canvas.add(txt);
        editor.canvas.renderAll();
        setToolbar(true);



        editor.canvas.getObjects().forEach((e) => {
            if (e.id === 'text-' + TextId) {
                editor.canvas.setActiveObject(e)
            }
        })

    }

    const canvasimgurl = useRef();

    const firstupdate = useRef(true)
    useEffect(() => {

        if (editor) {
            if (editor.canvas.getActiveObject()) {
                // bold
                // let boldcount = 0
                // if(editor.canvas.getActiveObject().getSelectedText()){
                //     let length = editor.canvas.getActiveObject().getSelectedText().length
                //     const spacecount = editor.canvas.getActiveObject().getSelectedText().length - editor.canvas.getActiveObject().getSelectedText().replaceAll(' ', '').length
                //     for(let i=0;i<length;i++){
                //        if( editor.canvas.getActiveObject().getSelectedText()[i] != ' '){
                //             if (editor.canvas.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold') {
                //                 boldcount++
                //             }
                //             else {

                //             }
                //             if(boldcount == length-spacecount){
                //                 setBold(true)
                //             }
                //             else{
                //                 setBold(false)
                //             }
                //         }
                //         else{
                //             // // // console.log('space');
                //         }
                //     }

                // }
                // bold
                if ((editor.canvas.getActiveObject().fontWeight === 'bold')) {
                    setBold(true)
                    editor.canvas.renderAll()
                }

                else {
                    setBold(false)
                    editor.canvas.renderAll()
                }


                // italic

                if ((editor.canvas.getActiveObject().fontStyle === 'italic')) {
                    setItalic(true)
                    editor.canvas.renderAll()
                }

                else {
                    setItalic(false)
                    editor.canvas.renderAll()
                }
                // underline

                if ((editor.canvas.getActiveObject().underline === true)) {
                    setUnderline(true)
                    editor.canvas.renderAll()
                }

                else {
                    setUnderline(false)
                    editor.canvas.renderAll()
                }

                if ((editor.canvas.getActiveObject().linethrough === true)) {
                    setLinethrough(true)
                    editor.canvas.renderAll()
                }

                else {
                    setLinethrough(false)
                    editor.canvas.renderAll()
                }

                if ((editor.canvas.getActiveObject().overline === true)) {
                    setOverline(true)
                    editor.canvas.renderAll()
                }

                else {
                    setOverline(false)
                    editor.canvas.renderAll()
                }

            }
        }
        if (fontSizeTemp.current) {
            fontSizeTemp.current = false
            return;
        }

        if (editor) {
            if (editor.canvas.getActiveObject()) {
                setTempCounter(editor.canvas.getActiveObject().fontSize)
                editor.canvas.renderAll()

            }
        }
        if (editor) {
            editor.canvas.preserveObjectStacking = true;
            editor.canvas.fireRightClick = true
            editor.canvas.id = name
            editor.canvas.canvasimg = canvasimgurl
            // editor.canvas.backgroundColor = '#FFFFFF'


            editor.canvas.renderAll();
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().borderColor = '#BD8AF5'
                editor.canvas.getActiveObject().borderScaleFactor = 2
                editor.canvas.getActiveObject().cornerStyle = "circle"
                editor.canvas.getActiveObject().cornerColor = '#FFFFFF'
                editor.canvas.getActiveObject().cornerSize = 9
                editor.canvas.getActiveObject().cornerStrokeColor = '#00000029'
                editor.canvas.getActiveObject().transparentCorners = false
                setBackgroundEdit(false)
                editor.canvas.renderAll();
            }

        }

        if (editor) {
            if (editor.canvas.getActiveObject()) {
                setDisabled(true)
            }
            else {
                setDisabled(false)
            }
            // right click to get active object
            editor.canvas.on('mouse:up', (e) => {
                if (e.button == 3) {
                    editor.canvas.setActiveObject(e.target)
                }
            })
            if (editor.canvas.getActiveObject()?.type === 'group') {
                setMultiselect(true)
                setgroupname('Ungroup')

            }
            else if ((editor.canvas.getActiveObject()?.type == 'activeSelection')) {
                setgroupname('Group')
                setMultiselect(true)
            }
            else {
                setMultiselect(false)
                setgroupname('Ungroup')
            }



            if (editor.canvas.getActiveObjects().length > 1) {
                let total = editor.canvas.getActiveObjects().length
                let tvalue = 0;
                let ivalue = 0;
                let ovalue = 0;

                editor.canvas.getActiveObjects().forEach((e) => {
                    if (e.type == 'textbox') {
                        tvalue++
                    }
                    else if (e.type == 'image') {
                        ivalue++
                    }
                    else if ((e.type != 'textbox') && (e.type != 'image')) {
                        ovalue++
                    }

                })
                if (tvalue == total) {
                    setAlltext(true)
                }
                else if (ivalue == total) {

                }
                else if (ovalue == total) {

                }
                else {
                    setAlltext(false)
                }
            }
            else {

            }
            editor.canvas.requestRenderAll();
        }
    }, [bold, italic, underline, editor, name])


    // firstupdate

    // remove active object in canvas
    const removeActiveobject = () => {
        if (editor) {
            // editor.canvas.remove(editor.canvas.getActiveObject())
            try {
                editor.canvas.remove(editor.canvas.getActiveObject())
                // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                let activeObjects = editor.canvas.getActiveObjects();
                if (activeObjects.length) {
                    activeObjects.forEach(function (object) {
                        editor.canvas.remove(object);
                        editor.canvas.renderAll();
                    });
                    editor.canvas.discardActiveObject().renderAll();
                }
            }
            catch {
            }

        }
    }



    // canvas size function start
    // width
    if (editor) {

        editor.canvas.setWidth(canvasWidth);
        editor.canvas.renderAll();
    }

    // height
    if (editor) {

        editor.canvas.setHeight(canvasHeight);
        editor.canvas.renderAll();
    }

    // // // console.log(canvasHeight, canvasWidth);




    useEffect(() => {
        // document.querySelector('.canvas-container').style.backgroundColor = "white";
        document.querySelector('.canvas-container').style.height = `${canvasHeight + 'px'}`;
        // document.querySelector('.lower-canvas').style.height = `${canvasHeight +'px'}`;
        // document.querySelector('.upper-canvas').style.height = `${canvasHeight +'px'}`;
        document.querySelector('.canvas-container').style.width = `${canvasWidth + 'px'}`;
        // document.querySelector('.lower-canvas').style.width = `${canvasWidth+'px'}`;
        // document.querySelector('.upper-canvas').style.width = `${canvasWidth+'px'}`;
        if ((canvasHeight < 740) || (canvasWidth < 1400)) {
            setScaleCanvas(1);
        }

        if ((canvasHeight > 740 && canvasHeight <= 800) || (canvasWidth > 1400 && canvasWidth <= 1450)) {
            setScaleCanvas(0.9);
        }

        if ((canvasHeight > 800 && canvasHeight <= 850) || (canvasWidth > 1500 && canvasWidth <= 1550)) {
            setScaleCanvas(0.85);
        }

        if ((canvasHeight > 850 && canvasHeight <= 900) || (canvasWidth > 1550 && canvasWidth <= 1600)) {
            setScaleCanvas(0.80);
        }

        if ((canvasHeight > 900 && canvasHeight <= 950) || (canvasWidth > 1650 && canvasWidth <= 1700)) {
            setScaleCanvas(0.75);
        }

        if ((canvasHeight > 950 && canvasHeight <= 1000) || (canvasWidth > 1700 && canvasWidth <= 1750)) {
            setScaleCanvas(0.75);
        }

        // if((canvasHeight>1000 && canvasHeight<= 1050) || (canvasWidth > 1800 && canvasWidth <= 1850)){
        //     setScaleCanvas(0.65);
        // }

        if ((canvasHeight > 1050 && canvasHeight <= 1100) || (canvasWidth > 1850 && canvasWidth <= 1920)) {
            setScaleCanvas(0.50);
        }

        if (canvasWidth > 1920) {

            setCanvaswidth(1920)
        }
        if (canvasHeight > 1080) {

            setCanvasHeight(1080)
        }


    }, [canvasHeight, canvasWidth])



    const [getActiveObjectText, setGetActiveObjectText] = useState('')
    const [getActiveObjectTextHead, setGetActiveObjectTextHead] = useState('')


    if (editor) {

        editor.canvas.setWidth(canvasWidth);
        editor.canvas.renderAll();

    }

    if (editor) {

        editor.canvas.setHeight(canvasHeight);
        editor.canvas.renderAll();
    }

    const getText = () => {
        setGetActiveObjectText(editor.canvas.getActiveObject().text)

        setGetActiveObjectTextHead(editor.canvas.getActiveObject().text)

        let obj = editor.canvas.getObjects()

        for (let i = 0; i < obj.length; i++) {


        }
    }


    const updateActiveObjectText = (e) => {
        setGetActiveObjectText(e.target.value)
        editor.canvas.getActiveObject().set("text", e.target.value)
        editor.canvas.renderAll()
        // updateDesignfromapi2()



    }

    useEffect(() => {

        editor?.canvas.getObjects('textbox')

    }, [getActiveObjectText])


    // lock active object in canvas


    const lockmovementActiveobject = () => {
        if (editor) {
            if (editor.canvas.getActiveObject().lockMovementX === true) {
                editor.canvas.getActiveObject().set('lockMovementX', false);
                editor.canvas.getActiveObject().set('lockMovementY', false);
                editor.canvas.getActiveObject().set('lockScalingX', false);
                editor.canvas.getActiveObject().set('lockScalingY', false);
                editor.canvas.getActiveObject().set('hasControls', true);
                editor.canvas.getActiveObject().set('editable', true);
                editor.canvas.renderAll();
            }
            else {
                editor.canvas.getActiveObject().set('lockMovementX', true);
                editor.canvas.getActiveObject().set('lockMovementY', true);
                editor.canvas.getActiveObject().set('lockScalingX', true);
                editor.canvas.getActiveObject().set('lockScalingY', true);
                editor.canvas.getActiveObject().set('hasControls', false);
                editor.canvas.getActiveObject().set('editable', false);
                editor.canvas.getActiveObject().set('hasBorders', true);
                editor.canvas.renderAll();
            }
        }
    }

    const clearAll = () => {
        editor.canvas.clear();

    }


    // if drawing mode is on toolbar is visiable


    const drawingFunc = () => {
        if (editor) {
            if (editor.canvas.isDrawingMode === false) {
                editor.canvas.set('isDrawingMode', true)
                setisdrawing(true)
                setToolbar(false)
                editor.canvas.renderAll();

            }
            else {
                editor.canvas.set('isDrawingMode', false)
                setisdrawing(false)
                setToolbar(true)
                editor.canvas.renderAll();
            }
        }
    }
    // drawing function end

    // position function start
    const forward = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.bringForward(editor.canvas.getActiveObject())
                editor.canvas.renderAll();
            }
        }
    }
    const backward = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.sendBackwards(editor.canvas.getActiveObject())
                editor.canvas.renderAll();
            }
        }
    }
    const front = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.bringToFront(editor.canvas.getActiveObject())
                editor.canvas.renderAll();
            }
        }
    }

    const back = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.sendToBack(editor.canvas.getActiveObject())
                editor.canvas.renderAll();
            }
        }
    }

    // groupsection start

    const group = () => {
        if (!editor.canvas.getActiveObject()) {
            return;
        }
        if (editor.canvas.getActiveObject().type !== 'activeSelection') {
            return;
        }
        editor.canvas.getActiveObject().toGroup();
        editor.canvas.requestRenderAll();
    }
    const ungroup = () => {
        if (!editor.canvas.getActiveObject()) {
            return;
        }
        if (editor.canvas.getActiveObject().type !== 'group') {
            return;
        }
        editor.canvas.getActiveObject().toActiveSelection();
        editor.canvas.requestRenderAll();
    }





    const tempDownload = useRef(false)


    const [data1, setData1] = useState(null)

    useEffect(() => {
        if (download == true) {
            const ext = "png";
            const base64 = editor.canvas.toDataURL({
                multiplier: 3,
                format: ext,
                enableRetinaScaling: true
            });
            setData1(base64)

            const link = document.createElement("a");
            link.href = base64;
            link.download = `${designName + '-' + langFilter[langFilter.map(function (item) { return item.value; }).indexOf(targetlanNo)]?.label }.${ext}`;
            link.click();
            setDownload(false)

        }


    }, [download])

    useEffect(() => {
        if (downloadLangwise == true) {

            (async function () {
                // var formdata = new FormData();


                // formdata.append("languages", result);

                var requestOptions = {
                    method: 'GET',
                    headers: handleAuthTrue(new Headers()),
                    // body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/canvas/canvas-download/?"+`design_id=${designid}`+'&'+`canvas_translation_id=${canvasTransJsonId}`, requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    // console.log(response);
                    const link = document.createElement("a");
                    
                    link.href = LABS_API + response.url;
                    // link.download = `${designName + '-' + langFilter[langFilter.map(function (item) { return item.value; }).indexOf(targetlanNo)]?.label }.${ext}`;
                    link.click();
                    setDownloadLangwise(false)                    
                }
                else {

                    console.log('error');
                }
            })();


           

        }


    }, [downloadLangwise])

    useEffect(() => {
        if (downloadProject == true) {

            (async function () {
          

                var requestOptions = {
                    method: 'GET',
                    headers: handleAuthTrue(new Headers()),
                    // body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/canvas/canvas-download/?"+`design_id=${designid}`, requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    // console.log(response);
                    const link = document.createElement("a");
                    link.href = LABS_API + response.url;
                    // link.download = `${designName + '-' + langFilter[langFilter.map(function (item) { return item.value; }).indexOf(targetlanNo)]?.label }.${ext}`;
                    link.click();
                    setDownloadProject(false)
                
                }
                else {

                    console.log('error');
                }
            })();

           

        }


    }, [downloadProject])


    const [switchPages, setSwitchPages] = useState('display')

    const onAddImage = (e) => {


        setToolbar(true)
        setImgId(imgId + 1)
        const stickers = e.target.src;

        function checkImage(url) {
            var request = new XMLHttpRequest();
            request.open("GET", url, true);
            request.send();
            request.onreadystatechange = function () {
                if (this.readyState == this.HEADERS_RECEIVED) {
                    var contentType = request.getResponseHeader("Content-Type");
                    if (contentType == 'image/svg+xml') {
                        setElementsobject(true)
                        fabric.loadSVGFromURL(stickers, function (objects, options) {
                            var loadedObjects = fabric.util.groupSVGElements(objects, options);
                            loadedObjects.set({ subTargetCheck: true })
                            loadedObjects.scaleToWidth(editor.canvas.width / 3);
                            loadedObjects.scaleToHeight(editor.canvas.height / 3);
                            editor.canvas.add(loadedObjects);
                            editor.canvas.setActiveObject(loadedObjects);

                            editor.canvas.renderAll();
                        });

                    }
                    else {
                        var filterBrightness = new fabric.Image.filters.Brightness({
                            brightness: 0,
                        });
                        var filterContrast = new fabric.Image.filters.Contrast({
                            contrast: 0,
                        });
                        var filterSaturation = new fabric.Image.filters.Saturation({
                            saturation: 0,
                        });
                        var filterBlur = new fabric.Image.filters.Blur({
                            blur: 0,
                        });
                        var filterVibrance = new fabric.Image.filters.Vibrance({
                            vibrance: 0,
                        });

                        var filterNoice = new fabric.Image.filters.Noise({
                            noise: 0,
                        });

                        var filterHue = new fabric.Image.filters.HueRotation({
                            rotation: 0,
                        });

                        fabric.Image.fromURL(stickers, (img) => {
                            img.scaleToWidth(200);
                            var oImg = img.set({
                                id: 'Image-' + imgId,
                                left: 100,
                                top: 60,


                            })

                            oImg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)

                            editor.canvas.add(oImg).sendBackwards();
                            editor.canvas.getObjects().forEach((e) => {
                                if (e.id === 'Image-' + imgId) {
                                    editor.canvas.setActiveObject(e)
                                }
                            })
                            editor.canvas.renderAll();


                        }, { crossOrigin: 'anonymous' });
                    }

                }
            }
        }

        checkImage(e.target.src)

    }


    const handleUpload = (event) => {
        hiddenFileInput.current.click();
    };


    const [uploadimg, setUpload] = useState([])

    const handleFile = (e) => {

        let file = e.target.files;

        for (let i = 0; i < file.length; i++) {
            const fileType = file[i]['type'];

            (async function () {
                var formdata = new FormData();
                formdata.append("image_name", file[i].name);
                formdata.append("image", file[i])


                // formdata.append("languages", result);

                var requestOptions = {
                    method: 'POST',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/canvas/canvas-user-images/", requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    setCall(call + 1)
                }
                else {

                    // // // console.log('error');
                }
            })();

        }



    };
    const deleteuploadfromapi = (id) => {
        (async function () {


            var requestOptions = {
                method: 'DELETE',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-user-images/" + id + "/", requestOptions)
            setCall(call + 1)
            if (data.status === 200) {
                let response = await data.json()
            }
            else {

                console.log('error');
            }
            if (id == templateid) {
                editor.canvas.clear()
                setName('Document Name')
            }
        })();
    }

    const removeImage = (i) => {
        setFile(files.filter(x => x.name !== i));
    }




    const [scaleCanvas, setScaleCanvas] = useState(1)

    //Zoom
    const handleScale = (e) => {

        setScaleCanvas(e.target.value);
    }


    // linethrough function start

    const [linethrough, setLinethrough] = useState(false)



    // overline function start
    const [overline, setOverline] = useState(false)




    if (editor) {
        if (editor.canvas.getActiveObject()) {
            if (editor.canvas.getActiveObject().uppercase) {
                editor.canvas.on("text:editing:exited", function (e) {
                    editor.canvas.getActiveObject().set('uppercase', false)
                    // // // // console.log('updated text:', e.target.text);
                    // // // // console.log("text:editing:exited");
                });
            }
        }
    }

    // uppercase function end


    //   ###################################################################################################################################################



    var _clipboard = null

    function Copy() {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                if (editor.canvas.getActiveObject().isEditing == true) {
                    return false;
                }
                editor.canvas.getActiveObject().clone(function (cloned) {
                    _clipboard = cloned;
                    if (editor.canvas.getActiveObject().type === 'activeSelection') {
                        _clipboard = cloned;
                    }
                });
            }
        }
    }

    function Paste() {
        // clone again, so you can do multiple copies.
        if (editor) {
            _clipboard.clone(function (clonedObj) {
                editor.canvas.discardActiveObject();
                clonedObj.set({
                    left: clonedObj.left + 10,
                    top: clonedObj.top + 10,
                    evented: true,
                });
                if (clonedObj.type === 'activeSelection') {
                    clonedObj.canvas = editor.canvas;
                    clonedObj.forEachObject(function (obj) {
                        editor.canvas.add(obj);
                    });
                    clonedObj.setCoords();
                } else {
                    editor.canvas.add(clonedObj);
                }
                if (editor.canvas.getActiveObject()) {
                    clonedObj.set({ top: editor.canvas.getActiveObject().get('top') + 10 })
                    clonedObj.set({ top: editor.canvas.getActiveObject().get('left') + 10 })
                }
                else {
                    _clipboard.top += 10;
                    _clipboard.left += 10;
                }

                editor.canvas.setActiveObject(clonedObj);
                editor.canvas.requestRenderAll();
            });
        }
    }
    // const STEP = 5;

    // var Direction = {
    //     LEFT: 0,
    //     UP: 1,
    //     RIGHT: 2,
    //     DOWN: 3
    // };

    // useEffect(()=>{

    // window.addEventListener('keydown', function (options) {
    //     if (options.repeat) {
    //         return;
    //     }
    //     var key = options.which || options.keyCode; // key detection
    //     if (key === 37) { // handle Left key
    //         moveSelected(Direction.LEFT);
    //         // // // console.log('move left');
    //     } else if (key === 38) { // handle Up key
    //         moveSelected(Direction.UP);
    //         // // // console.log('move up');
    //     } else if (key === 39) { // handle Right key
    //         moveSelected(Direction.RIGHT);
    //         // // // console.log('move right');
    //     } else if (key === 40) { // handle Down key
    //         moveSelected(Direction.DOWN);
    //         // // // console.log('move down');
    //     }
    //     if (key === 46) {
    //         // // // console.log('deleted');
    //         setBackgroundUpdate(false)
    //         try {
    //             editor.canvas.remove(editor.canvas.getActiveObject())
    //             // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
    //             editor.canvas.renderAll()
    //             let activeObjects = editor.canvas.getActiveObjects();
    //             if (activeObjects.length) {
    //                 activeObjects.forEach(function (object) {
    //                     editor.canvas.remove(object);
    //                     editor.canvas.renderAll()
    //                 });
    //                 editor.canvas.discardActiveObject().renderAll();
    //             }
    //         }
    //         catch {
    //         }
    //     }
    //     //     if (e.which === 89 && e.ctrlKey) {
    //     //         // control + y
    //     //         //replay(redo, undo, '#undo', this);
    //     //     } else if (e.which === 90 && e.ctrlKey) {
    //     //         // control + z
    //     //         //replay(undo, redo, '#redo', this);
    //     //     } else 

    //     // if (options.which === 67 && options.ctrlKey) {
    //     //     // control + c
    //     //     options.preventDefault()
    //     //     Copy()
    //     //     // // // console.log('copy');
    //     // } else if (options.which === 86 && options.ctrlKey) {
    //     //     // control + v
    //     //     options.preventDefault()
    //     //     Paste()
    //     //     // // // console.log('paste');

    //     // }
    // });






    // },[])
    // function moveSelected(direction) {
    //     if (editor) {
    //         if (editor.canvas.getActiveObject()) {
    //             switch (direction) {
    //                 case Direction.LEFT:
    //                     editor.canvas.getActiveObject().set({ left: editor.canvas.getActiveObject().get('left') - STEP });
    //                     break;
    //                 case Direction.UP:
    //                     editor.canvas.getActiveObject().set({ top: editor.canvas.getActiveObject().get('top') - STEP });
    //                     break;
    //                 case Direction.RIGHT:
    //                     editor.canvas.getActiveObject().set({ left: editor.canvas.getActiveObject().get('left') + STEP });
    //                     break;
    //                 case Direction.DOWN:
    //                     editor.canvas.getActiveObject().set({ top: editor.canvas.getActiveObject().get('top') + STEP });
    //                     break;
    //             }
    //             editor.canvas.getActiveObject().setCoords();
    //             editor.canvas.renderAll();
    //             // // // console.log('selected objects was moved');
    //         } else {
    //             // // // console.log('no object selected');
    //         }
    //         editor.canvas.renderAll()
    //     }

    // }







    const [counterTemp, setTempCounter] = useState(0)

    //fontSize
    // Function is called everytime increment button is clicked
    const increaseFontSize = (e) => {
        // Counter state is incremented
        setCounter(parseInt(editor.canvas.getActiveObject().fontSize) + 1)
        setTempCounter(false)

    }



    // Function is called everytime decrement button is clicked
    const decreaseFontSize = (e) => {
        // Counter state is decremented
        setCounter(parseInt(editor.canvas.getActiveObject().fontSize) - 1)
        setTempCounter(true)

    }
    const fontSizeTemp = useRef(true)





    const fontsizeTEmp2 = useRef(true)
    useEffect(() => {

        if (fontsizeTEmp2.current) {
            fontsizeTEmp2.current = false
            return;
        }

        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().set("fontSize", `${counter}`)
                editor.canvas.renderAll();


            }
        }
    }, [counter])









    // ############################# 20/08/2022 individual character styles formating  // dhananjezhian



    //    const check2=()=>{
    //         // // // // console.log(editor.canvas.getActiveObject().get('text')[2]);
    //         // let temp = editor.canvas.getActiveObject().get('text').length
    //         // editor.canvas.getActiveObject().setSelectionStyles(({ fontWeight: 'bold' }), editor.canvas.getActiveObject().selectionStart+1, editor.canvas.getActiveObject().selectionEnd-1)
    //         // editor.canvas.renderAll()
    //         editor.canvas.getActiveObject().getSelectedText().forEach(element => {
    //             // // // console.log(element);
    //         });

    //    }
    //     const check =()=>{
    //         // // // // console.log(editor.canvas.getActiveObject().getSelectedText()[1]);
    //         let length = editor.canvas.getActiveObject().getSelectedText().length
    //         // // for(let i = 0;i<editor.canvas.getActiveObject().getSelectedText().length,i++){

    //         // // }     
    //         // if(editor.canvas.getActiveObject().getSelectedText()){
    //         //     editor.canvas.getActiveObject().setSelectionStyles(({ fontWeight: 'bold' }),editor.canvas.getActiveObject().selectionStart,editor.canvas.getActiveObject().selectionEnd - 5  )
    //         // }
    //         // editor.canvas.renderAll()
    //          // //     active.getActiveObject().setSelectionStyles(({ fontWeight: 'bold' }), 0, temp)
    //          let wordcount = 0 
    //          let space =0
    //          let bold =0
    //          if(editor){
    //              if(editor.canvas.getActiveObject().getSelectedText()){
    //                  for (let i = 0; i < editor.canvas.getActiveObject().getSelectedText().length; i++) {
    //                      if (editor.canvas.getActiveObject().getSelectedText()[i] != ' '){
    //                         wordcount++
    //                         // // // console.log(editor.canvas.getActiveObject().getSelectedText()[i]);
    //                          if(editor.canvas.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold'){
    //                             bold++
    //                         }
    //                      }
    //                      else {
    //                          space++
    //                      }
    //                  }
    //                  // // // console.log('bold',bold+", word:"+ wordcount,'space:'+space);
    //              }
    //          }

    //         //  // // // console.log(editor.canvas.getActiveObject().getSelectionStyles());
    //     }


    editor?.canvas.on('text:selection:changed', () => {
        let wordcount = 0
        let space = 0
        let bold = 0
        // if(editor.canvas.getActiveObject().getSelectedText()){
        // // // // console.log('veri 2');
        for (let i = 0; i < editor.canvas.getActiveObject().getSelectedText().length; i++) {
            if (editor.canvas.getActiveObject().getSelectedText()[i] != ' ') {
                // // // // console.log('veri 3');
                wordcount++
                //    // // // console.log(editor.canvas.getActiveObject().getSelectedText()[i]);
                if (editor.canvas.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold') {
                    bold++
                    //    // // // console.log('veri 4 ');
                }
            }
            else {
                space++
            }
        }
        // // // console.log('bold', bold + ", word:" + wordcount, 'space:' + space);
        if (bold == wordcount) {
            // // // // console.log('veri 4');
            setBold(true)
        }
        else {
            setBold(false)
        }
        // }

    })

    //  if(editor){
    //     if(editor.canvas.getActiveObject()?.type=='textbox'){
    //         // // // console.log('yes textbox');

    //     }
    //  }
    // useEffect(()=>{
    //         editor?.canvas.on('text:selection:changed', ()=>{
    //             let wordcount = 0 
    //             let space =0
    //              let bold =0
    //             if(editor.canvas.getActiveObject().getSelectedText()){
    //                 // // // console.log('veri 2');
    //                 for (let i = 0; i < editor.canvas.getActiveObject().getSelectedText().length; i++) {
    //                     if (editor.canvas.getActiveObject().getSelectedText()[i] != ' '){
    //                         // // // console.log('veri 3');
    //                        wordcount++
    //                        // // // console.log(editor.canvas.getActiveObject().getSelectedText()[i]);
    //                         if(editor.canvas.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold'){
    //                            bold++
    //                            // // // console.log('veri 4 ');
    //                        }
    //                     }
    //                     else {
    //                         space++
    //                     }
    //                 }
    //                 // // // console.log('bold',bold+", word:"+ wordcount,'space:'+space);
    //                 if(bold==wordcount){
    //                     // // // console.log('veri 4');
    //                     setBold(true)
    //                 }
    //                 else{
    //                    setBold(false)
    //                 }
    //             }
    //             else{
    //                 let temp = editor.canvas.getActiveObject().get('text').length  
    //                 // for(let i=0;i<temp;i++){
    //                 //     if(editor.canvas.getActiveObject().get('text')[i] != ' '){
    //                 //             // // // console.log('yes');
    //                 //     }
    //                 // } 
    //                 setBold(false)
    //             }
    //          })
    //          if(editor){
    //             if(editor.canvas.getActiveObject()?.type=='textbox'){
    //                 // // // console.log('yes textbox');

    //             }
    //          }

    // },[editor])

    const handleBold = () => {
        const active = editor.canvas
        if (editor.canvas.getActiveObject().getSelectedText()) {
            let boldcount = 0
            for (let i = 0; i < active.getActiveObject().getSelectedText().length; i++) {
                if (active.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold') {
                    // // // // console.log('yes  ');
                    boldcount++
                }
                else {
                    // // // // console.log('yes  ');
                }
            }

            // // // console.log('bold-count', boldcount);
            let temp = active.getActiveObject().get('text').length

            if (boldcount === (active.getActiveObject().getSelectedText().length)) {
                // // // console.log('unbold')
                active.getActiveObject().setSelectionStyles({ fontWeight: 'normal' })
                active.renderAll()

            }
            else if (boldcount === 0) {
                // // // console.log('bold');
                active.getActiveObject().setSelectionStyles({ fontWeight: 'bold' })

                active.renderAll()

            }
            else if (boldcount >= active.getActiveObject().getSelectedText().length / 2) {
                // // // console.log('bold')
                active.getActiveObject().setSelectionStyles({ fontWeight: 'bold' })


            }
            else if (boldcount <= active.getActiveObject().getSelectedText().length / 2) {
                // // // console.log('unbold')
                active.getActiveObject().setSelectionStyles({ fontWeight: 'normal' })

            }
            //     let wordcount = 0 
            //  let space =0
            //  let bold =0
            //  if(editor){
            //      if(editor.canvas.getActiveObject().getSelectedText()){
            //          for (let i = 0; i < editor.canvas.getActiveObject().getSelectedText().length; i++) {
            //              if (editor.canvas.getActiveObject().getSelectedText()[i] != ' '){
            //                 wordcount++
            //                 // // // console.log(editor.canvas.getActiveObject().getSelectedText()[i]);
            //                  if(editor.canvas.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold'){
            //                     bold++
            //                 }
            //              }
            //              else {
            //                  space++
            //              }
            //          }
            //          // // // console.log('bold',bold+", word:"+ wordcount,'space:'+space);
            //         //  if(bold==wordcount){
            //         //     setBold(true)
            //         //  }
            //         //  else{
            //         //     setBold(false)
            //         //  }
            //         editor.canvas.on('text:selection', function(e) {
            //             //this will only work using the dev build
            //               if(bold == wordcount){
            //                 setBold(true)
            //                 }
            //             else{
            //                 setBold(false)
            //                 }
            //         });
            //      }
            //  }

        }
        else {
            if (active.getActiveObject().fontWeight == 'normal') {
                let temp = active.getActiveObject().get('text').length
                active.getActiveObject().setSelectionStyles(({ fontWeight: 'bold' }), 0, temp)
                active.getActiveObject().set({ fontWeight: 'bold' })
                setBold(true)

            }
            else {
                let temp = active.getActiveObject().get('text').length
                active.getActiveObject().setSelectionStyles(({ fontWeight: 'normal' }), 0, temp)
                active.getActiveObject().set({ fontWeight: 'normal' })
                setBold(false)
            }
        }
        active.renderAll()
    }
    const handleItalic = () => {
        const active = editor.canvas
        if (editor.canvas.getActiveObject().getSelectedText()) {
            let italiccount = 0
            for (let i = 0; i < active.getActiveObject().getSelectedText().length; i++) {
                if (active.getActiveObject().getSelectionStyles()[i].fontStyle === 'italic') {
                    // // // // console.log('yes im true');
                    italiccount++
                }
                else {
                    // // // // console.log('yes im false');
                }
            }
            // // // console.log('italic-count', italiccount);
            let temp = active.getActiveObject().get('text').length
            if (italiccount === (active.getActiveObject().getSelectedText().length)) {
                // // // console.log('unitalic')
                // // // console.log(active.getActiveObject().getSelectedText().length / 2)
                active.getActiveObject().setSelectionStyles({ fontStyle: 'normal' })
                if (italiccount === temp) {
                    active.getActiveObject().set({ fontStyle: 'normal' })
                    setItalic(false)
                }
                setItalic(false)
                active.renderAll()
            }
            else if (italiccount === 0) {
                // // // console.log('italic');
                active.getActiveObject().setSelectionStyles({ fontStyle: 'italic' })
                if (italiccount === temp) {
                    setItalic(true)
                    active.getActiveObject().set({ fontStyle: 'italic' })
                }
                setItalic(false)
            }
            else if (italiccount >= active.getActiveObject().getSelectedText().length / 2) {
                // // // console.log('italic')
                active.getActiveObject().setSelectionStyles({ fontStyle: 'italic' })
                setItalic(false)
                active.getActiveObject().set({ fontStyle: 'normal' })
            }
            else if (italiccount <= active.getActiveObject().getSelectedText().length / 2) {
                // // // console.log('unitalic')
                active.getActiveObject().setSelectionStyles({ fontStyle: 'normal' })
                setItalic(false)
                active.getActiveObject().set({ fontStyle: 'normal' })
            }

        }
        else {
            if (active.getActiveObject().fontStyle == 'normal') {
                let temp = active.getActiveObject().get('text').length
                active.getActiveObject().setSelectionStyles(({ fontStyle: 'italic' }), 0, temp)
                active.getActiveObject().set({ fontStyle: 'italic' })
                setItalic(true)
            }
            else {
                let temp = active.getActiveObject().get('text').length
                active.getActiveObject().setSelectionStyles(({ fontStyle: 'normal' }), 0, temp)
                active.getActiveObject().set({ fontStyle: 'normal' })
                setItalic(false)

            }
        }
        active.renderAll()
    }
    const handleUnderline = () => {
        const active = editor.canvas
        if (editor.canvas.getActiveObject().getSelectedText()) {
            let Underlinecount = 0
            for (let i = 0; i < active.getActiveObject().getSelectedText().length; i++) {
                if (active.getActiveObject().getSelectionStyles()[i].underline === true) {
                    // // // // console.log('yes im true');
                    Underlinecount++
                }
                else {
                    // // // // console.log('yes im false');
                }
            }
            // // // console.log('Underlinecount', Underlinecount);
            let temp = active.getActiveObject().get('text').length
            if (Underlinecount === (active.getActiveObject().getSelectedText().length)) {
                // // // console.log('un-Underline')
                // // // console.log(active.getActiveObject().getSelectedText().length / 2)
                active.getActiveObject().setSelectionStyles({ underline: false })
                if (Underlinecount === temp) {
                    active.getActiveObject().set({ underline: false })
                    setUnderline(false)
                }
                setUnderline(false)
                active.renderAll()
            }
            else if (Underlinecount === 0) {
                // // // console.log('underline');
                active.getActiveObject().setSelectionStyles({ underline: true })
                if (Underlinecount === temp) {
                    setUnderline(true)
                    active.getActiveObject().set({ underline: true })
                }
                setUnderline(false)
            }
            else if (Underlinecount >= active.getActiveObject().getSelectedText().length / 2) {
                // // // console.log('underline')
                active.getActiveObject().setSelectionStyles({ underline: true })
                setUnderline(false)
                active.getActiveObject().set({ underline: false })
            }
            else if (Underlinecount <= active.getActiveObject().getSelectedText().length / 2) {
                // // // console.log('un-underline')
                active.getActiveObject().setSelectionStyles({ underline: false })
                setUnderline(false)
                active.getActiveObject().set({ underline: false })
            }

        }
        else {
            if (active.getActiveObject().underline == false) {
                let temp = active.getActiveObject().get('text').length
                active.getActiveObject().setSelectionStyles(({ underline: true }), 0, temp)
                active.getActiveObject().set({ underline: true })
                setUnderline(true)
            }
            else {
                let temp = active.getActiveObject().get('text').length
                active.getActiveObject().setSelectionStyles(({ underline: false }), 0, temp)
                active.getActiveObject().set({ underline: false })
                setUnderline(false)

            }
        }
        active.renderAll()
    }
    const handlinethrough = () => {
        const active = editor.canvas
        if (editor.canvas.getActiveObject().getSelectedText()) {
            let linethroughcount = 0
            for (let i = 0; i < active.getActiveObject().getSelectedText().length; i++) {
                if (active.getActiveObject().getSelectionStyles()[i].linethrough === true) {
                    // // // // console.log('yes im true');
                    linethroughcount++
                }
                else {
                    // // // // console.log('yes im false');
                }
            }
            // // // console.log('linethroughcount', linethroughcount);
            let temp = active.getActiveObject().get('text').length
            if (linethroughcount === (active.getActiveObject().getSelectedText().length)) {
                // // // console.log('un-linethrough')
                // // // console.log(active.getActiveObject().getSelectedText().length / 2)
                active.getActiveObject().setSelectionStyles({ linethrough: false })
                if (linethroughcount === temp) {
                    active.getActiveObject().set({ linethrough: false })
                    setLinethrough(false)
                }
                setLinethrough(false)
                active.renderAll()
            }
            else if (linethroughcount === 0) {
                // // // console.log('linethrough');
                active.getActiveObject().setSelectionStyles({ linethrough: true })
                if (linethroughcount === temp) {
                    setLinethrough(true)
                    active.getActiveObject().set({ linethrough: true })
                }
                setLinethrough(false)
            }
            else if (linethroughcount >= active.getActiveObject().getSelectedText().length / 2) {
                // // // console.log('linethrough')
                active.getActiveObject().setSelectionStyles({ linethrough: true })
                setLinethrough(false)
                active.getActiveObject().set({ linethrough: false })
            }
            else if (linethroughcount <= active.getActiveObject().getSelectedText().length / 2) {
                // // // console.log('un-linethrough')
                active.getActiveObject().setSelectionStyles({ linethrough: false })
                active.getActiveObject().set({ linethrough: false })
                setLinethrough(false)
            }

        }
        else {
            if (active.getActiveObject().linethrough == false) {
                let temp = active.getActiveObject().get('text').length
                active.getActiveObject().setSelectionStyles(({ linethrough: true }), 0, temp)
                active.getActiveObject().set({ linethrough: true })
                setLinethrough(true)
            }
            else {
                let temp = active.getActiveObject().get('text').length
                active.getActiveObject().setSelectionStyles(({ linethrough: false }), 0, temp)
                active.getActiveObject().set({ linethrough: false })
                setLinethrough(false)

            }
        }
        active.renderAll()
    }
    const handoverline = () => {
        const active = editor.canvas
        if (editor.canvas.getActiveObject().getSelectedText()) {
            let overlinecount = 0
            for (let i = 0; i < active.getActiveObject().getSelectedText().length; i++) {
                if (active.getActiveObject().getSelectionStyles()[i].overline === true) {
                    // // // // console.log('yes im true');
                    overlinecount++
                }
                else {
                    // // // // console.log('yes im false');
                }
            }
            // // // console.log('overlinecount', overlinecount);
            let temp = active.getActiveObject().get('text').length
            if (overlinecount === (active.getActiveObject().getSelectedText().length)) {
                // // // console.log('un-overline')
                // // // console.log(active.getActiveObject().getSelectedText().length / 2)
                active.getActiveObject().setSelectionStyles({ overline: false })
                if (overlinecount === temp) {
                    active.getActiveObject().set({ overline: false })
                    setOverline(false)
                }
                setOverline(false)
                active.renderAll()
            }
            else if (overlinecount === 0) {
                // // // console.log('overline');
                active.getActiveObject().setSelectionStyles({ overline: true })
                if (overlinecount === temp) {
                    setOverline(true)
                    active.getActiveObject().set({ overline: true })
                }
                setOverline(false)
            }
            else if (overlinecount >= active.getActiveObject().getSelectedText().length / 2) {
                // // // console.log('overline')
                active.getActiveObject().setSelectionStyles({ overline: true })
                setOverline(false)
                active.getActiveObject().set({ overline: false })
            }
            else if (overlinecount <= active.getActiveObject().getSelectedText().length / 2) {
                // // // console.log('un-overline')
                active.getActiveObject().setSelectionStyles({ overline: false })
                active.getActiveObject().set({ overline: false })
                setOverline(false)
            }

        }
        else {
            if (active.getActiveObject().overline == false) {
                let temp = active.getActiveObject().get('text').length
                active.getActiveObject().setSelectionStyles(({ overline: true }), 0, temp)
                active.getActiveObject().set({ overline: true })
                setOverline(true)
            }
            else {
                let temp = active.getActiveObject().get('text').length
                active.getActiveObject().setSelectionStyles(({ overline: false }), 0, temp)
                active.getActiveObject().set({ overline: false })
                setOverline(false)

            }
        }
        active.renderAll()
    }

    const [elementsobject, setElementsobject] = useState(false)
    const ele = (e) => {

        setElementsobject(true)
        fabric.loadSVGFromURL(e.target.src, function (objects, options) {
            var loadedObjects = fabric.util.groupSVGElements(objects, options);
            loadedObjects.set({ subTargetCheck: true })
            loadedObjects.scaleToWidth(editor.canvas.width / 3);
            loadedObjects.scaleToHeight(editor.canvas.height / 3);
            editor.canvas.add(loadedObjects);
            editor.canvas.setActiveObject(loadedObjects);

            editor.canvas.renderAll();
        });

    }


    if (editor) {
        if (editor.canvas.getActiveObject()?.type == 'group') {
            editor.canvas.on('mouse:dblclick', function (e) {
                // clicked item will be
                //   // // // console.log()  
                if (editor.canvas.getActiveObject()?.type == 'group') {
                    editor.canvas.setActiveObject(e.subTargets[0]);
                }
                //   editor.canvas.renderAll()
            });
        }
    }














    const [canvasbgcolor, setCanvasbgcolor] = useState('rgba(255,255,255,1)')

    const Eye = () => {
        const { open, close } = useEyeDropper()
        const [eyecolor, setEyecolor] = useState(canvasbgcolor)
        const [eyeerror, setEyeerror] = useState()
        // useEyeDropper will reject/cleanup the open() promise on unmount,
        // so setState never fires when the component is unmounted.
        const pickColor = () => {
            open()
                .then(eyecolor => setEyecolor(eyecolor.sRGBHex))
                .catch(e => {
                    // // // console.log(e)
                    // Ensures component is still mounted
                    // before calling setState
                    if (!e.canceled) setEyeerror(e)
                })
        }
        setCanvasbgcolor(eyecolor)
        return (
            <>
                <button onClick={pickColor} className="bgeye" style={{ border: 'none', }}><ColorizeIcon /></button>
                {!!eyeerror && <span>{eyeerror.message}</span>}
            </>
        )
    }


    const addbgimg = (e) => {

        var filterBrightness = new fabric.Image.filters.Brightness({
            brightness: 0,
        });
        var filterContrast = new fabric.Image.filters.Contrast({
            contrast: 0,
        });
        var filterSaturation = new fabric.Image.filters.Saturation({
            saturation: 0,
        });
        var filterBlur = new fabric.Image.filters.Blur({
            blur: 0,
        });
        var filterVibrance = new fabric.Image.filters.Vibrance({
            vibrance: 0,
        });

        var filterNoice = new fabric.Image.filters.Noise({
            noise: 0,
        });

        var filterHue = new fabric.Image.filters.HueRotation({
            rotation: 0,
        });
        // var filterPixalate = new fabric.Image.filters.Pixelate({
        //     blocksize: 0,
        // });




        fabric.Image.fromURL(e.target.src, (bg) => {
            // bg.scaleToWidth(200);

            var obg = bg.set({

                top: 0,
                lef: 0,

            })


            obg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)
            // editor.canvas.setBackgroundImage(obg);
            editor.canvas.add(obg)
            editor.canvas.setActiveObject(obg)
            editor.canvas.getActiveObject().set({

                id: 'background',
                top: 0,
                left: 0,
                originX: 'left',
                originY: 'top',
                crossOrigin: 'anonymous',
                scaleX: editor.canvas.getWidth() / obg.width,
                scaleY: editor.canvas.getHeight() / obg.height,

            })
            // editor.canvas.setBackgroundImage(editor.canvas.getActiveObject());

            editor.canvas.renderAll();
            setToolbar(true);
            setBackgroundUpdate(true);
            setDisableLi(true)

        }, { crossOrigin: 'anonymous' });

    };

    // const [yes, setYes] = useState(false)
    const [backgroundEdit, setBackgroundEdit] = useState(false)


    if (editor) {
        if (editor.canvas) {
            editor.canvas.on('mouse:dblclick', function (e) {
                // clicked item will be
                //   // // // console.log()  
                if (!editor.canvas.getActiveObject()) {
                    if (editor.canvas.backgroundImage.id == 'background') {
                        // editor.canvas.hoverCursor = 'move'
                        // editor.canvas.setActiveObject(editor.canvas.backgroundImage)
                        setBackgroundEdit(true)
                        // console.log(backgroundEdit);
                        editor.canvas.renderAll()
                    }
                }
                //   editor.canvas.renderAll()
            });
        }
    }


    //   const addasbackground = (file) => {

    //     var filterBrightness = new fabric.Image.filters.Brightness({
    //         brightness: 0,
    //     });
    //     var filterContrast = new fabric.Image.filters.Contrast({
    //         contrast: 0,
    //     });
    //     var filterSaturation = new fabric.Image.filters.Saturation({
    //         saturation: 0,
    //     });
    //     var filterBlur = new fabric.Image.filters.Blur({
    //         blur: 0,
    //     });
    //     var filterVibrance = new fabric.Image.filters.Vibrance({
    //         vibrance: 0,
    //     });

    //     var filterNoice = new fabric.Image.filters.Noise({
    //         noise: 0,
    //     });

    //     var filterHue = new fabric.Image.filters.HueRotation({
    //         rotation: 0,
    //     });
    //     // var filterPixalate = new fabric.Image.filters.Pixelate({
    //     //     blocksize: 0,
    //     // });





    //     fabric.Image.fromURL(URL.createObjectURL(file), (bg) => {
    //         var obg = bg.set({
    //             id: 'background',

    //         })

    //         obg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)
    //         editor.canvas.setBackgroundImage(obg);
    //         editor.canvas.renderAll();


    //     });

    //   }

    //   const addaselement = (file) => {
    //     setElementsobject(true)
    //     fabric.loadSVGFromURL(URL.createObjectURL(file), function (objects, options) {
    //         var loadedObjects = fabric.util.groupSVGElements(objects, options);
    //         loadedObjects.set({ subTargetCheck: true })
    //         loadedObjects.scaleToWidth(editor.canvas.width / 3);
    //         loadedObjects.scaleToHeight(editor.canvas.height / 3);
    //         editor.canvas.add(loadedObjects);
    //         editor.canvas.setActiveObject(loadedObjects);

    //         editor.canvas.renderAll();
    //     });
    // }

    const [backgroundUpdate, setBackgroundUpdate] = useState(false)

    const setAsbackgroundIm = () => {

        setDisableLi(true)
        if (editor) {
            if (editor.canvas.getActiveObject()) {

                // editor.canvas.setBackgroundImage(editor.canvas.getActiveObject(),editor.canvas.renderAll.bind(editor.canvas), {
                editor.canvas.getActiveObject().set({
                    top: 0,
                    left: 0,
                    originX: 'left',
                    originY: 'top',
                    scaleX: editor.canvas.getWidth() / editor.canvas.getActiveObject().width,
                    scaleY: editor.canvas.getHeight() / editor.canvas.getActiveObject().height,
                    crossOrigin: 'anonymous',
                    // id: 'background'

                });
                // editor.canvas.remove(editor.canvas.getActiveObject())
                editor.canvas.renderAll();
                setBackgroundUpdate(true)
                // // // console.log('clicked');
            }
        }

    }

    const handleConfirmBg = () => {
        editor.canvas.setBackgroundImage(editor.canvas.getActiveObject(), editor.canvas.renderAll.bind(editor.canvas), {
            id: 'background',
            scaleX: (editor.canvas.getWidth() / editor.canvas.getActiveObject().width) + 0.01,
            scaleY: (editor.canvas.getHeight() / editor.canvas.getActiveObject().height) + 0.01,
        })
        editor.canvas.remove(editor.canvas.getActiveObject())
        editor.canvas.renderAll()
        setBackgroundUpdate(false)
        setBackgroundEdit(false)
        setDisableLi(false)



    }

    const handleCancelBg = () => {
        setBackgroundUpdate(false)
        setDisableLi(false)

        editor.canvas.getActiveObject().set({
            top: 0,
            left: 0,
            scaleX: 0.4,
            scaleY: 0.4
        })
    }


    const removeBackground = () => {

        editor.canvas.setBackgroundImage(null, editor.canvas.renderAll.bind(editor.canvas));
        setCanvasbgcolor("#fff")
        setBackgroundEdit(false)

        editor.canvas.renderAll();

    }

    const [images, setImages] = useState()
    const [bgimages, setBgimages] = useState()
    const [elementsImages, setElementsImages] = useState()
    // const Auth = '563492ad6f917000010000012ccdde633dac40949cc7797a1528cf8b'
    async function api() {
        // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
        const response = await fetch(`https://pixabay.com/api/?key=30200687-76798c9d59045bc0f4818a479&q=${photosearchword}&image_type=photo&per_page=100`);
        const jsonifiedResponse = await response.json();
        // console.log(jsonifiedResponse);
        setImages(jsonifiedResponse.hits)
    }
    async function bgapi() {
        // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
        const response = await fetch(`https://pixabay.com/api/?key=30200687-76798c9d59045bc0f4818a479&q=pattern&image_type=photo&per_page=200`);
        const jsonifiedResponse = await response.json();
        // console.log(jsonifiedResponse);
        setBgimages(jsonifiedResponse.hits)
    }

    let today = new Date();
    let hour = today.getHours()
    async function elementsimage() {
        if(hour<12){
            setElementsearchword('morning')
        }
        else if(hour>=12){
            setElementsearchword('afternoon')
        }
        else if(hour>=15){
            setElementsearchword('evening')
        }
        else if(hour >= 18){
            setElementsearchword('night')
        }
        else if(hour >= 5){
            setElementsearchword('morning')
        }
    
        // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
        const response = await fetch(`https://pixabay.com/api/?key=30200687-76798c9d59045bc0f4818a479&q=${elementsearchword}&image_type=vector&per_page=200&order=latest`);
        const jsonifiedResponse = await response.json();
        // console.log(jsonifiedResponse);
        setElementsImages(jsonifiedResponse.hits)
    }




    // async function api() {
    //     // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
    //     const response = await fetch(`https://api.pexels.com/v1/search?query=${photosearchword}&per_page=100`, {
    //         method: "GET",
    //         headers: {
    //             Accept: "application/json",
    //             Authorization: Auth
    //         }
    //     });
    //     const jsonifiedResponse = await response.json();
    //     // // // console.log(jsonifiedResponse.photos);
    //     setImages(jsonifiedResponse.photos)
    // }
    // async function bgapi() {
    //     // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
    //     const response = await fetch(`https://api.pexels.com/v1/search?query=background&per_page=100`, {
    //         method: "GET",
    //         headers: {
    //             Accept: "application/json",
    //             Authorization: Auth
    //         }
    //     });
    //     const jsonifiedResponse = await response.json();
    //     // // // // console.log(jsonifiedResponse);
    //     setBgimages(jsonifiedResponse.photos)
    // }


    // //   api()
    const canvasimgurlapi = []

    // useEffect(() => {
    //     api()
    //     bgapi()
    // }, [])



    const photosearchinputer = useRef()
    const [photosearchword, setPhotosearchword] = useState('background')
    const [elementsearchword, setElementsearchword] = useState('morning')

    // const getsavedtemplate = () => {
    //     editor.canvas.clear()
    //     loadtemplateJsonCan()
    // }


    const [call, setCall] = useState(1)

    const [tempid, setTempid] = useState(1)

    // const saveAsTemp = () => {
    //     if (editor) {
    //         const thumbail = editor.canvas.toDataURL({ format: 'png' })
    //         // // // // console.log(thumbail);

    //         function dataURLtoFile(dataurl, filename) {

    //             var arr = dataurl.split(','),
    //                 mime = arr[0].match(/:(.*?);/)[1],
    //                 bstr = atob(arr[1]),
    //                 n = bstr.length,
    //                 u8arr = new Uint8Array(n);

    //             while (n--) {
    //                 u8arr[n] = bstr.charCodeAt(n);
    //             }

    //             return new File([u8arr], filename, { type: mime });
    //         }

    //         var file = dataURLtoFile(thumbail, 'thumbnail_' + name + "_" + tempid + '.png');


    //         const tempdata = JSON.stringify(editor.canvas.toJSON(['id', 'height', 'width', 'subTargetCheck', 'canvasimg', 'breakWords','status','lockMovementX','lockMovementY','editable']));

    //         (async function () {
    //             var formdata = new FormData();
    //             formdata.append("template_json", tempdata);
    //             formdata.append("thumbnail", file)
    //             formdata.append("file_name", name)
    //             formdata.append("width", canvasWidth)
    //             formdata.append("height", canvasHeight)


    //             // formdata.append("languages", result);

    //             var requestOptions = {
    //                 method: 'POST',
    //                 headers: handleAuthTrue(new Headers()),
    //                 body: formdata,
    //                 redirect: 'follow'
    //             };

    //             let data = await fetch(LABS_API + "/canvas/canvas-templates/", requestOptions)
    //             if (data.status === 200) {
    //                 let response = await data.json()
    //                 setCall(call + 1)
    //                 // getsavedtemplate()
    //             }
    //             else {

    //                 console.log('error');
    //             }
    //         })();
    //     }


    // }
    const [apiData, setApiData] = useState(null)

    const [temp, setTemp] = useState([])





    const loaddesignJsonCan = () => {
        (async function () {

            var requestOptions = {
                method: 'GET',
                headers: handleAuthTrue(new Headers()),
                // body: formdata,
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-designs/", requestOptions)
            if (data.status === 200) {
                let response = await data.json()
                setDesign(response)
                setApiData(response[response.length - 1].template_json)
                setName(response[response.length - 1].file_name)
                setDesignid(response[response.length - 1].id)
                setReuseddesignname(response[response.length - 1].file_name)
                setReuseddesignboolen(true)
                setReusedtempboolen(false)
                setCanvaswidth(response[response.length - 1].width)
                setCanvasHeight(response[response.length - 1].height)
                // editor.canvas.loadFromJSON(response[response.length - 1].template_json, function () {
                //     editor.canvas.renderAll();

                // }, function (o, object) {
                // })
            }
            else {

                console.log('error');
            }
        })();



    }


    // const loadtemplateJsonCan = () => {
    //     (async function () {

    //         var requestOptions = {
    //             method: 'GET',
    //             headers: handleAuthTrue(new Headers()),
    //             // body: formdata,
    //             redirect: 'follow'
    //         };

    //         let data = await fetch(LABS_API + "/canvas/canvas-templates/", requestOptions)
    //         if (data.status === 200) {
    //             let response = await data.json()
    //             setTemp(response)
    //             setApiData(response[response.length - 1].template_json)
    //             setName(response[response.length - 1].file_name)
    //             setTemplateid(response[response.length - 1].id)
    //             setReusedtempname(response[response.length - 1].file_name)
    //             setReusedtempboolen(true)
    //             setReuseddesignboolen(false)
    //             setCanvaswidth(response[response.length - 1].width)
    //             setCanvasHeight(response[response.length - 1].height)
    //             editor.canvas.loadFromJSON(response[response.length - 1].template_json, function () {
    //                 editor.canvas.renderAll();

    //             }, function (o, object) {
    //             })
    //         }
    //         else {

    //             console.log('error');
    //         }
    //     })();


    // }






    const [contextMenu, setContextMenu] = React.useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    // const deletetempfromapi = (id) => {
    //     (async function () {

    //         var requestOptions = {
    //             method: 'DELETE',
    //             headers: handleAuthTrue(new Headers()),
    //             // body: formdata,
    //             redirect: 'follow'
    //         };

    //         let data = await fetch(LABS_API + "/canvas/canvas-templates/" + id + "/", requestOptions)
    //         setCall(call + 1)
    //         if (data.status === 200) {
    //             let response = await data.json()
    //             // // // console.log('successfully deleted');
    //         }
    //         else {

    //             // // // console.log('error');
    //         }
    //     })();
    // }
    const [templateid, setTemplateid] = useState(null)
    // const updateTemplatefromapi = () => {
    //     if (editor) {
    //         const thumbail = editor.canvas.toDataURL({ format: 'png' })

    //         function dataURLtoFile(dataurl, filename) {

    //             var arr = dataurl.split(','),
    //                 mime = arr[0].match(/:(.*?);/)[1],
    //                 bstr = atob(arr[1]),
    //                 n = bstr.length,
    //                 u8arr = new Uint8Array(n);

    //             while (n--) {
    //                 u8arr[n] = bstr.charCodeAt(n);
    //             }

    //             return new File([u8arr], filename, { type: mime });
    //         }

    //         var file = dataURLtoFile(thumbail, 'thumbnail_' + name + "_" + tempid + '.png');
    //         // // // console.log(file);
    //         // setNavbar(true);
    //         // setAilaysaWorkspace(true);
    //         // // // // console.log(typeof (jsdata));
    //         // // // // console.log(JSON.stringify(jsdata));

    //         // const jsonTempdata = JSON.parse(tempdata);
    //         // // // // console.log(jsonTempdata);
    //         //  // // // console.log(jsdata,tLanguages);
    //         const tempdata = JSON.stringify(editor.canvas.toJSON(['id', 'height', 'width', 'subTargetCheck', 'canvasimg', 'breakWords','status','lockMovementX','lockMovementY','editable']));
    //         (async function () {
    //             var formdata = new FormData();
    //             formdata.append("template_json", tempdata);
    //             formdata.append("thumbnail", file)
    //             formdata.append("file_name", name)
    //             formdata.append("width", canvasWidth)
    //             formdata.append("height", canvasHeight)


    //             // formdata.append("languages", result);

    //             var requestOptions = {
    //                 method: 'PUT',
    //                 headers: handleAuthTrue(new Headers()),
    //                 body: formdata,
    //                 redirect: 'follow'
    //             };

    //             let data = await fetch(LABS_API + "/canvas/canvas-templates/" + templateid + "/", requestOptions)
    //             if (data.status === 200) {
    //                 let response = await data.json()
    //                 // // // console.log(response);
    //                 setCall(call + 1)
    //                 // setMultilingualCanvasData(JSON.parse(response.result_canvas))
    //             }
    //             else {

    //                 // // // console.log('error');
    //             }
    //         })();
    //     }


    // }

    const [canvasismodified, setCanvasismodified] = useState(false)
    const [translatedCanvasModified, setTranslatedCanvasModified] = useState(false)

    const [canvasstatechange, setCanvasstatechange] = useState(1)

    const canvasModifiedCallback = function () {
        setCanvasstatechange(canvasstatechange + 1)

    };

    if (editor) {
        // editor.canvas.on('before:transform', canvasModifiedCallback);
        // editor.canvas.on('before:selection:cleared', canvasModifiedCallback);
        // editor.canvas.on('object:added', canvasModifiedCallback);
        editor.canvas.on('object:modified', canvasModifiedCallback);
        // editor.canvas.on('selection:updated', canvasModifiedCallback);
        editor.canvas.on('text:editing', canvasModifiedCallback)
        editor.canvas.on('text:editing:exited', canvasModifiedCallback);
    }




    useEffect(() => {
        if (reuseddesignboolen) {
            if (canvasismodified == true) {
                updateDesignfromapi()
            }
            if (translatedCanvasModified == true) {
                updateDesignfromapi2()
            }
        }


    }, [canvasstatechange])

    const updateDesignfromapi = () => {
        if (editor) {
            const thumbail = editor.canvas.toDataURL({ 
                format: 'jpeg',
                quality:0.5
        })
        const exportImage = editor.canvas.toDataURL({ 
            format: 'png',
        })


            function dataURLtoFile(dataurl, filename) {

                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }

                return new File([u8arr], filename, { type: mime });
            }

            var file = dataURLtoFile(thumbail, 'thumbnail_' + name + "_" + designid + '.jpeg');
            var file2 = dataURLtoFile(exportImage, "PageNo_"+ pageNo + ".png");



            const tempdata = JSON.stringify(editor.canvas.toJSON(['id', 'height', 'width', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','newtext']));
            (async function () {

                var formdata = new FormData();

                formdata.append("source_json_file", tempdata);
                formdata.append("thumbnail_src", file)
                formdata.append("export_img_src", file2);
                formdata.append("src_page", pageNo)

                var requestOptions = {
                    method: 'PUT',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    // getProjectData2()
                    setPageData(response.source_json)
                    setJsonDatainfoFinal(response.source_json)
                }
                else {

                    console.log('error');
                }

            })();
        }


    }
  

    const pdf = () => {
      const svg = editor.canvas.toSVG();
    //   const image = editor.canvas.toSVG();
      const canvasimgurl = "data:image/svg+xml," + encodeURIComponent(svg);
      var blob = new Blob([svg], {type: "image/svg+xml"});  
      saveAs(blob, "graph.svg");
    //   const link = document.createElement("a");
    //   link.href = canvasimgurl;
    //   console.log(`${canvasimgurl}`);
    // console.log(svg);
    //   link.download = "eraser_example.svg";
    //   link.click();
    // document.querySelector("#image").innerHTML = svg;    
    //             // const a = document.createElement("a");
    //             // const blob = new Blob([svg], { type: "image/svg+xml" });
    //             // const blobURL = URL.createObjectURL(blob);
    //             // a.href = blobURL;
    //             // a.download = "eraser_example.svg";
    //             // a.click();
    //             // URL.revokeObjectURL(blobURL);
    //             // setDownload(false)
    // //     const doc = new jsPDF()
    // //     let x= 0
    // //    let  y= 0
    // //    let width = 600
    // //     let height = 600
    // //    doc.svg(image, {
    // //             x,
    // //             y,
    // //             width,
    // //             height
    // //         })
    // //         .then(() => {
    // //             // save the created pdf
    // //             doc.save('myPDF.pdf')
    // //         })
   
    //     // console.log('clicked');
    //     // const fabric = require('fabric').fabric;
    //     // const { createCanvas } = require('canvas')
    //     // const utils = require('jsdom/lib/jsdom/living/generated/utils');
    //     // // const svg = fs.readFileSync(__dirname + '/file.svg', 'utf8');
    //     // const mPDFcanvas = createCanvas(800, 800, 'pdf') // see "PDF Support" section
    //     // const el = fabric.document.createElement('canvas');
    //     // el[utils.implSymbol]._canvas = mPDFcanvas;
    //     // const fabricCanvas = new fabric.StaticCanvas(el, { width: 800, height: 800 });

    //     // fabric.loadSVGFromString(editor.canvas.toSVG(), function (objects, options) {
    //     //     var svg = fabric.util.groupSVGElements(objects, options);
    //     //     fabricCanvas.add(svg);
    //     //     fabricCanvas.renderAll();
    //     //     var stream = fabric.util.getNodeCanvas(fabricCanvas.lowerCanvasEl).createPDFStream();
    //     //     // var stream = fabricCanvas.createPNGStream();
    //     //     stream.pipe(filesaver.createWriteStream('output.pdf'));
    //     // });
    }



    const [targetlanNo, setTargetLanNo] = useState()
    const [canvasTransJsonId, setCanvasTransJsonId] = useState()

    const updateDesignfromapi2 = () => {
        if (editor) {
            const thumbail = editor.canvas.toDataURL({ 
                format: 'jpeg',
                quality: 0.5,
         })
            const exportImage = editor.canvas.toDataURL({ 
                format: 'png',
            })

            function dataURLtoFile(dataurl, filename) {

                var arr = dataurl.split(','),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }

                return new File([u8arr], filename, { type: mime });
            }

            var file = dataURLtoFile(thumbail, 'thumbnail_' + name + "_" + designid + '.jpeg');
            var file2 = dataURLtoFile(exportImage, "PageNo_"+ pageNo + ".png");
            const tempdata = JSON.stringify(editor.canvas.toJSON(['id', 'height', 'width', 'subTargetCheck', 'canvasimg', 'breakWords', 'status', 'lockMovementX', 'lockMovementY', 'editable','newtext']));
            (async function () {
                var formdata = new FormData();
                formdata.append("target_json_file", tempdata);
                formdata.append("canvas_translation_tar_thumb", file)
                formdata.append("canvas_translation_tar_export", file2)
                formdata.append("canvas_translation_tar_lang", `${targetlanNo}`)
                formdata.append("canvas_translation_target", `${canvasTransJsonId}`)
                formdata.append("tar_page", pageNo)



                var requestOptions = {
                    method: 'PUT',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(LABS_API + "/canvas/canvas-designs/" + designid + "/", requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    setLanguageData(response.canvas_translation)
                    let data2 = response.canvas_translation
                    let something = data2.filter(function (item) {
                        return item.id == canvasTransJsonId;
                    }).map(function ({ canvas_design, id, source_language, target_language, tranlated_json }) {
                        return { canvas_design, id, source_language, target_language, tranlated_json };
                    });
                    setPageData(something[0].tranlated_json)



                    // getProjectData2()
                }
                else {

                    console.log('error');
                }

            })();
        }


    }


    const [buttontext, setButtontext] = useState('Save as Template')
    const [reusedtempboolen, setReusedtempboolen] = useState(false)
    const [reusedtempname, setReusedtempname] = useState(null)
    const [buttontext2, setButtontext2] = useState('Save as Design')
    const [designid, setDesignid] = useState(new2.id)
    const [reuseddesignname, setReuseddesignname] = useState(null)
    const [reuseddesignboolen, setReuseddesignboolen] = useState(true)



    const getsaveddesign = () => {
        editor.canvas.clear()
        loaddesignJsonCan()
    }






    const deletedesignfromapi = (id) => {
        (async function () {


            var requestOptions = {
                method: 'DELETE',
                headers: handleAuthTrue(new Headers()),
                redirect: 'follow'
            };

            let data = await fetch(LABS_API + "/canvas/canvas-designs/" + id + "/", requestOptions)
            setCall(call + 1)
            if (data.status === 200) {
                let response = await data.json()
                getProjectData()
            }
            else {
                console.log('error');
            }
        })();
    }

    const reusedesigntem = useRef(true)



    const [transliterate, setTransliterate] = useState()

    // var _wrapLine = function (_line, lineIndex, desiredWidth, reservedSpace) {
    //     var lineWidth = 0,
    //         splitByGrapheme = this.splitByGrapheme,
    //         graphemeLines = [],
    //         line = [],
    //         // spaces in different languges?
    //         words = splitByGrapheme ? fabric.util.string.graphemeSplit(_line) : _line.split(this._wordJoiners),
    //         word = '',
    //         offset = 0,
    //         infix = splitByGrapheme ? '' : ' ',
    //         wordWidth = 0,
    //         infixWidth = 0,
    //         largestWordWidth = 0,
    //         lineJustStarted = true,
    //         additionalSpace = splitByGrapheme ? 0 : this._getWidthOfCharSpacing();

    //     reservedSpace = reservedSpace || 0;
    //     desiredWidth -= reservedSpace;
    //     for (var i = 0; i < words.length; i++) {
    //         // i would avoid resplitting the graphemes
    //         word = fabric.util.string.graphemeSplit(words[i]);
    //         wordWidth = this._measureWord(word, lineIndex, offset);
    //         offset += word.length;

    //         // Break the line if a word is wider than the set width
    //         if (this.breakWords && wordWidth >= desiredWidth) {

    //             if (!lineJustStarted) {
    //                 line.push(infix);
    //                 lineJustStarted = true;
    //             }

    //             // Loop through each character in word
    //             for (var w = 0; w < word.length; w++) {
    //                 var letter = word[w];
    //                 var letterWidth = this.getMeasuringContext().measureText(letter).width * this.fontSize / this.CACHE_FONT_SIZE;
    //                 if (lineWidth + letterWidth > desiredWidth) {
    //                     graphemeLines.push(line);
    //                     line = [];
    //                     lineWidth = 0;
    //                 } else {
    //                     line.push(letter);
    //                     lineWidth += letterWidth;
    //                 }
    //             }
    //             word = [];
    //         } else {
    //             lineWidth += infixWidth + wordWidth - additionalSpace;
    //         }

    //         if (lineWidth >= desiredWidth && !lineJustStarted) {
    //             graphemeLines.push(line);
    //             line = [];
    //             lineWidth = wordWidth;
    //             lineJustStarted = true;
    //         } else {
    //             lineWidth += additionalSpace;
    //         }

    //         if (!lineJustStarted) {
    //             line.push(infix);
    //         }
    //         line = line.concat(word);

    //         infixWidth = this._measureWord([infix], lineIndex, offset);
    //         offset++;
    //         lineJustStarted = false;
    //         // keep track of largest word
    //         if (wordWidth > largestWordWidth && !this.breakWords) {
    //             largestWordWidth = wordWidth;
    //         }
    //     }

    //     i && graphemeLines.push(line);

    //     if (largestWordWidth + reservedSpace > this.dynamicMinWidth) {
    //         this.dynamicMinWidth = largestWordWidth - additionalSpace + reservedSpace;
    //     }

    //     return graphemeLines;
    // };


    // fabric.util.object.extend(fabric.Textbox.prototype, {
    //     _wrapLine: _wrapLine,
    // });




    const check = () => {
        // // // // console.log(editor.canvas.getActiveObject().type);
        // // // // console.log(editor.canvas.getActiveObjects().length);
        console.log(images);
        // // // console.log(result);
    }


    const [disabled, setDisabled] = useState(false)
    const [alltext, setAlltext] = useState(false)
    const [multiselect, setMultiselect] = useState(false)
    const [groupname, setgroupname] = useState('Group')

    const SelectObject = (e) => {
        var objs = editor.canvas.getObjects();
        objs.forEach(function (obj) {
            console.log(obj.id);
            console.log(e.target.id);
            if (obj && obj.id == e.target.id) {
                editor.canvas.setActiveObject(obj);
                editor.canvas.renderAll();
            }


        });
    }


    const some = []
    const Ref = useRef(this);
    const lilTranslate = (k) => {

        //    const refinner = Ref.current.innerHTML 

        // // // console.log(some);


        // (async function () {
        //   var formdata = new FormData();
        //   formdata.append("Input", refinner);
        //   formdata.append("target_language", transliterate.replaceAll("ta", "Tamil").replaceAll("ja", "Japanese").replaceAll("zh-CN", "Chinese").replaceAll("en", "English").replaceAll("ar", "Arabic"));

        //   var requestOptions = {
        //     method: 'POST',
        //     headers: handleAuthTrue(new Headers()),
        //     body: formdata,
        //     redirect: 'follow'
        //   };

        //   let data = await fetch(LABS_API + "/voice/translate/", requestOptions)
        //   if (data.status === 200) {
        //     let response = await data.json()
        //         // // // console.log(response);
        //   }
        //   else {
        //     // // // console.log('error');
        //   }
        // })();
    }

    // const setPostEditedText = (e) => {
    //     if (document.querySelector(`#confirm${e.target.id}`).innerHTML == 'Confirmed') {
    //         editor.canvas.getActiveObject('textbox').set({
    //             lockMovementX: false,
    //             lockMovementY: false,
    //             editable: true,
    //             status: 'Unconfirmed'
    //         })
    //         editor.canvas.renderAll()
    //         setTranslatedCanvasModified(true)
    //     }
    //     else {

    //         editor.canvas.getActiveObject('textbox').set({
    //             lockMovementX: true,
    //             lockMovementY: true,
    //             editable: false,
    //             status: 'Confirmed'

    //         })
    //         editor.canvas.renderAll()
    //         setTranslatedCanvasModified(true)

    //     }

    // }



    // console.log(switchWorkspace);
    const confirmPostText = (e) => {
       
        // console.log(e.currentTarget.dataset.id);

        if (e.currentTarget.dataset.id  == 'boo') {
        editor.canvas.getActiveObject('textbox').set({
            lockMovementX: false,
            lockMovementY: false,
            editable: true,
            status: 'Unconfirmed'
        })
        editor.canvas.discardActiveObject()
        editor.canvas.renderAll()
        // setTranslatedCanvasModified(true)

        if(srLanguage == targetlanNo){
            updateDesignfromapi()
        }else{
            updateDesignfromapi2()

        }
        // console.log('unconfirmed');
    }
    }

    const unConfirmPostText = (e) => {
        // console.log('Confirmed');
        // console.log(e.currentTarget.dataset.id);
        if (e.currentTarget.dataset.id  == 'foo') {
        editor.canvas.getActiveObject('textbox').set({
            lockMovementX: true,
            lockMovementY: true,
            editable: false,
            status: 'Confirmed'

        })
        editor.canvas.discardActiveObject()
        editor.canvas.renderAll()
        if(srLanguage == targetlanNo){
            updateDesignfromapi()
        }else{
            updateDesignfromapi2()

        }
        // setTranslatedCanvasModified(true)


    }
    }



    const [transliteratebooleanstate, setTransliteratebooleanstate] = useState(false)

    const transliterateboolean = () => {
        if (transliteratebooleanstate) {
            setTransliteratebooleanstate(false)

        }
        else {
            setTransliteratebooleanstate(true)

        }
    }




    const [click, setClick] = useState(0)

    useEffect(() => {
        if (editor) {
            editor.canvas.getActiveObject()
        }
    }, [click])

    if (editor?.canvas.getActiveObject()?.type == 'textbox') {
        editor.canvas.getActiveObject().setControlsVisibility({
            mt: false, // middle top disable
            mb: false, // midle bottom
        })
        // editor.canvas.getActiveObject().on(("changed"),function(){
        //     //canvas.setActiveObject(textBox);
        //     var actualWidth = editor.canvas.getActiveObject().scaleX * editor.canvas.getActiveObject().width;
        //      var largest = editor.canvas.getActiveObject().__lineWidths[0];
        //      var tryWidth = (largest + 15) * editor.canvas.getActiveObject().scaleX;
        //      editor.canvas.getActiveObject().set("width",tryWidth);
        //      if((editor.canvas.getActiveObject().left + actualWidth) >= editor.canvas.getActiveObject().width - 10)
        //       {
        //           //// // // console.log(canvas.height - arrowLeft)
        //           editor.canvas.getActiveObject().set("width", editor.canvas.getActiveObject().width - editor.canvas.getActiveObject().left - 20)
        //      }
        //          editor.canvas.renderAll();

        //  });
        // // // // console.log((editor.canvas.getActiveObject().get('text').length)*18);
        // // // // console.log(editor.canvas.getActiveObject().__lineWidths[0]);
    }






    useEffect(() => {
        if (pageData != '') {
            // // // console.log(pageData);
        }
        // // // console.log('check fr logsss');
        if (languageData != '') {
            // // // console.log(languageData);
        }
    }, [pageData])




    const instaTranslateFunction = () => {
        editor.canvas.getObjects('textbox').forEach(e=>{
            if(e.newtext){
                (async function () {
                    var formdata = new FormData();
                    formdata.append("Input", e.text);
                    formdata.append("target_language", instaTranslate);
                    
                    var requestOptions = {
                        method: 'POST',
                        headers: handleAuthTrue(new Headers()),
                        body: formdata,
                        redirect: 'follow'
                    };
        
                    let data = await fetch(LABS_API + "/voice/translate/", requestOptions)
                    if (data.status === 200) {
                        let response = await data.json()
                        e.set({ text: response.text })
                        editor.canvas.renderAll();
        
                    }
                    else {
                        console.log('error');
                    }
                })();
            }
            else{
                return
            }
            if(e.newtext){
                console.log('yes');
            }
            // else{
            //     console.log('nope');
            // }
        })
        
    }



    return (

        <React.Fragment>
            <div className="ailaysa-canvas-workspace-wrapper container-fluid">
                <div className="ailaysa-canvas-workspace-siderbar-wrapper ">

                    {switchWorkspace == false &&
                        <div className={sideBarMyTemplateOpenClose || sideBarMyDesignOpenClose || sideBarTemplateOpenClose || sideBarBackgroundOpenClose || sideBarElementOpenClose || sideBarUploadOpenClose || sideBarTextOpenClose || sideBarPhotosOpenClose ? "ailaysa-canvas-editor-sidebar col-3 transition" : "ailaysa-canvas-editor-sidebar col-1 transition"}>
                            <div className='row sidbar-inner-wrap px-2'>

                                <div className={sideBarMyTemplateOpenClose || sideBarMyDesignOpenClose || sideBarTemplateOpenClose || sideBarBackgroundOpenClose || sideBarElementOpenClose || sideBarUploadOpenClose || sideBarTextOpenClose || sideBarPhotosOpenClose ? "ailaysa-canvas-sidebar-item-icons col-2 transition" : "ailaysa-canvas-sidebar-item-icons col-12 transition"}>
                                    <ul className="side-bar-list">
                                        <li className={sideBarMyDesignOpenClose ? "side-bar-act" : ""} style={{ pointerEvents: disableLi ? 'none' : '', opacity: disableLi ? '0.6' : '1' }} onClick={handleMyDesignSidebarOpen}>
                                            <div>
                                                <img src={template} alt='My Design' />
                                            </div>
                                            <span className={sideBarUploadOpenClose || sideBarMyDesignOpenClose || sideBarBackgroundOpenClose || sideBarMyTemplateOpenClose || sideBarTemplateOpenClose || sideBarElementOpenClose || sideBarPhotosOpenClose || sideBarTextOpenClose ? "sidebar-names" : "sidebar-names-expaneded"} >My Projects</span>
                                        </li>
                                        <li className={sideBarMyTemplateOpenClose ? "side-bar-act" : "d-none"} style={{ pointerEvents: disableLi ? 'none' : '', opacity: disableLi ? '0.6' : '1' }} onClick={handleMyTemplateSidebarOpen}>
                                            <div>
                                                <img src={template} alt='My Template' />
                                            </div>
                                            <span className={sideBarUploadOpenClose || sideBarMyDesignOpenClose || sideBarBackgroundOpenClose || sideBarMyTemplateOpenClose || sideBarTemplateOpenClose || sideBarElementOpenClose || sideBarPhotosOpenClose || sideBarTextOpenClose ? "sidebar-names" : "sidebar-names-expaneded"} >My Templates</span>

                                        </li>
                                        <li className={sideBarTemplateOpenClose ? "side-bar-act" : "d-none"} style={{ pointerEvents: disableLi ? 'none' : '', opacity: disableLi ? '0.6' : '1' }} onClick={handleTemplateSidebarOpen}>
                                            <div>
                                                <img src={template} alt='template' />
                                            </div>

                                            <span className={sideBarUploadOpenClose || sideBarMyDesignOpenClose || sideBarBackgroundOpenClose || sideBarMyTemplateOpenClose || sideBarTemplateOpenClose || sideBarElementOpenClose || sideBarPhotosOpenClose || sideBarTextOpenClose ? "sidebar-names" : "sidebar-names-expaneded"} >Templates</span>

                                        </li>
                                        <li className={sideBarTextOpenClose ? "side-bar-act" : ""} style={{ pointerEvents: disableLi ? 'none' : '', opacity: disableLi ? '0.6' : '1' }} onClick={handleTextSidebarOpen}>
                                            <div>
                                                <img src={text} alt='text' />
                                            </div>
                                            <span className={sideBarUploadOpenClose || sideBarMyDesignOpenClose || sideBarBackgroundOpenClose || sideBarMyTemplateOpenClose || sideBarTemplateOpenClose || sideBarElementOpenClose || sideBarPhotosOpenClose || sideBarTextOpenClose ? "sidebar-names" : "sidebar-names-expaneded"} >Text</span>


                                        </li>
                                        <li className={sideBarElementOpenClose ? "side-bar-act" : ""} style={{ pointerEvents: disableLi ? 'none' : '', opacity: disableLi ? '0.6' : '1' }} onClick={handleElementSidebarOpen}>
                                            <div>
                                                <img src={elements} alt='elements' />
                                            </div>
                                            <span className={sideBarUploadOpenClose || sideBarMyDesignOpenClose || sideBarBackgroundOpenClose || sideBarMyTemplateOpenClose || sideBarTemplateOpenClose || sideBarElementOpenClose || sideBarPhotosOpenClose || sideBarTextOpenClose ? "sidebar-names" : "sidebar-names-expaneded"} >Graphics</span>


                                        </li>
                                        <li className={sideBarPhotosOpenClose ? "side-bar-act" : ""} style={{ pointerEvents: disableLi ? 'none' : '', opacity: disableLi ? '0.6' : '1' }} onClick={handlePhotoSidebarOpen}>
                                            <div>
                                                <img src={photos} alt='photos' />
                                            </div>
                                            <span className={sideBarUploadOpenClose || sideBarMyDesignOpenClose || sideBarBackgroundOpenClose || sideBarMyTemplateOpenClose || sideBarTemplateOpenClose || sideBarElementOpenClose || sideBarPhotosOpenClose || sideBarTextOpenClose ? "sidebar-names" : "sidebar-names-expaneded"} >Photos</span>

                                        </li>
                                        <li className={sideBarBackgroundOpenClose ? "side-bar-act" : ""} onClick={handleBackgroundSidebaropen} >
                                            <div>
                                                <img src={background} alt='backfround' />
                                            </div>

                                            <span className={sideBarUploadOpenClose || sideBarMyDesignOpenClose || sideBarBackgroundOpenClose || sideBarMyTemplateOpenClose || sideBarTemplateOpenClose || sideBarElementOpenClose || sideBarPhotosOpenClose || sideBarTextOpenClose ? "sidebar-names" : "sidebar-names-expaneded"} >Background</span>

                                        </li>
                                        <li className={sideBarUploadOpenClose ? "side-bar-act" : ""} style={{ pointerEvents: disableLi ? 'none' : '', opacity: disableLi ? '0.6' : '1' }} onClick={handleUploadSidebarOpen}>
                                            <div>
                                                <img src={upload} alt='upload' />
                                            </div>
                                            <span className={sideBarUploadOpenClose || sideBarMyDesignOpenClose || sideBarBackgroundOpenClose || sideBarMyTemplateOpenClose || sideBarTemplateOpenClose || sideBarElementOpenClose || sideBarPhotosOpenClose || sideBarTextOpenClose ? "sidebar-names" : "sidebar-names-expaneded"} >Uploads</span>

                                        </li>
                                    </ul>
                                </div>
                                <div className={sideBarMyDesignOpenClose ? "ailaysa-canvas-sidebar-detail col-10 transition" : "d-none transition"}>
                                    <div className="sidebar-content">
                                        <div className="sidebar-tabs-heading">
                                            <h4>My Projects</h4>

                                            <CloseIcon className="close-icon" onClick={handleSidebarClose} />
                                        </div>
                                        <div className="sidebar-search">
                                            <SearchIcon className="seacrh-icon" />
                                            <span className="search-placeholder-text">Search...</span>
                                        </div>
                                        <div className="grid-1 ">
                                            {design.map((file, key) => {


                                                return (

                                                    <>
                                                        {(file.canvas_translation != '') &&
                                                            <div style={{ width: '100%' }} key={key}>
                                                                {

                                                                    file.source_json.map((filesource, keysoure) => {
                                                                        return (
                                                                            <>
                                                                                {(keysoure == '0') &&
                                                                                
                                                                                    <div className={designid == filesource.canvas_design?  "grid-item2": "grid-item-uploads2 "} >
                                                                                        <div className="project-list" onClick={() => {
                                                                                            setCanvasismodified(true)
                                                                                            setTranslatedCanvasModified(false)
                                                                                            setName(file.file_name)
                                                                                            setDesignid(filesource.canvas_design)
                                                                                            setCanvaswidth(file.width)
                                                                                            setSrLanguage(file?.canvas_translation[0]?.source_language)
                                                                                            setPostEditAccess(false)
                                                                                            setCanvasHeight(file.height);
                                                                                            setDesignName(file.file_name)
                                                                                            setCanvaswidth(file.width);
                                                                                            setReuseddesignname(file.file_name)
                                                                                            setReuseddesignboolen(true)
                                                                                            setReusedtempboolen(false)
                                                                                            setCanvasHeight(file.height)
                                                                                            setPageNo(filesource.page_no)
                                                                                            setJsonDatainfoFinal(file.source_json)
                                                                                            setLanguageData(file.canvas_translation)
                                                                                            setPageData(null)
                                                                                            updateProjectData()
                                                                                            if (typeof (filesource.json) == 'string') {

                                                                                                setCanvasbgcolor(JSON.parse(filesource.json).background)
                                                                                            }
                                                                                            else {

                                                                                                setCanvasbgcolor(filesource.json.background)
                                                                                            }
                                                                                            editor.canvas.loadFromJSON(filesource.json, function () {
                                                                                                editor.canvas.renderAll.bind(editor.canvas.renderAll());
                                                                                            })

                                                                                        }}>
                                                                                            <div className="project-thumb">
                                                                                                <img className="uploaded-images" src={LABS_API + '/' + filesource.thumbnail} />
                                                                                            </div>
                                                                                            <div className="project-name" >{file.file_name} </div>
                                                                                        </div>
                                                                                        <a onClick={() => {
                                                                                            deletedesignfromapi(filesource.canvas_design)
                                                                                        }}><CloseIcon className="remove-icon2" /></a>

                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        }</>
                                                )

                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className={sideBarMyTemplateOpenClose ? "ailaysa-canvas-sidebar-detail col-10 transition" : "d-none transition"}>
                                    <div className="sidebar-content">
                                        <div className="sidebar-tabs-heading">
                                            <h4>My Template</h4>


                                            <CloseIcon className="close-icon" onClick={handleSidebarClose} />
                                        </div>
                                        <div className="sidebar-search">
                                            <SearchIcon className="seacrh-icon" />
                                            <span className="search-placeholder-text">Search...</span>
                                        </div>

                                    </div>
                                </div>
                                <div className={sideBarTemplateOpenClose ? "ailaysa-canvas-sidebar-detail col-10 transition" : "d-none transition"} >
                                    <div className="sidebar-content">
                                        <div className="sidebar-tabs-heading">
                                            <h4>Template</h4>
                                            <CloseIcon className="close-icon" onClick={handleSidebarClose} />
                                        </div>
                                        <div className="sidebar-search">
                                            <SearchIcon className="seacrh-icon" />
                                            <span className="search-placeholder-text">Search...</span>
                                        </div>
                                        <div className="grid-container">
                                            <div className="grid-item item1" >1</div>
                                            <div className="grid-item item2">2</div>
                                            <div className="grid-item item3">3</div>
                                            <div className="grid-item item4">4</div>
                                            <div className="grid-item item5">5</div>
                                            <div className="grid-item item6">6</div>
                                            <div className="grid-item item7">7</div>
                                            <div className="grid-item item8">8</div>
                                            <div className="grid-item item9">9</div>
                                            <div className="grid-item item10">10</div>
                                            <div className="grid-item item11">11</div>
                                            <div className="grid-item item12">12</div>
                                            <div className="grid-item item13">13</div>
                                            <div className="grid-item item14">14</div>
                                            <div className="grid-item item15">15</div>
                                            <div className="grid-item item16">16</div>
                                            <div className="grid-item item17">17</div>
                                            <div className="grid-item item18">18</div>
                                            <div className="grid-item item19">19</div>

                                        </div>

                                    </div>
                                </div>
                                <div className={sideBarElementOpenClose ? "ailaysa-canvas-sidebar-detail col-10 transition" : "d-none transition"}>
                                    <div className="sidebar-content">
                                    <div className="sidebar-tabs-heading">
                                        <h4>Elements</h4>
                                        <button className="" onClick={()=>{
                                            check()
                                        }}>check</button>
                                        <CloseIcon className="close-icon" onClick={handleSidebarClose} />
                                    </div>
                                    <div className="sidebar-search">
                                        <SearchIcon onClick={()=>{
                                            if(elementsearchword.length > 0){
                                                elementsimage()
                                            }
                                            else{
                                                setElementsearchword('random')
                                                elementsimage()
                                            }
                                        }} className="seacrh-icon" />
                                        <span className="search-placeholder-text elements" > <input type="text" placeholder="search..." onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                if ((e.target.value.length > 0) && (e.target.value != ' ')) {
                                                    elementsimage()
                                                }
                                                else {
                                                    setElementsearchword('random')
                                                }
                                            }
                                        }} onChange={event => {
                                            // setPhotosearchword(event.target.value)
                                            if (event.target.value.length > 0 && event.target.value != ' ') {
                                                setElementsearchword(event.target.value)
                                            }
                                            else {
                                                setElementsearchword('random')
                                            }

                                        }} /></span>
                                    </div>
                                    <button onClick={getJsonData} className="btn w-100" style={{ backgroundColor: '#F3F2F2' }}> canvas data</button>
                                    <div className="grid-container-elements">
                                    {elementsImages == ''  && 
                                    <>
                                    <Skeleton variant="rectangular" width={170} height={150} />
                                    <Skeleton variant="rectangular" width={170} height={150} />
                                    <Skeleton variant="rectangular" width={170} height={150} />
                                    <Skeleton variant="rectangular" width={170} height={150} />
                                    <Skeleton variant="rectangular" width={170} height={150} />
                                    <Skeleton variant="rectangular" width={170} height={150} />
                                    <Skeleton variant="rectangular" width={170} height={150} />
                                    <Skeleton variant="rectangular" width={170} height={150} />
                                    <Skeleton variant="rectangular" width={170} height={150} />
                                    <Skeleton variant="rectangular" width={170} height={150} />
                                    </>}
                                    {elementsImages?.map((file, key) => {
                                            return (
                                                <div key={key} className="grid-item-uploads" >
                                                    <img className="uploaded-images" src={file.largeImageURL} onClick={onAddImage} onDragEnd={onAddImage} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                </div>
                                <div className={sideBarUploadOpenClose ? "ailaysa-canvas-sidebar-detail col-10 transition" : "d-none transition"}>
                                    <div className="sidebar-content">
                                        <div className="sidebar-tabs-heading">
                                            <h4>Uploads</h4>
                                            <CloseIcon className="close-icon" onClick={handleSidebarClose} />
                                        </div>
                                        <div className="sidebar-search">
                                            <SearchIcon className="seacrh-icon" />
                                            <span className="search-placeholder-text">Search...</span>
                                        </div>
                                        <div className="upload-div">
                                            <ButtonBase className="upload" onClick={handleUpload}>
                                                Upload
                                            </ButtonBase>
                                            <input type="file" onChange={handleFile} style={{ display: 'none' }} ref={hiddenFileInput} name="file[]" accept=".jpg,.png,.jpeg,.svg,.gif" multiple />
                                        </div>
                                        <div className="grid">
                                            {uploadimg.map((file, key) => {
                                                return (
                                                    <div key={key} className="grid-item-uploads  grid-item">
                                                        <CloseIcon className="remove-icon" onClick={() => {
                                                            deleteuploadfromapi(file.id)
                                                        }} />
                                                        <img className="uploaded-images" src={LABS_API + '/' + file.image} onClick={onAddImage} onDragEnd={onAddImage} />
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </div>
                                </div>
                                <div className={sideBarTextOpenClose ? "ailaysa-canvas-sidebar-detail col-10 transition" : "d-none transition"}>
                                    <div className="sidebar-content">
                                        <div className="sidebar-tabs-heading">
                                            <h4>Text</h4>
                                            <CloseIcon className="close-icon" onClick={handleSidebarClose} />
                                        </div>
                                        <div className="sidebar-search">
                                            <SearchIcon className="seacrh-icon" />
                                            <span className="search-placeholder-text">Search...</span>
                                        </div>
                                        <button onClick={getJsonData} className="btn w-100" style={{ backgroundColor: '#F3F2F2' }}> canvas data</button>

                                        <div className="add-text">
                                            <button onClick={getJsonData} className="d-none"> canvas data</button>

                                        </div>
                                        <div className="text-styles-container2 grid-container">
                                            <div className="grid-item">
                                                {/* <img src={} /> */}
                                            </div>
                                            <div className="grid-item">2</div>
                                            <div className="grid-item">3</div>
                                            <div className="grid-item">4</div>
                                            <div className="grid-item">5</div>
                                            <div className="grid-item">6</div>
                                            <div className="grid-item">7</div>
                                            <div className="grid-item">8</div>
                                            <div className="grid-item">9</div>
                                            <div className="grid-item">10</div>
                                            <div className="grid-item">11</div>
                                            <div className="grid-item">12</div>
                                            <div className="grid-item">13</div>
                                            <div className="grid-item">14</div>
                                            <div className="grid-item">15</div>
                                            <div className="grid-item">16</div>
                                            <div className="grid-item">17</div>
                                            <div className="grid-item">18</div>
                                            <div className="grid-item">19</div>
                                            <div className="grid-item">20</div>

                                        </div>
                                    </div>
                                </div>
                                <div className={sideBarPhotosOpenClose ? "ailaysa-canvas-sidebar-detail col-10 transition" : "d-none transition"}>
                                    <div className="sidebar-content">
                                        <div className="sidebar-tabs-heading">
                                            <h4>Photos</h4>
                                            <button className="d-none" onClick={() => {
                                                pdf()
                                            }}>check</button>
                                            <CloseIcon className="close-icon" onClick={handleSidebarClose} />
                                        </div>
                                        <div className="sidebar-search photo">

                                            <span className="search-placeholder-text"><input type="text" onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    if ((e.target.value.length > 0) && (e.target.value != ' ') && (e.target.value != '')) {
                                                        api()
                                                    }
                                                    else {
                                                        setPhotosearchword('background')
                                                    }
                                                }
                                            }} className="photosearcher" ref={photosearchinputer} onChange={event => {
                                                if ((event.target.value.length > 0) && (event.target.value != ' ')) {
                                                    setPhotosearchword(event.target.value)
                                                }
                                                else {
                                                    setPhotosearchword('background')
                                                }

                                            }} placeholder="Search" /><button onClick={() => {
                                                // setPhotosearchword(photosearchinputer.current.value.replaceAll(' ','+'))
                                                api()
                                                // // // // console.log(photosearchword);
                                            }}><SearchIcon className="seacrh-icon" /></button></span>

                                        </div>
                                        <div className="grid-container">

                                            {images?.map((file, key) => {
                                                return (
                                                    <div key={key} className="grid-item-uploads ">

                                                        <img className="uploaded-images" src={file.largeImageURL} onClick={onAddImage} onDragEnd={onAddImage} />
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>
                                <div className={sideBarBackgroundOpenClose ? "ailaysa-canvas-sidebar-detail col-10 transition" : "d-none transition"}>
                                    <div className="sidebar-content" >
                                        <div className="sidebar-tabs-heading">
                                            <h4>Background</h4>
                                            <CloseIcon className="close-icon" onClick={handleSidebarClose} />
                                        </div>
                                        <div className="drawing-tools">
                                            <div className="drawingmode-onoff d-none" >
                                                <BrushIcon className={'drawingsection-icon ' + (isdrawing ? "toolbar-icons-active" : "toolbar-icons")} onClick={drawingFunc} />
                                            </div>

                                            <div className="backgroundcolorchanger">
                                                <h6>Color</h6>
                                                <span>

                                                    <SketchPicker className="backgroundcolorpicker" color={canvasbgcolor} onChange={(color) => {
                                                        setCanvasbgcolor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")")
                                                        editor.canvas.backgroundImage = ''
                                                        setBackgroundEdit(false)
                                                    }} />
                                                    <TwitterPicker className="bgtwitter" color={canvasbgcolor} onChange={(color) => {
                                                        setCanvasbgcolor(color.hex)
                                                        editor.canvas.backgroundImage = ''
                                                        setBackgroundEdit(false)

                                                    }} />
                                                    <Eye />
                                                </span>

                                            </div>
                                            <div className="grid-container-background grid-container">
                                                {bgimages?.map((file, key) => {
                                                    return (
                                                        <div key={key} className="grid-item-uploads ">
                                                            <img className="uploaded-images" src={file.largeImageURL} onClick={addbgimg} onDragEnd={addbgimg} />
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={sideBarMyTemplateOpenClose || sideBarMyDesignOpenClose || sideBarBackgroundOpenClose || sideBarTemplateOpenClose || sideBarElementOpenClose || sideBarUploadOpenClose || sideBarTextOpenClose || sideBarPhotosOpenClose || switchWorkspace ? "ailaysa-canvas-editor-wrapper col-9 transition" : "ailaysa-canvas-editor-wrapper col-11 transition"}>
                        {switchWorkspace == false &&
                            <div className={sideBarMyTemplateOpenClose || sideBarMyDesignOpenClose || sideBarBackgroundOpenClose || sideBarTemplateOpenClose || sideBarElementOpenClose || sideBarUploadOpenClose || sideBarTextOpenClose || sideBarPhotosOpenClose ? "toolbar-container col-9 transition" : "toolbar-container col-11 transition"}>
                                {backgroundUpdate == false &&
                                    <>
                                        <div className="toolbars">
                                            {/* font size */}
                                            {(editor?.canvas.getActiveObject()?.type == 'textbox') &&
                                                <TextProperties editor={editor} fabric={fabric} counter={counter} counterTemp={counterTemp} Copy={Copy} Paste={Paste} decreaseFontSize={decreaseFontSize} increaseFontSize={increaseFontSize} handleBold={handleBold} handleItalic={handleItalic} handleUnderline={handleUnderline} handoverline={handoverline} handlinethrough={handlinethrough} bold={bold} italic={italic} underline={underline} overline={overline} linethrough={linethrough} />
                                            }

                                            {sideBarElementOpenClose &&
                                                <div className="shapes-prop-container" >
                                                    <Shapes editor={editor} fabric={fabric} />
                                                </div>
                                            }
                                            {(editor?.canvas.getActiveObject()?.type == 'path' || editor?.canvas.getActiveObject()?.type == 'rect' || editor?.canvas.getActiveObject()?.type == 'polygon' || editor?.canvas.getActiveObject()?.type == 'circle' || editor?.canvas.getActiveObject()?.type == 'ellipse' || editor?.canvas.getActiveObject()?.type == 'triangle' || editor?.canvas.getActiveObject()?.type == 'polyline' || editor?.canvas.getActiveObject()?.type == 'group') &&
                                                <div className="shapes-prop-container">
                                                    {/* <BrushIcon className={isdrawing ? "toolbar-icons-active" : "toolbar-icons"} onClick={drawingFunc} /> */}
                                                    {/* <button className='set-as-background-but toolbar-icons' onClick={setAsbackgroundIm}>Set as Background</button> */}
                                                    <ElementsProperties editor={editor} fabric={fabric} />
                                                </div>

                                            }


                                            {(editor?.canvas.getActiveObject()?.type == 'image') &&

                                                <ImageProperties editor={editor} fabric={fabric} setAsbackgroundIm={setAsbackgroundIm} />
                                            }
                                            {backgroundEdit &&
                                                <BackgroundProperties editor={editor} fabric={fabric} removeBackground={removeBackground} />



                                            }

                                        </div>
                                        <GeneralProperties editor={editor} fabric={fabric} lockmovementActiveobject={lockmovementActiveobject} canvasWidth={canvasWidth} canvasHeight={canvasHeight} clearAll={clearAll} />
                                    </>
                                }
                            </div>
                        }
                        {backgroundUpdate == true &&
                            <div className={sideBarMyTemplateOpenClose || sideBarMyDesignOpenClose || sideBarTemplateOpenClose || sideBarBackgroundOpenClose || sideBarElementOpenClose || sideBarUploadOpenClose || sideBarTextOpenClose || sideBarPhotosOpenClose ? "toolbar-container col-9 transition" : "toolbar-container col-11 transition"}>
                                <div className="toolbars">
                                    <div className='set-as-background-but toolbar-icons' onClick={handleConfirmBg} >
                                        <CheckIcon className="confirm-close-ic" />
                                        <span>Confirm</span>
                                    </div>
                                    <div className='set-as-background-but toolbar-icons' onClick={handleCancelBg} >
                                        <CloseIcon className="confirm-close-ic" />
                                        <span>Cancel</span>
                                    </div>

                                </div>
                            </div>
                        }



                        <div className="canvas-scaler" style={{ transform: `scale(${scaleCanvas})` }}>
                            <div className="canvas-pages-toolbar" >
                                <span className="page-no" style={{ fontSize: `${15/scaleCanvas}px` }}>Page-No:{pageNo}</span>
                                {switchPages == 'display1' && 
                                <div className="insta-translate-div">
                                    <ButtonBase className="dup-add-icon " onClick={instaTranslateFunction} style={{ fontSize: `${1.5/scaleCanvas}rem` }} disabled={editor.canvas.getActiveObject('textbox')?.newtext ? false : true} >
                                        <Tooltip TransitionComponent={Zoom} title="Translated Active text box" placement="top">
                                            <TranslateOutlinedIcon className="icon-d-a" />
                                        </Tooltip>
                                    </ButtonBase>
                                    <ButtonBase style={{ fontSize: `${1.5/scaleCanvas}rem` }} className="dup-add-icon " onClick={addHeading}>
                                        <Tooltip TransitionComponent={Zoom} title="Add Text" placement="top">
                                            <TextFieldsIcon className="icon-d-a" />
                                        </Tooltip>
                                    </ButtonBase>
                                </div>
                                }
                                <div className="language-wrapper-flex" style={{width: `${45/scaleCanvas}vh`}} >
                                    {/* <div className="dup-add-icon" >
                                        <ContentCopyIcon className="icon-d-a" onClick={fill} />
                                    </div>
                                    <div className="dup-add-icon">
                                        <AddIcon className="icon-d-a" style={{ visibility: 'hidden' }} />
                                    </div> */}
                                    {jsonDataInfoFinal?.map((k, v) => {
                                        // console.log(k);
                                        return (
                                            <>
                                                {v == 0 && <ButtonBase disabled={(targetlanNo == srLanguage)? true : false} className={(targetlanNo == srLanguage)? "language-select-list-active" : "language-select-list"} style={{ fontSize: `${15/scaleCanvas}px` }} onClick={() => {
                                                    setCanvasismodified(true)
                                                    setTranslatedCanvasModified(false)
                                                    setPostEditAccess(true)
                                                    setPageNo(k.page_no)
                                                    setPageData(jsonDataInfoFinal)
                                                    // setDesignName(langFilter[langFilter.map(function (item) { return item.value; }).indexOf(srLanguage)]?.label)
                                                    // // console.log(jsonDataInfoFinal);
                                                    setJsonDatainfo(jsonDataInfoFinal[0].json);
                                                    setDesignid(k.canvas_design)
                                                    setSwitchPages('display')
                                                    setTargetLanNo(srLanguage)

                                                    if (typeof (k.json) == 'string') {

                                                        setCanvasbgcolor(JSON.parse(file.json).background)
                                                    }
                                                    else {

                                                        setCanvasbgcolor(k.json.background)
                                                    }
                                                    editor.canvas.loadFromJSON(k.json, function () {
                                                        editor.canvas.renderAll.bind(editor.canvas.renderAll());
                                                    })

                                                }} >

                                                    { }
                                                    {langFilter[langFilter.map(function (item) { return item.value; }).indexOf(srLanguage)]?.label}

                                                </ButtonBase>
                                                }
                                            </>)
                                    })}




                                    {languageData?.map((file, key) => {
                                        return (
                                            <ButtonBase id={key} disabled={(targetlanNo == file.target_language)? true : false} className={(targetlanNo == file.target_language)? "language-select-list-active" : "language-select-list"} style={{ fontSize: `${15/scaleCanvas}px` }} onClick={() => {
                                                setCanvasismodified(false)
                                                // setDesignName(designName +"-"+langFilter[langFilter.map(function (item) { return item.value; }).indexOf(file.target_language)]?.label)
                                                setPostEditAccess(true)
                                                setTranslatedCanvasModified(true)
                                                setSwitchPages('display1')
                                                setJsonDatainfo(jsonDataInfoFinal[0].json);
                                                setPageNo(file.tranlated_json[0].page_no)
                                                setTargetLanNo(file.target_language)
                                                setPageData(file.tranlated_json)
                                                setInstaTranslate(langFilter[langFilter.map(function (item) { return item.value; }).indexOf(file.target_language)]?.label)
                                                setCanvasTransJsonId(file.id)
                                                if (typeof (file.tranlated_json[0].json) == 'string') {

                                                    setCanvasbgcolor(JSON.parse(file.tranlated_json[0].json).background)
                                                }
                                                else {

                                                    setCanvasbgcolor(file.tranlated_json[0].json.background)
                                                }
                                                editor.canvas.loadFromJSON(file.tranlated_json[0].json, function () {
                                                    editor.canvas.renderAll.bind(editor.canvas.renderAll());
                                                })
                                            }} >

                                                { }
                                                {langFilter[langFilter.map(function (item) { return item.value; }).indexOf(file.target_language)]?.label}

                                            </ButtonBase>

                                        )

                                    })}
                                </div>
                            </div>
                            <div onContextMenu={handleContextMenu} style={{ border: backgroundEdit ? '3px solid #BD8AF5' : 'none' }}>
                                <FabricJSCanvas id="canvas" className="canvas-space" style={{ width: canvasWidth }} onReady={onReady} />
                                <Menu
                                    open={contextMenu !== null}
                                    onClose={handleClose}
                                    anchorReference="anchorPosition"
                                    anchorPosition={
                                        contextMenu !== null
                                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                            : undefined
                                    }
                                >
                                    <MenuItem className='d-none' disabled={disabled === false} onClick={() => {
                                        Copy();
                                        Paste();
                                        handleClose()
                                    }}>Duplicate</MenuItem>
                                    <MenuItem onClick={() => {
                                        removeActiveobject()
                                        handleClose();
                                    }}>Delete</MenuItem>
                                </Menu>
                            </div>
                        </div>
                        <div className={sideBarTemplateOpenClose || sideBarMyDesignOpenClose || sideBarMyTemplateOpenClose || sideBarBackgroundOpenClose || sideBarElementOpenClose || sideBarUploadOpenClose || sideBarTextOpenClose || sideBarPhotosOpenClose || switchWorkspace  ? "zoom-container col-9 transition" : "zoom-container col-11 transition"} style={{ zIndex: '2', bottom: '10px', right: switchWorkspace  ? '' : '0', left: switchWorkspace  ? '0' : '', pointerEvents: disableLi ? 'none' : '', opacity: disableLi ? '0.6' : '1' }} >
                            <div className="row" style={{ width: '100%' }}>
                                <div className={sideBarTemplateOpenClose || sideBarMyDesignOpenClose || sideBarMyTemplateOpenClose || sideBarBackgroundOpenClose || sideBarElementOpenClose || sideBarUploadOpenClose || sideBarTextOpenClose || sideBarPhotosOpenClose ? "col-10" : "col-10"} style={{ background: 'white' }}>
                                    <div className="page-wrapper">
                                        <Swiper
                                            slidesPerView={sideBarTemplateOpenClose || sideBarMyDesignOpenClose || sideBarMyTemplateOpenClose || sideBarBackgroundOpenClose || sideBarElementOpenClose || sideBarUploadOpenClose || sideBarTextOpenClose || sideBarPhotosOpenClose ? 18 : 20}
                                            spaceBetween={15}
                                            freeMode={true}
                                            mousewheel={true}
                                            keyboard={true}
                                            grabCursor={true}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[FreeMode, Pagination, Keyboard, Mousewheel]}
                                            className="mySwiper">
                                            {pageData?.map((file, index) => {
                                                return (
                                                    <>
                                                        <SwiperSlide key={index} className="slide-pages" onClick={() => {
                                                            setPageNo(file.page_no)
                                                            setCanvasTransJsonId(file.canvas_trans_json)
                                                            setJsonDatainfo(jsonDataInfoFinal[index].json);
                                                            if (typeof (file.json) == 'string') {

                                                                setCanvasbgcolor(JSON.parse(file.json).background)
                                                            }
                                                            else {

                                                                setCanvasbgcolor(file.json.background)
                                                            }
                                                            editor.canvas.loadFromJSON(file.json, function () {
                                                                editor.canvas.renderAll.bind(editor.canvas.renderAll());
                                                            })
                                                        }} >

                                                            <div className={(pageNo == file.page_no) ? "page-flex-active" : "page-flex"}  >
                                                                {file.thumbnail != null && <img src={LABS_API + '/' + file.thumbnail} className='img-thumb' />}
                                                                <span className="page-no-span">{index + 1}</span>
                                                            </div>
                                                        </SwiperSlide>
                                                    </>
                                                )
                                            })}
                                        </Swiper>
                                    </div>
                                </div>
                                <div className={sideBarTemplateOpenClose || sideBarMyDesignOpenClose || sideBarMyTemplateOpenClose || sideBarBackgroundOpenClose || sideBarElementOpenClose || sideBarUploadOpenClose || sideBarTextOpenClose || sideBarPhotosOpenClose ? "col-2 zoom-con" : "col-2 zoom-con"}>
                                    <div className="zoom">
                                        <input type="range" min="0.1" max="1" step="0.1" value={scaleCanvas} onChange={(e) => { setScaleCanvas(e.target.value); }} ></input>
                                        <div className="zoom-range-percentage">
                                            <span>{scaleCanvas * 100}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    {switchWorkspace == true &&
                        <>
                            <div className="ailaysa-canvas-translation-post-editing-wrapper col-3">
                                <div className="d-flex align-items-center justify-content-between mb-4 gap-5">
                                    <div className="d-flex align-items-center">
                                        <span className="source-target-lang">{langFilter[langFilter.map(function (item) { return item.value; }).indexOf(srLanguage)]?.label}</span>
                                        <ArrowRightAltIcon className="arrow-right-icon" />
                                        <span className="source-target-lang">{langFilter[langFilter.map(function (item) { return item.value; }).indexOf(targetlanNo)]?.label}</span>
                                    </div>
                                    <div className="d-flex align-items-center gap-3">
                                        <Transliteration onClick={transliterateboolean} className={transliteratebooleanstate ? "posteditor-icons active" : "posteditor-icons"} />
                                        <FormatSizeIcon className="posteditor-icons" />
                                        <SpellcheckIcon className="posteditor-icons" />
                                        <FilterAltOutlinedIcon className="posteditor-icons" />
                                    </div>
                                </div>
                                <div className="workspace-input-itrate mt-3">
                                    {editor &&
                                        Object.entries(editor.canvas.getObjects('textbox')).map(([k, v]) => {
                                            return (
                                                <div className="ailaysa-canvas-text-postediting mb-2">
                                                    <div className="border-bottom pb-2 postediting-head-text ">
                                                        {jsonDataInfo  &&
                                                        <div className="source-text">{
                                                            Object.entries(jsonDataInfo?.objects?.filter(function (el) {
                                                                return el.type == 'textbox'
                                                            }))?.map(([key, val]) => {
                                                                return (
                                                                    <>
                                                                        {k == key && <div className="source-text" ref={Ref}>{val.text}</div>}
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                        
                                                        </div>
                                                        }
                                                        <div className="postediting-tags">
                                                            <span className="tag">{parseInt(k) + 1}</span>
                                                            <HelpOutlineIcon className="posteditor-icons" />
                                                        </div>
                                                    </div>
                                                    <div className="translated-postediting-canvas-area">
                                                        <textarea rows={5} onClick={SelectObject} id={`${parseInt(v.id)}`} value={v.text} placeholder={v.text} onChange={updateActiveObjectText} className={!transliteratebooleanstate ? 'ailaysa-canvas-textarea' : 'ailaysa-canvas-textarea d-none'}></textarea>
                                                        <ReactTransliterate

                                                            renderComponent={(props) => <textarea rows={5} {...props} />}
                                                            value={v.text}
                                                            className={transliteratebooleanstate ? 'ailaysa-canvas-textarea' : 'ailaysa-canvas-textarea d-none'}
                                                            onChangeText={(v) => {
                                                                editor.canvas.getActiveObject().set({ text: v })
                                                            }}
                                                            onChange={updateActiveObjectText}
                                                            onClick={SelectObject}
                                                            lang={transliterate}
                                                        />
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div id={`confirms1${k}`} className={`${(v.status?.toLowerCase())}-div`}>
                                                                <CircleIcon id={`confirms2${k}`} className={`${v.status?.toLowerCase()}-icon`} />
                                                                <span id={`confirm${k}`} className={`${v.status?.toLowerCase()}-text`}>{v.status}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center gap-2">

                                                                <CheckCircleOutlineOutlinedIcon id={`${k}`} data-id="foo" name={`${v.status}`} className="posteditor-icons-sec" style={{ display: v.status == 'Unconfirmed'? 'block' : 'none' }} onClick={(e) =>
                                                                    unConfirmPostText(e)} />
                                                                <Unconfirmed id={`${k}`} name={`${v.status}`} data-id="boo" className="posteditor-icons-sec padding-un" style={{ display: v.status == 'Confirmed'? 'block' : 'none' }} onClick={(e) =>
                                                                confirmPostText(e)} />
                                                                
                                                                {
                                                                    (v.text != '') ? (
                                                                        <img src={eraser} onClick={() => {
                                                                            v.text = ''
                                                                            setClick(click + 1)
                                                                        }} className="posteditor-icons-sec" />
                                                                    ) : (
                                                                        <TranslateOutlinedIcon onClick={() => {

                                                                            (async function () {
                                                                                var formdata = new FormData();
                                                                                formdata.append("Input", some[k]);
                                                                                formdata.append("target_language", transliterate.replaceAll("ta", "Tamil").replaceAll("ja", "Japanese").replaceAll("zh-CN", "Chinese").replaceAll("en", "English").replaceAll("ar", "Arabic"));

                                                                                var requestOptions = {
                                                                                    method: 'POST',
                                                                                    headers: handleAuthTrue(new Headers()),
                                                                                    body: formdata,
                                                                                    redirect: 'follow'
                                                                                };

                                                                                let data = await fetch(LABS_API + "/voice/translate/", requestOptions)
                                                                                if (data.status === 200) {
                                                                                    let response = await data.json()
                                                                                    editor.canvas.getActiveObject().set({ text: response.text })
                                                                                    editor.canvas.getActiveObject().selectable = true;
                                                                                    editor.canvas.discardActiveObject(editor.canvas.getActiveObject())
                                                                                    editor?.canvas.renderAll()
                                                                                }
                                                                                else {
                                                                                    console.log('error');
                                                                                }
                                                                            })();
                                                                            setClick(click + 1)
                                                                        }} className="posteditor-icons-sec" />
                                                                    )

                                                                }
                                                                <ChatBubbleOutlineIcon className="posteditor-icons-sec" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
            <Popover
                open={pop}
                onClose={() => setPop(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <TextField
                    hiddenLabel
                    type="number"
                    id="filled-hidden-label-small"
                    placeholder="Width"
                    variant="filled"
                    size="small"
                    inputProps={{ min: 0, max: 1920 }}
                    value={canvasWidth}
                    onChange={(e) => setCanvaswidth(e.target.value)}
                />
                <TextField
                    hiddenLabel
                    type="number"
                    id="filled-hidden-label-small"
                    inputProps={{ min: 0, max: 1080 }}
                    placeholder="Height"
                    variant="filled"
                    size="small"
                    value={canvasHeight}
                    onChange={(e) => setCanvasHeight(e.target.value)}
                />
            </Popover>
        </React.Fragment>
    )




}


export default AilaysaCanvasWorkspace;