import React, { useEffect, useState, useRef } from "react";
import SocialMediaList from "../components/social-media-localization/SocialMediaList";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Config from "../config/Config";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  languages,
  SmlTargetlanguageses,
} from "../assets/languages/GetLanguages";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import AlertModal from "../components/social-media-localization/modal/AlertModal";
import SuccessModal from "../components/social-media-localization/modal/SuccessModal";
import { useNavigate } from "react-router-dom";

const SocialMediaLocalisation = (props) => {
  const navigate = useNavigate();
  const [smlLocalizationTab, setSmlLocalizationTab] = useState("twitter");
  const [sourceLanguageOptions, setSourceLanguageOptions] = useState([
    languages[2],
  ]);
  const [targetLanguageOptions, setTargetLanguageOptions] = useState([]);
  const [tweetUrl, setTweetUrl] = useState("");
  const [tweetSourceTxt, setTweetSourceTxt] = useState("");
  const [translatedTweetTxt, setTranslatedTweetTxt] = useState({});
  const [translatedTweetData, setTranslatedTweetData] = useState("");
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [originalTxt, setOriginalTxt] = useState(false);
  const [translatedTxt, setTranslatedTxt] = useState(false);
  const [translatedTxtEdit, setTranslatedTxtEdit] = useState("");
  const [resultLangTranslatedTxt, setResultLangTranslatedTxt] = useState({});

  useEffect(() => {
    let tempLang = [];
    targetLanguageOptions?.map((each) => {
      tempLang.push(each.code);
    });
    const res = tempLang.reduce((acc, curr) => ((acc[curr] = ""), acc), {});
    setResultLangTranslatedTxt(res);
  }, [targetLanguageOptions]);

  const getTweetSourceData = async () => {
    var requestOptions = {
      method: "POST",
      headers: handleAuthTrue(new Headers()),
      body: handleSocialMediaSourceFormData(new FormData()),
      auth: true,
      redirect: "follow",
    };
    let responseData = await fetch(
      Config.LABS_API + "/social/source_tweet/",
      requestOptions
    );
    if (responseData.status === 200) {
      let response = await responseData.json();
      setTweetSourceTxt(response);
      setTranslatedTxt(false);

      // if(response.msg === "check your Tweet url.seems to be not correct"){
      //     setShowAlertModal(true)
      // }else{
      //     setShowAlertModal(false)
      // }
      // }else if(responseData.status === 500){
      //     setShowAlertModal(true)
    } else {
      console.log("error");
    }
  };

  const getDataFromTweetUrl = async () => {
    var requestOptions = {
      method: "POST",
      headers: handleAuthTrue(new Headers()),
      body: handleSocialMediaFormData(new FormData()),
      auth: true,
      redirect: "follow",
    };
    let responseData = await fetch(
      Config.LABS_API + "/social/tweet/",
      requestOptions
    );
    if (responseData.status === 200) {
      let response = await responseData.json();
      setTranslatedTweetTxt(response);
    } else {
      console.log("error");
    }
  };

  const postTweet = async () => {
    var requestOptions = {
      method: "POST",
      headers: handleAuthTrue(new Headers()),
      body: handleTweetTranslateData(new FormData()),
      auth: true,
      redirect: "follow",
    };
    let responseData = await fetch(
      Config.LABS_API + "/social/post_tweet/",
      requestOptions
    );
    if (responseData.status === 200) {
      let response = await responseData.json();
      setTranslatedTweetData(response);
      if (response.msg === "Tweet needs to be shorter") {
        setShowAlertModal(true);
      } else {
        setTweetUrl("");
        setTweetSourceTxt("");
        setTranslatedTweetTxt({});
        setTargetLanguageOptions([]);
        setTranslatedTxtEdit("");
        setOriginalTxt(false);
        setTranslatedTxt(false);
        setShowSuccessModal(true);
        // window.open();
      }
    } else {
      console.log("error");
    }
  };

  const handleTweetTranslateData = (formData) => {
    Object.entries(resultLangTranslatedTxt).map((entry) => {
      formData.append("data", entry[1]);
    });
    return formData;
  };

  const handleCloseALertModal = () => {
    setShowAlertModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const modaloption = {
    closeMaskOnClick: false,
    width: "auto",
    onClose: handleCloseALertModal,
  };

  const newModaloption = {
    closeMaskOnClick: false,
    width: "auto",
    onClose: handleCloseSuccessModal,
  };

  const handleSocialMediaFormData = (formData) => {
    formData.append("url", tweetUrl);
    targetLanguageOptions.map((eachTargetLanguage) => {
      formData.append("target_language", eachTargetLanguage?.language);
    });
    return formData;
  };

  const handleSocialMediaSourceFormData = (formData) => {
    formData.append("url", tweetUrl);
    return formData;
  };

  const handleAuthTrue = (authentication) => {
    let token = Config.userState != null ? Config.userState.token : "";
    authentication.append("Authorization", `Bearer ${token}`);
    return authentication;
  };
  return (
    <React.Fragment>
      <section className="sml-section-wrapper">
        <div className="container">
          <div className="sml-wrap">
            <h1>
              <div onClick={() => navigate("/")} className="back-icon">
                <ArrowBackIosNewIcon className="arrow-icon" />
              </div>
              Social Media Localization
            </h1>
            {/* <p>This is a graphical interface to easily try all our models without writing a single line of code. For more information about the API, please see the documentation, and if you're new please first register and get a free API token.</p> */}
            <SocialMediaList
              smlLocalizationTab={smlLocalizationTab}
              setSmlLocalizationTab={setSmlLocalizationTab}
              languages={languages}
              SmlTargetlanguageses={SmlTargetlanguageses}
              sourceLanguageOptions={sourceLanguageOptions}
              targetLanguageOptions={targetLanguageOptions}
              setSourceLanguageOptions={setSourceLanguageOptions}
              setTargetLanguageOptions={setTargetLanguageOptions}
              getDataFromTweetUrl={getDataFromTweetUrl}
              tweetUrl={tweetUrl}
              setTweetUrl={setTweetUrl}
              translatedTweetTxt={translatedTweetTxt}
              postTweet={postTweet}
              tweetSourceTxt={tweetSourceTxt}
              setTweetSourceTxt={setTweetSourceTxt}
              getTweetSourceData={getTweetSourceData}
              showAlertModal={showAlertModal}
              setShowAlertModal={setShowAlertModal}
              originalTxt={originalTxt}
              setOriginalTxt={setOriginalTxt}
              translatedTxt={translatedTxt}
              setTranslatedTxt={setTranslatedTxt}
              translatedTxtEdit={translatedTxtEdit}
              setTranslatedTxtEdit={setTranslatedTxtEdit}
              resultLangTranslatedTxt={resultLangTranslatedTxt}
              setResultLangTranslatedTxt={setResultLangTranslatedTxt}
            />
          </div>
        </div>
      </section>
      <Rodal
        visible={showAlertModal}
        {...modaloption}
        showCloseButton={false}
        className="ai-mark-confirm-box"
      >
        <AlertModal
          setShowAlertModal={setShowAlertModal}
          showAlertModal={showAlertModal}
        />
      </Rodal>
      <Rodal
        visible={showSuccessModal}
        {...newModaloption}
        showCloseButton={false}
        className="ai-mark-confirm-box"
      >
        <SuccessModal
          showSuccessModal={showSuccessModal}
          setShowSuccessModal={setShowSuccessModal}
        />
      </Rodal>
    </React.Fragment>
  );
};

export default SocialMediaLocalisation;
