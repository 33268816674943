import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import SubtitleLocalizationArea from "../components/subtitle-localization/SubtitleLocalizationArea";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Config from "../config/Config";
import { languages, Targetlanguageses} from "../assets/languages/GetLanguages";
import { useNavigate } from "react-router-dom";
import AlertModal from "../components/social-media-localization/modal/AlertModal";

export const LoadingMarkup = () => {
    return(
        <div className="py-4 text-center">
            <section className="animation-logo">
                <div className="svg-logo"></div>
            </section>
        </div>
    )
};
const SubtitleLocalization = (props) => {
    const navigate = useNavigate();
    const LABS_API = Config.LABS_API
    const [sourceLanguageOptions, setSourceLanguageOptions] = useState([languages[2]]);
    const [targetLanguageOptions, setTargetLanguageOptions] = useState(0);
    const [urlLink, setUrlLink] = useState("")
    const [ytSRTFile, setYtSRTFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showYoutubeAlertModal, setShowYoutubeAlertModal] = useState(false)
    const [youtubeUrlId, setYoutubeUrlId] = useState("")
    const [youtubeEmbedBox, setYoutubeEmbedBox] = useState(false)
    const [formValidation, setFormValidation] = useState(false)

    // const targetLanguageOptionsRef = useRef(getLanguages);

    // useEffect(() => {
    //     removeSelectedSourceFromTarget();
    // }, [sourceLanguageOptions]);

    // const removeSelectedSourceFromTarget = () => {
    //     setTargetLanguageOptions(targetLanguageOptionsRef.current.filter((element) => element.value != getLanguages?.value));
    // };

    const handleYoutubeEmbedBox = (e) => {
        if(urlLink === ""){
            setYoutubeEmbedBox(false)
            return false
        }
        return true
    }


    const fieldValidation = () => {
        if(urlLink === ""){
            setFormValidation(true)
            return false
        }
        return true
    }

    const handleCloseALertModal = () => {
        setShowYoutubeAlertModal(false)
    }

    const modaloption = {
        closeMaskOnClick: false,
        width: "auto",
        onClose: handleCloseALertModal,
    };

    const handleAuthTrue = (authentication) => {
        let token = Config.userState != null ? Config.userState.token : "";
        authentication.append("Authorization", `Bearer ${token}`)
        return authentication;
    }

    const handleCheckSrt = async() => {
        var requestOptions = {
            method: 'GET',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
        };

        let responseData = await fetch(LABS_API+"/social/sub_check/?url="+urlLink, requestOptions)
        if(responseData.status === 200){
            let response = await responseData.json()
            if(response.msg === "subs not found"){
                setShowYoutubeAlertModal(true)
            }else{
                handleSrtLocalizationPost()
                handleDownloadSRT()
            }
        }else{
            console.log("error");
        }
    }

    const handleSrtLocalizationPost = async() => {
        setLoading(true)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        let responseData = await fetch(LABS_API+"/social/get_srt/?url="+urlLink+"&target_language="+targetLanguageOptions.language, requestOptions)
        if(responseData.status === 200){
            setLoading(false)
            setUrlLink("")
            setTargetLanguageOptions([])
            setYoutubeUrlId("")
            setYoutubeEmbedBox(false)
        }else{
            console.log("error")
        }
    }

    const handleDownloadSRT = (e) => {
        window.location.href = `${Config.LABS_API}/social/get_srt/?url=${urlLink}&target_language=${targetLanguageOptions.language}`  
    }


    return (
        <React.Fragment>
            <section className="sublocal-section-wrapper">
                <div className="container">
                    <div className="sublocal-wrap">
                        <h1>
                            <div onClick={() => navigate("/")} className="back-icon">
                                <ArrowBackIosNewIcon className="arrow-icon"/>
                            </div>
                            SRT Localisation
                        </h1>
                        {/* <p>This is a graphical interface to easily try all our models without writing a single line of code. For more information about the API, please see the documentation, and if you're new please first register and get a free API token.</p> */}
                        <SubtitleLocalizationArea 
                            languages={languages}
                            Targetlanguageses={Targetlanguageses}
                            sourceLanguageOptions={sourceLanguageOptions}
                            targetLanguageOptions={targetLanguageOptions}
                            setSourceLanguageOptions={setSourceLanguageOptions}
                            setTargetLanguageOptions={setTargetLanguageOptions}
                            urlLink={urlLink}
                            setUrlLink={setUrlLink}
                            loading={loading}
                            handleCheckSrt={handleCheckSrt}
                            youtubeUrlId={youtubeUrlId} 
                            setYoutubeUrlId={setYoutubeUrlId}
                            youtubeEmbedBox={youtubeEmbedBox} 
                            setYoutubeEmbedBox={setYoutubeEmbedBox}
                            fieldValidation={fieldValidation}
                            formValidation={formValidation}
                            setFormValidation={setFormValidation}
                            handleYoutubeEmbedBox={handleYoutubeEmbedBox}
                        />
                    </div>
                </div>
            </section>
            {
                loading &&
                <LoadingMarkup />
            }
            <Rodal visible={showYoutubeAlertModal} 
                {...modaloption} 
                showCloseButton={false} 
                
                className="ai-mark-confirm-box"
                setShowYoutubeAlertModal={setShowYoutubeAlertModal} 
                showYoutubeAlertModal={showYoutubeAlertModal} 
            >
                <AlertModal 
                    setShowYoutubeAlertModal={setShowYoutubeAlertModal} 
                    showYoutubeAlertModal={showYoutubeAlertModal} 
                />
            </Rodal>
        </React.Fragment>
    )
}

export default SubtitleLocalization;