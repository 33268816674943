import React, { useEffect, useState, useRef } from "react";
import { ButtonBase } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const ProfileGenerator = (props) => {
    const { 
            descTxtWrap, 
            inputHandler, 
            fetchProfileTranslatedText, 
            translatedTxtData,
            open,
            setOpen,
            transGenFieldValidation,
            transGenValidation,
            resetForm,
            handleResetForm,
            transGenData
        } = props


    useEffect(() => {
        if(descTxtWrap){
          let descriptionBox = document.getElementById('profile-translated-area')
          descriptionBox.scrollIntoView()
        }
      }, [descTxtWrap])

      const validationError = {
        color: 'red',
    } 

    return (
        <React.Fragment>
            <div className="profile-gen-wrapper">
                <div className="profile-gen-area">
                    <h2 className="title">Profile Generator</h2>
                    <div className="profile-form-wrapper">
                        <div className="form-wrapper-row">
                            <div className="form-item-wrapper">
                                <label>Profile's Name</label>
                                <input type="text" name="profileName" value={transGenData.profileName} onChange={inputHandler} className="proj-form-input"/>
                                {transGenValidation.profileName && <small style={validationError}>Required</small>}
                            </div>
                            <div className="form-item-wrapper">
                                <label>University</label>
                                <input type="text" name="university" value={transGenData.university} onChange={inputHandler} className="proj-form-input"/>
                                {transGenValidation.university && <small style={validationError}>Required</small>}
                            </div>
                            <div className="form-item-wrapper">
                                <label>Graduation</label>
                                <input type="text" name="graduate" value={transGenData.graduate} onChange={inputHandler} className="proj-form-input"/>
                                {transGenValidation.graduate && <small style={validationError}>Required</small>}
                            </div>
                            <div className="form-item-wrapper">
                                <label>Experience</label>
                                <input type="number" name="experience" value={transGenData.experience} onChange={inputHandler} className="proj-form-input"/>
                                {transGenValidation.experience && <small style={validationError}>Required</small>}
                            </div>
                            <div className="form-item-wrapper">
                                <label>Company</label>
                                <input type="text" name="compName" value={transGenData.compName} onChange={inputHandler} className="proj-form-input"/>
                                {transGenValidation.compName && <small style={validationError}>Required</small>}
                            </div>
                            <div className="form-item-wrapper">
                                <label>Company's Position</label>
                                <input type="text" name="compPosition" value={transGenData.compPosition} onChange={inputHandler} className="proj-form-input"/>
                                {transGenValidation.compPosition && <small style={validationError}>Required</small>}
                            </div>
                            <div className="form-item-wrapper">
                                <label>Technology Expert</label>
                                <input type="text" name="techExpert" value={transGenData.techExpert} onChange={inputHandler} className="proj-form-input"/>
                                {transGenValidation.techExpert && <small style={validationError}>Required</small>}
                            </div>
                            <div className="form-item-wrapper">
                                <label>Profile's Skills</label>
                                <input type="text" name="skills" value={transGenData.skills} onChange={inputHandler} className="proj-form-input" placeholder="Enter comma seperated values"/>
                                {transGenValidation.skills && <small style={validationError}>Required</small>}
                            </div>
                            <div className="form-item-wrapper">
                                <label>Profile's Hobby</label>
                                <input type="text" name="hobby" value={transGenData.hobby} onChange={inputHandler} className="proj-form-input" placeholder="Enter comma seperated values"/>
                                {transGenValidation.hobby && <small style={validationError}>Required</small>}
                            </div>
                        </div>
                    </div>
                    <div className="btn-row">
                        {
                            resetForm &&
                            <ButtonBase onClick={handleResetForm} className="translate-btn">
                                <span>Reset</span>
                            </ButtonBase>
                        }

                        <ButtonBase onClick={() => { transGenFieldValidation() && fetchProfileTranslatedText()}} className="translate-btn">
                            <span>Generate</span>
                        </ButtonBase>
                    </div>
                </div>
                <AnimatePresence exitBeforeEnter>
                    {
                        descTxtWrap &&
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: -20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 0.4 }}
                            id="profile-translated-area"
                            className="social-media-translated-txt-area"
                        >
                            <h3>Generated Profile Description</h3>
                            <div className="translated-area-row">
                                <div className="translated-area-box">
                                    <div className="header">
                                        <p>First Person</p>
                                    </div>
                                    <div className="translated-txt">
                                        <p className="text-area-form">
                                            {translatedTxtData.firstPersonTemp}
                                        </p> 
                                        <div className="btn-row">
                                            <ClickAwayListener onClickAway={() => setOpen({...open, firstPerson: false})}>
                                                <Tooltip
                                                    placement="top"
                                                    arrow
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={() => setOpen({...open, firstPerson: false})}
                                                    open={open.firstPerson}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title="Copied!"
                                                >
                                                    <ButtonBase onClick={() => {navigator.clipboard.writeText(translatedTxtData.firstPersonTemp);setOpen({...open, firstPerson: true})}} className="translate-btn">
                                                        <ContentCopyIcon className="icon"/>
                                                    </ButtonBase>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </div>
                                    </div>
                                </div>
                                <div className="translated-area-box">
                                    <div className="header">
                                        <p>Third Person</p>
                                    </div>
                                    <div className="translated-txt">
                                        <p className="text-area-form">
                                            {translatedTxtData.thirdPersonTemp}
                                        </p> 
                                        <div className="btn-row">
                                            <ClickAwayListener onClickAway={() => setOpen({...open, thirdPerson: false})}>
                                                <Tooltip
                                                    placement="top"
                                                    arrow
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={() => setOpen({...open, thirdPerson: false})}
                                                    open={open.thirdPerson}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title="Copied!"
                                                >
                                                    <ButtonBase onClick={() => {navigator.clipboard.writeText(translatedTxtData.thirdPersonTemp); setOpen({...open, thirdPerson: true})}} className="translate-btn">
                                                        <ContentCopyIcon className="icon"/>
                                                    </ButtonBase>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </div>
                                    </div>
                                </div>
                                <div className="translated-area-box">
                                    <div className="header">
                                        <p>First Person Translated</p>
                                    </div>
                                    <div className="translated-txt">
                                        <p className="text-area-form">
                                            {translatedTxtData.tamilTempFirstPerson}
                                        </p> 
                                        <div className="btn-row">
                                            <ClickAwayListener onClickAway={() => setOpen({...open, tamilFirstPerson: false})}>
                                                <Tooltip
                                                    placement="top"
                                                    arrow
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={() => setOpen({...open, tamilFirstPerson: false})}
                                                    open={open.tamilFirstPerson}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title="Copied!"
                                                >
                                                    <ButtonBase onClick={() => {navigator.clipboard.writeText(translatedTxtData.tamilTempFirstPerson); setOpen({...open, tamilFirstPerson: true})}} className="translate-btn">
                                                        <ContentCopyIcon className="icon"/>
                                                    </ButtonBase>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </div>
                                    </div>
                                </div>
                                <div className="translated-area-box">
                                    <div className="header">
                                        <p>Third Person Translated</p>
                                    </div>
                                    <div className="translated-txt">
                                        <p className="text-area-form">
                                            {translatedTxtData.tamilTempThirdPerson}
                                        </p> 
                                        <div className="btn-row">
                                            <ClickAwayListener onClickAway={() => setOpen({...open, tamilThirdPerson: false})}>
                                                <Tooltip
                                                    placement="top"
                                                    arrow
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={() => setOpen({...open, tamilThirdPerson: false})}
                                                    open={open.tamilThirdPerson}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    title="Copied!"
                                                >
                                                    <ButtonBase onClick={() => {navigator.clipboard.writeText(translatedTxtData.tamilTempThirdPerson); setOpen({...open, tamilThirdPerson: true})}} className="translate-btn">
                                                        <ContentCopyIcon className="icon"/>
                                                    </ButtonBase>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    }

                </AnimatePresence>
            </div>
        </React.Fragment>
    )
}

export default ProfileGenerator;