import { ButtonBase } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { motion, AnimatePresence } from "framer-motion";
import Select, { components } from "react-select";

const TwitterLocalization = (props) => {
  const {
    sourceLanguageOptions,
    targetLanguageOptions,
    setTargetLanguageOptions,
    setSourceLanguageOptions,
    SmlTargetlanguageses,
    languages,
    getDataFromTweetUrl,
    tweetUrl,
    setTweetUrl,
    translatedTweetTxt,
    postTweet,
    setTweetSourceTxt,
    tweetSourceTxt,
    getTweetSourceData,
    showAlertModal,
    setShowAlertModal,
    originalTxt,
    setOriginalTxt,
    translatedTxt,
    setTranslatedTxt,
    translatedTxtEdit,
    setTranslatedTxtEdit,
    resultLangTranslatedTxt,
    setResultLangTranslatedTxt,
  } = props;

  const translatedTxtRef = useRef(null);
  const [invalidTwitterURL, setInvalidTwitterURL] = useState(false);

  const handleUrlSubmitted = () => {
    getTweetSourceData();
    setOriginalTxt(tweetUrl === "" ? false : true);
  };

  function executeScroll() {
    const twitterIntoView = document.getElementById("twitter-translated-area");
    twitterIntoView?.scrollIntoView();
  }

  const handleTranslateTxt = () => {
    getDataFromTweetUrl();
    executeScroll();
    setTranslatedTxt(true);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon className="icon" />
      </components.DropdownIndicator>
    );
  };

  const customAssignStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      color: "#3C4043",
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: "500",
      lineHeight: "24px",
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: "6px 0px",
      boxShadow: "0px 3px 6px #00000029",
      border: "1px solid #DADADA",
      borderRadius: "4px",
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #CED4DA",
      borderLeft: "2px solid transparent",
      color: state.isSelected
        ? "#ffffff"
        : state.isDisabled
        ? "#cccccc"
        : "#7E7E7E",
      background: state.isSelected ? "#F4F5F7" : "#ffffff",
      padding: "2px 8px",
      color: "#3C4043",
      fontSize: "15px",
      fontWeight: "400",
      lineHeight: "24px",
      opacity: state.isDisabled ? 0.5 : 1,
      "&:hover": {
        background: "#F4F5F7",
        borderLeft: "2px solid #0074D3",
        cursor: "pointer",
      },
    }),
    control: (base, state) => ({
      ...base,
      border: 0,
      borderRadius: 4,
      transtion: 0.3,
      color: state.isFocused ? "#0074D3" : "#3C4043",
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: "500",
      lineHeight: "24px",
      minWidth: "200px",
      minHeight: 44,
      padding: 0,
      height: state.isFocused ? 44 : 44,
      boxShadow: 0,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#EBEBEB",
        height: 44,
      },
    }),
  };

  useEffect(() => {
    if (translatedTxt) {
      translatedTxtRef.current.scrollIntoView();
    }
  }, [translatedTweetTxt]);

  const handleEnterKey = (e) => {
    // submit url on enter key press
    if (e.which === 13) {
      handleUrlSubmitted();
    }
  };

  const handleTranslatedResult = (e, outputLang) => {
    setResultLangTranslatedTxt({
      ...resultLangTranslatedTxt,
      [outputLang]: e.target.value,
    });
  };

  useEffect(() => {
    setResultLangTranslatedTxt(translatedTweetTxt);
  }, [translatedTweetTxt]);

  useEffect(() => {
    console.log(resultLangTranslatedTxt);
  }, [resultLangTranslatedTxt]);

  return (
    <React.Fragment>
      <motion.div
        animate={{ opacity: 1, x: 0 }}
        initial={{ opacity: 0, x: -30 }}
        exit={{ opacity: 0, x: 30 }}
        transition={{ duration: 0.4 }}
        className="social-media-localization-area"
      >
        <div className="social-media-url-area">
          <input
            type="url"
            value={tweetUrl}
            placeholder="Paste the tweet URL/Id here"
            onChange={(e) => setTweetUrl(e.target.value)}
            onKeyUp={handleEnterKey}
          />
          <ButtonBase
            disabled={tweetUrl === ""}
            onClick={handleUrlSubmitted}
            className="url-search-btn"
          >
            <KeyboardBackspaceIcon className="arrow-icon" />
          </ButtonBase>
        </div>
        <AnimatePresence exitBeforeEnter>
          {originalTxt ? (
            <motion.div
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: -20 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.4 }}
              className="social-media-original-txt-area"
            >
              <h3>Original Tweet</h3>
              <div className="original-text-area-wrap">
                <p className="text-area-form">{tweetSourceTxt}</p>
                <div className="bottom-btn-wrap">
                  <div className="lang-select-wrap">
                    <Select
                      classNamePrefix="select-src-lang"
                      menuPlacement="auto"
                      styles={customAssignStyles}
                      isSearchable={false}
                      options={languages}
                      getOptionLabel={(option) => option.language}
                      getOptionValue={(option) => option.code}
                      isOptionDisabled={(option) => option.disabled}
                      hideSelectedOptions={false}
                      value={sourceLanguageOptions}
                      onChange={setSourceLanguageOptions}
                      placeholder="Source Language"
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    <Select
                      classNamePrefix="select-lang"
                      menuPlacement="auto"
                      styles={customAssignStyles}
                      isMulti={true}
                      isSearchable={false}
                      options={SmlTargetlanguageses}
                      getOptionLabel={(option) => option.language}
                      getOptionValue={(option) => option.code}
                      hideSelectedOptions={false}
                      value={targetLanguageOptions}
                      onChange={setTargetLanguageOptions}
                      placeholder="Target Language(s)"
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <ButtonBase
                    disabled={
                      sourceLanguageOptions.length === 0 ||
                      targetLanguageOptions.length === 0
                    }
                    onClick={handleTranslateTxt}
                    className="translate-btn"
                  >
                    <span>Translate</span>
                  </ButtonBase>
                </div>
              </div>
            </motion.div>
          ) : null}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {translatedTxt && (
            <motion.div
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: -20 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.4 }}
              id="twitter-translated-area"
              className="social-media-translated-txt-area"
            >
              <h3 ref={translatedTxtRef}>Translated Tweet</h3>
              <div className="translated-area-row">
                {Object.entries(translatedTweetTxt).map((entry, index) => {
                  return (
                    <div key={index} className="translated-area-box">
                      <div className="header">
                        {SmlTargetlanguageses?.filter(
                          (each) => each.code === entry[0]
                        ).map((lang) => {
                          return <p key={lang.id}>{lang.language}</p>;
                        })}
                      </div>
                      <div className="translated-txt">
                        <textarea
                          value={resultLangTranslatedTxt[entry[0]]}
                          className="text-area-form"
                          onChange={(e) => handleTranslatedResult(e, entry[0])}
                        ></textarea>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="publish-button-row">
                <ButtonBase onClick={postTweet} className="translate-btn">
                  <span>Publish Tweet</span>
                </ButtonBase>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </React.Fragment>
  );
};

export default TwitterLocalization;
