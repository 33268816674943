import { ButtonBase } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { motion, AnimatePresence } from "framer-motion";


const LinkedinLocalization = (props) => {

    const [originalTxt, setOriginalTxt] = useState(false);
    const [translatedTxt, setTranslatedTxt] = useState(false);

    const linkedinTranslatedArea = useRef()

    const handleUrlSubmitted = () => {
        setOriginalTxt(true)
    }

    const handleTranslateTxt = () => {
        setTranslatedTxt(true)
        linkedinTranslatedArea.current?.scrollIntoView({block: "start"});
    }

    return (
        <React.Fragment>
            <motion.div
                animate={{ opacity: 1, x: 0 }}
                initial={{ opacity: 0, x: 30 }}
                exit={{ opacity: 0, x: -30 }}
                transition={{ duration: 0.4 }} 
                className="social-media-localization-area"
            >
                <div className="social-media-url-area">
                    <input type="url" placeholder="Paste the linkedin post URL/Id here"/>
                    <ButtonBase onClick={handleUrlSubmitted} className="url-search-btn">
                        <KeyboardBackspaceIcon className="arrow-icon"/>
                    </ButtonBase>
                </div>
                <AnimatePresence exitBeforeEnter>
                    {
                        originalTxt &&
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: -20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 0.4 }}
                            className="social-media-original-txt-area"
                        >
                            <h3>Original Text</h3>
                            <div className="original-text-area-wrap">
                                <textarea className="text-area-form" placeholder="Enter your text here..."></textarea>
                                <div className="bottom-btn-wrap">
                                    <div className="lang-select-wrap">
                                        <ButtonBase className="lang-select-btn">
                                            <span className="txt">Source Language</span>
                                            <ArrowDropDownIcon className="icon" />
                                        </ButtonBase>
                                        <ButtonBase className="lang-select-btn">
                                            <span className="txt">Target Language(s)</span>
                                            <ArrowDropDownIcon className="icon" />
                                        </ButtonBase>
                                    </div>
                                    <ButtonBase onClick={handleTranslateTxt} className="translate-btn">
                                        <span>Translate</span>
                                    </ButtonBase>
                                </div>
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
                <AnimatePresence exitBeforeEnter>
                    {
                        translatedTxt &&
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: -20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 0.4 }}
                            className="social-media-translated-txt-area"
                            ref={linkedinTranslatedArea}
                        >
                            <h3>Translated Text</h3>
                            <div className="translated-area-row">
                                <div className="translated-area-box">
                                    <div className="header">
                                        <p>Tamil</p>
                                    </div>
                                    <div className="translated-txt">
                                        <p>"ஒரு யோசனையை எடுத்துக் கொள்ளுங்கள். அந்த ஒரு யோசனையை உங்கள் வாழ்க்கையாக ஆக்குங்கள் - அதை நினைத்துப் பாருங்கள், அதைக் கனவு காணுங்கள், அந்த யோசனையில் வாழுங்கள். மூளை, தசைகள், நரம்புகள், உங்கள் உடலின் ஒவ்வொரு பகுதியும் அந்த எண்ணத்தால் நிறைந்திருக்கட்டும், விட்டுவிடுங்கள். மற்ற ஒவ்வொரு யோசனையும் மட்டுமே. இதுவே வெற்றிக்கான வழி." --சுவாமி விவேகானந்தர்</p>
                                    </div>
                                </div>
                                <div className="translated-area-box">
                                    <div className="header">
                                        <p>Telugu</p>
                                    </div>
                                    <div className="translated-txt">
                                        <p>"ఒక ఆలోచన తీసుకోండి. ఆ ఒక్క ఆలోచనను మీ జీవితంగా చేసుకోండి - దాని గురించి ఆలోచించండి, దాని గురించి కలలు కనండి, ఆ ఆలోచనతో జీవించండి. మెదడు, కండరాలు, నరాలు, మీ శరీరంలోని ప్రతి భాగం, ఆ ఆలోచనతో నిండిపోనివ్వండి మరియు వదిలివేయండి. ప్రతి ఇతర ఆలోచన ఒక్కటే. విజయానికి ఇదే మార్గం." --స్వామి వివేకానంద</p>
                                    </div>
                                </div>
                            </div>
                            <div className="publish-button-row">
                                <ButtonBase className="translate-btn">
                                    <span>Publish Post</span>
                                </ButtonBase>
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
            </motion.div>
        </React.Fragment>
    )
}

export default LinkedinLocalization;