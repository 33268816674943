


function ButtonLoader(props) {

  const{
   color
  } = props

  
  
  return (
    <div className="save-btn-spinner">
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
        <div style={{background:color}}></div>
    </div>
  )
}

export default ButtonLoader
