import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DesignerImages from '../components/designer-assets/DesignerImages'
import '../assets/styles/designerAssets.css'

const DesignerAssets = (props) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <DesignerImages />
        </React.Fragment>
    )
}

export default DesignerAssets;