import React, { useEffect, useLayoutEffect, useState, useRef, useCallback } from "react";
import Draggable, { DraggableCore } from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import { Popover } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useEyeDropper from 'use-eye-dropper'
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import FormatAlignCenterOutlinedIcon from '@mui/icons-material/FormatAlignCenterOutlined';
import FormatAlignRightOutlinedIcon from '@mui/icons-material/FormatAlignRightOutlined';
import FormatAlignJustifyOutlinedIcon from '@mui/icons-material/FormatAlignJustifyOutlined';
import { SketchPicker ,CompactPicker, TwitterPicker } from 'react-color'
import Linethrough from '@mui/icons-material/FormatStrikethrough';
import Spacing from '@mui/icons-material/FormatLineSpacing';
import Overline from '@mui/icons-material/FormatOverline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ColorizeIcon from '@mui/icons-material/Colorize';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import SubscriptIcon from '@mui/icons-material/Subscript';
import BlockIcon from '@mui/icons-material/Block';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBold from "@mui/icons-material/FormatBold";
import FormatUnderlined from "@mui/icons-material/FormatUnderlined";
import { ButtonBase } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));

const TextProperties = ({handleOpenFontFamily,editor,fabric,counter,actFontFam, counterTemp,decreaseFontSize,increaseFontSize,Copy,Paste,handleBold,handleItalic,handleUnderline,handoverline, handlinethrough,bold,italic,underline,overline,linethrough}) => {

    const classes = useStyles();
     //draggable
     const [state, setState] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    });

    const onStart = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags + 1 });
    };
    const onStop = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags - 1 });
    };

    const dragHandlers = { onStart, onStop };


    const FontFamilyCanvasUpdate = ({editor}) => {








        if(editor?.canvas.getActiveObject()?.type=='textbox'){
            // editor.canvas.getActiveObject().set()
            editor.canvas.getActiveObject().setControlsVisibility({
                mt: false, // middle top disable
                mb: false, // midle bottom
            })
            // editor.canvas.getActiveObject().on(("changed"),function(){
            //     //canvas.setActiveObject(textBox);
            //     var actualWidth = editor.canvas.getActiveObject().scaleX * editor.canvas.getActiveObject().width;
            //      var largest = editor.canvas.getActiveObject().__lineWidths[0];
            //      var tryWidth = (largest + 15) * editor.canvas.getActiveObject().scaleX;
            //      editor.canvas.getActiveObject().set("width",tryWidth);
            //      if((editor.canvas.getActiveObject().left + actualWidth) >= editor.canvas.getActiveObject().width - 10)
            //       {
            //           //console.log(canvas.height - arrowLeft)
            //           editor.canvas.getActiveObject().set("width", editor.canvas.getActiveObject().width - editor.canvas.getActiveObject().left - 20)
            //      }
            //          editor.canvas.renderAll();
            
            //  });
            // console.log((editor.canvas.getActiveObject().get('text').length)*18);
            // console.log(editor.canvas.getActiveObject().__lineWidths[0]);
        }




        return (
            <div>
                <div className="select-font-fam" id="font-fam-select-height" onClick={handleOpenFontFamily} >{actFontFam}</div>
            </div>
        )


    }

    const ChangeFontcolor = ({ dragHandlers }) => {

        const [color, setColor] = useState("#11111")
        // useEffect(()=>{
        //  if(editor){
        //         if(editor.canvas.getActiveObject()){
        //             setColor(editor.canvas.getActiveObject().fill)
        //             editor.canvas.renderAll()
        //     }
        //  }
        // },[editor])

        const onchangecolor = () => {
            if (editor) {
                if (editor.canvas.getActiveObject().getSelectedText()) {
                    editor.canvas.getActiveObject().setSelectionStyles({ fill: color })
                    editor.canvas.renderAll()
                }
                else {
                    let temp = editor.canvas.getActiveObject().get('text').length
                    editor.canvas.getActiveObject().setSelectionStyles(({ fill: color }), 0, temp)
                    editor.canvas.getActiveObject().set({fill: color})
                    editor.canvas.renderAll()
                }
            }
        }
        const temp = useRef(true)
        useEffect(() => {
            if (temp.current) {
                temp.current = false
                return
            }
            if (editor) {
                if (editor.canvas.getActiveObject().getSelectedText()) {
                    try {
                        // editor.canvas.getActiveObject().set('fill',color)
                        editor.canvas.getActiveObject().setSelectionStyles({ fill: color })
                        editor.canvas.renderAll()
                    }
                    catch {
                        console.log('color not change');
                    }
                }
                else {
                    try {
                        let temp = editor.canvas.getActiveObject().get('text').length
                        editor.canvas.getActiveObject().setSelectionStyles(({ fill: color }), 0, temp)
                        editor.canvas.getActiveObject().set({fill: color})
                        editor.canvas.renderAll()
                    }
                    catch {
                        console.log('color not change');
                    }
                }

            }
        }, [color])

        const Eye = () => {
            const { open, close } = useEyeDropper()
            const [eyecolor, setEyecolor] = useState(color)
            const [eyeerror, setEyeerror] = useState()
            // useEyeDropper will reject/cleanup the open() promise on unmount,
            // so setState never fires when the component is unmounted.
            const pickColor = () => {
                open()
                    .then(eyecolor => setEyecolor(eyecolor.sRGBHex))
                    .catch(e => {
                        console.log(e)
                        // Ensures component is still mounted
                        // before calling setState
                        if (!e.canceled) setEyeerror(e)
                    })
            }
            setColor(eyecolor)
            return (
                <>
                    <button onClick={pickColor} className="eyecplacer" style={{ border: 'none', }}><ColorizeIcon /></button>
                    {!!eyeerror && <span>{eyeerror.message}</span>}
                </>
            )
        }



        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);
            }
        }

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;


        return (
            <div className="fontcolorcontainer">
                <Button aria-describedby={id} color="white" size="sm" variant="outline" className="fontcolor-btn" onClick={handleCloseAndOpen}>
                    <FormatColorTextIcon className={anchorEl ? "toolbar-icons-active  " : "toolbar-icons "} />
                </Button>
                <Draggable handle="strong" {...dragHandlers}>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className={classes.popover}
                        classes={{
                            paper: classes.popoverContent,
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >

                        <div className="div-fontcolorsection box no-cursor">
                            <strong className='cursor d-flex align-items-center  gap-2 mb-3'>
                                <div className="drag-me ">
                                    Drag
                                </div>
                                <CloseIcon className="close-icon-pop" onClick={handleClose} />

                            </strong>

                            <SketchPicker color={color} onChange={(color) => {
                                setColor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")");
                                onchangecolor()

                            }} />
                            <TwitterPicker color={color} onChange={(e) => {
                                setColor("rgba(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")")
                            }} />
                            <Eye />
                        </div>
                    </Popover>
                </Draggable>
            </div>
        );
    }

    const Textalign = () => {

        const alignCenter = () => {

            editor.canvas.getActiveObject().textAlign = "center";
            editor.canvas.renderAll();
    
    
        }
    
        const alignLeft = () => {
    
            editor.canvas.getActiveObject().textAlign = "left";
            editor.canvas.renderAll();
    
    
        }
    
        const alignRight = () => {
    
            editor.canvas.getActiveObject().textAlign = "right";
            editor.canvas.renderAll();
    
    
        }
    
        const alignJustify = () => {
    
            editor.canvas.getActiveObject().textAlign = "justify";
            editor.canvas.renderAll();
    
        }
        

        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);

            }
        }
        const handleClose = () => {
            setAnchorEl(null);
        };


        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div className="textalignContainer">
                <FormatAlignLeftOutlinedIcon className={anchorEl ? "toolbar-icons-active" : "toolbar-icons"} onClick={handleCloseAndOpen} />
                <Popover
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    id={id}
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="div-alignSection">
                        <Button color="white" className="btn" onClick={alignLeft}><FormatAlignLeftOutlinedIcon className="postition-icon" /><span className="postition-icon-text"> Left</span></Button>
                        <Button color="white" className="btn" onClick={alignCenter}><FormatAlignCenterOutlinedIcon className="postition-icon" /><span className="postition-icon-text"> Center</span></Button>
                        <Button color="white" className="btn" onClick={alignRight}><FormatAlignRightOutlinedIcon className="postition-icon" /> <span className="postition-icon-text">Right</span></Button>
                        <Button color="white" className="btn" onClick={alignJustify}><FormatAlignJustifyOutlinedIcon className="postition-icon" /> <span className="postition-icon-text">Justify</span></Button>
                    </div>
                </Popover>
            </div>
        );
    }

    const TextCaps = () => {

        const [anchorEl, setAnchorEl] = React.useState(null);

        // const handleClick = (event) => {
        //     setAnchorEl(event.currentTarget);
        // };

        // const handleClose = () => {
        //     setAnchorEl(null);
        // };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const onuppercase = () => {
            editor.canvas.getActiveObject().set('uppercase', true)
            editor.canvas.getActiveObject().set('text', editor.canvas.getActiveObject().text.toUpperCase())
            editor.canvas.renderAll()
        }

        const capitalization = () => {
            editor.canvas.getActiveObject().set('uppercase', true)
            editor.canvas.getActiveObject().set('text', editor.canvas.getActiveObject().text.charAt(0).toUpperCase() + editor.canvas.getActiveObject().text.slice(1).toLowerCase())
            editor.canvas.renderAll()
        }

        const onlowercase = () => {
            editor.canvas.getActiveObject().set('uppercase', false)
            editor.canvas.getActiveObject().set('text', editor.canvas.getActiveObject().text.toLowerCase())
            editor.canvas.renderAll()
        }

        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);

            }
        }

        const handleClose = () => {
            setAnchorEl(null);
        };


        return (

            <div className="">
                <div className={anchorEl ? "toolbar-icons-active" : "toolbar-icons"} onClick={handleCloseAndOpen}>Aa</div>
                <Popover
                    id={id}
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="textcaps-container">
                        <div className="abc-icon-text" onClick={onuppercase}>Uppercase</div>
                        <div className="abc-icon-text" onClick={onlowercase}>Lowercase</div>
                        <div className="abc-icon-text" onClick={capitalization}>Capitalize</div>
                    </div>
                </Popover>
            </div>

        )
    }

    const Spacingtoggler = () => {
        const [linespacing, setLinespacing] = useState(100)
        const linespacinginputer = useRef(null)


        useEffect(() => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setLinespacing((editor.canvas.getActiveObject().lineHeight * 100))
                    editor.canvas.renderAll()
                }
            }
        }, [editor])

        const changeLinespacingvalue = () => {
            setLinespacing(linespacinginputer.current.value)

        }
        const linespacingtemp = useRef(true)
        useEffect(() => {
            if (linespacingtemp.current) {
                linespacingtemp.current = false
                return;
            }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    // editor.canvas.getActiveObject().set({opacity : opacity/100})
                    // editor.canvas.renderAll()

                    try {
                        editor.canvas.getActiveObject().set({ lineHeight: linespacing / 100 })
                        editor.canvas.renderAll()
                        // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                        // let activeObjects = editor.canvas.getActiveObjects();
                        // if (activeObjects.length) {
                        //     activeObjects.forEach(function (object) {
                        //         object.set({lineHeight : linespacing/100})
                        //         editor.canvas.renderAll()
                        //     });
                        // }   
                    }
                    catch {
                    }
                }
            }
        }, [linespacing])

        // character spacing

        const [charspacing, setCharspacing] = useState(100)
        const charspacinginputer = useRef(null)


        useEffect(() => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    setCharspacing((editor.canvas.getActiveObject().charSpacing))
                    editor.canvas.renderAll()
                }
            }
        }, [editor])

        const changeCharspacingvalue = () => {
            setCharspacing(charspacinginputer.current.value)

        }
        const charspacingtemp = useRef(true)
        useEffect(() => {
            if (charspacingtemp.current) {
                charspacingtemp.current = false
                return;
            }
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    // editor.canvas.getActiveObject().set({opacity : opacity/100})
                    // editor.canvas.renderAll()

                    try {
                        editor.canvas.getActiveObject().set({ charSpacing: charspacing })
                        editor.canvas.renderAll()
                        // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                        // let activeObjects = editor.canvas.getActiveObjects();
                        // if (activeObjects.length) {
                        //     activeObjects.forEach(function (object) {
                        //         object.set({lineHeight : linespacing/100})
                        //         editor.canvas.renderAll()
                        //     });
                        // }   
                    }
                    catch {
                    }
                }
            }
        }, [charspacing])




        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);

            }
        }

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;
        const spaceingbtn = useRef(null)
        return (
            <div className="spacecontainer">
                <Spacing className={anchorEl ? "toolbar-icons-active" : "toolbar-icons"} onClick={handleCloseAndOpen} />
                <Popover
                    id={id}
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="div-spacesection">
                        <div className="linespacing">
                            <label className="adjust-headers m-0">LineSpacing</label>
                            <div className="linespacing-inputflex">
                                <input type="range" className="form-range" value={linespacing} min={1} max={250} ref={linespacinginputer} onChange={changeLinespacingvalue} id="linespacing" />
                                <span className='input-value'>{linespacing / 100}</span></div>
                        </div>

                        <div className="charspacing">
                            <label className="adjust-headers m-0">LetterSpacing</label>
                            <div className="charspacing-inputflex">
                                <input type="range" className="form-range" value={charspacing} min={1} max={1000} ref={charspacinginputer} onChange={changeCharspacingvalue} id="charspacing" />
                                <span className='input-value'>{charspacing / 100}</span></div>
                        </div>
                    </div>
                </Popover>
            </div>
        );
    }

    const TextEffects = ({ dragHandlers }) => {

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        // shadow blur
        const [shadowBlur, setShadowBlur] = useState(0)

        const handleShadowBlur = (e) => {
            setShadowBlur(e.target.value);

        }


        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject().shadow) {
                    setShadowBlur(editor.canvas.getActiveObject().shadow.blur)
                    editor.canvas.renderAll()
                }
            }
        }, [editor])


        // useEffect(() => {

        //     if(ShadowTemp.current){
        //         ShadowTemp.current = false;
        //         return;
        //     }



        //     if(editor.canvas.getActiveObject().type == 'i-text'){

        //         editor.canvas.getActiveObject().set('shadow', new fabric.Shadow({
        //             blur : shadowBlur,
        //             offsetY : offsetY/10,
        //         }
        //         ))
        //         editor.canvas.renderAll();
        //     }

        // },[shadowBlur])

        const [offsetY, setOffsetY] = useState(0)

        const handleOffY = (e) => {
            setOffsetY(e.target.value);

        }

        const offYTemp = useRef(true)

        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject().shadow) {
                    setOffsetY(editor.canvas.getActiveObject().shadow.offsetY * 10)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])

        const [offsetX, setOffsetX] = useState(0)

        const handleOffX = (e) => {
            setOffsetX(e.target.value);

        }


        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject().shadow) {
                    setOffsetX(editor.canvas.getActiveObject().shadow.offsetX * 10)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])

        const [shadowColor, setShadowColor] = useState('#000')

        const handleShadowColor = (e) => {
            setShadowColor(e.target.value);

        }


        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject().shadow) {
                    setShadowColor(editor.canvas.getActiveObject().shadow.color)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])




        useEffect(() => {

            if (offYTemp.current) {
                offYTemp.current = false;
                return;
            }

            if (editor.canvas.getActiveObject().type == 'textbox') {

                editor.canvas.getActiveObject().set('shadow', new fabric.Shadow({
                    blur: shadowBlur,
                    color: shadowColor,
                    offsetX: offsetX / 10,
                    offsetY: offsetY / 10,

                }
                ))
                editor.canvas.renderAll();
            }

        }, [offsetY, shadowBlur, offsetX, shadowColor])



        const [textBackgroundColors, setTextBackgroundColors] = useState()


        const handleTextBackgroundColor = (e) => {


            setTextBackgroundColors(e.target.value)

        }

        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject().type == 'textbox') {
                    setTextBackgroundColors(editor.canvas.getActiveObject().textBackgroundColor)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])


        const colorTemp = useRef(true)

        useEffect(() => {

            if (colorTemp.current) {
                colorTemp.current = false;
                return;
            }

            if (editor.canvas.getActiveObject()?.type == 'textbox') {

                editor.canvas.getActiveObject().set({
                    'textBackgroundColor': textBackgroundColors

                })
                editor.canvas.renderAll();
            }

        }, [textBackgroundColors])


        const [strokeColor, setStrokeColor] = useState('#000')
        const [strokeWidth, setStrokeWidth] = useState(0)

        const handleStrokeColor = (e) => {


            setStrokeColor(e.target.value)

        }

        const handleStrokeWidth = (e) => {


            setStrokeWidth(e.target.value)

        }

        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject().type == 'textbox') {
                    setStrokeColor(editor.canvas.getActiveObject().stroke)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])

        useEffect(() => {
            // if(opacitytemp.current){
            //     opacitytemp.current = false
            //     return;
            // }
            if (editor) {
                if (editor.canvas.getActiveObject().type == 'textbox') {
                    setStrokeWidth(editor.canvas.getActiveObject().strokeWidth * 50)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])


        const stcolorTemp = useRef(true)

        useEffect(() => {

            if (stcolorTemp.current) {
                stcolorTemp.current = false;
                return;
            }

            if (editor.canvas.getActiveObject()?.type == 'textbox') {

                editor.canvas.getActiveObject().set({
                    stroke: strokeColor,
                    strokeWidth: strokeWidth / 50,

                })
                editor.canvas.renderAll();
            }

        }, [strokeColor, strokeWidth])


        // const stWidthTemp = useRef(true)

        // useEffect(() => {

        //     if (stWidthTemp.current) {
        //         stWidthTemp.current = false;
        //         return;
        //     }

        //     if (editor.canvas.getActiveObject().type == 'i-text') {

        //         editor.canvas.getActiveObject().set({
        //             strokeWidth: strokeWidth/50,
        //         })
        //         editor.canvas.renderAll();
        //     }

        // }, [strokeWidth])




        const [shadowProperty, setShadowProperty] = useState(false)
        const [blurProperty, setBlurProperty] = useState(false)
        const [strokeProperty, setStrokeProperty] = useState(false)
        // const [hallowProperty, setHallowProperty] = useState(false)


        const handleSelectShadowProperty = () => {

            setShadowProperty(true)
            setStrokeProperty(false)
            // setHallowProperty(false)
            setBlurProperty(false)

        }

        const handleSelectBlurProperty = () => {

            setBlurProperty(true)
            setShadowProperty(false)
            // setHallowProperty(false)

            setStrokeProperty(false)

        }


        const handleSelectStrokeProperty = () => {

            setStrokeProperty(true)
            // setHallowProperty(false)
            setShadowProperty(false)
            setBlurProperty(false)

            editor.canvas.getActiveObject().set({
                fill: "transparent",
                stroke: strokeColor ? strokeColor : '#111',
                strokeWidth: strokeWidth ? strokeWidth / 50 : 1,
            })
            editor.canvas.renderAll()

        }

        // const handleHallowProperty = () => {

        //     setStrokeProperty(false)
        //     setHallowProperty(true)
        //     setShadowProperty(false)
        //     setBlurProperty(false)

        // }

        // const [state, setState] = useState({
        //     activeDrags: 0,
        //     deltaPosition: {
        //       x: 0, y: 0
        //     },
        //     controlledPosition: {
        //       x: -400, y: 200
        //     }
        //   });

        //   const onStart = () => {
        //     const { activeDrags } = state;
        //     setState({ ...state, activeDrags: activeDrags + 1 });
        //   };
        //   const onStop = () => {
        //     const { activeDrags } = state;
        //     setState({ ...state, activeDrags: activeDrags - 1 });
        //   };

        //   const dragHandlers = { onStart, onStop };


        // snapping position
// editor.canvas.on({'scaling': function(e) {
//             var obj = this,
//             w = obj.width * obj.scaleX,
//             h = obj.height * obj.scaleY,
//             s = obj.strokeWidth;

//         obj.set({
//             'height'     : h,
//             'width'      : w,
//             'scaleX'     : 1,
//             'scaleY'     : 1
//         });
//     }
// });






// var edgedetection = 10
// editor.canvas.on('object:moving', function (e) {
//     var obj = e.target;
//     console.log(editor.canvas.getActiveObject().width);
//     obj.setCoords(); //Sets corner position coordinates based on current angle, width and height
//     editor.canvas.forEachObject(function (targ) {
//         const activeObject = editor.canvas
//         if (targ === activeObject.getActiveObject()) return;
//         if (Math.abs(activeObject.getActiveObject().oCoords.tr.x - targ.oCoords.tl.x) < edgedetection) {
//             activeObject.getActiveObject().left = targ.left - editor.canvas.getActiveObject().width ;
//         }
//         if (Math.abs(activeObject.getActiveObject().oCoords.tl.x - targ.oCoords.tr.x) < edgedetection) {
//             activeObject.getActiveObject().left = targ.left +  targ.width ;
//         }
//         if (Math.abs(activeObject.getActiveObject().oCoords.br.y - targ.oCoords.tr.y) < edgedetection) {
//             activeObject.getActiveObject().top = targ.top - editor.canvas.getActiveObject().height;
//         }
//         if (Math.abs(targ.oCoords.br.y - activeObject.getActiveObject().oCoords.tr.y) < edgedetection) {
//             activeObject.getActiveObject().top = targ.top + targ.height;
//         }
    
        
//     });
// });

// editor.canvas.on('object:scaling',(e)=>
// {
//     editor.canvas.getActiveObject().set({width:(editor.canvas.getActiveObject().get('width')*editor.canvas.getActiveObject().scaleX).toFixed(2)})
//     editor.canvas.getActiveObject().set({height:(editor.canvas.getActiveObject().get('height')*editor.canvas.getActiveObject().scaleX).toFixed(2)})
//     console.log(editor.canvas.getActiveObject().get('width'));
//     console.log(editor.canvas.getActiveObject().get('height'));
// } );
const handleCloseAndOpen = (event) => {
    if (anchorEl == null) {
        setAnchorEl(event.currentTarget);
    }
    else {
        setAnchorEl(null);
    }
}




        return (

            <div className="text-effects">
                <button aria-describedby={id} className={anchorEl ? "toolbar-icons-active filters-button " : "toolbar-icons filters-button"} onClick={handleCloseAndOpen}>
                    Effects
                </button>
                <Draggable handle="strong" {...dragHandlers}>
                    <Popover
                        className={classes.popover}
                        classes={{
                            paper: classes.popoverContent,
                        }}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}

                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className="text-effects-wrapper box ">
                            <strong className='cursor d-flex align-items-center  gap-2 mb-3'>
                                <div className="drag-me ">
                                    Drag
                                </div>
                                <CloseIcon className="close-icon-pop" onClick={handleClose} />

                            </strong>
                            <div className="text-filter-div">
                                <div className={shadowProperty ? "text-effects-grid-items-act" : "text-effects-grid-items"} onClick={handleSelectShadowProperty} >
                                    <span className="text-grid tshadow">A</span>
                                </div>
                                <div className={blurProperty ? "text-effects-grid-items-act ms-2 me-2" : "text-effects-grid-items ms-2 me-2"} onClick={handleSelectBlurProperty}>
                                    <span className="text-grid tbackground">A</span>
                                </div>
                                <div className={strokeProperty ? "text-effects-grid-items-act" : "text-effects-grid-items"} onClick={handleSelectStrokeProperty}>
                                    <span className="text-grid tStroke">A</span>
                                </div>
                                {/* <div className={hallowProperty ? "text-effects-grid-items-act ms-2" : "text-effects-grid-items ms-2"} onClick={handleHallowProperty}>
                                <span className="text-grid tHallow">A</span>
                            </div> */}
                            </div>
                            {shadowProperty &&
                                <div className="text-effects-head-adjust">

                                    <span>Shadow</span>
                                </div>

                            }
                            {blurProperty &&
                                <div className="text-effects-head-adjust">

                                    <span>Background</span>
                                </div>

                            }
                            {strokeProperty &&
                                <div className="text-effects-head-adjust">

                                    <span>Stroke</span>
                                </div>

                            }
                            {/* {hallowProperty &&
                            <div className="text-effects-head-adjust">

                                <span>Hallow</span>
                            </div>

                        } */}
                            {shadowProperty &&
                                <div className="text-effects-adjust">
                                    <label className="form-label text-effect-input-value">blur</label>
                                    <div className="text-effect-range-container">
                                        <input type="range" className="form-range text-effects-slider-range" value={shadowBlur} min={0} max={100} onChange={handleShadowBlur} id="customRange1" />
                                        <span className="text-effect-input-value">{shadowBlur}</span>
                                    </div>
                                    <label className="form-label text-effect-input-value">offset-X</label>
                                    <div className="text-effect-range-container">
                                        <input type="range" className="form-range text-effects-slider-range" value={offsetX} min={-100} max={100} onChange={handleOffX} id="customRange1" />
                                        <span className="text-effect-input-value">{offsetX}</span>
                                    </div>
                                    <label className="form-label text-effect-input-value">offset-Y</label>
                                    <div className="text-effect-range-container">
                                        <input type="range" className="form-range text-effects-slider-range" value={offsetY} min={-100} max={100} onChange={handleOffY} id="customRange1" />
                                        <span className="text-effect-input-value">{offsetY}</span>
                                    </div>
                                    <div className="text-effect-range-container-color">
                                        <span className="text-effect-input-value-color">color</span>
                                        <SketchPicker color={shadowColor} onChange={(e) => {
                                            setShadowColor("rgba(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")");
                                        }} />
                                    </div>
                                </div>

                            }
                            {blurProperty &&
                                <div className="text-effects-adjust">
                                    {/* <label className="form-label text-effect-input-value">Opacity</label>
                            <div className="text-effect-range-container">
                                <input type="range" className="form-range text-effects-slider-range" value={offsetY} min={0} max={100} onChange={handleOffY} id="customRange1" />
                                <span className="text-effect-input-value">{offsetY}</span>
                            </div> */}
                                    <div className="text-effect-range-container-color">
                                        <span className="text-effect-input-value-color">background color</span>
                                        <SketchPicker color={textBackgroundColors} onChange={(e) => {
                                            setTextBackgroundColors("rgba(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")");
                                        }} />
                                    </div>
                                </div>

                            }
                            {strokeProperty &&
                                <div className="text-effects-adjust">
                                    <label className="form-label text-effect-input-value">stoke Width</label>
                                    <div className="text-effect-range-container">
                                        <input type="range" className="form-range text-effects-slider-range" value={strokeWidth} min={0} max={100} onChange={handleStrokeWidth} id="customRange1" />
                                        <span className="text-effect-input-value">{strokeWidth}</span>
                                    </div>
                                    <div className="text-effect-range-container-color">
                                        <span className="text-effect-input-value">strokecolor</span>
                                        <input type="color" className="form-range text-effects-color-range" value={strokeColor} onChange={handleStrokeColor} />
                                        {/* <span className="text-effect-input-value-color">strokecolor</span> */}
                                        {/* <SketchPicker  color={strokeColor} onChange={(e) => {
                                            setStrokeColor(e.hex);
                                            }} /> */}
                                    </div>
                                </div>

                            }

                            {/* {hallowProperty &&
                            <div className="text-effects-adjust">
                            <label className="form-label text-effect-input-value">stoke Width</label>
                            <div className="text-effect-range-container">
                                <input type="range" className="form-range text-effects-slider-range" value={strokeWidth} min={0} max={100} onChange={handleStrokeWidth} id="customRange1" />
                                <span className="text-effect-input-value">{strokeWidth}</span>
                            </div>
                            <div className="text-effect-range-container-color">
                                <span className="text-effect-input-value">strokecolor</span>
                                <input type="color" className="form-range text-effects-color-range" value={strokeColor} onChange={handleStrokeColor} />
                            </div>
                        </div>

                        } */}









                            {/* <div className="text-effects-grid-items">
                            <span className="text-grid">A</span>
                        </div>
                        <div className="text-effects-grid-items">
                            <span className="text-grid">A</span>
                        </div>
                        <div className="text-effects-grid-items">
                            <span className="text-grid">A</span>
                        </div>
                        <div className="text-effects-grid-items">
                            <span className="text-grid">A</span>
                        </div>
                        <div className="text-effects-grid-items">
                            <span className="text-grid">A</span>
                        </div>
                        <div className="text-effects-grid-items">
                            <span className="text-grid">A</span>
                        </div> */}
                        </div>
                    </Popover>
                </Draggable>
            </div>

        )


    }

    const Textscripts = () => {

        const [anchorEl, setAnchorEl] = React.useState(null);

        // const handleClick = (event) => {
        //     setAnchorEl(event.currentTarget);
        // };

        // const handleClose = () => {
        //     setAnchorEl(null);
        // };

        const superscript = () => {
            var active = editor.canvas.getActiveObject();
            if (!active) return;
            active.setSelectionStyles({
                fontSize: undefined,
                deltaY: undefined,
            });
            active.setSuperscript();
            editor.canvas.requestRenderAll();
        }

        const subscript = () => {
            var active = editor.canvas.getActiveObject();
            if (!active) return;
            active.setSelectionStyles({
                fontSize: undefined,
                deltaY: undefined,
            });
            active.setSubscript();
            editor.canvas.requestRenderAll();
        }
        const nonescript = () => {
            var active = editor.canvas.getActiveObject();
            if (!active) return;
            active.setSelectionStyles({
                fontSize: undefined,
                deltaY: undefined,
            });
            editor.canvas.requestRenderAll();
        }

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;



        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);

            }


        }
        const handleClose = () => {
            setAnchorEl(null);
        };



        return (

            <div className="">
                <div className={anchorEl ? "toolbar-icons-active" : "toolbar-icons"} onClick={handleCloseAndOpen}><SubscriptIcon /> </div>
                <Popover
                    id={id}
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="textcaps-container">
                        <div className="abc-icon-text" onClick={nonescript}><BlockIcon /> None</div>
                        <div className="abc-icon-text" onClick={superscript}><SuperscriptIcon /> Superscript</div>
                        <div className="abc-icon-text" onClick={subscript}><SubscriptIcon /> Subscript</div>
                    </div>
                </Popover>
            </div>

        )
    }


    return(
        <>
                                            <div className="font-fam-tool" style={{ visibility: editor?.canvas.getActiveObject()?.type == 'textbox' ? 'visible' : 'hidden' }}>
                                                <FontFamilyCanvasUpdate />
                                            </div>
                                            <div className="font-size" style={{ visibility: editor.canvas.getActiveObject()?.type == 'textbox' ? 'visible' : 'hidden' }}>
                                                <RemoveIcon value={counter} onClick={decreaseFontSize} className="toolbar-icons br" />
                                                <span className="font-size-span" >{counterTemp}</span>
                                                <AddIcon value={counter} onClick={increaseFontSize} className="toolbar-icons bl" />
                                            </div>
                                            <div className="fonts-toolbar" style={{ visibility: editor.canvas.getActiveObject()?.type == 'textbox' ? 'visible' : 'hidden' }} >
                                                <div className="d-flex align-items-center gap-2">
                                                    <FormatBold onClick={handleBold} className={bold ? "toolbar-icons-active" : "toolbar-icons"} />
                                                    <FormatItalicIcon onClick={handleItalic} className={italic ? "toolbar-icons-active" : "toolbar-icons"} />
                                                    <FormatUnderlined onClick={handleUnderline} className={underline ? "toolbar-icons-active" : "toolbar-icons"} />
                                                    {/* <FormatColorTextIcon className="toolbar-icons" onClick={inputToggler} /> */}
                                                    <ChangeFontcolor />

                                                    {/* <input id="color-input" type="color" ref={hiddenColorInput} onChange={changeFontcolor} style={{ display: 'none' }} /> */}
                                                    <div className="d-flex align-items-center gap-2" style={{ visibility: editor.canvas.getActiveObject()?.type == 'textbox' ? 'visible' : 'hidden' }}>
                                                        <Textalign className='textalign' />
                                                        <Linethrough onClick={handlinethrough} className={'linethrough ' + (linethrough ? "toolbar-icons-active" : "toolbar-icons")} />
                                                        <Overline onClick={handoverline} className={'overline ' + (overline ? "toolbar-icons-active" : "toolbar-icons")} />

                                                        <TextCaps className="toolbar-icons" />
                                                        {/* <Uppercase onClick={handuppercase} className='toolbar-icons' /> */}
                                                        <Spacingtoggler className="toolbar-icons" />
                                                        <TextEffects className="toolbar-icons" />
                                                        {/* <ContentCopyIcon onClick={Copy} /> */}
                                                        {/* <ContentPasteIcon onClick={Paste} /> */}
                                                        <ContentCopyIcon className="toolbar-icons" onClick={() => {
                                                            Copy();
                                                            Paste();
                                                        }} />
                                                        <Textscripts className="toolbar-icons" />
                                                    </div>
                                                    {/* <BrushIcon className={isdrawing ? "toolbar-icons-active" : "toolbar-icons"} onClick={drawingFunc} /> */}
                                                </div>
                                            </div>
                                        </>
    )
}

export default TextProperties;