import React, { useEffect, useState, useRef } from "react";
import TextToSpeechLayout from "../components/text-to-speech/TextToSpeechLayout";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { browserName, CustomView } from 'react-device-detect';
import { useNavigate } from "react-router-dom";


const TextToSpeech = (props) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
           <section className="tts-section-wrapper">
                <div className="container">
                    <div className="tts-wrap">
                        <h1>
                            <div onClick={() => navigate("/")} className="back-icon">
                                <ArrowBackIosNewIcon className="arrow-icon"/>
                            </div>
                            Text to Speech
                        </h1>
                        {/* <p>This is a graphical interface to easily try all our models without writing a single line of code. For more information about the API, please see the documentation, and if you're new please first register and get a free API token.</p> */}
                        <CustomView condition={browserName === "Firefox" || browserName === "Opera"}>
                            <h1>Not supported</h1>
                        </CustomView>
                        <CustomView condition={browserName !== "Opera" && browserName !== "Firefox"}>
                            <TextToSpeechLayout />
                        </CustomView>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default TextToSpeech;