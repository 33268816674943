import { ButtonBase } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, Navigate } from "react-router-dom";
import { Form, Input, Label, FormGroup } from "reactstrap";
import Cookies from "js-cookie";
import Config from "../config/Config";
import axios from "axios";


export const refreshBackgroundImage = () => {

    var loginImages = [
        "login-page-bg.jpg",
        "banner1.jpg",
        "banner2.jpg",
        "banner3.jpg",
        "banner4.jpg",
        "banner5.jpg",
        "banner6.jpg",
        // "banner7.jpg",
        "banner8.jpg",
        "banner9.jpg",
    ];
    document.getElementsByClassName("login-left-side-wrapper")[0].style.backgroundImage =
        "url(" + window.location.origin + "/media/images/auth/login/" + loginImages[Math.floor(Math.random() * loginImages.length)] + ")";
};


const Login = (props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [pwdVisibility, setPwdVisibility] = useState(false);
    const [pwdSignVisibility, setPwdSignVisibility] = useState(false);


    const handleFormDataSub = (formData) => {
        formData.append("email", "ailaysademo@gmail.com");
        formData.append("password", "Ai_mt#4321");
        return formData;
      };
      
        const handleSubmitSub = async(e) => {
            var requestOptions = {
                method: 'POST',
                body: handleFormDataSub(new FormData()),
                redirect: 'follow',
               
              };
              setShowLoginLoader(true)
              let data = await fetch(Config.MT_URL + "/users/login/", requestOptions)
              if(data.status === 200){
                  let response = await data.json()
                  console.log(response)
                  console.log(response.access_token)
    
                  let userData = {
                      id: response?.user?.pk,
                      firstName: response.user.first_name,
                      lastName: response.user.last_name,
                      userName: response.user.username,
                      email: response.user.email,
                      token: response.access_token,
                      refresh_token: response.refresh_token,
                  };
                  Cookies.set(process.env.REACT_APP_USER_COOKIE_KEY_NAME_SUB, JSON.stringify(userData), { domain: Config.COOKIE_DOMAIN_SUB });
                  setTimeout(() => {
                    setShowLoginLoader(false)
                    window.location.href = "/";
                }, 500);
              }else{
                  setShowLoginLoader(false)
              }
         
      };

    const [showLoginLoader, setShowLoginLoader] = useState(false);

    useEffect(() => {
        refreshBackgroundImage();
        if (Config.userState != null) {
            window.location.href = "/";
        }
    }, []);

    const handleSubmit = async(e) => {
        e.preventDefault()
        var requestOptions = {
            method: 'POST',
            body: handleFormData(new FormData()),
            redirect: 'follow'
        };
        setShowLoginLoader(true)
        let data = await fetch(Config.LABS_API + "auth/dj-rest-auth/login/", requestOptions)
        if(data.status === 200){
            let response = await data.json()
            console.log(response)
            let userData = {
                id: response?.user?.pk,
                firstName: response.user.first_name,
                lastName: response.user.last_name,
                userName: response.user.username,
                email: response.user.email,
                token: response.access_token,
                refresh_token: response.refresh_token,
            };
            
            Cookies.set(process.env.REACT_APP_USER_COOKIE_KEY_NAME, JSON.stringify(userData), { domain: Config.COOKIE_DOMAIN });
            // handleSubmitSub()
            setTimeout(() => {
                setShowLoginLoader(false)
                window.location.href = "/";
            }, 500);
           
        }else{
            setShowLoginLoader(false)
        }
    };

    const handleFormData = (formData) => {
        formData.append("email", email);
        formData.append("password", password);
        return formData;
    };

    const handlePwdVisibilityChange = () => {
        setPwdVisibility(!pwdVisibility);
    };

    const handlePwdSignVisibilityChange = () => {
        setPwdSignVisibility(!pwdSignVisibility);
    };

    return (
        <React.Fragment>
            <section className="login-bg">
                <div className="login-left-side-wrapper">
                    <div className="login-left-side">
                        <div className="top-area">
                            <div className="logo">
                                <img src={window?.innerHeight < 754 ?  window.location.origin + "/media/images/auth/ai-logo.svg" : window.location.origin + "/media/images/auth/white-ai-logo.svg"} alt="login-logo" />
                            </div>
                        </div>
                        {/* <div className="bottom-area">
                            <div className="quotes-wrapper img-swap-active-anim">
                                <p className="quote">
                                    &quot;One should not aim at being possible to understand <br></br> but at being impossible to misunderstand.&quot;
                                </p>
                                <p className="author">MARCUS FABIUS QUINTILIAN</p>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="login-form">
                        {/* <div className="login-heading-part">
                            <Link className="mob-auth-logo" to="/">
                                <img src={window.location.origin + "/media/images/auth/auth-mob-ailaysa-logo.svg"} alt="login-logo" />
                            </Link>
                        </div> */}
                        <div className="form-area-wrapper img-swap-active-anim">
                            {/* {
                                showError400?.invalid_credentials &&
                                <div className="glb-alert error submenu_fadeIn submenu-animated">
                                    <ErrorIcon
                                        style={{
                                            width: 24,
                                            color: "#E74C3C",
                                        }}
                                    />
                                    <div className="d-flex align-items-center justify-content-between glb-alert-gap">
                                        <span>{t("invalid_credentials")}</span>
                                    </div>
                                </div>
                            }
                            {
                                showError400?.email_not_verified && 
                                <div className="glb-alert error submenu_fadeIn submenu-animated">
                                    <ErrorIcon
                                        style={{
                                            width: 24,
                                            color: "#E74C3C",
                                        }}
                                    />
                                    <div className="d-flex align-items-center justify-content-between glb-alert-gap">
                                        <span>{t("email_not_verified")}</span>
                                    </div>
                                </div>
                            }
                            {
                                showError400?.valid_data_required && 
                                <div className="glb-alert error submenu_fadeIn submenu-animated">
                                    <ErrorIcon
                                        style={{
                                            width: 24,
                                            color: "#E74C3C",
                                        }}
                                    />
                                    <div className="d-flex align-items-center justify-content-between glb-alert-gap">
                                        <span>{t("valid_data_required")}</span>
                                    </div>
                                </div>
                            }
                            {
                                showError401?.user_inactive &&
                                <div className="glb-alert error submenu_fadeIn submenu-animated">
                                    <ErrorIcon
                                        style={{
                                            width: 24,
                                            color: "#E74C3C",
                                        }}
                                    />
                                    <div className="d-flex align-items-center justify-content-between glb-alert-gap">
                                        <span>Account is deactivated</span>
                                    </div>
                                </div>
                            }
                            {
                                showError500?.error_500 &&
                                <div className="glb-alert info submenu_fadeIn submenu-animated">
                                    <InfoIcon 
                                        style={{
                                            width: 24,
                                            color: "#0078D4",
                                        }}
                                    />
                                    <div className="d-flex align-items-center justify-content-between glb-alert-gap">
                                        <span>{t("error_500")}</span>
                                    </div>
                                </div>
                            }
                            {
                                showError500?.something_went_wrong &&
                                <div className="glb-alert error submenu_fadeIn submenu-animated">
                                    <ErrorIcon
                                        style={{
                                            width: 24,
                                            color: "#E74C3C",
                                        }}
                                    />
                                    <div className="d-flex align-items-center justify-content-between glb-alert-gap">
                                        <span>{t("something_went_wrong")}</span>
                                    </div>
                                </div>
                            }
                            {
                                showError200?.something_went_wrong &&
                                <div className="glb-alert error submenu_fadeIn submenu-animated">
                                    <ErrorIcon
                                        style={{
                                            width: 24,
                                            color: "#E74C3C",
                                        }}
                                    />
                                    <div className="d-flex align-items-center justify-content-between glb-alert-gap">
                                        <span>{t("something_went_wrong")}</span>
                                    </div>
                                </div>
                            }
                                                        {
                                showError201?.something_went_wrong &&
                                <div className="glb-alert error submenu_fadeIn submenu-animated">
                                    <ErrorIcon
                                        style={{
                                            width: 24,
                                            color: "#E74C3C",
                                        }}
                                    />
                                    <div className="d-flex align-items-center justify-content-between glb-alert-gap">
                                        <span>{t("something_went_wrong")}</span>
                                    </div>
                                </div>
                            } */}
                            <h1 className="title">Log in</h1>
                            <Form>
                                <FormGroup className="ai-login-form-group">
                                    <Label htmlFor="email-id">Email *</Label>
                                    <Input 
                                        type="email" 
                                        id="email-id" 
                                        className="ai-textbox" 
                                        value={email} 
                                        onChange={(e) => setEmail(e.target.value)} 
                                    />
                                    {/* {!requirementSatisfied && email?.length === 0 ? (
                                        required
                                    ) : emailError ? (
                                        <h6 className="ai-max-words-validation-new-alert-new">{emailError}</h6>
                                    ) : null} */}
                                </FormGroup>
                                <FormGroup className="ai-login-form-group">
                                    <div className="login-forget-pwd">
                                        <Label htmlFor="password">Password *</Label>
                                    </div>
                                    <Input
                                        type={pwdVisibility ? "text" : "password"}
                                        id="password"
                                        className="ai-textbox"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span className="pwd-visibility-btn" onClick={handlePwdVisibilityChange}>
                                        <img
                                            src={
                                                pwdVisibility
                                                    ? window.location.origin + "/media/images/auth/pwd_visibility.svg"
                                                    : window.location.origin + "/media/images/auth/pwd_visibility_off.svg"
                                            }
                                            alt="pwdvisibility"
                                        />
                                    </span>
                                    {/* {!requirementSatisfied && password?.length === 0 ? required : null} */}
                                </FormGroup>
                                <div className="ai-mt-2">
                                    <ButtonBase
                                        type="submit"
                                        className="w-100" 
                                        onClick={handleSubmit}
                                    >
                                        <span className="ai-login-submit-btn">
                                            {showLoginLoader && (
                                                <div className="save-btn-spinner">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            )}
                                            {showLoginLoader ? "Logging in" : "Log in"}
                                        </span>
                                    </ButtonBase>
                                </div>
                            </Form>
                        </div>
                    </div>
            </section>
        </React.Fragment>
    )
}

export default Login;