import React, { useEffect, useState, useRef } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Select, { components } from "react-select";
import InfinityScrollComponent from "./InfinityScrollComponent";
import Config from "../../config/Config";
import { ButtonBase } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const DesignerImages = (props) => {

    const {
       
    } = props

    const [focusOnSearch, setFocusOnSearch] = useState(false)
  
    const [inputUploadText, setInputUploadText] = useState("");

    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputUploadText(lowerCase);
    };

   
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownIcon className="icon" />
            </components.DropdownIndicator>
        );
    };

    const customAssignStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#9B9FA2",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "24px",
        }),
        menu: (provided, state) => ({
            ...provided,
            padding: "6px 0px",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #DADADA",
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: "0px solid #CED4DA",
            borderLeft: "2px solid transparent",
            color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
            background: state.isSelected ? "#F4F5F7" : "#ffffff",
            padding: "2px 8px",
            color: "#3C4043",
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            opacity: state.isDisabled ? 0.5 : 1,
            whiteSpace: "nowrap",
            "&:hover": {
                background: "#F4F5F7",
                borderLeft: "2px solid #0074D3",
                cursor: "pointer",
            },
        }),
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? "2px solid #0074D3" : "2px solid #C0C8CE",
            borderRadius: 4,
            transtion: 0.3,
            color: state.isFocused ? "#0074D3" : "#3C4043",
            fontFamily: "Roboto",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "24px",
            width: "100%",
            minheigh: 51,
            padding: 0,
            height: state.isFocused ? 51 : 51,
            boxShadow: 0,
            "&:hover": {
                cursor: "pointer",
                fontWeight: "400",
                border: "2px solid #0074D3",
                height: 51,
            },
        }),
    };

    const [ assetsData , setAssetsData ] = useState([])
    const [ assetsList , setAssetsList ] = useState([])
    const [ hasMore , setHasMore ] = useState(true)
    const [ selectedCategory , setSelectedCategory ] = useState({label: 'Ai Images',value: 1})
    const [ selectedShapeCategory , setSelectedShapeCategory ] = useState({value: 1, label: 'blobs'})
    const [ selectedShapeType , setSelectedShapeType ] = useState({value: 1, label: 'Outline'})

    const [ selectedType , setSelectedType ] = useState({value: 1, label: 'background'})
    const [tempTags, setTempTags] = useState('')
    const [postivePrompt, setPositivePrompt] = useState('')
    const [negativePrompt, setNegativePrompt] = useState('')

    const typeSearch = useRef()

    const category = [
        {value: 1 , label: 'Ai Generation'},
    ]
    const type = [
        {value: 1 , label: 'Ai photo'},
        {value: 2 , label: 'Ai background'},
    ]
    const handleSelectedCategory = (selected) => {
        setSelectedCategory(selected)
    }

    const handleSelectedShapeCategory = (selected) => {
        setSelectedShapeCategory(selected)
    }

    const handleSelectedType = (selected) => {
        setSelectedType(selected)
    }




    const handleTags = (e) => {
        setTempTags(e.target.value)
    }

    const handlePositivePrompt = (e) => {
        setPositivePrompt(e.target.value)
    }

    const handleNegativePrompt = (e) => {
        setNegativePrompt(e.target.value)
    }

    // const [ categoryOptions, setCategoryOptions] = useState([])

    const categoryOptions = [
        {label: 'Ai Images',value: 1 }
    ] 

    const [ shapesCategoryOptions, setShapesCategoryOptions] = useState([])

    const [ typeOptions, setTypeOptions] = useState([])
    const [ shapeTypeOptions, setShapeTypeOptions] = useState([])

    const [showLoginLoader, setShowLoginLoader] = useState(false);

    const postImage = (id,type) => {
        // setIsSaved(false)
        setShowLoginLoader(true)
        var formdata = new FormData();

        if(type == 'svg'){

            formdata.append("shape_type", selectedCategory.value);

        }else{

            formdata.append("category", selectedCategory.value);

        }

        formdata.append("positive_prompt", postivePrompt);
        formdata.append("tags", tempTags);
        formdata.append("user_asset", id);
        formdata.append("negative_prompt", negativePrompt);



        Config.axios({
            url: Config.AI_CANVAS_STAGING_API + "canvas/generated-asset/",
            method: "POST",
            data: formdata,
            auth: true,
            success: (response) => {
              console.log(response)
                setShowLoginLoader(false)
                getDesignerAssets()
            },
            error: (err) => {
                console.log(err)
      
                // Config.toast('Something went wrong', 'error')
            }
        })
      
      }

    const getDesignerAssetsCategory = () => {
         
        Config.axios({
            url: Config.AI_CANVAS_STAGING_API + "canvas/design-assert",
            method: "GET",
            auth: true,
            success: (response) => {
                // let options 
                console.log(response.data)
                let type = []
                let category = []
                let shapesCategory = []
                let shapesType = []

                response.data?.asset_type.forEach((each) => {
                    type.push({
                        value:each.id,
                        label:each.name
                    })
                });

                response.data?.Image_category.forEach((each) => {
                    category.push({
                        value:each.id,
                        label:each.category
                    })
                });
                response.data?.shape_category.forEach((each) => {
                    shapesCategory.push({
                        value:each.id,
                        label:each.name
                    })
                });

                response.data?.shape_type.forEach((each) => {
                    shapesCategory.push({
                        value:each.id,
                        label:each.name
                    })
                });
                setShapesCategoryOptions(shapesCategory)
                // setCategoryOptions(category)
                setTypeOptions(type)
                setShapeTypeOptions()
                // setAssetsList(response.data)
                // setAssetsData(response.data.results)
            },
            error: (err) => {
                console.log(err)
                // Config.toast('Something went wrong', 'error')
            },
        });
          
    }
    console.log(tempTags)

    function sendFunc(file) {

        var num = 1;
        var name = file;
        var ext = name.split('.');
        ext = ext[ext.length - 1];
        return ext?.toLowerCase();
    }
    const uploadListStructure =
    <>
        {assetsData.map((file, key) => {
            return (

                <div className="upoad-image-details "
                >
                    <div className="image-container">

                        <img className="inpaint-images" src={Config.AI_CANVAS_STAGING_API + '' + file.thumbnail} data-id={Config.AI_CANVAS_STAGING_API + '' + file.image} />
                    </div>
                    <div className="split-bar"></div>
                    {file.status ? (<span>Already added</span>) : (<div className="content-container d-flex gap-2">
                        {console.log(sendFunc(file.image_name))}
                    <Select
                        styles={customAssignStyles}
                        options={ sendFunc(file.image_name) == 'svg' ? shapesCategoryOptions : categoryOptions}
                        defaultValue={sendFunc(file.image_name) == 'svg' ? selectedShapeCategory : selectedCategory}
                        value={sendFunc(file.image_name) == 'svg' ? selectedShapeCategory : selectedCategory}
                        isSearchable={true}
                        onChange={sendFunc(file.image_name) == 'svg' ? handleSelectedShapeCategory : handleSelectedCategory}
                        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                    />
                        
                         <input placeholder="tags" className="p-2"  type='text'  
                                    onChange={handleTags} />
                                             {sendFunc(file.image_name) != 'svg' && <input placeholder="Negative Prompt" className="p-2" type='text'  
                                    onChange={handleNegativePrompt} />}
                    {sendFunc(file.image_name) != 'svg' && <input type='text' className="p-2"  placeholder="Positive Prompt"
                                    onChange={handlePositivePrompt} />}
                     <ButtonBase onClick={() => { !showLoginLoader && postImage(file.id,sendFunc(file.image_name))}}>
                     {showLoginLoader && (
                          <div className="save-btn-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                        {showLoginLoader ?  <span className="save-main-btn">Save</span> :  <span className="save-main-btn">Save</span>}
                           
                        </ButtonBase>
                    </div>)}
                </div>
            )
        })}
</>
function oddOrEven(x) {
    return (x & 1) ? "odd" : "even";
}
const skeletonLoader = <div>Loading...</div>


    const getDesignerAssets = () => {
        let getUrl = inputUploadText != '' ? Config.AI_CANVAS_STAGING_API + `canvas/canvas-user-images/?search=${inputUploadText}` : Config.AI_CANVAS_STAGING_API + "canvas/canvas-user-images/?page=1"
         
        Config.axios({
            url: getUrl,
            method: "GET",
            auth: true,
            success: (response) => {
                // let options 
                console.log(response.data)
                setAssetsList(response.data)
                setAssetsData(response.data.results)
                if(response.data.next != null){
                    setHasMore(true)
                }else{
                    setHasMore(false)  
                }
            },
            error: (err) => {
                console.log(err)
                // Config.toast('Something went wrong', 'error')
            },
        });
          
    }


    const fetchImageOnSearch = (e) => {
        if (e.key === 'Enter') {
          if ((e.target.value.length > 0) && (e.target.value != ' ')) {
            getDesignerAssets(e.target.value)
          }
          else {
            getDesignerAssets()
            setInputUploadText('')
          }
        }
      }

    useEffect(() => {
        getDesignerAssets()
        getDesignerAssetsCategory()
    },[])

    return (
        <React.Fragment>
            <div className="subtitle-localization-wrapper">
            <div className={focusOnSearch ? "main-tab-search active-search-bar" : "main-tab-search"}>
                    <SearchIcon className="seacrh-icon" onClick={inputHandler} />
                    <span className="search-placeholder-text">
                        <input type="text" onKeyDown={fetchImageOnSearch} onClick={() => {
                            setFocusOnSearch(true)
                        }} onBlur={(e) => {
                            setFocusOnSearch(false);
                        }} className="searcher" ref={typeSearch} onChange={inputHandler} placeholder="Search..." />
                    </span>
                </div>
            <InfinityScrollComponent
                    allData={assetsData}
                    setAllData={setAssetsData}
                    setAllList={setAssetsList}
                    allList={assetsList}
                    height={`calc(100vh - 107px)`}
                    path={inputUploadText != '' ? `canvas/canvas-user-images/?search=${inputUploadText}&` : 'canvas/canvas-user-images/?'}
                    jsx={uploadListStructure}
                    show={false}
                    loader={skeletonLoader}
                    hasMore={hasMore}
                    setHasMore={setHasMore}
                />


            </div>
        </React.Fragment>
    )
}

export default DesignerImages;