import { ButtonBase } from "@mui/material";
import React, { useEffect, useState } from "react";

function AlertModal(props) {
  const { showSuccessModal, setShowSuccessModal } = props;

  return (
    <React.Fragment>
      <div className="confirmation-wrapper">
        <img
          src={window.location.origin + "/media/icons/congrats-tick.svg"}
          alt="confirm-icon"
        />
        <h2>Your Post has been successfully posted in Twitter!</h2>
        <h2>
          <a href="https://twitter.com/DAilaysa" target="_blank">
            View posted tweets
          </a>
        </h2>
        <div className="button-row">
          <ButtonBase
            onClick={() => setShowSuccessModal(false)}
            className="submit-txt"
          >
            <span>OK</span>
          </ButtonBase>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AlertModal;
