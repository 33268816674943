import React, { useEffect, useState, useRef } from "react";
import { Collapse } from 'reactstrap';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Select, { components } from "react-select";
import { getPrompt, getSocialMedia } from "./GetPrompt";
import { ButtonBase } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const AiWritingArea = (props) => {

    const {setGetPromptOptions, 
        getPromptOptions, 
        setGeneratedTxt, 
        generatedTxt,
        inputHandler,
        fetchProductDescription,
        productDescription,
        downloadTxtFile,
        fieldValidation,
        formValidation,
        productData,
        resetForm,
        handleResetForm,
        aiWritingOptions,
        setSelectAiWritingOptions,
        selectAiWritingOptions,
        getSocialMediaOptions,
        setGetSocialMediaOptions,
        headlineData,
        headlineinputHandler,
        fetchHeadlineGenerator,
        headlineFieldValidation,
        headlineFormValidation,
        headlineResults,
        headlineGeneratedTxt,
        handleHeadlineResetForm,
        open,
        setOpen,
        resetHeadlineForm
    } = props

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownIcon className="icon" />
            </components.DropdownIndicator>
        );
    };

    const customAssignStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#9B9FA2",
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
        }),
        menu: (provided, state) => ({
            ...provided,
            padding: "6px 0px",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #DADADA",
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: "0px solid #CED4DA",
            borderLeft: "2px solid transparent",
            color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
            background: state.isSelected ? "#F4F5F7" : "#ffffff",
            padding: "2px 8px",
            color: "#3C4043",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            "&:hover": {
                background: "#F4F5F7",
                borderLeft: "2px solid #0074D3",
                cursor: "pointer",
            },
        }),
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? "2px solid #0074D3" : "2px solid #C0C8CE",
            borderRadius: 4,
            transtion: 0.3,
            color: state.isFocused ? "#0074D3" : "#3C4043",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            minheigh: 50,
            padding: 0,
            height: state.isFocused ? 50 : 50,
            boxShadow: 0,
            "&:hover": {
                cursor: "pointer",
                fontWeight: "400",
                border: "2px solid #0074D3",
                height: 50,
            },
        }),
    };

    const validationError = {
        color: 'red',
    } 

    useEffect(() => {
      if(generatedTxt){
        let descriptionBox = document.getElementById('output-description-box')
        descriptionBox.scrollIntoView()
      }
    }, [generatedTxt])

    useEffect(() => {
        if(headlineGeneratedTxt){
          let descriptionBox = document.getElementById('output-description-box')
          descriptionBox.scrollIntoView()
        }
      }, [headlineGeneratedTxt])
    

    return (
        <React.Fragment>
            <div className="ai-writing-select-area">
                <h2 className="title">Select Ai Writing</h2>
                    <Select
                        classNamePrefix="select-ai-writing"
                        styles={customAssignStyles}
                        isSearchable={false}
                        options={aiWritingOptions}
                        hideSelectedOptions={false}
                        value={selectAiWritingOptions}
                        onChange={setSelectAiWritingOptions}
                        placeholder="Select Ai writing"
                        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                    />
            </div>
            <AnimatePresence exitBeforeEnter>
                {
                    selectAiWritingOptions.id === 1 &&
                    <motion.div
                        animate={{ opacity: 1, y: 0 }}
                        initial={{ opacity: 0, y: -20 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.4 }} 
                        className="ai-writing-wrapper"
                    >
                        <div className="ai-choose-ai-prompt-wrapper">
                            <p>Write a prompt for creating a</p>
                            <Select
                                classNamePrefix="select-prompt"
                                styles={customAssignStyles}
                                isSearchable={false}
                                options={getPrompt}
                                hideSelectedOptions={false}
                                value={getPromptOptions}
                                onChange={setGetPromptOptions}
                                // placeholder="Select Source Language"
                                components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                                
                            />
                            <p>for a product on Amazon.</p>
                        </div>
                        <div className="ai-writing-product-wrapper">
                            <div className="ai-write-form-group">
                                <label htmlFor="">Name of the Product</label>
                                <input type="text" className="product_name" name="productName" value={productData.productName} onChange={inputHandler} />
                                {formValidation.productName && <small style={validationError}>Required</small>}
                            </div>
                            <div className="ai-write-form-group">
                                <label htmlFor="">Keywords</label>
                                <input type="text" className="product_name" name="keywords" value={productData.keywords} onChange={inputHandler} placeholder="Enter comma seperated values"/>
                                {formValidation.keywords && <small style={validationError}>Required</small>}
                            </div>
                            <div className="ai-write-form-group">
                                <label htmlFor="">Description</label>
                                <textarea name="desc" rows="8" onChange={inputHandler} value={productData.desc} placeholder="Enter a short description about the product">
                                </textarea>
                                {formValidation.desc && <small style={validationError}>Required</small>}
                            </div>
                            <div className="btn-row">
                                {
                                    resetForm &&
                                    <ButtonBase onClick={handleResetForm} className="translate-btn">
                                        <span>Reset</span>
                                    </ButtonBase>
                                }

                                <ButtonBase onClick={() => { fieldValidation() && fetchProductDescription()}} className="translate-btn">
                                    <span>Generate</span>
                                </ButtonBase>
                            </div>
                        </div>
                        {
                            generatedTxt &&
                            <motion.div
                                animate={{ opacity: 1, y: 0 }}
                                initial={{ opacity: 0, y: -20 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{ duration: 0.4 }}
                                className="ai-writing-generated-content"
                            >
                                <p className="sub-text">Generated Content</p>
                                <div id="output-description-box">
                                    {
                                        Object.entries(productDescription)?.map((item, index) => {
                                            
                                            return(
                                                <div key={index} className="generated-content-wrapper">
                                                    {item[1]}
                                                    <div className="btn-row">
                                                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                                                            <Tooltip
                                                                placement="top"
                                                                arrow
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                onClose={() => setOpen(false)}
                                                                open={open}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                                title="Copied!"
                                                            >
                                                                <ButtonBase onClick={() => {navigator.clipboard.writeText(item[1]); setOpen(true)}} className="translate-btn">
                                                                    <ContentCopyIcon className="icon"/>
                                                                </ButtonBase>
                                                            </Tooltip>
                                                        </ClickAwayListener>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {/* <div className="btn-row">
                                    <ButtonBase className="download-btn" onClick={downloadTxtFile}>
                                        <FileDownloadOutlinedIcon className="icon" />
                                        <span>Download text</span>
                                    </ButtonBase>
                                </div> */}
                            </motion.div>
                        }
                    </motion.div>
                }
            </AnimatePresence>
            <AnimatePresence exitBeforeEnter>
                {
                    selectAiWritingOptions.id === 2 &&
                    <motion.div
                        animate={{ opacity: 1, y: 0 }}
                        initial={{ opacity: 0, y: -20 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.4 }} 
                        className="ai-writing-wrapper"
                    >
                        <div className="ai-choose-ai-prompt-wrapper">
                            <p>Write a prompt for creating a</p>
                            <Select
                                classNamePrefix="select-prompt"
                                styles={customAssignStyles}
                                isSearchable={false}
                                options={getSocialMedia}
                                hideSelectedOptions={false}
                                value={getSocialMediaOptions}
                                onChange={setGetSocialMediaOptions}
                                // placeholder="Select Source Language"
                                components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                                
                            />
                            <p>for generate Headline.</p>
                        </div>
                        <div className="ai-writing-product-wrapper">
                            <div className="ai-write-form-group">
                                <label htmlFor="">Name of the Product</label>
                                <input type="text" className="product_name" name="productName" value={headlineData.productName} onChange={headlineinputHandler} />
                                {headlineFormValidation.productName && <small style={validationError}>Required</small>}
                            </div>
                            <div className="ai-write-form-group">
                                <label htmlFor="">Keywords</label>
                                <input type="text" className="product_name" name="keywords" value={headlineData.keywords} onChange={headlineinputHandler} placeholder="Enter comma seperated values"/>
                                {headlineFormValidation.keywords && <small style={validationError}>Required</small>}
                            </div>
                            <div className="ai-write-form-group">
                                <label htmlFor="">Description</label>
                                <textarea name="desc" rows="8" onChange={headlineinputHandler} value={headlineData.desc} placeholder="Enter a short description about the product">
                                </textarea>
                                {headlineFormValidation.desc && <small style={validationError}>Required</small>}
                            </div>
                            <div className="btn-row">
                                {
                                    resetHeadlineForm &&
                                    <ButtonBase onClick={handleHeadlineResetForm} className="translate-btn">
                                        <span>Reset</span>
                                    </ButtonBase>
                                }

                                <ButtonBase onClick={() => { headlineFieldValidation() && fetchHeadlineGenerator()}} className="translate-btn">
                                    <span>Generate</span>
                                </ButtonBase>
                            </div>
                        </div>
                        {
                            headlineGeneratedTxt &&
                            <motion.div
                                animate={{ opacity: 1, y: 0 }}
                                initial={{ opacity: 0, y: -20 }}
                                exit={{ opacity: 0, y: 20 }}
                                transition={{ duration: 0.4 }}
                                className="ai-writing-generated-content"
                            >
                                <p className="sub-text">Generated Headline list</p>
                                <div className="generated-content-wrapper" id="output-description-box">
                                    <ul>
                                        {
                                            headlineResults?.map((item, index) => {
                                                return(
                                                    <li key={index}>{item}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                    <div className="btn-row">
                                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                                            <Tooltip
                                                placement="top"
                                                arrow
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={() => setOpen(false)}
                                                open={open}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="Copied!"
                                            >
                                                <ButtonBase onClick={() => {navigator.clipboard.writeText(headlineResults); setOpen(true)}} className="translate-btn">
                                                    <ContentCopyIcon className="icon"/>
                                                </ButtonBase>
                                            </Tooltip>
                                        </ClickAwayListener>
                                    </div>
                                </div>
                                {/* <div className="btn-row">
                                    <ButtonBase className="download-btn" onClick={downloadTxtFile}>
                                        <FileDownloadOutlinedIcon className="icon" />
                                        <span>Download text</span>
                                    </ButtonBase>
                                </div> */}
                            </motion.div>
                        }
                    </motion.div>
                }
            </AnimatePresence>
        </React.Fragment>
    );
}

export default AiWritingArea;