import React, { useEffect, useState, useRef } from "react";
import { Collapse } from 'reactstrap';
// import YouTube from 'react-youtube';
import { ButtonBase } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Select, { components } from "react-select";
import { motion, AnimatePresence } from "framer-motion";
import Config from "../../config/Config";
import RefreshIcon from '@mui/icons-material/Refresh';





const ConvertWorkSpaceForm = (props) => {

    const LABS_API = Config.LABS_API
    const handleAuthTrue = (authentication) => {
        let token = Config.userState != null ? Config.userState.token : "";
        authentication.append("Authorization", `Bearer ${token}`)
        return authentication;
      }

    const [progress, setProgress] = useState()



    const {
        yes2,
        setYes2,
        responseId,
        yes,
        setYes,
        setTitle,
        title,
        targetLanguageOptions,
        setTargetLanguageOptions,
        setSourceLanguageOptions,
        Targetlanguageses,
        languages,
        loading,
        fieldValidation,
        formValidation,
        setFormValidation,
        switchbutton,
        setSwitchButton,
        type,
        setType,
        pdf,
        setPdf,
        handleConvert,
        handleDownload,
        url,
        setResponseId,
        setPercentage,
        setUrl
    } = props

    
    const Typeoptions = [
        { value: 'ocr', label: 'OCR' },
        { value: 'text', label: 'Text' }
      ]

      const customAssignStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#9B9FA2",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "24px",
        }),
        menu: (provided, state) => ({
            ...provided,
            padding: "6px 0px",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #DADADA",
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: "0px solid #CED4DA",
            borderLeft: "2px solid transparent",
            color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
            background: state.isSelected ? "#F4F5F7" : "#ffffff",
            padding: "2px 8px",
            color: "#3C4043",
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            opacity: state.isDisabled ? 0.5 : 1,
            "&:hover": {
                background: "#F4F5F7",
                borderLeft: "2px solid #0074D3",
                cursor: "pointer",
            },
        }),
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? "2px solid #0074D3" : "2px solid #C0C8CE",
            borderRadius: 4,
            transtion: 0.3,
            color: state.isFocused ? "#0074D3" : "#3C4043",
            fontFamily: "Roboto",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "24px",
            width: "100%",
            minheigh: 51,
            padding: 0,
            height: state.isFocused ? 51 : 51,
            boxShadow: 0,
            "&:hover": {
                cursor: "pointer",
                fontWeight: "400",
                border: "2px solid #0074D3",
                height: 51,
            },
        }),
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownIcon className="icon" />
            </components.DropdownIndicator>
        );
    };


    const handleUpload = (e) => {
        setPdf(e.target.files);
    }

    const handleSetType = (selected)  => {
            setType(selected)
    }

    const handleClear = () => {
        const file = document.querySelector('.file');
        file.value = '';



       
    }

    

    console.log(title);


    const showAllFiles = () => {
        (async function () {
                
            var requestOptions = {
              method: 'GET',
              headers: handleAuthTrue(new Headers()),
              redirect: 'follow'
            };
    
            let data = await fetch(LABS_API + `/exportpdf/convertpdftodocx?get_all=True`, requestOptions)
            if (data.status === 200) {
              let response = await data.json()
              console.log(response);
              setTitle(response.reverse())
            }
            else {
              console.log('error');
            }
          })();

    }

    useEffect(() => {
        
        if(yes == true){
            const timerId = setInterval(() => {

                (async function () {
    
                    var requestOptions = {
                      method: 'GET',
                      headers: handleAuthTrue(new Headers()),
                      redirect: 'follow'
                    };
            
                    let data = await fetch(LABS_API + `/exportpdf/convertpdftodocx?get_all=True`, requestOptions)
                    if (data.status === 200) {
                      let response = await data.json()
                      console.log(response);
                      setTitle(response.reverse())
                      if(response[response.length-1].docx_file_urls != null){
                        clearInterval(timerId);
                        setYes(false)
                      }
                    }
                    else {
                      console.log('error');
                    }
                  })();
    
            }, 5000);


            
        }
        
    },[yes])

    useEffect(() => {

        if(yes2 == true){
            const timerId2 = setInterval(() => {

                (async function () {
                    
                    var requestOptions = {
                      method: 'GET',
                      headers: handleAuthTrue(new Headers()),
                      redirect: 'follow'
                    };
            
                    let data = await fetch(LABS_API + `/exportpdf/convertpdftodocx?pdf_status_id=${responseId}`, requestOptions)
                    if (data.status === 200) {
                      let response = await data.json()
                      console.log(response);
                      console.log(response.no_of_page_finished);
                          if(response.context.url === 'SUCCESS'){
                                clearInterval(timerId2);
                                setYes2(false)
                        }
                        
                    }
                    else {
                      console.log('error');
                    }
                  })();
                }, 5000);
    
        }

    },[yes2])


    return(
        <React.Fragment>

             <div className="convert-inner-main-wrapper col-3">
                <div className="upload-file">
                    <h2 className="title">Upload File</h2>
                    <div className="upload-div">
                    <input className="form-control file" type="file"  id="formFile" accept=".pdf" onChange={handleUpload}/>
                    </div>
                </div>
                <div className="lang-select-row">
                    <div className="select-lang">
                            <label>Target Language</label>
                            <Select
                                classNamePrefix="select-lang"
                                styles={customAssignStyles}
                                isSearchable={true}
                                menuPlacement="auto"
                                options={Targetlanguageses}
                                getOptionLabel={(option) => option.language}
                                getOptionValue={(option) => option.code}
                                hideSelectedOptions={false}
                                value={targetLanguageOptions}
                                onChange={setTargetLanguageOptions}
                                placeholder="Select Target Language"
                                components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            />
                    </div>
                    <div className="select-lang"  >
                        <label>Type</label>
                        <Select
                            classNamePrefix="select-lang"
                            styles={customAssignStyles}
                            isSearchable={false}
                            menuPlacement="auto"
                            options={Typeoptions}
                            getOptionValue={(Typeoptions) => Typeoptions.code}
                            hideSelectedOptions={false}
                            value={type}
                            onChange={handleSetType}  
                            placeholder="Select Type"
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            
                        />
                    </div>
                </div>

                <div className="convert-download-file">
                    <div className="convert-download-div">
                        <div className="convert-down-a">
                        <ButtonBase className="convert-download" onClick={() => {handleConvert()
                        handleClear()}} disabled={type ? false : true}>
                            Convert
                        </ButtonBase>

                        </div>
                    </div>
                </div>
                

                
            </div>
            <div className="col-9 conver-files">
                <div className="refresh-container">
                <RefreshIcon className="refresh-icon" onClick={showAllFiles}>show all files</RefreshIcon>
                <h2 className="heading-file-list">Converted PDF Files</h2>
                <div></div>
                </div>
            <div className="row each-file-row-head">
                                <div className='col-1 sn-no'>SN.NO</div>
                                <div className='col-7 pdf-name' style={{textAlign: 'left'}}                               
                                >PDF NAME</div>
                                <div className='col-1 pdf-total-page' >PAGES</div>

                                <div className='col-1 pdf-translated-percentage'>STATUS</div>
                                <div className='col-2 download-each'>
                                    DOWNLOAD
                                </div>
                                </div>
                <div className="converted-files-inner-wrap" >
                
                {   
                    title?.map((file, i) => {
                        return(
                            <div className="row each-file-row">
                                <div className='col-1 sn-no'><span className="name-sn">{i+1}.</span></div>
                                <div className='col-7 pdf-name' style={{textAlign: 'left'}}                               
                                ><span className="name-pdf">{file.pdf_file_name}</span></div>
                                <div className='col-1 pdf-total-page' ><span className="name-page"> {file.pdf_no_of_page}</span></div>

                                <div className='col-1 pdf-translated-percentage'><span className="name-status">{((file.counter/file.pdf_no_of_page)*100).toFixed(2)}%</span></div>
                                <div className='col-2 download-each '>
                                    {(file.docx_file_urls != null) &&
                                    <ButtonBase className="convert-file-download" >
                                    <a className="inner-a"  href={file.docx_file_urls}>
                                        Download
                                    </a></ButtonBase>
                                    }

                                     {(file.docx_file_urls == null) &&
                                    <ButtonBase className="convert-file-download2" style={{ background: file.counter != file.pdf_no_of_page? '#5cb85c' : '#f0ad4e'}} disabled={(file.pdf_no_of_page == file.counter)? false : true}  onClick={() =>{
                                        (async function () {
                                            
                                            var requestOptions = {
                                              method: 'GET',
                                              headers: handleAuthTrue(new Headers()),
                                              redirect: 'follow'
                                            };
                                    
                                            let data = await fetch(LABS_API + `/exportpdf/convertpdftodocx?pdf_status_id=${file.pdf_task_id
                                            }`, requestOptions)
                                            if (data.status === 200) {
                                              let response = await data.json()
                                              console.log(response);
                                              console.log('clicked');
                                              showAllFiles()
                                            }
                                            else {
                                              console.log('error');
                                            }
                                          })();  
                                          
                                    }
                                        

                                    } >
                                        <span className="inner-a2" >{file.counter != file.pdf_no_of_page? 'Progress' : 'Retry'}</span>
                                        {/* {(file.counter == "None of none") &&  <span className="inner-a2" >{file.counter != file.pdf_no_of_page? 'Progress' : 'Retry'}</span>} */}
                                        {/* {(file.counter != "None of none") &&  <span className="inner-a2" >Pending</span>} */}

                                   </ButtonBase>
                                    }
                                    <ButtonBase className="convert-file-download-delete" style={{background: '#00FF00'}}  onClick={() => {
                                             (async function () {
                                            
                                                var requestOptions = {
                                                  method: 'DELETE',
                                                  headers: handleAuthTrue(new Headers()),
                                                  redirect: 'follow'
                                                };
                                        
                                                let data = await fetch(LABS_API + `/exportpdf/convertpdftodocx?id=${file.id
                                                }`, requestOptions)
                                                if (data.status === 200) {
                                                  let response = await data.json()
                                                  console.log(response);
                                                  console.log('clicked');
                                                  showAllFiles()
                                                }
                                                else {
                                                  console.log('error');
                                                }
                                              })();  
                                    }
                                    }><span className="inner-a-delete">Delete</span></ButtonBase>
                                </div>
                                </div>
                        )
                    })
                }
            </div>
                </div>
        </React.Fragment>
       

    )




}


export default ConvertWorkSpaceForm;