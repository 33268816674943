import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useNavigate } from "react-router-dom";
import { ButtonBase } from "@mui/material";


const NotFound = (props) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <section className="sml-section-wrapper">
                <div className="container">
                    <div className="not-found-wrap">
                        <h1>
                            Page Not Found
                        </h1>
                        <ButtonBase onClick={() => navigate("/")} className="back-to-home">
                            Back to Home
                        </ButtonBase>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default NotFound;
