import React, { useEffect, useRef, useState } from "react";
import Select, { components } from 'react-select';
import Config from "../config/Config";
import { useNavigate } from "react-router-dom";
import BottomDrawer from "./BottomDrawer";
import { Apis } from "../api/ApiManager";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Tooltip } from "@mui/material";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ButtonLoader from "./ButtonLoader";
import Skeleton from '@mui/material/Skeleton';

const PromptForm = (props) => {

    const {
        data,
        getDomain

    }
        = props


    const navigate = useNavigate();

    const [prompts, setPrompts] = useState([])
    const [selectedPrompts, setSelectedPrompts] = useState(null)


    const [domainOptions, setDomainOptions] = useState([])
    const [selectedDomain, setSelectedDomain] = useState(null)

    const [useCaseOptions, setUseCaseOptions] = useState([])
    const [selectedUseCase, setSelectedUseCase] = useState(null)

    const [categoryOptions, setCategoryOptions] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)


    const [subCategoryOptions, setsubCategoryOptions] = useState([])
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)

    const [isDeleting, setIsDeleteBook] = useState(false);

    const resultDivRef = useRef(null)


    const URL_SEARCH_PARAMS = new URLSearchParams(window.location.search);

    const [stateSettings, setStateSettings] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const scrollToTop = () => {
        resultDivRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      };


    useEffect(() => {
        if (data?.length > 0) {
            let domain = []
            data.forEach((each) => {
                domain.push({ label: each.name, value: each.id, data: each.use_cases })
            })
            setDomainOptions(domain)
            if (URL_SEARCH_PARAMS.get('domain') != null) {
                let selectedDomain = domain.find((each) => each.value == URL_SEARCH_PARAMS.get('domain'))
                setSelectedDomain(selectedDomain)
                console.log(selectedDomain)
                let useCase = []
                selectedDomain?.data?.forEach((each) => {
                    useCase.push({ label: each.name, value: each.id, data: each.categories })
                })
                setUseCaseOptions(useCase)
                if (URL_SEARCH_PARAMS.get('usecase') != null) {
                    let selectedUsecase = useCase.find((each) => each.value == URL_SEARCH_PARAMS.get('usecase'))
                    setSelectedUseCase(selectedUsecase)
                    let categories = []
                    selectedUsecase?.data?.forEach((each) => {
                        categories.push({ label: each.name, value: each.id, data: each.subcategories })
                    })
                    setCategoryOptions(categories)

                    if (URL_SEARCH_PARAMS.get('category') != null) {
                        let selectedCategory = categories.find((each) => each.value == URL_SEARCH_PARAMS.get('category'))
                        setSelectedCategory(selectedCategory)

                        let subCategories = []
                        selectedCategory?.data?.forEach((each) => {
                            subCategories.push({ label: each.name, value: each.id, data: each.prompts })
                        })
                        setsubCategoryOptions(subCategories)

                        if (URL_SEARCH_PARAMS.get('subcategory') != null) {
                            let selectedSubCategory = subCategories.find((each) => each.value == URL_SEARCH_PARAMS.get('subcategory'))
                            setSelectedSubCategory(selectedSubCategory)
                            let prompts = []
                            selectedSubCategory?.data?.forEach((each) => {
                                prompts.push({ label: each.content, value: each.id, data: null, visible: each.published, generated: each.is_generated  })
                            })
                            setPrompts(prompts)

                            if (URL_SEARCH_PARAMS.get('prompt') != null) {
                                getPreview(URL_SEARCH_PARAMS.get('prompt'))
                                setSelectedPrompts(URL_SEARCH_PARAMS.get('prompt'))
                            }

                        }
                    }

                }

            }
        }

    }, [data])



    const handleSelectDomain = (selected) => {
        setSelectedDomain(selected)
        navigate(`/prompt?domain=${selected?.value}`)
        let useCase = []
        console.log(selected)

        selected?.data?.forEach((each) => {
            useCase.push({ label: each.name, value: each.id, data: each.categories })
        })

        setUseCaseOptions(useCase)
        setCategoryOptions([])
        setsubCategoryOptions([])
        setPrompts([])
        setSelectedUseCase(null)
        setSelectedCategory(null)
        setSelectedSubCategory(null)


    }

    const handleSelectUseCase = (selected) => {

        setSelectedUseCase(selected)
        console.log(selected)
        navigate(`/prompt?domain=${URL_SEARCH_PARAMS.get('domain')}&usecase=${selected.value}`)
        let categories = []
        console.log(selected)
        selected?.data?.forEach((each) => {
            categories.push({ label: each.name, value: each.id, data: each.subcategories })
        })
        console.log(categories)
        setCategoryOptions(categories)
        console.log('settingasdlasdj')
        setSelectedCategory(null)
        setSelectedSubCategory(null)
        setPrompts(null)

    }


    const handleCategorySelect = (selected) => {

        setSelectedCategory(selected)

        navigate(`/prompt?domain=${URL_SEARCH_PARAMS.get('domain')}&usecase=${URL_SEARCH_PARAMS.get('usecase')}&category=${selected.value}`)
        let subCategories = []
        selected?.data?.forEach((each) => {
            subCategories.push({ label: each.name, value: each.id, data: each.prompts })
        })
        setsubCategoryOptions(subCategories)
        setSelectedSubCategory(null)
        setPrompts(null)
    }



    const handleSubCategorySelect = (selected) => {

        setSelectedSubCategory(selected)
        console.log(selected)
        navigate(`/prompt?domain=${URL_SEARCH_PARAMS.get('domain')}&usecase=${URL_SEARCH_PARAMS.get('usecase')}&category=${URL_SEARCH_PARAMS.get('category')}&subcategory=${selected.value}`)
        let prompts = []
        selected?.data?.forEach((each) => {
            prompts.push({ label: each.content, value: each.id, data: null, visible: each.published, generated: each.is_generated })
        })
        setPrompts(prompts)

    }

    const toggleSettingsDrawer = (anchor, open) => {
        setStateSettings({ ...stateSettings, [anchor]: open });


    }

    const [selectedAddHeading, setSelectedAddHeading] = useState('')

    const [type, setType] = useState(null)
    const [updateContent, setUpdateContent]  = useState([])


    const handleAdd = (title,type) => {
        toggleSettingsDrawer('right', true)
        setSelectedAddHeading(title)
        setType(type)
        if(title == 'Domain'){
            setUpdateContent(domainOptions)
        }else if(title == 'Usecase'){
            setUpdateContent(useCaseOptions)

        }else if(title == 'Category'){
            setUpdateContent(categoryOptions)

        }else if(title == 'Sub-Category'){
            setUpdateContent(subCategoryOptions)

        }
        else if(title == 'Prompt'){
            setUpdateContent(prompts)

        }

    }


    const handleSubmit = async () => {
        await handleAddUserInpputs()
        toggleSettingsDrawer('right', false)

    }

    const [isLoading, setIsLoading] = useState(false)

    const [userInputs, setUserInputs] = useState([])

    // Initialize state to hold values of input boxes
    const [inputValues, setInputValues] = useState([""]);

    const handleAddUserInpputs = async () => {

        setIsLoading(true)

        const URL_SEARCH_PARAMS = new URLSearchParams(window.location.search);


        let formdata = new FormData()
        if (selectedAddHeading == 'Domain') {
            inputValues.forEach((each) => {
                formdata.append("name", each);
            })
        }

        if (selectedAddHeading == 'Usecase') {
            inputValues.forEach((each) => {
                formdata.append("domain", URL_SEARCH_PARAMS.get('domain'));
                formdata.append("name", each);
            })
        }


        if (selectedAddHeading == 'Category') {
            inputValues.forEach((each) => {
                formdata.append("use_case", URL_SEARCH_PARAMS.get('usecase'));
                formdata.append("name", each);
            })
        }

        if (selectedAddHeading == 'Sub-Category') {
            inputValues.forEach((each) => {
                formdata.append("category", URL_SEARCH_PARAMS.get('category'));
                formdata.append("name", each);
            })
        }

        if (selectedAddHeading == 'Prompt') {
            inputValues.forEach((each) => {
                formdata.append("subcategory", URL_SEARCH_PARAMS.get('subcategory'));
                formdata.append("content", each);
            })
        }

        const url = selectedAddHeading == 'Domain' ? Apis.createDomain : selectedAddHeading == 'Usecase' ? Apis.createUseCase : selectedAddHeading == 'Category' ? Apis.createCategory : selectedAddHeading == 'Sub-Category' ? Apis.createSubCategory : Apis.createPrompt

        Config.axios({
            url: url,
            method: "POST",
            data: formdata,
            auth: true,
            success: async (response) => {
                console.log(response.data)
                // Config.showToast('added','success')
                if (selectedAddHeading == 'Domain') {
                    // Check if the 'domain' parameter exists
                    if (URL_SEARCH_PARAMS.has('domain')) {
                        // If 'domain' parameter exists, update its value
                        URL_SEARCH_PARAMS.set('domain', response.data.id);
                        setUseCaseOptions([])
                        setSelectedUseCase(null)
                        setCategoryOptions([])
                        setSelectedCategory(null)
                        setsubCategoryOptions([])
                        setSelectedSubCategory(null)
                        setPrompts([])

                    } else {
                        // If 'domain' parameter doesn't exist, add it with a value
                        URL_SEARCH_PARAMS.append('domain', response.data.id);


                    }
                    // Construct the updated URL with the modified parameters
                    const updatedUrl = `${window.location.pathname}?${URL_SEARCH_PARAMS.toString()}`;
                    navigate(updatedUrl)

                } else if (selectedAddHeading == 'Usecase') {
                    // Check if the 'domain' parameter exists
                    if (URL_SEARCH_PARAMS.has('usecase')) {
                        // If 'domain' parameter exists, update its value
                        URL_SEARCH_PARAMS.set('usecase', response.data.id);
                        setCategoryOptions([])
                        setSelectedCategory(null)
                        setsubCategoryOptions([])
                        setSelectedSubCategory(null)
                        setPrompts([])

                    } else {
                        // If 'domain' parameter doesn't exist, add it with a value
                        URL_SEARCH_PARAMS.append('usecase', response.data.id);

                    }
                    // Construct the updated URL with the modified parameters
                    const updatedUrl = `${window.location.pathname}?${URL_SEARCH_PARAMS.toString()}`;
                    navigate(updatedUrl)
                } else if (selectedAddHeading == 'Category') {
                    // Check if the 'domain' parameter exists
                    if (URL_SEARCH_PARAMS.has('category')) {
                        // If 'domain' parameter exists, update its value
                        URL_SEARCH_PARAMS.set('category', response.data.id);
                        setsubCategoryOptions([])
                        setSelectedSubCategory(null)
                        setPrompts([])

                    } else {
                        // If 'domain' parameter doesn't exist, add it with a value
                        URL_SEARCH_PARAMS.append('category', response.data.id);

                    }
                    // Construct the updated URL with the modified parameters
                    const updatedUrl = `${window.location.pathname}?${URL_SEARCH_PARAMS.toString()}`;
                    navigate(updatedUrl)
                } else if (selectedAddHeading == 'Sub-Category') {
                    // Check if the 'domain' parameter exists
                    if (URL_SEARCH_PARAMS.has('subcategory')) {
                        // If 'domain' parameter exists, update its value
                        URL_SEARCH_PARAMS.set('subcategory', response.data.id);
                        setsubCategoryOptions([])
                        setSelectedSubCategory(null)
                        setPrompts([])


                    } else {
                        // If 'domain' parameter doesn't exist, add it with a value
                        URL_SEARCH_PARAMS.append('subcategory', response.data.id);

                    }
                    // Construct the updated URL with the modified parameters
                    const updatedUrl = `${window.location.pathname}?${URL_SEARCH_PARAMS.toString()}`;
                    navigate(updatedUrl)
                }
                await getDomain()
                setInputValues([""])
                setIsLoading(false)
            },
            error: (err) => {
                setInputValues([""])
                setIsLoading(false)

            }
        })
    }

    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'papayawhip',
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? 'hsla(291, 64%, 42%, 0.5)'
                : isSelected
                    ? 'hsla(291, 64%, 42%, 1)'
                    : undefined,
            zIndex: 1,
        }),
        menu: base => ({
            ...base,
            zIndex: 100,
        }),
    }


    // Function to handle change of an input box
    const handleChange = (index, event) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = event.target.value;
        setInputValues(newInputValues);
    };

    // Function to handle change of an input box
    const handleUpdateChange = (index, event,data) => {
        console.log(data)
        const newInputValues = [...updateContent];
        newInputValues[index].label = event.target.value;
        setUpdateContent(newInputValues);
        Config.debounceApiCall(() => handleUpdateOptions(data,event.target.value),500)


    };


    //   // Function to handle change of an input box
    //   const handleUpdatePrompt = (index, event,data) => {
    //     const newInputValues = [...updateContent];
    //     newInputValues[0] = event.target.value;
    //     setUpdateContent(newInputValues);
    //     Config.debounceApiCall(() => handleUpdateOptions(data,event.target.value),500)


    // };



    const handleUpdateOptions = (id,value) => {
        console.log(id)
        const url = selectedAddHeading == 'Domain' ? Apis.createDomain : selectedAddHeading == 'Usecase' ? Apis.createUseCase : selectedAddHeading == 'Category' ? Apis.createCategory : selectedAddHeading == 'Sub-Category' ? Apis.createSubCategory : Apis.createPrompt
        let formdata = new FormData()
        // formdata.append(selectedAddHeading == 'Prompt' ? "content" : "name", value);

        if (selectedAddHeading == 'Domain') {
                formdata.append("name", value);
        }

        if (selectedAddHeading == 'Usecase') {
                formdata.append("domain", URL_SEARCH_PARAMS.get('domain'));
                formdata.append("name", value);
        }


        if (selectedAddHeading == 'Category') {
                formdata.append("use_case", URL_SEARCH_PARAMS.get('usecase'));
                formdata.append("name", value);
        }

        if (selectedAddHeading == 'Sub-Category') {
                formdata.append("category", URL_SEARCH_PARAMS.get('category'));
                formdata.append("name", value);
        }

        if (selectedAddHeading == 'Prompt') {
                formdata.append("subcategory", URL_SEARCH_PARAMS.get('subcategory'));
                formdata.append("content", value);
        }

        Config.axios({
            url: url + `${id}/` ,
            method: "PUT",
            data: formdata,
            auth: true,
            success: async(response) => {
                console.log(response)                
            },
            error: (err) => {

            }
        })
    }

   


    const userGenerate = (each) => {
        setIsLoadingContent(true)
        let formdata = new FormData()
        formdata.append("content", document.querySelector('.active-prompt').innerText);
        Config.axios({
            url: Apis.openiOutput + `${URL_SEARCH_PARAMS.get('prompt')}/` ,
            method: "POST",
            data: formdata,
            auth: true,
            success: async(response) => {
                
                getPreview(URL_SEARCH_PARAMS.get('prompt'))        
                getDomain()
                setIsLoadingContent(false)     

            },
            error: (err) => {
                setIsLoadingContent(false)

            }
        })
    }



    const publish = (each) => {
        let formdata = new FormData()
        console.log(each?.visible)
        formdata.append("published", each?.visible ? 'no' : 'yes');
        Config.axios({
            url: Apis.publishPrompt + `${each?.value}/` ,
            method: "PUT",
            data: formdata,
            auth: true,
            success: async(response) => {
                console.log(response) 
                Config.showToast(each?.visible ?"Un-Published successfully" : "Published successfully",'success')

                getDomain()          
            },
            error: (err) => {
            }
        })
    }


    const [promptData, setPromptData] = useState([])
    const [promptIndex, setPromptIndex] = useState(0)


    const getPreview = (each) => {
        navigate(`/prompt?domain=${URL_SEARCH_PARAMS.get('domain')}&usecase=${URL_SEARCH_PARAMS.get('usecase')}&category=${URL_SEARCH_PARAMS.get('category')}&subcategory=${URL_SEARCH_PARAMS.get('subcategory')}&prompt=${each}`)
        Config.axios({
            url: Apis.getGeneratePromptOutput + `${each}/` ,
            method: "GET",
            auth: true,
            success: async(response) => {
                console.log(response) 
                setPromptData(response.data.generatedoutput)
                setPromptIndex(response.data.generatedoutput.length-1)

            },
            error: (err) => {

            }
        })
    }

    const [isLoadingContent, setIsLoadingContent] = useState(false)

    const generatePreview = (each) => {
        setIsLoadingContent(true)
        navigate(`/prompt?domain=${URL_SEARCH_PARAMS.get('domain')}&usecase=${URL_SEARCH_PARAMS.get('usecase')}&category=${URL_SEARCH_PARAMS.get('category')}&subcategory=${URL_SEARCH_PARAMS.get('subcategory')}&prompt=${each}`)
        Config.axios({
            url: Apis.generatePromptOutput + `${each}/` ,
            method: "POST",
            auth: true,
            success: async(response) => {
                console.log(response) 
                setPromptData(response.data.generatedoutput)
                setPromptIndex(response.data.generatedoutput.length-1)
                setIsLoadingContent(false)
            },
            error: (err) => {
                setIsLoadingContent(false)

            }
        })
    }


    const handleNavigate = (type) => {
        if(type == 'prev'){
            setPromptIndex(promptIndex - 1)
            scrollToTop()
        }else{
            setPromptIndex(promptIndex + 1)
            scrollToTop()
        }
    }

    const handleDeletePrompt = async(id) => {
        setIsDeleteBook(true)




        Config.axios({
            url:  Apis.createPrompt + `${id}`,
            method: "DELETE",
            auth: true,
            success: async(response) => {
                setPrompts(prevState => prevState.filter(item => item.value !== id));
                Config.showToast("Deleted successfully",'success')
                setIsDeleteBook(false)
                setPromptData([])
                if(id == URL_SEARCH_PARAMS.get('prompt')){
                    navigate(`/prompt?domain=${URL_SEARCH_PARAMS.get('domain')}&usecase=${URL_SEARCH_PARAMS.get('usecase')}&category=${URL_SEARCH_PARAMS.get('category')}&subcategory=${URL_SEARCH_PARAMS.get('subcategory')}`)
                }
          
            },
            error: (err) => {
                console.log(err)
                // Config.showToast(t("something_went_wrong"),'error')
                setIsDeleteBook(false)

            },
        });
    }


    const handleDeleteCheck = async(id) => {
    

        if(selectedAddHeading == 'Domain'){
            await handleDelete(id)
            if(URL_SEARCH_PARAMS.get('domain') == id){
                setUseCaseOptions([])
                setCategoryOptions([])
                setsubCategoryOptions([])
                setPrompts([])
                setSelectedDomain(null)
                setSelectedUseCase(null)
                setSelectedCategory(null)
                setSelectedSubCategory(null)
                navigate(`/prompt`)

                
            }
        }else if(selectedAddHeading == 'Usecase'){
            await handleDelete(id)
            if(URL_SEARCH_PARAMS.get('usecase') == id){
                setCategoryOptions([])
                setsubCategoryOptions([])
                setPrompts([])
                setSelectedUseCase(null)
                setSelectedCategory(null)
                setSelectedSubCategory(null)
                navigate(`/prompt?domain=${URL_SEARCH_PARAMS.get('domain')}`)
                
            }

        }else if(selectedAddHeading == 'Category'){
            await handleDelete(id)
            if(URL_SEARCH_PARAMS.get('category') == id){
                setsubCategoryOptions([])
                setPrompts([])
                setSelectedCategory(null)
                setSelectedSubCategory(null)
                navigate(`/prompt?domain=${URL_SEARCH_PARAMS.get('domain')}&usecase=${URL_SEARCH_PARAMS.get('usecase')}`)
                
            }

        }else if(selectedAddHeading == 'Sub-Category'){
            await handleDelete(id)
            if(URL_SEARCH_PARAMS.get('subcategory') == id){
                setPrompts([])
                setSelectedSubCategory(null)
                navigate(`/prompt?domain=${URL_SEARCH_PARAMS.get('domain')}&usecase=${URL_SEARCH_PARAMS.get('usecase')}&category=${URL_SEARCH_PARAMS.get('category')}`)
                
            }

        }
        else if(selectedAddHeading == 'Prompt'){
           
        }
    }


    const handleDelete = async(id) => {
        setIsDeleteBook(true)

        const url = selectedAddHeading == 'Domain' ? Apis.createDomain : selectedAddHeading == 'Usecase' ? Apis.createUseCase : selectedAddHeading == 'Category' ? Apis.createCategory : selectedAddHeading == 'Sub-Category' ? Apis.createSubCategory : Apis.createPrompt



        Config.axios({
            url: url + `${id}`,
            method: "DELETE",
            auth: true,
            success: async(response) => {
                setUpdateContent(prevState => prevState.filter(item => item.value !== id));
                Config.showToast("Deleted successfully",'success')
                setIsDeleteBook(false)
          
            },
            error: (err) => {
                console.log(err)
                // Config.showToast(t("something_went_wrong"),'error')
                setIsDeleteBook(false)

            },
        });
    }
    


    // Function to add a new input box
    const addInputBox = () => {
        setInputValues([...inputValues, '']);
    };

    const updatePrompt = (each) => {
        let data = []
        data.push(each)
        console.log(data)
        setUpdateContent(data)
        toggleSettingsDrawer('right', true)
        setSelectedAddHeading('Prompt')
        setType('edit')
    }

    const handleCloseDrawer = () => {
        toggleSettingsDrawer('right', false)

    }

    const drawerJsx = <div className="drawer-inner-wrap">
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:'20px'}}>
           
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'5px'}}>
                <span onClick={() => handleCloseDrawer()} style={{cursor:'pointer',display:'flex', alignItems:'center', justifyContent:'center',width:'30px', height: '30px',border: '1px solid #D9D9D9',marginRight:'5px'}} ><CloseOutlinedIcon /></span>
                <h3 style={{marginBottom:'0'}} className="add-header">{selectedAddHeading} create</h3>
            </div>
            {selectedAddHeading == 'Prompt' && <span>
                {inputValues[0]?.length} / 10000
            </span>}
        </div>
        {selectedAddHeading != 'Prompt' ? (
            <>
                {inputValues.map((value, index) => (
                    <input
                        key={index}
                        type="text"
                        value={value}
                        onChange={(event) => handleChange(index, event)}
                        placeholder={`Enter ${selectedAddHeading.toLowerCase()} name`}
                        className="input-box-add"
                        style={{marginBottom:'10px'}}
                    />
                ))}
                {/* <input className="input-box-add" type="text"   onChange={(event) => handleChange(index, event)} placeholder={`Enter ${selectedAddHeading.toLowerCase()} name`} /> */}

            </>
        ) : (
            <>
                {inputValues.map((value, index) => (
                    <textarea
                        key={index}
                        type="text"
                        maxLength={10000}
                        value={value}
                        onChange={(event) => handleChange(index, event)} className="input-box-add" rows={5}
                        placeholder={`Enter ${selectedAddHeading.toLowerCase()} name`}
                        style={{height: 'calc(100vh - 150px)'}}
                    />
                ))}
                {/* <textarea  onChange={(event) => handleChange(index, event)} className="input-box-add" rows={5}/> */}
            </>

        )}
        <button className="submit-button" onClick={handleSubmit}>Submit</button>
    </div>
    const editJsx = <div className="drawer-inner-wrap">
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:'20px'}}>
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'5px'}}>
                <span onClick={() => handleCloseDrawer()} style={{cursor:'pointer',display:'flex', alignItems:'center', justifyContent:'center',width:'30px', height: '30px',border: '1px solid #D9D9D9',marginRight:'5px'}} ><CloseOutlinedIcon /></span>
                <h3 style={{marginBottom:'0'}} className="add-header">{selectedAddHeading} Update</h3>
            </div>
            {selectedAddHeading == 'Prompt' && <span>
                {updateContent[0]?.label?.length} / 10000
            </span> }
        </div>
    {selectedAddHeading != 'Prompt' ? (
        <>
            {updateContent.map((data, index) => (
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'5px',marginBottom:'10px'}}>
                {console.log(data)}
                <input
                    key={index}
                    type="text"
                    value={data.label}
                    onChange={(event) => handleUpdateChange(index, event,data.value)}
                    placeholder={`Enter ${selectedAddHeading.toLowerCase()} name`}
                    className="input-box-add"
                />
                <button onClick={() =>  handleDeleteCheck(data.value)} style={{height: '42px'}} className="secondary-button-prompt-form delete">
                Delete 
                </button>
            </div>
            ))}
            

        </>
    ) : (
        <>
            {updateContent.map((data, index) => (
                 <div style={{display:'flex', alignItems:'start', justifyContent:'center', gap:'5px',marginBottom:'10px'}}>
                    {console.log(data.value)}
                    <textarea
                        key={index}
                        type="text"
                        value={data.label}
                        onChange={(event) => handleUpdateChange(index, event,data.value)} className="input-box-add" rows={5}
                        placeholder={`Enter ${selectedAddHeading.toLowerCase()} name`}
                        style={{height: 'calc(100vh - 150px)'}}
                    />
                    {/* <button style={{height: '42px'}} className="secondary-button-prompt-form delete">
                    Delete 
                    </button> */}
                </div>
            ))}
        </>

    )}
    </div>

    const customProjectTypeSelectStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#3C4043",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
        }),
        menu: (provided, state) => ({
            ...provided,
            padding: "6px 0px 0px 0px",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #DADADA",
            borderRadius: "4px",

        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: "0px solid #CED4DA",
            borderLeft: "2px solid transparent",
            color: state.isSelected ? "#292929" : state.isDisabled ? "#cccccc" : "#292929",
            background: state.isSelected ? "#F4F5F7" : "#ffffff",
            display: "flex",
            marginBottom: "0.2rem",
            padding: "4px 6px",
            // color: "#292929",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            "&:hover": {
                background: "#F4F5F7",
                borderLeft: "2px solid #0074D3",
                cursor: "pointer",
            },
        }),
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? "2px solid #0074D3" : "2px solid #DBDBDB",
            backgroundColor: state.isSelected ? "#FFFFFF" : "#FFFFFF",
            borderRadius: 4,
            transtion: 0.3,
            color: "#3C4043",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            boxShadow: 0,
            padding: "0px 10px",
            width: '100%',
            height: 40,
            "&:hover": {
                cursor: "pointer",
            },
        }),
        menuList: (base) => ({
            ...base,

            "::-webkit-scrollbar": {
                width: "4px",
                height: "0px",
            },
            "::-webkit-scrollbar-track": {
                background: "#f1f1f1"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#888"
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555"
            }
        })
    };



    // ]
    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                {props.selectProps.menuIsOpen ? <span id="triangle-up"></span> : <span id="triangle-down"></span>}

            </components.DropdownIndicator>
        );
    };

    console.log(prompts)

    const [isEditing, setIsEditing] = useState(false)
    const [selectedEditing, setSelectedEditing] = useState(null)


    useEffect(() => {
        if(!stateSettings?.right){
            getDomain()
        }
    },[stateSettings?.right])

   
  

    function replaceWithContentEditable(text) {
        // Regular expression to find strings inside []
        const regex = /\[(.*?)\]/g;
      
        // Extract text inside []
        const matches = text.match(regex);

    
        
        // If matches are found, extract the text and wrap it in <span> tags
        if (matches) {
          matches.forEach(match => {
            const extractedText = match.slice(1, -1); // Remove the square brackets
            const span = document.createElement('span');
            span.classList = 'highlighted-heading'
            span.contentEditable = true;
            span.textContent = `[${extractedText}]`;
            const replacement = span.outerHTML;
            text = text.replace(match, replacement);

          });
        }
        
        return text;
      }


    return (

        <div className="prompt-form">
            <h3 className="heading">Prompt Maker</h3>
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', width: '100%' }}>
                <div style={{ width: '100%' }}>
                    <span style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <label>Domain</label>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <button onClick={() => handleAdd('Domain','edit')} className="secondary-button-prompt-form edit"  >
                                Edit
                            </button>
                            <button onClick={() => handleAdd('Domain','add')} className="button-prompt-form">
                                Add
                            </button>
                        </div>
                    </span>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={domainOptions}
                        value={selectedDomain}
                        onChange={handleSelectDomain}
                        styles={customProjectTypeSelectStyles}
                        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                    />
                </div>
                <div style={{ width: '100%' }}>
                    <span style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <label>UseCase</label>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <button disabled={selectedDomain == null} onClick={() => handleAdd('Usecase','edit')} className="secondary-button-prompt-form edit"  >
                                Edit
                            </button>
                            <button disabled={selectedDomain == null} onClick={() => handleAdd('Usecase','add')} className="button-prompt-form">
                                Add
                            </button>
                        </div>
                    </span>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={useCaseOptions}
                        value={selectedUseCase}
                        onChange={handleSelectUseCase}
                        styles={customProjectTypeSelectStyles}
                        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                    />

                </div>
                <div style={{ width: '100%' }}>
                    <span style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <label>Category</label>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <button disabled={selectedUseCase == null} onClick={() => handleAdd('Category','edit')} className="secondary-button-prompt-form edit"  >
                                Edit
                            </button>
                            <button disabled={selectedUseCase == null} onClick={() => handleAdd('Category','add')} className="button-prompt-form">
                                Add
                            </button>
                        </div>
                    </span>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={categoryOptions}
                        value={selectedCategory}
                        onChange={handleCategorySelect}
                        styles={customProjectTypeSelectStyles}
                        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                    />
                   
                </div>
                <div style={{ width: '100%' }}>
                    <span style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <label>Sub-Category</label>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <button disabled={selectedCategory == null} onClick={() => handleAdd('Sub-Category','edit')} className="secondary-button-prompt-form edit"  >
                                Edit
                            </button>
                            <button disabled={selectedCategory == null} onClick={() => handleAdd('Sub-Category','add')} className="button-prompt-form">
                                Add
                            </button>
                        </div>
                    </span>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={subCategoryOptions}
                        value={selectedSubCategory}
                        onChange={handleSubCategorySelect}
                        styles={customProjectTypeSelectStyles}
                        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                    />
                
                </div>
            </div>
            <div className="prompt-fetchbox">
                <div className="heading" style={{ width: '100%' }}>
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <h3 >Prompts</h3>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {/* <button  style={{ width: '150px' }}  className="secondary-button-prompt-form edit" onClick={() => handleAdd('Prompt','edit')} disabled={selectedSubCategory == null}  >
                                Edit Prompt
                            </button> */}
                            <button style={{ width: '150px' }} disabled={selectedSubCategory == null} onClick={() => handleAdd('Prompt','add')} className="button-prompt-form">
                                Add Prompt
                            </button>
                        </div>
                    </span>
                </div>
                <div style={{ display:'flex',alignItems:'center',justifyContent:'center',width:'100%',gap:'10px'}}> 
                    <div className="prompt-main-container" style={{ height: `calc(100vh - 363px)`, overflow: 'auto', padding:`0 15px 0 0`,width:'100%'}}>
                        {prompts?.length == 0 &&
                            <div>
                                No prompt available
                            </div>
                        }
                        {prompts?.map((each,index) => {
                            return (
                                <div className="prompts-list-each" onClick={() => {setPromptData([]);each.generated && getPreview(each?.value); }}  key={each?.value}>
                                    {/* <div onClick={() => each.generated ? getPreview(each?.value) : generatePreview(each?.value)}> */}
                                        <div onClick={() => {
                                            
                                            navigate(`/prompt?domain=${URL_SEARCH_PARAMS.get('domain')}&usecase=${URL_SEARCH_PARAMS.get('usecase')}&category=${URL_SEARCH_PARAMS.get('category')}&subcategory=${URL_SEARCH_PARAMS.get('subcategory')}&prompt=${each.value}`)
                                            setSelectedPrompts(each.value)}}>
                                        {/* <span className="s-no">{index+1}. </span> */}
                                        <div  style={{ padding: '10px' }}  contentEditable className={URL_SEARCH_PARAMS.get('prompt') == each.value ? 'active-prompt' : ''} dangerouslySetInnerHTML={{__html : replaceWithContentEditable(each.label)}}></div>
                                        <div style={{ display:'flex', flexDirection:'row',padding: 0, alignItems:'center', justifyContent:'start', gap: '10px', marginTop: '10px'}}>
                                        {selectedPrompts == each.value &&  <button className="prompt-options-button" onClick={() => userGenerate(each?.value)}>Generate</button>}
                                       {(selectedPrompts == each.value && each.generated && prompts.length == 0) &&  <button className="prompt-options-button" onClick={() => getPreview(each?.value)}>Results</button>}
                                        </div>
                                       

                                    </div>
                                    <div className="prompt-options ">
                                        <span className="visible" onClick={() => publish(each)}>
                                            {/* <VisibilityOutlinedIcon  className="edit-icon icons" /> */}
                                            <Tooltip arrow title={each.published ? "Publish" : "Unpublish"} placement="top">
                                             {each.visible ? <VisibilityOutlinedIcon className="visible-icon icons" />  : <VisibilityOffOutlinedIcon className="visible-icon icons" />}
                                            </Tooltip>
                                        </span>
                                        <span className="edit" onClick={() => updatePrompt(each)}>
                                            <Tooltip arrow title="Edit" placement="top">
                                                <EditIcon  className="edit-icon icons" />
                                            </Tooltip>
                                        </span>
                                        <span onClick={() => handleDeletePrompt(each.value)} className="delete">
                                            <Tooltip arrow title="Delete" placement="top">
                                                <DeleteOutlineIcon className="delete-icon icons" />
                                            </Tooltip>
                                        </span>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                    <div style={{width:'100%'}} className="result-container">
                        
                     <div className="prompt-main-container" ref={resultDivRef} style={{ height: `calc(100vh - 430px)`, overflow: 'auto', padding:`0 15px 0 0`,width:'100%'}}>
                        {isLoadingContent && <div>
                                    <Skeleton />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation={false} />
                            </div>}
                     {(promptData?.length == 0 && !isLoadingContent) && 
                        <span style={{width:'100%', height: '100%', display:'flex',alignItems:'center', justifyContent:'center'}}>
                             <b>Select Prompt to View result</b>
                        </span>
                     }
                     {(promptData?.length != 0 && !isLoadingContent) && <div >
                               <Markdown remarkPlugins={[remarkGfm]}>{promptData[promptIndex]?.text}</Markdown>
                            </div>}
                            
                    </div>
                   <div className="bottom-navigation" style={{visibility: (promptData?.length != 0 && !isLoadingContent) ? 'visible' : 'hidden'}}>
                               
                                <div className="navigation">
                                    <button disabled={promptIndex == 0} className="navigation-button" onClick={() => promptIndex != 0 &&  handleNavigate('prev')}>
                                        <ChevronLeftIcon />    
                                    </button>
                                    <span className="potition">{promptIndex+1}</span>
                                    <button disabled={promptIndex == promptData.length -1} className="navigation-button" onClick={() => promptIndex != promptData.length -1 && handleNavigate('next')}>
                                        <NavigateNextIcon />
                                    </button>
                                </div>
                                <Tooltip arrow title="Generate new" placement="top">
                                    <div style={{display:'none'}} className="regenerate-button" onClick={() => generatePreview(URL_SEARCH_PARAMS.get('prompt'))}>
                                        <AddIcon />
                                    </div>
                                </Tooltip>
                            </div>
                    </div>
                </div>
                
            </div>
            <BottomDrawer
                // height={window.innerHeight - 60} 
                customClass="prompt-drawer"
                jsx={type == 'edit' ? editJsx : drawerJsx}
                toggleDrawer={toggleSettingsDrawer}
                state={stateSettings}
                setState={setStateSettings} />
        </div>

    )


}

export default PromptForm;