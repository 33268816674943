import Draggable, { DraggableCore } from 'react-draggable';
import React, { useEffect, useLayoutEffect, useState, useRef ,useCallback} from "react";
import { Popover } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import samp5 from '../../../assets/photos/samp5.jpg'
import greyscale from '../../../assets/photos/greyscale.png'
import sepia from '../../../assets/photos/sepia.png'
import blackandwhite from '../../../assets/photos/blackandwhite.png'
import brownie from '../../../assets/photos/brownie.png'
import kodachrome from '../../../assets/photos/kodachrome.png'
import technicolor from '../../../assets/photos/technicolor.png'
import poloriod from '../../../assets/photos/poloriod.png'
import vintage from '../../../assets/photos/vintage.png'
import OpacityIcon from '@mui/icons-material/Opacity';

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));

const BackgroundProperties = ({editor, removeBackground, fabric}) =>{

    const classes = useStyles();

    //draggable
    const [state, setState] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    });

    const onStart = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags + 1 });
    };
    const onStop = () => {
        const { activeDrags } = state;
        setState({ ...state, activeDrags: activeDrags - 1 });
    };

    const dragHandlers = { onStart, onStop };


    const FilterBackground = ({ dragHandlers }) => {

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const grayscaleFilter = () => {
            var filter = new fabric.Image.filters.Grayscale();
            editor.canvas.backgroundImage.filters.push(filter);
            editor.canvas.backgroundImage.applyFilters();
            editor.canvas.renderAll();

            // editor.canvas.backgroundImage.filters.push('grayscale')
            // console.log(editor.canvas.backgroundImage);
            // editor.canvas.backgroundImage.app
            // // something.filters['grayscale']
            // editor.canvas.renderAll();
            //    console.log(something);
        }

        const removeFilters = () => {
            // fabric.util.removeFromArray(editor.canvas.backgroundImage.filters, Grayscale);
            // var len = editor.canvas.backgroundImage.filters.length
            // console.log(len.slice(7));
            editor.canvas.backgroundImage.filters.length = 7;
            editor.canvas.backgroundImage.applyFilters();
            editor.canvas.renderAll();


        }

        const sepiaFilter = () => {

            var filter = new fabric.Image.filters.Sepia();
            editor.canvas.backgroundImage.filters.push(filter);
            editor.canvas.backgroundImage.applyFilters();
            editor.canvas.renderAll();
        }

        const blackwhiteFilter = () => {

            var filter = new fabric.Image.filters.BlackWhite();
            editor.canvas.backgroundImage.filters.push(filter);
            editor.canvas.backgroundImage.applyFilters();
            editor.canvas.renderAll();
        }

        const brownieFilter = () => {

            var filter = new fabric.Image.filters.Brownie();
            editor.canvas.backgroundImage.filters.push(filter);
            editor.canvas.backgroundImage.applyFilters();
            editor.canvas.renderAll();
        }

        const vintageFilter = () => {

            var filter = new fabric.Image.filters.Vintage();
            editor.canvas.backgroundImage.filters.push(filter);
            editor.canvas.backgroundImage.applyFilters();
            editor.canvas.renderAll();
        }

        const kodachromeFilter = () => {

            var filter = new fabric.Image.filters.Kodachrome();
            editor.canvas.backgroundImage.filters.push(filter);
            editor.canvas.backgroundImage.applyFilters();
            editor.canvas.renderAll();
        }

        const technicolorFilter = () => {

            var filter = new fabric.Image.filters.Technicolor();
            editor.canvas.backgroundImage.filters.push(filter);
            editor.canvas.backgroundImage.applyFilters();
            editor.canvas.renderAll();
        }

        const polaroidFilter = () => {

            var filter = new fabric.Image.filters.Polaroid();
            editor.canvas.backgroundImage.filters.push(filter);
            editor.canvas.backgroundImage.applyFilters();
            editor.canvas.renderAll();
        }



        return (
            <div className="filter-container" style={{ visibility: editor?.canvas.backgroundImage?.type == 'image' ? 'visible' : 'hidden' }}>
                <button aria-describedby={id} className="filters-button" onClick={handleClick}>
                    Filters
                </button>
                <Draggable handle="strong" {...dragHandlers}>

                    <Popover

                        id={id}
                        className={classes.popover}
                        classes={{
                            paper: classes.popoverContent,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className="filter-div-wrapper box no-cursor">
                            <strong className='cursor d-flex align-items-center  gap-2 mb-3'>
                                <div className="drag-me ">
                                    Drag
                                </div>
                                <CloseIcon className="close-icon-pop" onClick={handleClose} />

                            </strong>
                            <div className="filter-div">
                                <div className="filter-gride-items" onClick={removeFilters}>
                                    <img className="image-filter-samp" src={samp5} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">None</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={grayscaleFilter}>
                                    <img className="image-filter-samp" src={greyscale} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Greyscale</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={sepiaFilter}>
                                    <img className="image-filter-samp" src={sepia} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Sepia</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={blackwhiteFilter}>
                                    <img className="image-filter-samp" src={blackandwhite} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Black/White</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={brownieFilter}>
                                    <img className="image-filter-samp" src={brownie} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Brownie</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={vintageFilter}>
                                    <img className="image-filter-samp" src={vintage} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Vintage</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={kodachromeFilter}>
                                    <img className="image-filter-samp" src={kodachrome} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Kodachrome</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={technicolorFilter}>
                                    <img className="image-filter-samp" src={technicolor} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Technicolor</div>
                                    </div>
                                </div>
                                <div className="filter-gride-items" onClick={polaroidFilter}>
                                    <img className="image-filter-samp" src={poloriod} alt="" />
                                    <div class="middle">
                                        <div class="overlay-text">Poloroid</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popover>
                </Draggable>
            </div>

        )


    }


    //Background Filters
    const AdjustBackground = ({ dragHandlers }) => {

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const [brightness, setBrightness] = useState(0)
        const [contrast, setContrast] = useState(0)
        const [saturation, setSaturation] = useState(0)
        const [blur, setBlur] = useState(0)
        const [vibrance, setVibrance] = useState(0)
        const [noise, setNoise] = useState(0)
        const [hue, setHue] = useState(0)

        const [pixalate, setPixalate] = useState(0)






        const handleBrightness = (e) => {
            setBrightness(e.target.value);

        }

        const brightnessTemp = useRef(true)

        useEffect(() => {

            // if(brightnessTemp.current){
            //     brightnessTemp.current = false
            //     return;
            // }

            if (editor) {
                if (editor.canvas.backgroundImage) {
                    setBrightness(editor.canvas.backgroundImage.filters[0].brightness * 200)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])


        useEffect(() => {

            if (brightnessTemp.current) {
                brightnessTemp.current = false;
                return;
            }



            if (editor.canvas.backgroundImage.type == 'image') {

                editor.canvas.backgroundImage.filters[0].brightness = brightness / 200
                editor.canvas.backgroundImage.applyFilters();
                editor.canvas.renderAll();
            }

        }, [brightness])




        const handleContrast = (e) => {
            setContrast(e.target.value);

        }
        const contrastTemp = useRef(true)


        useEffect(() => {
            // if(contrastTemp.current){
            //     contrastTemp.current = false
            //     return;
            // }

            if (editor) {
                if (editor.canvas.backgroundImage) {
                    setContrast(editor.canvas.backgroundImage.filters[1].contrast * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])

        useEffect(() => {

            if (contrastTemp.current) {
                contrastTemp.current = false;
                return;
            }

            if (editor.canvas.backgroundImage.type == 'image') {
                editor.canvas.backgroundImage.filters[1].contrast = contrast / 100
                editor.canvas.backgroundImage.applyFilters();
                editor.canvas.renderAll();
            }

        }, [contrast])

        const handleSaturation = (e) => {
            setSaturation(e.target.value);

        }

        const saturationTemp = useRef(true)
        useEffect(() => {
            // if(saturationTemp.current){
            //     saturationTemp.current = false
            //     return;
            // }

            if (editor) {
                if (editor.canvas.backgroundImage) {
                    setSaturation(editor.canvas.backgroundImage.filters[2].saturation * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])


        useEffect(() => {

            if (saturationTemp.current) {
                saturationTemp.current = false;
                return;
            }



            if (editor.canvas.backgroundImage.type == 'image') {

                editor.canvas.backgroundImage.filters[2].saturation = saturation / 100
                editor.canvas.backgroundImage.applyFilters();
                editor.canvas.renderAll();
            }

        }, [saturation])

        const handleBlur = (e) => {
            setBlur(e.target.value);

        }
        const blurTemp = useRef(true)
        useEffect(() => {
            // if(blurTemp.current){
            //     blurTemp.current = false
            //     return;
            // }

            if (editor) {
                if (editor.canvas.backgroundImage) {
                    setBlur(editor.canvas.backgroundImage.filters[3].blur * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])

        useEffect(() => {

            if (blurTemp.current) {
                blurTemp.current = false;
                return;
            }



            if (editor.canvas.backgroundImage.type == 'image') {

                editor.canvas.backgroundImage.filters[3].blur = blur / 100
                editor.canvas.backgroundImage.applyFilters();
                editor.canvas.renderAll();
            }

        }, [blur])

        const handleVibrance = (e) => {
            setVibrance(e.target.value);

        }

        const vibranceTemp = useRef(true)

        useEffect(() => {
            // if(vibranceTemp.current){
            //     vibranceTemp.current = false
            //     return;
            // }

            if (editor) {
                if (editor.canvas.backgroundImage) {
                    setVibrance(editor.canvas.backgroundImage.filters[4].vibrance * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])


        useEffect(() => {

            if (vibranceTemp.current) {
                vibranceTemp.current = false;
                return;
            }



            if (editor.canvas.backgroundImage.type == 'image') {

                editor.canvas.backgroundImage.filters[4].vibrance = vibrance / 100
                editor.canvas.backgroundImage.applyFilters();
                editor.canvas.renderAll();
            }

        }, [vibrance])

        const handleNoise = (e) => {
            setNoise(e.target.value);

        }

        const noiseTemp = useRef(true)

        useEffect(() => {
            // if(noiseTemp.current){
            //     noiseTemp.current = false
            //     return;
            // }

            if (editor) {
                if (editor.canvas.backgroundImage) {
                    setNoise(editor.canvas.backgroundImage.filters[5].noise / 10)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])



        useEffect(() => {

            if (noiseTemp.current) {
                noiseTemp.current = false;
                return;
            }



            if (editor.canvas.backgroundImage.type == 'image') {

                editor.canvas.backgroundImage.filters[5].noise = noise * 10
                editor.canvas.backgroundImage.applyFilters();
                editor.canvas.renderAll();
            }

        }, [noise])

        // const handlePixalate = (e) => {
        //     setPixalate(e.target.value);

        // }

        // useEffect(()=>{
        //     // if(opacitytemp.current){
        //     //     opacitytemp.current = false
        //     //     return;
        //     // }
        //     if(editor){
        //         if(editor.canvas.backgroundImage){
        //             setPixalate(editor.canvas.backgroundImage.filters[7].blocksize)
        //             editor.canvas.renderAll()

        //         }
        //     }
        // },[editor])


        // const PixalateTemp = useRef(true)
        // useEffect(() => {

        //     if(PixalateTemp.current){
        //         PixalateTemp.current = false;
        //         return;
        //     }



        //     if(editor.canvas.backgroundImage.type == 'image'){

        //         editor.canvas.backgroundImage.filters[7].blocksize = pixalate
        //         editor.canvas.backgroundImage.applyFilters();
        //         editor.canvas.renderAll();
        //     }

        // },[pixalate])

        const handleHueRotation = (e) => {
            setHue(e.target.value);

        }

        const hueTemp = useRef(true)

        useEffect(() => {
            // if(hueTemp.current){
            //     hueTemp.current = false
            //     return;
            // }

            if (editor) {
                if (editor.canvas.backgroundImage) {
                    setHue(editor.canvas.backgroundImage.filters[6].rotation * 100)
                    editor.canvas.renderAll()

                }
            }
        }, [editor])



        useEffect(() => {

            if (hueTemp.current) {
                hueTemp.current = false;
                return;
            }



            if (editor.canvas.backgroundImage.type == 'image') {

                editor.canvas.backgroundImage.filters[6].rotation = hue / 100
                editor.canvas.backgroundImage.applyFilters();
                editor.canvas.renderAll();
            }

        }, [hue])




        return (

            <div className="adjust">
                <button aria-describedby={id} className={anchorEl ? "toolbar-icons-active filters-button " : "toolbar-icons filters-button"} onClick={handleClick}>
                    Adjust Background Effects
                </button>
                <Draggable handle="strong" {...dragHandlers}>
                    <Popover
                        className={classes.popover}
                        classes={{
                            paper: classes.popoverContent,
                        }}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >

                        <div className="adjust-div-wrapper box no-cursor">
                            <strong className='cursor d-flex align-items-center  gap-2 mb-3'>
                                <div className="drag-me ">
                                    Drag
                                </div>
                                <CloseIcon className="close-icon-pop" onClick={handleClose} />

                            </strong>
                            <div className="adjust-div">


                                <div>
                                    <label className="form-label adjust-headers">Brightness</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={brightness} min={-100} max={100} onChange={handleBrightness} id="customRange1" />
                                        <span className="input-value">{brightness}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Contrast</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={contrast} min={-100} max={100} onChange={handleContrast} id="customRange1" />
                                        <span className="input-value">{contrast}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Saturation</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={saturation} min={-100} max={100} onChange={handleSaturation} id="customRange1" />
                                        <span className="input-value">{saturation}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Vibrance</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={vibrance} min={0} max={100} onChange={handleVibrance} id="customRange1" />
                                        <span className="input-value">{vibrance}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Blur</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={blur} min={0} max={100} onChange={handleBlur} id="customRange1" />
                                        <span className="input-value">{blur}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Noise</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={noise} min={0} max={100} onChange={handleNoise} id="customRange1" />
                                        <span className="input-value">{noise}</span>
                                    </div>
                                    <label className="form-label adjust-headers">Hue</label>
                                    <div className="d-flex align-items-center gap-2">
                                        <input type="range" className="form-range slider-range" value={hue} min={-100} max={100} onChange={handleHueRotation} id="customRange1" />
                                        <span className="input-value">{hue}</span>
                                    </div>
                                    {/* <label className="form-label adjust-headers">Pixalate</label>
                            <div className="d-flex align-items-center gap-2">
                                <input type="range" className="form-range slider-range" value={pixalate} min={0.01} max={10} onChange={handlePixalate} id="customRange1" />
                                <span className="input-value">{pixalate}</span>
                            </div> */}
                                </div>
                            </div>
                        </div>

                    </Popover>
                </Draggable>
            </div>

        )
    }

    const Bgopacity = () => {

        const [bgopacity, setBgopacity] = useState(100)
        const bgopacityinputer = useRef(null)





        useEffect(() => {

            if (editor) {

                if (editor.canvas.backgroundImage) {
                    // console.log('final temp',temp);
                    setBgopacity(editor.canvas.backgroundImage.opacity * 100)
                    editor.canvas.renderAll()

                }
            }

        }, [editor])


        // if(editor){
        //     if(editor.canvas.getActiveObject()){
        //         console.log(editor.canvas.getActiveObject().opacity);
        //         // setOpacity(editor.canvas.getActiveObject().opacity)
        //         editor.canvas.renderAll()
        //     }
        // }


        const changebgopacityvalue = () => {
            setBgopacity(bgopacityinputer.current.value)
        }


        const opacitytemp = useRef(true)
        useEffect(() => {
            if (opacitytemp.current) {
                opacitytemp.current = false
                return;
            }
            if (editor) {
                if (editor.canvas.backgroundImage) {
                    // editor.canvas.getActiveObject().set({opacity : opacity/100})
                    // editor.canvas.renderAll()

                    editor.canvas.backgroundImage.set({ opacity: bgopacity / 100 })
                    editor.canvas.renderAll()
                }
            }
        })




        const [anchorEl, setAnchorEl] = React.useState(null);

        // const handleClick = (event) => {
        //     setAnchorEl(event.currentTarget);
        // };

        // const handleClose = () => {
        //     setAnchorEl(null);
        // };

        const handleCloseAndOpen = (event) => {
            if (anchorEl == null) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);

            }
        }

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div className="opacitycontainer">
                <OpacityIcon className={anchorEl ? "bgOpacity-button toolbar-icons-active" : "bgOpacity-button toolbar-icons"} onClick={handleCloseAndOpen} />
                <Popover
                    id={id}
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="div-opacitysection">
                        <label for="customRange1" className="form-label">Opacity</label>
                        <div className="opacity-inputflex">
                            <input type="range" className="form-range" value={bgopacity} min={0} max={100} ref={bgopacityinputer} onChange={() => {
                                changebgopacityvalue();
                            }} id="customRange1" />
                            <span className='spanOpacity'>{bgopacity}</span>
                        </div>
                    </div>

                </Popover>
            </div>
        );
    }



    return(
        <>
                                            <div className="d-flex align-items-center" style={{ background: '#ffffff', borderRadius: '4px' }}>
                                                <FilterBackground editor={editor} fabric={fabric}/>
                                                <AdjustBackground editor={editor} fabric={fabric}/>
                                            </div>
                                            <Bgopacity editor={editor} fabric={fabric}/>
                                            <button className='set-as-background-but toolbar-icons' onClick={removeBackground}>Remove Background</button>

                                        </>
    )
}


export default BackgroundProperties;