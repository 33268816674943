import { TextField } from "@mui/material"
import { useState } from "react"



function InputMuiComponent(props) {

  const{
   
    onBlur,
    customClass,
    isError,
    errorText,
    label,
    value,
    onChange,
    onKeyUp,
    type,
    autoComplete,
    required,
    disable
  } = props

  const [focused , setFocused] = useState(false)
  
  
  return (
      <TextField
      error={isError}
      className={customClass}
      type={'search'}
      id="filled-error-helper-text"
      label={label}
      value={value}
      // autoComplete='off'
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      disabled={disable}
      required={focused ? required : value?.length > 0 ? required : false}
      // autoComplete={"new-password"}
      
      inputProps={{
        autoComplete: 'none',
    }}
      onChange={onChange}
      onKeyUp={onKeyUp == null ? () => {} : onKeyUp}
      // defaultValue="Hello World"
      helperText={errorText}
      variant="filled"
    />

  )
}

export default InputMuiComponent
