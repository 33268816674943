import { ButtonBase } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import Config from "../../config/Config";
import { Link } from 'react-router-dom';
import makeAnimated from 'react-select/animated';
import Creatable from "react-select/creatable";
import { components } from "react-select";

const LoadingMarkup = ({percentage}) => {
    return(
        <div className="py-4 text-center">
            <section className="animation-logo display-flex flex-column">
                <div className="svg-logo"></div>
            </section>
        </div>
    )
};

const Menu = props => {
  const optionSelectedLength = props.getValue().length || 0;
  return (
    <components.Menu {...props}>
      {optionSelectedLength < 5 ? (
        props.children
      ) : (
        <div style={{ margin: 15 }}>Max limit Reached</div>
      )}
    </components.Menu>
  );
};



const AilaysaCanvasLanguageSelection = ({setName, setSendThumb,name,result,sendThumb,sendHeight,sendWidth,sourceLang,setSourceLang,setResult,setTranslate,setTranslatedCanvases,jsdata,canvas,setCanvas,setNavbar,setMultilingualCanvasData,tLanguages,setTLanguages}) => {    const LABS_API = Config.LABS_API
    const navigate = useNavigate();
    const handleAuthTrue = (authentication) => {
        let token = Config.userState != null ? Config.userState.token : "";
        authentication.append("Authorization", `Bearer ${token}`)
        return authentication;
      }
    
    //   const [sourceLang , setSourceLang] = useState()
    const [loading,setLoading] = useState(false)
    const animatedComponents = makeAnimated();

      console.log(sourceLang);
      const [options, setOptions] = useState([]);

    //   const options = [
    //     // { value: 'ar', label: 'Arabic' },
    //     // { value: 'zh-CN', label: 'Chinese' },
    //     // { value: 'ja', label: 'Japanese' },
    //     // { value: 'ta', label: 'Tamil' },
    //     // { value: 'en', label: 'English'}
    //   ]
    const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 5;
      useEffect(() => {

        (async function () {
                    //   var formdata = new FormData();
                    //   formdata.append("canvas_json", tempdata);
                    //   formdata.append("languages", result);
                    // console.log(formdata);
                      var requestOptions = {
                        method: 'GET',
                        headers: handleAuthTrue(new Headers()),
                        // body: formdata,
                        redirect: 'follow'
                      };
              
                      let data = await fetch(LABS_API + "/canvas/languages", requestOptions)
                      if (data.status === 200) {
                        let response = await data.json()
                        const results = []
                        response.forEach((value) => {
                            results.push({
                              label: value.language,
                              value: value.id,
                            });
                          });
                        console.log(response);
                        setOptions([
                            {label: 'Select a company', value: ''}, 
                            ...results
                          ])
                        // setNames
                        // setMultilingualCanvasData(response)
                      }
                      else{
        
                        console.log('error');
                      }
                    })();

    },[])
    console.log('languages');
    console.log(options)
    // const options = [
    //     // { value: 'ar', label: 'Arabic' },
    //     // { value: 'zh-CN', label: 'Chinese' },
    //     // { value: 'ja', label: 'Japanese' },
    //     // { value: 'ta', label: 'Tamil' },
    //     // { value: 'en', label: 'English'}
    //   ]

      
    const selectStyle ={

        border: '2px solid #0078D4'   
   
       }

    //    let result = tLanguages.map(option => option.value);

       useEffect(() => {
       let result = tLanguages.map(option => option.value);

       setResult(result)

       },[tLanguages])

    //    console.log(result);

       const handleLanguageSelect = (selected) => {
        setTLanguages(selected);
        console.log(selected);
       }

       const handleSourceLang = (selected) => {

        setSourceLang(selected.value)
       }

       
       const sendData = () => {
       
            (async function () {
              var formdata = new FormData();

              tLanguages.map(option => {
                formdata.append("canvas_translation_tar_lang", option.value);

              })


              formdata.append("src_lang", sourceLang);
              console.log(formdata);
              var requestOptions = {
                method: 'PUT',
                headers: handleAuthTrue(new Headers()),
                body: formdata,
                redirect: 'follow'
              };
      
              let data = await fetch(LABS_API + "/canvas/canvas-designs/" + sendThumb + "/", requestOptions)

              if (data.status === 200) {
                let response = await data.json()
                console.log(response);
                navigate("/ailaysa-canvas-workspace",{ state : { id: sendThumb }})
                setNavbar(true)

                // setMultilingualCanvasData(response)
              }
              else{

                console.log('error');
              }
            })();
       }


  
     



    return(
        <div className="language-wrapper">
            <div className="header-language-selection-page">
                <div className="language-selection-page-back" onClick={() =>{setTranslate("Ailaysacanvas")}}>
                    <ArrowBackIosIcon />
                    <h4>Back to canvas</h4>
                </div>
            </div>
            <div className="inner-language-wrap">
                <div>
                    <h4>Select Source Language</h4>
                    <Select
                    // defaultValue={options[0]}
                    onChange={handleSourceLang}
                    options={options}
                    // value={sourceLang}
                    />
                </div>
                <div>
                    <h4>Select Target Language(s)</h4>
                    <Creatable
                        onChange={handleLanguageSelect}
                        // defaultValue={[options[2], options[3]]}
                        // value={tLanguages}
                        // components={{ Menu }}
                        // isValidNewOption={isValidNewOption}
                        closeMenuOnSelect={false}
                        isMulti
                        isClearable={false}
                        name="colors"
                        options={options}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />

                </div>
                <div className="language-select-button-wrap">
                    <ButtonBase className="create-multi-canvas-button" onClick={() =>{
                        sendData();
                        setLoading(true);
                        // setTranslatedCanvases(true);
                        // setTranslate("Ailaysacanvas")
                        // navigate("/ailaysa-canvas-workspace")
                        // setNavbar(true)
                        setName('Document name')
                    }   
                        }>
                        <span>Create Multilingual Canvas</span>
                    </ButtonBase>
                    <ButtonBase className="canvas-button" onClick={() =>{
                        
                        setTranslate("Ailaysacanvas");
                        }}>
                        <span>Cancel</span>
                    </ButtonBase>
                </div>
            </div>
            {
        loading && <LoadingMarkup />
       }
        </div>
    )

}

export default AilaysaCanvasLanguageSelection;