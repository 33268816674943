import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import SocialPostCanvas from "../components/social-post-localisation/SocialPostCanvas";
import { useNavigate } from "react-router-dom";
import { languages } from "../components/social-post-localisation/language";

const SocialPostLocalisation = (props) => {
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <section className="spl-section-wrapper">
                <div className="container">
                    <div className="spl-wrap-header">
                        <h1>
                            <div onClick={() => navigate("/")} className="back-icon">
                                <ArrowBackIosNewIcon className="arrow-icon"/>
                            </div>
                            Image Localization
                        </h1>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="spl-wrap">
                        <SocialPostCanvas />
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default SocialPostLocalisation;