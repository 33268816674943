import './Canvas.css';
// import Ailaysa from './Ailaysa.svg';
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import React from 'react';
import { useState, useEffect } from 'react';
import samp1 from './testing/samp1.jpg'
import samp2 from './testing/samp2.png'
import samp3 from './testing/samp3.jpg'
import samp4 from './testing/samp4.png'
import samp5 from './testing/samp5.jpg'
import samp6 from './testing/samp6.jpg'
import samp7 from './testing/samp7.png'
import samp8 from './testing/samp8.jpg'
import { languages } from './language';
import axios from 'axios';
import { fontList } from './Toolbar'
// import 'fabric-history';
// import { GrTextAlignCenter, GrTextAlignLeft, GrTextAlignRight } from 'react-icons/gr';
// import { FaUnderline, FaBold, FaItalic } from 'react-icons/fa';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalic from '@mui/icons-material/FormatItalic';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import CanvasComponent from './CanvasComponent';
import Config from '../../config/Config'
// import FormatBold from '@mui/icons-material/FormatBold';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AlignVerticalBottom from '@mui/icons-material/AlignVerticalBottom';
import AlignHorizontalCenter from '@mui/icons-material/AlignHorizontalCenter';
import { clear } from '@testing-library/user-event/dist/clear';
import { height } from '@mui/system';
import zIndex from '@mui/material/styles/zIndex';



function Canvas() {
    const CANVAS_TRANSLATION_API = Config.LABS_API
    // Bools for checkbox

    const [checked1, setChecked1] = useState(true)
    const [checked2, setChecked2] = useState(false)
    const [checked3, setChecked3] = useState(false)
    const [checked4, setChecked4] = useState(false)
    const [checked5, setChecked5] = useState(false)

    const [canvasHeight, setCanvasHeight] = useState(0)
    const [canvasWidth, setCanvaswidth] = useState(0)


    const [bold, setBold] = useState(false)
    const [italic, setItalic] = useState(false)
    const [underline, setUnderline] = useState(false)


    // Manual Translation call check
    const [clicked, setCLicked] = useState('0')
    // Dynamic Translation call check
    const [clicked2, setCLicked2] = useState('0')

    //Before and after translation call check
    const [callout, setCallout] = useState(false)
    const [access, setAccess] = useState(false)

    //Text Color
    const [color, setColor] = useState("#000000");

    //Text Font
    const [font, setFont] = useState("Arial");

    const fon = ["Arial", "Times New Roman", "Roboto"]

    //translation Source and Target set
    const [targetLang, setTargetLang] = useState(0);
    const [targetLangMan, setTargetLangMan] = useState(0);
    const [letterSpacing, setLetterSpacing] = useState(0);
    const [lineHeight, setLineHeight] = useState(0);


    


    // Manual translation state variable
    const [txt1, setTxt1] = useState('');
    const [txt2, setTxt2] = useState('');

    // Dynamic Translation state variable
    const [dynamicText, setDynamicText] = useState('');
    const [dynamicTranslatedText, setDynamicTranslatedText] = useState('');

    // Dynamic Translated Text Position variable
    const [top, setTop] = useState('50');
    const [left, setLeft] = useState('100');

    // Dynamic NEW POSTITION
    const [top1, setTop1] = useState('50');
    const [left1, setLeft1] = useState('100');


    // importing function from FabricJS
    const { editor, onReady } = useFabricJSEditor();


    const [isToggled, setToggle] = useState(false);
    //  Counter is a state initialized to 0
    const [counter, setCounter] = useState(20)

    // Function is called everytime increment button is clicked
    const handleClick1 = () => {
        // Counter state is incremented
        setCounter(counter + 1)
        handleSize()
    }

    // Function is called everytime decrement button is clicked
    const handleClick2 = () => {
        // Counter state is decremented
        setCounter(counter - 1)
        handleSize()

    }


    const handleAuthTrue = (authentication) => {
        let token = Config.userState != null ? Config.userState.token : "";
        authentication.append("Authorization", `Bearer ${token}`)
        return authentication;
    }






    // Function of Navigation between Resolutions
    const handleChange1 = () => {
        setChecked1(true)
        setChecked3(false)
        setChecked2(false)
        setChecked4(false)
        setChecked5(false)

        const file = document.querySelector('.file');
        file.value = '';

    }

    const handleChange2 = () => {
        setChecked2(true)
        setChecked1(false)
        setChecked3(false)
        setChecked4(false)
        setChecked5(false)

        const file = document.querySelector('.file');
        file.value = '';
    }

    const handleChange3 = () => {
        setChecked3(true)
        setChecked2(false)
        setChecked1(false)
        setChecked4(false)
        setChecked5(false)

        const file = document.querySelector('.file');
        file.value = '';

    }

    const handleChange4 = () => {
        setChecked4(true)
        setChecked3(false)
        setChecked2(false)
        setChecked1(false)
        setChecked5(false)
        const file = document.querySelector('.file');
        file.value = '';

    }

    const handleChange5 = () => {
        setChecked5(true)
        setChecked4(false)
        setChecked3(false)
        setChecked2(false)
        setChecked1(false)
        const file = document.querySelector('.file');
        file.value = '';

    }


    // const [fontSize, setFontSize] = useState(null)


    // Add Text Box Function
    function Addtext() {
        editor.canvas.add(new fabric.IText('தமிழில் வாக்கியம்', {
            left: 50,
            top: 100,
            fontFamily: font,
            fill: color,
            fontSize: "36",
            fontWeight: '500'
        }));

        setAccess(true);
    }

    function Addtext38() {
        editor.canvas.add(new fabric.IText('Tap and Type', {
            left: 50,
            top: 100,
            fontFamily: font,
            fill: color,
            fontSize: "38",
            charSpacing: "200",
            fontWeight: '800',
            // lineHeight: "71px"
        }));

        setAccess(true);
    }

    function Addtext22() {
        editor.canvas.add(new fabric.IText('Tap and Type', {
            left: 50,
            top: 100,
            fontFamily: font,
            fill: color,
            fontSize: "20",
            fontWeight: '400',
        }));

        setAccess(true);
    }

    // const shad = "5px 5px 10px red"

    function Addtext16() {
        editor.canvas.add(new fabric.IText('Tap and Type', {
            left: 50,
            top: 100,
            fontFamily: font,
            fill: color,
            fontSize: "22",
            fontWeight: '400',
        }));

        setAccess(true);
    }

    function Addtexts1() {
        editor.canvas.add(new fabric.IText('Hello', {
            left: 50,
            top: 100,
            fontFamily: font,
            fill: "#3a71c9",
            fontSize: "38",
            fontWeight: '600',
            shadow: "5px 5px 5px #d15260",
            zIndex: 2
        }));

        setAccess(true);
    }

    function Addtexts2() {
        editor.canvas.add(new fabric.IText('Good Morning', {
            left: 50,
            top: 100,
            fontFamily: font,
            fill: "#96f288",
            fontSize: "38",
            fontWeight: '600',
            shadow: "5px 5px 5px #d15260",
            zIndex: 2
            

        }));

        setAccess(true);
    }

    function Addtexts3() {
        editor.canvas.add(new fabric.IText('Yes', {
            left: 50,
            top: 100,
            fontFamily: font,
            fill: "#5bb5ae",
            fontSize: "38",
            fontWeight: '600',
            shadow: "6px 6px 0px #a4b3a8",
            zIndex: 2

        }));

        setAccess(true);
    }

    function Addtexts4() {
        editor.canvas.add(new fabric.IText('Thank You!', {
            left: 50,
            top: 100,
            fontFamily: font,
            fill: "#ded66a",
            fontSize: "38",
            fontWeight: '600',
            shadow: "1px 1px 2px red",
            stroke: "0 0 0.2em blue",

            // shadow: "0 0 0.2em blue",
        }));

        setAccess(true);
    }



    //Manual Translation Functions
    const handleInpChanSource = (e) => {

        setTxt1(e.target.value);

    }

    const handleInpChanTarget = (e) => {

        setTxt2(e.target.value);

    }

    function Addtext2() {

        const txt = (new fabric.IText(txt1, {
            left: 50,
            top: 100,
            fontFamily: font,
            fill: color,
            fontSize: '32',
        }));

        editor.canvas.add(txt);
        // setTxt1("");
    }

    function Addtext3() {

        const txt = (new fabric.IText(txt2, {
            left: 50,
            top: 100,
            fontFamily: font,

            fill: color,
            fontSize: '32',
        }));

        editor.canvas.add(txt);
        setTxt2("");
        setTargetLangMan(0)

    }


    //Dynamic Translation Functions

    const handleInpChanSource2 = (e) => {

        setDynamicText(e.target.value);

    }

    function Addtext4() {

        const txt = (new fabric.IText(dynamicText, {
            left: 50,
            top: 100,
            fontFamily: font,

            fill: color,
            fontSize: '32',
        }));

        editor.canvas.add(txt);

    }


    
    const uppercase = () => {

       
        editor.canvas.getActiveObject().set("text",editor.canvas.getActiveObject().text.toUpperCase());
        editor.canvas.renderAll();
    }


    const lowercase = () => {
        // editor.canvas.getActiveObject().set("text",editor.canvas.getActiveObject().text.toLowerCase());
        editor.canvas.getActiveObject().set("text",editor.canvas.getActiveObject().text.toLowerCase());
        editor.canvas.renderAll();
    }


    //Function for adding Local Background Image in Canvas
    const onAddBackground = (e) => {

        const image = e.target.files[0];
        fabric.Image.fromURL(URL.createObjectURL(image), (img) => {
            editor.canvas.setBackgroundImage(img);
            editor.canvas.renderAll();

        });
    };




    const removeObjectFromCanvas = () => {
        editor.canvas.remove(editor.canvas.getActiveObject());
    };

    const downloadImage = () => {
        const ext = "png";
        const base64 = editor.canvas.toDataURL({
            format: ext,
            enableRetinaScaling: true
        });
        const link = document.createElement("a");
        link.href = base64;
        link.download = `PrimarCanvas.${ext}`;
        link.click();
    };

    useEffect(() => {


        document.querySelector('[type="color"]').addEventListener('input', function (e) {
            setColor(this.value);

        })
    }, [color])

    
    useEffect(() => {


        document.querySelector("#letterSpacing").addEventListener('input', function (e) {
            setLetterSpacing(this.value);

        })
    }, [letterSpacing])

    useEffect(() => {


        document.querySelector("#lineHeight").addEventListener('input', function (e) {
            setLineHeight(this.value);

        })
    }, [lineHeight])

    console.log(letterSpacing);
    console.log(lineHeight);

    const handleSize = () => {
        editor.canvas.getActiveObject().set("fontSize", `${counter}`)
        editor.canvas.renderAll();

    }

    const handleColorChange = () => {

        editor.canvas.getActiveObject().set("fill", color);
        editor.canvas.renderAll();

    }

    //Functions for Adding Template Images in Canvas
    const onAddImage1 = () => {
        fabric.Image.fromURL(
            samp1,
            function (oImg) {
                editor.canvas.setBackgroundImage(oImg);
                editor.canvas.setBackgroundImage(oImg);
                editor.canvas.renderAll();

            }
        );
    };

    const onAddImage2 = () => {
        fabric.Image.fromURL(
            samp2,
            function (oImg) {
                editor.canvas.setBackgroundImage(oImg);
                editor.canvas.renderAll();

            }
        );
    };

    const onAddImage3 = () => {
        fabric.Image.fromURL(
            samp3,
            function (oImg) {
                editor.canvas.setBackgroundImage(oImg);
                editor.canvas.renderAll();

            }
        );
    };

    const onAddImage4 = () => {
        fabric.Image.fromURL(
            samp4,
            function (oImg) {
                editor.canvas.setBackgroundImage(oImg);
                editor.canvas.renderAll();

            }
        );
    };

    const onAddImage5 = () => {
        fabric.Image.fromURL(
            samp5,
            function (oImg) {
                editor.canvas.setBackgroundImage(oImg);
                editor.canvas.renderAll();

            }
        );
    };

    const onAddImage6 = () => {
        fabric.Image.fromURL(
            samp6,
            function (oImg) {
                editor.canvas.setBackgroundImage(oImg);
                editor.canvas.renderAll();

            }
        );
    };

    const onAddImage7 = () => {
        fabric.Image.fromURL(
            samp7,
            function (oImg) {
                editor.canvas.setBackgroundImage(oImg);
                editor.canvas.renderAll();

            }
        );
    };

    const onAddImage8 = () => {
        fabric.Image.fromURL(
            samp8,
            function (oImg) {
                editor.canvas.setBackgroundImage(oImg);
                editor.canvas.renderAll();

            }
        );
    };



    //Function for Preview of Image Generated form Canvas
    const preview = () => {
        let image = new Image();
        image.crossOrigin = "anonymous";
        image.src = editor.canvas.toDataURL();
        document.getElementById("myImg").src = editor.canvas.toDataURL();



    };
    const clearAll = () => {

        editor.canvas.clear();
    
      }


    //Google API call

    useEffect(() => {

        if (clicked == '1') {
            (async function () {
                var formdata = new FormData();
                formdata.append("Input", editor.canvas.getActiveObject().text);
                formdata.append("target_language", targetLang);

                var requestOptions = {
                    method: 'POST',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(CANVAS_TRANSLATION_API + "/voice/translate/", requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    setDynamicTranslatedText(response.text)
                    setCLicked('0')

                }
                else {
                    console.log('error');
                }
            })();
        }
    }, [clicked == '1']);


    const transGenerateText = () => {


        // editor.canvas.remove(editor.canvas.getActiveObject());
        // const txt = (new fabric.IText(dynamicTranslatedText, {
        //   left: left1,
        //   top: top1,
        //   fontFamily: font,
        //   fill: color,
        //   fontSize: '32',
        // }));
        // editor.canvas.add(txt);

        editor.canvas.getActiveObject().set({ text: dynamicTranslatedText })
        editor.canvas.renderAll();

        let image = new Image();
        image.crossOrigin = "anonymous";
        image.src = editor.canvas.toDataURL();
        document.getElementById("myImg").src = editor.canvas.toDataURL();

        setTargetLang(0)
        setDynamicText('')
        setDynamicText('')
        setCallout(!callout)
    };

    const transGenerateText2 = () => {

        // editor.canvas.remove(editor.canvas.getActiveObject());
        // const txt = (new fabric.IText(dynamicTranslatedText, {
        //   left: left1,
        //   top: top1,
        //   fontFamily: font,
        //   fill: color,
        //   fontSize: '32',
        // }));
        // editor.canvas.add(txt);

        editor.canvas.getActiveObject().set({ text: dynamicTranslatedText })

        editor.canvas.renderAll();

        setTargetLang(0)
        setDynamicText('')
        setCallout(!callout)
    };



    const transGenDownloadImage = () => {
        const ext = "png";
        const base64 = document.getElementById("myImg").src
        const link = document.createElement("a");
        link.href = base64;
        link.download = `TranslatedCanvas.${ext}`;
        link.click();
    };


    const handleChangeMan = (e) => {

        setTargetLangMan(e.target.value);
        setCLicked2('1');

    }


    const handleChange = (e) => {
        setTargetLang(e.target.value);
        setCLicked('1');
        console.log(editor.canvas.getActiveObject().left, editor.canvas.getActiveObject().top);
        setTop1(editor.canvas.getActiveObject().top);
        setLeft1(editor.canvas.getActiveObject().left);

    };


    useEffect(() => {

        if (clicked2 == '1') {
            (async function () {
                var formdata = new FormData();
                formdata.append("Input", txt1);
                formdata.append("target_language", targetLangMan);

                var requestOptions = {
                    method: 'POST',
                    headers: handleAuthTrue(new Headers()),
                    body: formdata,
                    redirect: 'follow'
                };

                let data = await fetch(CANVAS_TRANSLATION_API + "/voice/translate/", requestOptions)
                if (data.status === 200) {
                    let response = await data.json()
                    setTxt2(response.text)
                    setCLicked2('0')

                }
                else {
                    console.log('error');
                }
            })();
        }
    }, [clicked2 == '1']);


    // const onMouseMove = (e) => {
    //   let rect = e.currentTarget.getBoundingClientRect();
    //   let x = e.clientX - rect.left;
    //   let y = e.clientY - rect.top;

    //   setLeft(x);
    //   setTop(y);

    // };


    useEffect(() => {


        document.querySelector('.fontFam').addEventListener('input', function (e) {
            setFont(this.value);

        })

    }, [font])


    const handlefontSet = () => {

        editor.canvas.getActiveObject().set("fontFamily", font);
        editor.canvas.renderAll();


    }

    console.log("acess" + access)

    useEffect(() => {
        window.addEventListener('keydown', e => {
            if (e.key === 'Delete') {
                console.log('delte');
                try {
                    editor.canvas.remove(editor.canvas.getActiveObject())
                    // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                    let activeObjects = editor.canvas.getActiveObjects();
                    if (activeObjects.length) {
                            activeObjects.forEach(function (object) {
                                editor.canvas.remove(object);
                            });
                    }
                }
                catch {
                }
            }

        })
    });



    const getActiveObjs = () => {

        let activeObjects = editor.canvas.getActiveObjects();
                    if (activeObjects.length) {
                            activeObjects.forEach(function (object) {
                                console.log(object.text);
                                object.set({text: "yes"})
                                editor.canvas.renderAll();
                            });

                    }


                }


    const handleLetterSpacingChange = () => {

        editor.canvas.getActiveObject().set("charSpacing", letterSpacing);
        editor.canvas.renderAll();

    }

    const handleLineHeightChange = () => {

        editor.canvas.getActiveObject().set("lineHeight", lineHeight);
        editor.canvas.renderAll();

    }





    (function () {
        if (typeof EventTarget !== 'undefined') {
            let supportsPassive = false;
            try {
                const opts = Object.defineProperty({}, 'passive', {
                    get: () => {
                        supportsPassive = true;
                    },
                });
                window.addEventListener('testPassive', null, opts);
                window.removeEventListener('testPassive', null, opts);
            } catch (e) { }
            const func = EventTarget.prototype.addEventListener;
            EventTarget.prototype.addEventListener = function (type, fn) {
                this.func = func;
                this.func(type, fn, supportsPassive ? { passive: false } : false);
            };
        }
    })();

    const undo = () => {

        editor.canvas.undo();
        // editor.canvas.renderAll();

    }


    const redo = () => {

        editor.canvas.redo();
        // editor.canvas.renderAll();


    }


    const alignCenter = () => {

        editor.canvas.getActiveObject().textAlign = "center";
        editor.canvas.renderAll();


    }

    const alignLeft = () => {

        editor.canvas.getActiveObject().textAlign = "left";
        editor.canvas.renderAll();


    }

    const alignRight = () => {

        editor.canvas.getActiveObject().textAlign = "right";
        editor.canvas.renderAll();


    }

    const onBold = () => {

        editor.canvas.getActiveObject().fontWeight = 'Bold';
        editor.canvas.renderAll()
        // editor.canvas.getActiveObject().fontWeight = "500";
        // editor.canvas.renderAll()

    }


    const unBold = () => {

        editor.canvas.getActiveObject().set("fontWeight", "");
        editor.canvas.renderAll();
    }


    const handleBold = () => {
        if (!bold) {
            onBold();
            setBold(true);

        } else {
            unBold();
            setBold(false);
        }
    };



    const onUnderline = () => {

        editor.canvas.getActiveObject().set('underline', true);
        editor.canvas.renderAll()

    }

    const unUnderline = () => {

        editor.canvas.getActiveObject().set("underline", false);
        editor.canvas.renderAll();
    }


    const handleUnderline = () => {
        if (!underline) {
            onUnderline();
            setUnderline(true);

        } else {
            unUnderline();
            setUnderline(false);
        }
    };


    const onItalic = () => {

        editor.canvas.getActiveObject().set({ 'fontStyle': 'italic' });
        editor.canvas.renderAll()

    }

    const unItalic = () => {

        editor.canvas.getActiveObject().set("fontStyle", "");
        editor.canvas.renderAll();
    }


    const handleItalic = () => {
        if (!italic) {
            onItalic();
            setItalic(true);

        } else {
            setItalic(false);
            unItalic();

        }
    };


    const canvas = new fabric.Canvas('canvas9');
    const canvas2 = new fabric.Canvas('canvas8');



    const dataCanvas = () => {

        console.log(editor.canvas.getContext);
    }



    //Position Align to pages
    
    const alignTop = () => {

        editor.canvas.getActiveObject().set({top: 0});
        editor.canvas.renderAll();
    }

    const alignBottom = () => {

        editor.canvas.getActiveObject().set({top: editor.canvas.height - (editor.canvas.getActiveObject().height * editor.canvas.getActiveObject().scaleY)});
        editor.canvas.renderAll();
    }

    const alignPageLeft = () => {

        editor.canvas.getActiveObject().set({left: 0});
        editor.canvas.renderAll();
    }

    const alignPageRight = () => {

        editor.canvas.getActiveObject().set({left: editor.canvas.width - (editor.canvas.getActiveObject().width * editor.canvas.getActiveObject().scaleX)});
        editor.canvas.renderAll();
    }

    const alignPageHorizontalCenter = () => {

        editor.canvas.getActiveObject().set({left: (editor.canvas.width / 2) - ((editor.canvas.getActiveObject().width * editor.canvas.getActiveObject().scaleX) / 2)});
        editor.canvas.renderAll();
    }

    const alignPageVerticalCenter = () => {

        editor.canvas.getActiveObject().set({top: (editor.canvas.height / 2) - ((editor.canvas.getActiveObject().height * editor.canvas.getActiveObject().scaleY) / 2)});
        editor.canvas.renderAll();
    }

    const [jsdata, setJsdata] = useState(null)
    const getJsonData = () => {


        // editor.canvas.deactivateAllWithDispatch();
        // editor.canvas.renderAll();
        // const image   = editor.canvas.toSVG();
        // const svg_image = "data:image/svg+xml,"+encodeURIComponent(image);
        // console.log(svg_image);
        setJsdata(editor.canvas.toJSON())
        
    }
    console.log(jsdata);

    const setJsonData = () => { 
        editor.canvas.loadFromJSON(jsdata,function() {
            editor.canvas.renderAll(); 
         },function(o,object){
            console.log(o,object)
         })
    }

    const bringForward = () => {

        editor.canvas.getActiveObject().bringToFront();

     }

     const PushBackward = () => { 
        editor.canvas.getActiveObject().sendBackwards();

     }

     const onAddImage = (e) => {
        console.log(e.target.files);
           
        const stickers = e.target.files[0];
        fabric.Image.fromURL(URL.createObjectURL(stickers), (img) => {
            img.scaleToWidth(200);
            var oImg = img.set({ 
                left: 100,
                top: 60
            })
            editor.canvas.add(oImg).sendBackwards();
            editor.canvas.renderAll();

        });
        const file = document.querySelector('.file');
        file.clear();
            

    
     }

     const addFromGallery = (e) => {
        const stickersImg = e.target.src
        // console.log(stickersImg);
        fabric.Image.fromURL(stickersImg, (img) => {
            img.scaleToWidth(200);
            var oImg = img.set({ 
                left: 100,
                top: 60
            })
            editor.canvas.add(oImg).sendBackwards();
            editor.canvas.renderAll();
            
        });

     }

     const [call , setCall] = useState(false)

   

    //  if(canvasWidth != 0) {

    //     editor.canvas.setWidth(canvasWidth);
    //     editor.canvas.renderAll();

       
     
    //  }

    //  if(canvasHeight != 0) {

    //     editor.canvas.setHeight(canvasHeight);
    //     editor.canvas.renderAll();
    //  }
     

     const gettingCanvasWidth = (e) => {

        console.log(e.target.value);
        setCanvaswidth(e.target.value)
     }

     const gettingCanvasHeight = (e) => {

        console.log(e.target.value);
        setCanvasHeight(e.target.value)
     }

     const zoomIn = () => {
        console.log('zooming in...');
        var multiplier = 1.1;
    
        editor.canvas.zoomToPoint( new fabric.Point(parseInt(editor.canvas.width / 2 ), parseInt(editor.canvas.height / 2 ) )  , editor.canvas.getZoom() * multiplier ) ;
    
        editor.canvas.renderAll();
    } 


    const grayscale = () => {
        var filter = new fabric.Image.filters.Grayscale();
        editor.canvas.getActiveObject().filters.push(filter);
        editor.canvas.getActiveObject().applyFilters();
        editor.canvas.renderAll();

        // editor.canvas.getActiveObject().filters.push('grayscale')
        // console.log(editor.canvas.getActiveObject());
        // editor.canvas.getActiveObject().app
        // // something.filters['grayscale']
        // editor.canvas.renderAll();
    //    console.log(something);
    }

    const removeFilters = () => {

        editor.canvas.getActiveObject().filters.length = 0;
        editor.canvas.getActiveObject().applyFilters();
        editor.canvas.renderAll();


    }
    
    const sepia = () => {

        var filter = new fabric.Image.filters.Sepia();
        editor.canvas.getActiveObject().filters.push(filter);
        editor.canvas.getActiveObject().applyFilters();
        editor.canvas.renderAll();
    }

    const blackwhite = () => {

        var filter = new fabric.Image.filters.BlackWhite();
        editor.canvas.getActiveObject().filters.push(filter);
        editor.canvas.getActiveObject().applyFilters();
        editor.canvas.renderAll();
    }

    const brownie = () => {

        var filter = new fabric.Image.filters.Brownie();
        editor.canvas.getActiveObject().filters.push(filter);
        editor.canvas.getActiveObject().applyFilters();
        editor.canvas.renderAll();
    }

    const vintage = () => {

        var filter = new fabric.Image.filters.Vintage();
        editor.canvas.getActiveObject().filters.push(filter);
        editor.canvas.getActiveObject().applyFilters();
        editor.canvas.renderAll();
    }

    const kodachrome = () => {

        var filter = new fabric.Image.filters.Kodachrome();
        editor.canvas.getActiveObject().filters.push(filter);
        editor.canvas.getActiveObject().applyFilters();
        editor.canvas.renderAll();
    }

    const technicolor = () => {

        var filter = new fabric.Image.filters.Technicolor();
        editor.canvas.getActiveObject().filters.push(filter);
        editor.canvas.getActiveObject().applyFilters();
        editor.canvas.renderAll();
    }

    const polaroid = () => {

        var filter = new fabric.Image.filters.Polaroid();
        editor.canvas.getActiveObject().filters.push(filter);
        editor.canvas.getActiveObject().applyFilters();
        editor.canvas.renderAll();
    }

    const brightness = (e) => {

        var filter = new fabric.Image.filters.Brightness({
            brightness: 0.50
          });
          editor.canvas.getActiveObject().filters.push(filter);
          editor.canvas.getActiveObject().applyFilters();
          editor.canvas.renderAll();

    }

    const addCircle = () => {

        var circle = new fabric.Circle({
            left: 100,
            top: 100,
            radius: 75,
            fill: '#ddd',
          });
          editor.canvas.add(circle);


    }

    const addSquare = () => {

        var circle = new fabric.Circle({
            left: 100,
            top: 100,
            radius: 75,
            fill: '#ddd',
          });
          editor.canvas.add(circle);


    }

    const addRectangle = () => {

        var circle = new fabric.Circle({
            left: 100,
            top: 100,
            radius: 75,
            fill: '#ddd',
          });
          editor.canvas.add(circle);


    }

    const addTriangle = () => {

        var circle = new fabric.Circle({
            left: 100,
            top: 100,
            radius: 75,
            fill: '#ddd',
          });
          editor.canvas.add(circle);


    }

    const addEllipsis = () => {

        var shape = new fabric.Ellipse({
            left: 150,
            top: 150,
            rx: 100,
            ry: 50,
            fill: '#ddd',
          });
          editor.canvas.add(shape);


    }

    const addPolygon = () => {

        var shape = new fabric.Polygon([
            {x:100,y:100},
            {x:150,y:150},
            {x:200,y:250},
            {x:220,y:100},
            {x:300,y:80},
            {x:80,y:20}
            ], {
            left: 150,
            top: 150,
            });
            editor.canvas.add(shape);


    }
    

    return (
        <div className="App">
            {/* <nav id="navbar" className='navbar fixed-top navbar-expand-lg navbar-light ail-work-nav ail-workspace-blue-nav'>
        <a href='#' className="navbar-display-logo">
          <div className="logo-flex">
            <img src={Ailaysa} alt="logo" />
            <span className='canvas-logo'>Canvas</span>
          </div>
        </a>
      </nav> */}
            <div className='row padd noLRMar'>
                <div className='col-3 sidebar-active-area-wrapper p-0'>
                    <div className="list-group">
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Add Background
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                    <div className="accordion-body">
                                        <label>Resolutions</label>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={checked1} onChange={handleChange1} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                800 x 600
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={checked2} onChange={handleChange2} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                1024 x 768
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={checked3} onChange={handleChange3} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                1280 x 720
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={checked4} onChange={handleChange4} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                Demo Custom size
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={checked5} onChange={handleChange5} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                Custom size Canva
                                            </label>
                                        </div>
                                        <input type="file" multiple onChange={onAddBackground} className="form-control-sm list-group-item file marNone" disabled={!checked1 && !checked2 && !checked3 && !checked4} />
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                        Dynamic Translation
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                    <div className="accordion-body">
                                        <div className="form-floating mt-2" >
                                            <input type="color" id="floatingInputGrid" onChange={handleColorChange} className="form-control list-group-item list-group-item-action color-ele-height" />
                                            <label htmlFor="floatingInputGrid">Color Picker</label>
                                        </div>
                                        <div className="form-floating">
                                            <select type='text' className="form-select form-select-action fontFam" onClick={handlefontSet} aria-label="form-select-sm example">
                                                {fontList.map((font) => (
                                                    <option value={font} key={font}>
                                                        {font}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="floatingInputGrid">Font Family</label>
                                        </div>
                                        <div className="list-group-item list-group-item-action d-flex gap-3 justify-content-around">
                                            <div className="d-flex gap-2 border">
                                                <button onClick={alignLeft}><FormatAlignLeftIcon /></button>
                                                <button onClick={alignCenter}> <FormatAlignCenterIcon /></button>
                                                <button onClick={alignRight}><FormatAlignRightIcon /></button>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <button onClick={handleBold}><FormatBoldIcon /></button>
                                                <button onClick={handleUnderline}> <FormatUnderlinedIcon /></button>
                                                <button onClick={handleItalic}><FormatItalic /></button>
                                            </div>
                                        </div>
                                        <div className='mb-2 mt-2'>
                                            <div>
                                                <button onClick={Addtext} className="list-group-item list-group-item-action but fs-2">Add Heading</button>
                                            </div>
                                            {checked1 &&
                                                <div className="form-floating">
                                                    <select className="form-select form-select-action mt-2" value={targetLang} onChange={handleChange} disabled={access && editor.canvas.getActiveObject() ? false : true} aria-label=".form-select-sm example">
                                                        <option>Open and Select Language</option>
                                                        {languages.map((lang) => (
                                                            <option value={lang.language} key={lang.code}>
                                                                {lang.language}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="floatingInputGrid">Target Language</label>
                                                </div>
                                            }
                                            {checked2 &&
                                                <div className="form-floating">
                                                    <select className="form-select form-select-action mt-2" value={targetLang} onChange={handleChange} disabled={access && editor.canvas.getActiveObject() ? false : true} aria-label=".form-select-sm example">
                                                        <option>Open and Select Language</option>
                                                        {languages.map((lang) => (
                                                            <option value={lang.language} key={lang.code}>
                                                                {lang.language}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="floatingInputGrid">Target Language</label>
                                                </div>
                                            }
                                            {checked3 &&
                                                <div className="form-floating">
                                                    <select className="form-select form-select-action mt-2" value={targetLang} onChange={handleChange} disabled={access && editor.canvas.getActiveObject() ? false : true} aria-label=".form-select-sm example">
                                                        <option>Open and Select Language</option>
                                                        {languages.map((lang) => (
                                                            <option value={lang.language} key={lang.code}>
                                                                {lang.language}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="floatingInputGrid">Target Language</label>
                                                </div>
                                            }

                                        </div>
                                        <button onClick={removeObjectFromCanvas} className="list-group-item list-group-item-action but">Remove Selected text</button>

                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                        Manual Translation
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                    <div className="accordion-body">
                                        <div className='mb-2 mt-2'>
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="floatingInputGrid" value={txt1} onChange={handleInpChanSource} placeholder="Type your Text......." />
                                                <label htmlFor="floatingInputGrid">Primary Text</label>
                                            </div>
                                            <button onClick={Addtext2} className="list-group-item list-group-item-action but" disabled={txt1 ? false : true}>Add Source Text</button>
                                        </div>
                                        <div className='mb-2 mt-2'>
                                            <div className="form-floating">
                                                <select className="form-select form-select-action mt-2" value={targetLangMan} onChange={handleChangeMan} disabled={txt1 ? false : true} aria-label=".form-select-sm example">
                                                    <option>Open and Select Language</option>
                                                    {languages.map((lang) => (
                                                        <option value={lang.language} key={lang.code}>
                                                            {lang.language}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor="floatingInputGrid">Target Language</label>
                                            </div>
                                        </div>
                                        <div className="mt-2 mb-2">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="floatingInputGrid" value={txt2} onChange={handleInpChanTarget} placeholder="Your Translated Text......." />
                                                <label htmlFor="floatingInputGrid">Translated Text</label>
                                            </div>
                                            <button onClick={Addtext3} className="list-group-item list-group-item-action but" disabled={txt2 ? false : true}>Add Target Text</button>
                                        </div>
                                        <button onClick={removeObjectFromCanvas} className="list-group-item list-group-item-action but">Remove Selected text</button>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                        Canvas size
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                    <div className="accordion-body">
                                        <div class="input-group mb-3">
                                            <input type="number" class="form-control" onChange={gettingCanvasHeight} placeholder="Height" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                        </div>
                                        <div class="input-group mb-3">
                                            <input type="number" class="form-control" onChange={gettingCanvasWidth} placeholder="Width" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                        Text
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                                    <div className="accordion-body">
                                        <label>Heading Size</label>
                                        <button onClick={Addtext38} className="list-group-item list-group-item-action but fs-2">Add Heading</button>
                                        <button onClick={Addtext22} className="list-group-item list-group-item-action but fs-4">Add Heading</button>
                                        <button onClick={Addtext16} className="list-group-item list-group-item-action but">Add Heading</button>
                                        <label>Heading Size</label>
                                        <div class="container text-center">
                                            <div class="row row-cols-2">
                                                <div class="col border p-3 txt1-style" onClick={Addtexts1}>Hello</div>
                                                <div class="col border p-3 txt2-style" onClick={Addtexts2}>Good Morning</div>
                                                <div class="col border p-3 txt3-style" onClick={Addtexts3}>Yes</div>
                                                <div class="col border p-3 txt4-style" onClick={Addtexts4}>Thank You!</div>
                                            </div>
                                        </div>
                                        <label>Size</label>
                                        <div class="d-flex justify-content-center">
                                            <RemoveIcon onClick={handleClick2} />
                                            <div>{counter}</div>
                                            <AddIcon onClick={handleClick1} />
                                        </div>
                                        <div>
                                            <label class="form-label">Uppercase</label>
                                            <div>
                                            <button type="button" onClick={uppercase}>uppercase</button>
                                            </div>
                                        </div>
                                        <div>
                                            <label class="form-label">Lowercase</label>
                                            <div> 
                                            <button type="button" onClick={lowercase}>lower</button>
                                            </div>
                                        </div>
                                        <div>
                                        <label for="customRange2" class="form-label" >Letter Spacing</label>
                                        <input type="range" class="form-range" min="-200" value={letterSpacing} onChange={handleLetterSpacingChange} max="800" id="letterSpacing" />
                                        <label for="customRange3" class="form-label">Line Spacing</label>
                                        <input type="range" class="form-range" min="-1" max="3" value={lineHeight} onChange={handleLineHeightChange} id="lineHeight" />
                                        </div>
                                        <div>
                                            <label class="form-label">Align to pages</label>
                                            <div className="d-flex justify-content-evenly">
                                                <div className="d-flex justify-content-evenly flex-column gap-2">
                                                    <AlignVerticalTopIcon onClick={alignTop} />
                                                    <AlignVerticalCenterIcon onClick={alignPageVerticalCenter} />
                                                    <AlignVerticalBottomIcon onClick={alignBottom} />        
                                                </div>
                                                <div className="d-flex justify-content-evenly flex-column gap-2">
                                                    <AlignHorizontalLeftIcon onClick={alignPageLeft} />
                                                    <AlignHorizontalCenter onClick={alignPageHorizontalCenter} />
                                                    <AlignHorizontalRightIcon onClick={alignPageRight}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                                        Image
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                                    <div className="accordion-body">
                                    <label class="form-label">upload image</label>
                                        <input type="file" multiple onChange={onAddImage} className="form-control-sm list-group-item file marNone mb-2" disabled={!checked1 && !checked2 && !checked3 && !checked4} />
                                    <label class="form-label mb-2">images</label>
                                        <div className='container'>
                                          <div className="row mb-2">
                                            <div className="col">
                                                <img src={samp1} onClick={addFromGallery} width="100%" />
                                            </div>
                                            <div className="col">
                                                <img src={samp2} onClick={addFromGallery} width="100%" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col">
                                                <img src={samp3} onClick={addFromGallery} width="100%" />
                                            </div>
                                            <div className="col">
                                                <img src={samp4} onClick={addFromGallery} width="100%" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col">
                                                <img src={samp5} onClick={addFromGallery} width="100%" />
                                            </div>
                                            <div className="col">
                                                <img src={samp6} onClick={addFromGallery} width="100%" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col">
                                                <img src={samp7} onClick={addFromGallery} width="100%" />
                                            </div>
                                            <div className="col">
                                                <img src={samp8} onClick={addFromGallery} width="100%" />
                                            </div>
                                        </div>                  
                                        </div>
                                    <label className="form-label mb-2">Image filters</label>
                                    
                                        <div className="d-flex flex-column gap-2">
                                            <button onClick={grayscale}>Grayscale</button>   
                                            <button onClick={sepia}>Sepia</button>  
                                            <button onClick={blackwhite}>Black/White</button>               
                                            <button onClick={brownie}>Brownie</button>  
                                            <button onClick={vintage}>Vintage</button>               
                                            <button onClick={kodachrome}>Kodachrome</button>               
                                            <button onClick={technicolor}>Technicolor</button>               
                                            <button onClick={polaroid}>Polaroid</button>
                                            <button onClick={removeFilters}>Remove Filters</button>                
                                            <label for="customRange2" class="form-label">brightness</label>
                                                {/* <input type="range" onChange={brightness} id="brightness-value" value="0.1" min="-0.50" max="0.50" step="0.02"></input> */}
                                                {/* <input type="range" onChange={brightness} class="form-range"  id="customRange" /> */}
                                                <button onClick={brightness}>Brightness</button>
                                        </div>

                                    </div>
                                    <label className="form-label mb-2">Vector Image</label>
                                    <div className="d-flex flex-column gap-2">
                                        <button onClick={addCircle}>Circle</button>
                                        <button onClick={addSquare}>Square</button>
                                        <button onClick={addRectangle}>Rectangle</button>
                                        <button onClick={addTriangle}>Triangle</button>
                                        <button onClick={addEllipsis}>Ellipsis</button>
                                        <button onClick={addPolygon}>Polygon</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {checked1 &&
                            <div>
                                <button className="list-group-item list-group-item-action" onClick={downloadImage}>Export</button>
                                <button className="list-group-item list-group-item-action" onClick={dataCanvas}>data</button>
                            </div>
                        }
                        {checked2 &&
                            <button className="list-group-item list-group-item-action" onClick={downloadImage}>Export</button>
                        }
                        {checked3 &&
                            <button className="list-group-item list-group-item-action" onClick={downloadImage}>Export</button>
                        }

                        {/* <button onClick={alignLeft}>Text Left</button>
            <button onClick={alignCenter}>Text Center</button>
            <button onClick={alignRight}>Text Right</button> */}
                        {/* <button onClick={bold}>Bold</button>
            <button onClick={underline}>Underline</button>
            <button onClick={italic}>FaItalic</button> */}
                    </div>
                </div>
                <div className='col-9 ai-working-col-wrapper'>
                    {checked1 && (
                        <>
                            <FabricJSCanvas className="canvas1" onReady={onReady} />
                            <div className="d-flex justify-content-center mt-3 gap-2">
                                <button type="button" onClick={bringForward} className="btn btn-primary">Forward</button>
                                <button type="button" onClick={PushBackward} className="btn btn-primary">Backward</button>
                                <button type="button" onClick={undo} className="btn btn-primary">Undo</button>
                                <button type="button" onClick={transGenerateText2} className="btn btn-primary" disabled={dynamicTranslatedText && editor.canvas.getActiveObject() ? false : true}>Translate</button>
                                <button type="button" onClick={redo} className="btn btn-primary">Redo</button>
                                <button type="button" onClick={getJsonData} className="btn btn-primary">getJson</button>
                                <button type="button" onClick={clearAll} className="btn btn-primary">Clear</button>
                                <button type="button" onClick={setJsonData} className="btn btn-primary">setJson</button>
                                <button type="button" onClick={getActiveObjs} className="btn btn-primary">get selection</button>
                                <button type="button" onClick={zoomIn} className="btn btn-primary">zoomIn</button>


                            </div>
                        </>
                    )}
                    {checked2 && (
                        <>
                            <FabricJSCanvas className="canvas2"  onReady={onReady} />
                            <div className="d-flex justify-content-center mt-3 gap-2">
                                <button type="button" onClick={undo} className="btn btn-primary">Undo</button>
                                <button type="button" onClick={transGenerateText2} className="btn btn-primary" disabled={dynamicTranslatedText && editor.canvas.getActiveObject() ? false : true}>Translate</button>
                                <button type="button" onClick={redo} className="btn btn-primary">Redo</button>
                            </div>
                        </>
                    )}
                    {checked3 && (
                        <>
                            <FabricJSCanvas className="canvas3" onReady={onReady} />
                            <div className="d-flex justify-content-center mt-3 gap-2">
                                <button type="button" onClick={undo} className="btn btn-primary">Undo</button>
                                <button type="button" onClick={transGenerateText2} className="btn btn-primary" disabled={dynamicTranslatedText && editor.canvas.getActiveObject() ? false : true}>Translate</button>
                                <button type="button" onClick={redo} className="btn btn-primary">Redo</button>
                            </div>
                        </>
                    )}
                    {checked4 && (
                        <>
                            <div className="canvas-flex justify-content-around">
                                <div>
                                    <FabricJSCanvas className="canvas4a" onReady={onReady} />
                                </div>
                                <div>
                                    <div className="canvas4b">
                                        <img className='transgenerated-image' id='myImg' />
                                    </div>
                                </div>
                            </div>

                            <div className='but-flex'>
                                <div className="d-flex gap-2">
                                    <button type="button" onClick={undo} className="btn btn-primary">Undo</button>
                                    <div className='row g-1'>
                                        {callout &&
                                            <div className="callout">After Translation</div>

                                        }
                                        <button type="button" onClick={downloadImage} className="btn btn-primary">Export</button>
                                    </div>
                                    <button type="button" onClick={redo} className="btn btn-primary">Redo</button>

                                </div>
                                <div className='row g-1'>
                                    <button type="button" onClick={preview} className="btn btn-primary">Preview</button>
                                    <div className="form-floating">
                                        <select className="form-select form-select-action" value={targetLang} onChange={handleChange} disabled={access && editor.canvas.getActiveObject() ? false : true} aria-label=".form-select-sm example">
                                            <option>Open and Select Language</option>
                                            {languages.map((lang) => (
                                                <option value={lang.language} key={lang.code}>
                                                    {lang.language}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="floatingInputGrid">Target Language</label>
                                    </div>
                                    <button type="button" onClick={transGenerateText} className="btn btn-primary" disabled={access ? false : true}>Translate</button>
                                </div>
                                <div className='row g-1 margin'>
                                    {callout &&
                                        <div className="callout">Before Translation</div>
                                    }
                                    <button type="button" onClick={transGenDownloadImage} className="btn btn-primary">Export</button>
                                </div>
                            </div>
                            <div className='row noLRMar justify-content-center mar-small gap border-top pt-4'>
                                <div className="col-1">
                                    <img className="size-img" src={samp1} onClick={onAddImage1} />
                                </div>
                                <div className="col-1">
                                    <img className="size-img" src={samp2} onClick={onAddImage2}></img>
                                </div>
                                <div className="col-1">
                                    <img className="size-img" src={samp3} onClick={onAddImage3}></img>
                                </div>
                                <div className="col-1">
                                    <img className="size-img" src={samp4} onClick={onAddImage4}></img>
                                </div>
                                <div className="col-1">
                                    <img className="size-img" src={samp5} onClick={onAddImage5}></img>
                                </div>
                                <div className="col-1">
                                    <img className="size-img" src={samp6} onClick={onAddImage6}></img>
                                </div>
                                <div className="col-1">
                                    <img className="size-img" src={samp7} onClick={onAddImage7}></img>
                                </div>
                                <div className="col-1">
                                    <img className="size-img" src={samp8} onClick={onAddImage8}></img>
                                </div>
                            </div>
                        </>
                    )}
                    {checked5 &&
                        <>
                            <CanvasComponent canvasHeight={canvasHeight} canvasWidth={canvasWidth}/>
                        </>

                    }
                </div>
            </div>

        </div>
    );
}

export default Canvas;
