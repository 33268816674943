import React, { useState, useRef, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ButtonBase } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import TextFieldsIcon from '@mui/icons-material/TextFields';


import { fabric } from 'fabric';
// this component takes one prop, data, which is an object with data.sizePixels, an array that represents the width,height of the "backgroundObject"

const CanvasComponent = ({ canvasHeight, canvasWidth }) => {


  // const [canvasHeight, setCanvasHeight] = useState(0)
  // const [canvasWidth, setCanvaswidth] = useState(0)

  const [canvas, setCanvas] = useState('');
  const [canvas2, setCanvas2] = useState('');
  const [hasRender, setRender] = useState(false);

  const ref = React.createRef()

  useEffect(() => {
    // setCanvas(initCanvas());
    setCanvas(new fabric.Canvas('canvas', {
      height: canvasHeight? canvasHeight : 500 ,
      width: canvasWidth? canvasWidth : 500 ,
      backgroundColor: 'white',
            

    }))
    
  }, []);



  const zoomCanvas = () => {
    var zoomLevel = 2;
    var objectLeft = 250;
    var objectTop  = 150;

    var newLeft = (-objectLeft * zoomLevel) + canvas.width  / 2;
    var newTop  = (-objectTop  * zoomLevel) + canvas.height / 2;

    canvas.setViewportTransform([zoomLevel, 0, 0, zoomLevel, newLeft, newTop]);
    canvas.renderAll();
  }


  // const initCanvas = () => (
    
  //   new fabric.Canvas('canvas', {
  //     height: canvasHeight? canvasHeight : 500 ,
  //     width: canvasWidth? canvasWidth : 500 ,
  //     backgroundColor: 'white',
            

  //   })
  // )

    

  
  if(canvasWidth != 0) {

    canvas.setWidth(canvasWidth);
    canvas.renderAll();
    

   

 }

 useEffect(() => { 

  setCanvas2(
    new fabric.Canvas('canvas2', {
      height: canvasHeight? canvasHeight : 500 ,
        width: canvasWidth? canvasWidth : 500 ,
      backgroundColor: 'white',
    })
    )

 },[hasRender])

 if(canvasHeight != 0) {

    canvas.setHeight(canvasHeight);
    canvas.renderAll();
 }


 const addCanvas = () => {

  setRender(true)



 }

//  const discardCanvas = () => {

//   setRender(false)

//  }

//  const gettingCanvasWidth = (e) => {

//     console.log(e.target.value);
//     setCanvaswidth(e.target.value)
//  }

//  const gettingCanvasHeight = (e) => {

//     console.log(e.target.value);
//     setCanvasHeight(e.target.value)
//  }


const addtext = () => { 
  const txt = (new fabric.IText("hello", {
    left: 50,
    top: 100,
    fontFamily: 'Ariel',
    fill: 'black',
    fontSize: '32',
}));


  canvas.add(txt);
  canvas.renderAll();
}



  return (
    
    <div className="zoom" ref={ref}>
      <div className='toolbar'>
      {/* <ButtonBase onClick={handleClick}>
                  <Tooltip TransitionComponent={Zoom} title="Image Upload" placement="top">
                    <UploadFileIcon className="icon" />
                    </Tooltip>
                  </ButtonBase>
                  <input id="file-input" type="file" multiple onChange={addBackground} ref={hiddenFileInput} style={{ display: 'none' }} /> */}
                <ButtonBase onClick={addtext}>
                  <Tooltip TransitionComponent={Zoom} title="Add Text" placement="top">
                    <TextFieldsIcon className="icon" />
                  </Tooltip>
                </ButtonBase>
                {/* <ButtonBase onClick={handleClickTwo}>
                <Tooltip TransitionComponent={Zoom} title="Color" placement="top">
                  <FormatColorTextIcon className="icon" />
                  </Tooltip>
                </ButtonBase>
                <input id="color-input" type="color" onChange={handleColorChange} ref={hiddenColorInput} style={{ visibility: 'block' }} /> */}
                {/* <ButtonBase onClick={alignLeft}>
                  <Tooltip TransitionComponent={Zoom} title="Align left" placement="top">
                    <FormatAlignLeftIcon className="icon" />
                  </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={alignCenter}>
                <Tooltip TransitionComponent={Zoom} title="Align center" placement="top">
                  <FormatAlignCenterIcon className="icon" />
                  </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={alignRight}>
                <Tooltip TransitionComponent={Zoom} title="Align right" placement="top">
                  <FormatAlignRightIcon className="icon" />
                </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={handleBold}>
                <Tooltip TransitionComponent={Zoom} title="Bold" placement="top">
                  <FormatBoldIcon className="icon" />
                </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={handleItalic}>
                <Tooltip TransitionComponent={Zoom} title="Italic" placement="top">
                  <FormatItalic className="icon" />
                  </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={handleUnderline}>
                <Tooltip TransitionComponent={Zoom} title="Underline" placement="top">

                  <FormatUnderlinedIcon className="icon" />
                </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={removeObjectFromCanvas}>
                <Tooltip TransitionComponent={Zoom} title="Remove selection" placement="top">
                  <ClearIcon className="icon" />
                </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={downloadImage}>
                <Tooltip TransitionComponent={Zoom} title="Export" placement="top">
                  <FileDownloadIcon className="icon" />
                  </Tooltip>
                </ButtonBase>
                <ButtonBase onClick={clearAll}>
                <Tooltip TransitionComponent={Zoom} title="Clear" placement="top">
                  <DeleteIcon className="icon" />
                </Tooltip>
                </ButtonBase> */}
      </div>
      <div className="mb-5 d-flex justify-content-center flex-column gap-3"> 
        <div className="text-center">
          {/* <ButtonBase>
            <AddIcon onClick={addCanvas} />
          </ButtonBase> */}
        </div> 
        <canvas id="canvas" className='can' />
        {/* {hasRender &&
        <>
          <div className="text-center">
        </div>  
        <canvas id="canvas2"  className='can' />
        </>
        } */}
      </div>
        {/* <button onClick={zoomCanvas}>zoom</button> */}
    </div>
  );
};

export default CanvasComponent;