import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useNavigate } from "react-router-dom";
import ProfileGenerator from "../components/trans-generation/ProfileGenerator"
import Config from "../config/Config";
import { LoadingMarkup } from "./SubtitleLocalization";


const TransGeneration = (props) => {
    const navigate = useNavigate();
    const [descTxtWrap, setDescTxtWrap] = useState(false)
    const [resetForm, setResetForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [translatedTxtData, setTranslatedTxtData] = useState({
        firstPersonTemp: "",
        tamilTempFirstPerson: "",
        thirdPersonTemp: "",
        tamilTempThirdPerson: "",
    })
    const [transGenData, setTransGenData] = useState({
        profileName: "",
        university: "",
        graduate: "",
        experience: "",
        compName: "",
        compPosition: "",
        techExpert: "",
        skills: "",
        hobby: ""
    })

    const [transGenValidation, setTransGenValidation] = useState({
        profileName: false,
        university: false,
        graduate: false,
        experience: false,
        compName: false,
        compPosition: false,
        techExpert: false,
        skills: false,
        hobby: false
    })

    const [open, setOpen] = React.useState({
        firstPerson: false,
        thirdPerson: false,
        tamilFirstPerson: false,
        tamilThirdPerson: false
    });


    const inputHandler = (e) => {
        var { name, value } = e.target;
        setTransGenData({
          ...transGenData,
          [name]: value,
        });
        if(value === ""){
            setTransGenValidation({
                ...transGenValidation,
                [name]: true 
            })
        }else{
            setTransGenValidation({
                ...transGenValidation,
                [name]: false 
            })
        }
    };

    const handleResetForm = () => {
        setTransGenData({
            profileName: "",
            university: "",
            graduate: "",
            experience: "",
            compName: "",
            compPosition: "",
            techExpert: "",
            skills: "",
            hobby: ""
        });
        setDescTxtWrap(false)
        setResetForm(false)
    }

    const transGenFieldValidation = () => {
        if(transGenData.profileName === "" && transGenData.university === "" && transGenData.graduate === "" && transGenData.experience === "" && transGenData.compName === "" && transGenData.compPosition === "" && transGenData.techExpert === "" && transGenData.skills === "" && transGenData.hobby === ""){
            setTransGenValidation({
                profileName: true,
                university: true,
                graduate: true,
                experience: true,
                compName: true,
                compPosition: true,
                techExpert: true,
                skills: true,
                hobby: true
            })
            return false
        }if(transGenData.profileName === ""){
            setTransGenValidation({
                ...transGenValidation,
                profileName: true
            })
            return false
        }if(transGenData.university === ""){
            setTransGenValidation({
                ...transGenValidation,
                university: true
            })
            return false
        }if(transGenData.graduate === ""){
            setTransGenValidation({
                ...transGenValidation,
                graduate: true
            })
            return false
        }if(transGenData.experience === ""){
            setTransGenValidation({
                ...transGenValidation,
                experience: true
            })
            return false
        }if(transGenData.compName === ""){
            setTransGenValidation({
                ...transGenValidation,
                compName: true
            })
            return false
        }if(transGenData.compPosition === ""){
            setTransGenValidation({
                ...transGenValidation,
                compPosition: true
            })
            return false
        }if(transGenData.techExpert === ""){
            setTransGenValidation({
                ...transGenValidation,
                techExpert: true
            })
            return false
        }if(transGenData.skills === ""){
            setTransGenValidation({
                ...transGenValidation,
                skills: true
            })
            return false
        }if(transGenData.hobby === ""){
            setTransGenValidation({
                ...transGenValidation,
                hobby: true
            })
            return false
        }
        return true
    }

    const handleFormData = (formdata) => {
        formdata.append("name", transGenData.profileName);
        formdata.append("university", transGenData.university);
        formdata.append("graduate", transGenData.graduate);
        formdata.append("experience", transGenData.experience);
        formdata.append("technology_expert", transGenData.techExpert);
        formdata.append("company_name", transGenData.compName);
        formdata.append("current_position", transGenData.compPosition);
        formdata.append("hobby", transGenData.hobby);
        formdata.append("skills", transGenData.skills);
        return formdata;
    };

    // fetch the Translated text
    const fetchProfileTranslatedText = async() => {
        setLoading(true)
        var requestOptions = {
        method: 'POST',
        body: handleFormData(new FormData()),
        redirect: 'follow'
        };

        let data = await fetch( Config.LABS_API + "/trans_generator", requestOptions)
        if(data.status === 200){
            let response = await data.json()
            setTranslatedTxtData({
                firstPersonTemp: response.first_person_template,
                tamilTempFirstPerson: response.tamil_template_first_person,
                thirdPersonTemp: response.third_person_template,
                tamilTempThirdPerson: response.tamil_template_second_person,
            })
            setResetForm(true)
            setDescTxtWrap(true)
            setLoading(false)
        }
    }
    

    return (
        <React.Fragment>
            <section className="sml-section-wrapper">
                <div className="container">
                    <div className="sml-wrap trans-gen-wrap">
                        <h1>
                            <div onClick={() => navigate("/")} className="back-icon">
                                <ArrowBackIosNewIcon className="arrow-icon"/>
                            </div>
                            Trans-Generation
                        </h1>
                        <ProfileGenerator 
                            descTxtWrap={descTxtWrap}
                            inputHandler={inputHandler}
                            fetchProfileTranslatedText={fetchProfileTranslatedText}
                            translatedTxtData={translatedTxtData}
                            open={open}
                            setOpen={setOpen}
                            transGenValidation={transGenValidation}
                            transGenFieldValidation={transGenFieldValidation}
                            resetForm={resetForm}
                            handleResetForm={handleResetForm}
                            transGenData={transGenData}
                        />
                    </div>
                </div>
            </section>
            {
                loading &&
                <LoadingMarkup />
            }
        </React.Fragment>
    )
}

export default TransGeneration;