import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useNavigate } from "react-router-dom";
import NerKeywordArea from "../components/ner-keywords/NerKeywordArea";
import { AnalysedOptions } from "../components/ner-keywords/AnalysedOptions";
import Config from "../config/Config";
import Rodal from "rodal";
import AlertModal from "../components/social-media-localization/modal/AlertModal";

const NERKeyword = (props) => {
    const navigate = useNavigate();
    const [analysedValue, setAnalysedValue] = useState("")
    const [analysedOptionData, setAnalysedOptionData] = useState([])
    const [analysedTab, setAnalysedTab] = useState(1)
    const [namedEntitiesList, setNamedEntitiesList] = useState(null)
    const [keywordsList, setKeywordsList] = useState(null)
    const [noNamedEntities, setNoNamedEntities] = useState(false)
    const [noKeywords, setNoKeywords] = useState(false)
    const [NERTrigger, setNERTrigger] = useState(false)
    const [keywordTrigger, setKeywordTrigger] = useState(false)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const analysedValueRef = useRef(null)


    const handleCloseALertModal = () => {
        setShowAlertModal(false)
    }

    const modaloption = {
        closeMaskOnClick: false,
        width: "auto",
        onClose: handleCloseALertModal,
    };

    // handle analyse button click
    const handleAnalyse = () => {
        switch(analysedTab){
            case 1:
                getNamedEntityValues()
                break;
            case 2:
                getKeywordsValue()
                break;
        }
    } 
    
    useEffect(() => {
        removeMarkTag()
        setNamedEntitiesList(null)
        setKeywordsList(null)
    }, [analysedTab])

    
    // highlight named entities 
    useEffect(() => {
        if(namedEntitiesList !== "no_named_entity"){
            namedEntitiesList?.forEach((eachEntity) => {
                const searchText = eachEntity[0]
                const regex = new RegExp(searchText, 'gi');
                let text = analysedValueRef.current.innerHTML;
                // text = text.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
                const newText = text.replace(regex, '<mark class="highlight">$&</mark>');
                analysedValueRef.current.innerHTML = newText;
            })
        }else{
            setNoNamedEntities(true)
            setShowAlertModal(true)
        }
    }, [namedEntitiesList, NERTrigger])

    // highlight keywords 
    useEffect(() => {
        if(keywordsList !== "no keyword"){
            keywordsList?.forEach((keyword) => {
                const searchText = keyword
                const regex = new RegExp(searchText, 'gi');
                let text = analysedValueRef.current.innerHTML;
                // text = text.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
                const newText = text.replace(regex, '<mark class="highlight">$&</mark>');
                analysedValueRef.current.innerHTML = newText;
            })
        }else{
            setNoKeywords(true)
            setShowAlertModal(true)
        }
    }, [keywordsList, keywordTrigger])    

    /* Remove the specified :tags in the :text */
    const removeSpecificTag = (text, tags = []) => {
        if (typeof tags == "string") tags = [tags];
        let regExp = "";
        tags.map((tag) => {
            regExp = new RegExp("<" + tag + "[^>]*>", "g");
            if (text != null) text = text.replace(regExp, "");
            regExp = new RegExp("</" + tag + ">", "g");
            if (text != null) text = text.replace(regExp, "");
        });
        return text;
    };

    // remove all mark tags from contenteditable
    const removeMarkTag = () => {
        analysedValueRef.current.innerHTML = removeSpecificTag(
            analysedValueRef.current.innerHTML,
            "mark"
        );
    } 

    const getNamedEntityValues = async() => {
        var formdata = new FormData();
        formdata.append("text", analysedValue);

        var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
        };

        let data = await fetch( Config.LABS_API + "/named_entity_recognition", requestOptions)
        if(data.status === 200){
            let response = await data.json()
            setNamedEntitiesList(response.named_entity)
            setNERTrigger(!NERTrigger)
        }
    }

    const getKeywordsValue = async() => {
        var formdata = new FormData();
        formdata.append("text", analysedValue);

        var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
        };

        let data = await fetch( Config.LABS_API + "/keyword_extraction_model", requestOptions)
        if(data.status === 200){
            let response = await data.json()
            setKeywordsList(response.keywords)
            setKeywordTrigger(!keywordTrigger)
        }
    } 

    // download the named entities as text file
    const downloadNamedEntities = () => {
        const element = document.createElement("a");
        let temp = ""
        namedEntitiesList.map((each) => temp += each[0]+"\n")
        const file = new Blob([temp], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `named-entities.txt`
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    // download the kewords as text file
    const downloadKeywords = () => {
        const element = document.createElement("a");
        let temp = ""
        keywordsList.map((each) => temp += each+"\n")
        const file = new Blob([temp], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `keywords.txt`
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    } 



    return (
        <React.Fragment>
            <section className="sml-section-wrapper">
                <div className="container">
                    <div className="sml-wrap">
                        <h1>
                            <div onClick={() => navigate("/")} className="back-icon">
                                <ArrowBackIosNewIcon className="arrow-icon"/>
                            </div>
                            NER Keywords
                        </h1>
                        <NerKeywordArea
                            analysedValue={analysedValue}
                            analysedValueRef={analysedValueRef}
                            setAnalysedValue={setAnalysedValue}
                            AnalysedOptions={AnalysedOptions}
                            analysedOptionData={analysedOptionData}
                            setAnalysedOptionData={setAnalysedOptionData}
                            getNamedEntityValues={getNamedEntityValues}
                            removeMarkTag={removeMarkTag}
                            analysedTab={analysedTab}
                            setAnalysedTab={setAnalysedTab}
                            handleAnalyse={handleAnalyse}
                            namedEntitiesList={namedEntitiesList}
                            keywordsList={keywordsList}
                            downloadNamedEntities={downloadNamedEntities}
                            downloadKeywords={downloadKeywords}
                        />
                    </div>
                </div>
            </section>
            <Rodal visible={showAlertModal} 
                {...modaloption} 
                showCloseButton={false} 
                noNamedEntities={noNamedEntities} setNoNamedEntities={setNoNamedEntities} noKeywords={noKeywords} setNoKeywords={setNoKeywords} className="ai-mark-confirm-box">
                <AlertModal setShowAlertModal={setShowAlertModal} showAlertModal={showAlertModal} noNamedEntities={noNamedEntities} setNoNamedEntities={setNoNamedEntities} noKeywords={noKeywords} setNoKeywords={setNoKeywords} />
            </Rodal>
        </React.Fragment>
    )
}

export default NERKeyword;