import React, { useEffect, useState, useRef } from "react";
import "./assets/styles/style.scss";
import "./assets/styles/responsive.scss";
import LabRoutes from "./routes/LabRoutes";
import Header from "./layouts/Header";
import Login from "./authentication/Login";
import Config from "./config/Config";
import CanvasHeader from "./layouts/CanvasHeader";
import { ToastContainer } from "react-toastify";



function App() {

  const [navbar, setNavbar ] = useState(false)
  const [pop, setPop] = useState(false)
  const [switchWorkspace, setSwitchWorkspace] = useState(false)
  const [postEditAccess, setPostEditAccess] = useState(false)
  const [lastUpdated, setLastUpdated] = useState(false)


  const [ailaysaWorkspace , setAilaysaWorkspace] = useState(false)
  const [download , setDownload] = useState(false)
  const [fileFormate , setFileFormate] = useState('JPG')
  const [fileSize , setFileSize] = useState(1)
  const [currentCanvasWidth , setCurrentCanvasWidth] = useState(600)
  const [currentCanvasHeight , setCurrentCanvasHeight] = useState(600)



  const [downloadProject , setDownloadProject] = useState(false)
  const [downloadLangwise , setDownloadLangwise] = useState(false)

  const [name , setName] = useState('Document Name')
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opens = Boolean(anchorEl);
  const ids = opens ? 'simple-popover' : undefined;
  
  const handleCloseAndOpen = (event) => {
    if (anchorEl == null) {
        setAnchorEl(event.currentTarget);
    }
    else {
        setAnchorEl(null);

    }
}

  const [location, setLocation] = useState(true)

useEffect(() => {
  setLocation(window.location.pathname?.includes('elearning') ? false : true)
},[window.location.pathname?.includes('elearning')])

  return (
    <React.Fragment>
      {Config.userState ? 
        (
          <React.Fragment>
            {navbar === true? (
              <CanvasHeader lastUpdated={lastUpdated} setFileSize={setFileSize} fileSize={fileSize} fileFormate={fileFormate} setFileFormate={setFileFormate} setDownloadLangwise={setDownloadLangwise} setDownloadProject={setDownloadProject} postEditAccess={postEditAccess} handleCloseAndOpen={handleCloseAndOpen} anchorEl={anchorEl} setAnchorEl={setAnchorEl} setPop={setPop} download={download} name={name} setName={setName} setDownload={setDownload} setNavbar={setNavbar} setSwitchWorkspace={setSwitchWorkspace} switchWorkspace={switchWorkspace} ailaysaWorkspace={ailaysaWorkspace} />
            ):(
              <>
             {/* {location &&  <Header />} */}
             </>
            )}
            <LabRoutes setLastUpdated={setLastUpdated} lastUpdated={lastUpdated} fileFormate={fileFormate} fileSize={fileSize} setDownloadLangwise={setDownloadLangwise} setDownloadProject={setDownloadProject} downloadLangwise={downloadLangwise} downloadProject={downloadProject} handleCloseAndOpen={handleCloseAndOpen} setPostEditAccess={setPostEditAccess} anchorEl={anchorEl} opens={opens} ids={ids} setNavbar={setNavbar} name={name} setName={setName} pop={pop} setDownload={setDownload} download={download} setPop={setPop} switchWorkspace={switchWorkspace} setSwitchWorkspace={setSwitchWorkspace} setAilaysaWorkspace={setAilaysaWorkspace} />
          </React.Fragment>
        )
      : 
        (<Login />)
      }
          <ToastContainer
          hideProgressBar={true}
          limit={1}
          position='top-center'
          autoClose={2000}
        />
    </React.Fragment>

  );
}

export default App;
