import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';



export default function BottomDrawer(props) {

    const {
        toggleDrawer,
        state,
        setState,
        jsx,
        customClass,
        height
    } = props


  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 , height: height != null ? height : 'auto'}}
      role="presentation"
      // onClick={() => toggleDrawer(anchor, false)}
      // onKeyDown={() => toggleDrawer(anchor, false)}
    >
      <div className='drawer-closer' onClick={() => toggleDrawer(anchor, false)}><span></span></div>
        {jsx}
    </Box>
  );

  return (
    <div>
      {(['left', 'right', 'top', 'bottom']).map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            className={customClass}
            onClose={() => toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}