import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import SocialPostCanvas from "../components/social-post-localisation/SocialPostCanvas";
import { useNavigate } from "react-router-dom";
import ConvertWorkSpaceForm from "../components/convert-workspace/ConvertWorkSpaceForm";
import { languages, Tlanguageses} from "../assets/languages/GetLanguages";
import Config from "../config/Config";
import FileSaver from "file-saver";

export const LoadingMarkup = ({percentage}) => {
    return(
        <div className="py-4 text-center">
            <section className="animation-logo display-flex flex-column">
                <div className="svg-logo"></div>
                <span className="percentage mt-3" style={{ color: "#0078D4", fontWeight: "bold" }}>{percentage}</span>
            </section>
        </div>
    )
};

const Convert = (props) => {
    const navigate = useNavigate();
    const [targetLanguageOptions, setTargetLanguageOptions] = useState(0);
    const [loading, setLoading] = useState(false)
    const [switchbutton, setSwitchButton] = useState(false);
    const [type, setType] = useState('');
    const [pdf, setPdf] = useState(null);
    const [responseId, setResponseId] = useState('');
    const [url, setUrl] = useState(null);
    const [percentage, setPercentage] = useState('');
    const [title, setTitle] = useState()
    // const something = []


    const LABS_API = Config.LABS_API

    console.log(type.value);
    console.log(targetLanguageOptions.language);
    console.log(pdf);
    console.log('Hello'.toLowerCase());

    const handleAuthTrue = (authentication) => {
        let token = Config.userState != null ? Config.userState.token : "";
        authentication.append("Authorization", `Bearer ${token}`)
        return authentication;
      }

      
      useEffect(() => {
        (async function () {
            
          var requestOptions = {
            method: 'GET',
            headers: handleAuthTrue(new Headers()),
            redirect: 'follow'
          };
  
          let data = await fetch(LABS_API + `/exportpdf/convertpdftodocx?get_all=True`, requestOptions)
          if (data.status === 200) {
            let response = await data.json()
            console.log(response);
            setTitle(response.reverse())
           
          }
          else {
            console.log('error');
          }
        })();
      },[])

      // let respon = '' ;
    const handleConvert = () => {

        (async function () {
            var formdata = new FormData();
            formdata.append("pdf_request_file", pdf[0]);
            formdata.append("file_language", targetLanguageOptions.language.toLowerCase());
            formdata.append("format", type.value);
            
            var requestOptions = {
              method: 'POST',
              headers: handleAuthTrue(new Headers()),
              body: formdata,
              redirect: 'follow'
            };
    
            let data = await fetch(LABS_API + "/exportpdf/convertpdftodocx", requestOptions)
            if (data.status === 200) {
              let response = await data.json()
              setResponseId(response.result)  
              setPercentage(response.result.no_of_page_finished)
              

            }
            else {
              console.log('error');
            }
          })();

          


    }

    console.log(percentage);


    // us

    const [yes, setYes] = useState(false)
    const [yes2, setYes2] = useState(false)


    useEffect(() => {

      if(responseId != ''){


                                            (async function () {
                
                                        var requestOptions = {
                                          method: 'GET',
                                          headers: handleAuthTrue(new Headers()),
                                          redirect: 'follow'
                                        };
                                
                                        let data = await fetch(LABS_API + `/exportpdf/convertpdftodocx?pdf_status_id=${responseId}`, requestOptions)
                                        if (data.status === 200) {
                                          let response = await data.json()
                                          console.log(response);
                                          console.log(response.no_of_page_finished);
                                          // setProgress(response.no_of_page_finished)
                                              if(response.context.url === 'SUCCESS'){

                                            }
                                            
                                        }
                                        else {
                                          console.log('error');
                                        }
                                      })();

                                      (async function () {
            
                                        var requestOptions = {
                                          method: 'GET',
                                          headers: handleAuthTrue(new Headers()),
                                          redirect: 'follow'
                                        };
                                
                                        let data = await fetch(LABS_API + `/exportpdf/convertpdftodocx?get_all=True`, requestOptions)
                                        if (data.status === 200) {
                                          let response = await data.json()
                                          console.log(response);
                                          setTitle(response.reverse())
                                          setYes(true)
                                          setYes2(true)
                                          // setResponseId('')
                                        }
                                        else {
                                          console.log('error');
                                        }
                                      })();

                                      setPercentage('')
              setType('')
              setUrl('')
              setTargetLanguageOptions(0)
      }

    },[responseId])
   
    
    // useEffect(() => {

    //     const timerId = setInterval(() => {

    //     if(responseId != ''){

    //   (async function () {
                
    //         var requestOptions = {
    //           method: 'GET',
    //           headers: handleAuthTrue(new Headers()),
    //           redirect: 'follow'
    //         };
    
    //         let data = await fetch(LABS_API + `/exportpdf/convertpdftodocx?get_all=True`, requestOptions)
    //         if (data.status === 200) {
    //           let response = await data.json()
    //           console.log(response);
    //           // setTitle(response)
    //         //   setPercentage(response.no_of_page_finished)
    //         //     // lpercentage =lpercentage + 15
    //         //     // console.log(lpercentage);
                
    //         //   if(response.context.url === 'PENDING'){
    //         //         // setLoading(true);
    //         //   } 
    //         //   if(response.context.url === 'FAILURE'){
    //         //     // setLoading(false);
    //         //     clearInterval(timerId);
    //         //   } 

    //         //   if(response.context.url === 'SUCCESS'){
    //         //     // setLoading(false);
    //         //     setSwitchButton(true);
    //         //     setUrl(response.context.download);
    //         //     setPercentage(100)
    //         //     clearInterval(timerId);
    //         // setTitle((oldArray) => [...oldArray,response.context.download])

    //         // }

    //         }
    //         else {
    //           console.log('error');
    //         }
    //       })();
            
    //     }

    // }, 5000);

       

    // },[responseId])


    const handleDownload = () => {
    
        setPercentage('')
        // setSwitchButton(false)
        setType('')
        setUrl('')
        setResponseId('')
        setTargetLanguageOptions(0)
        // setPercentage(0)
    }



    console.log(title)

 

    return (
        <React.Fragment>
            <section className="convert-section-wrapper">
                <div className="container">
                    <div className="convert-wrap-header">
                        <h1>
                            <div onClick={() => navigate("/")} className="back-icon">
                                <ArrowBackIosNewIcon className="arrow-icon"/>
                            </div>
                            PDF to Docx
                        </h1>
                    </div>
                </div>
                <div className="container">
                    <div className="convert-wrap row">
                        <ConvertWorkSpaceForm 
                            languages={languages}
                            Targetlanguageses={Tlanguageses}
                            targetLanguageOptions={targetLanguageOptions}
                            setTargetLanguageOptions={setTargetLanguageOptions}
                            setSwitchButton={setSwitchButton}
                            switchbutton={switchbutton}
                            setType={setType}
                            type={type}
                            setPdf={setPdf}
                            pdf={pdf}
                            handleConvert={handleConvert}
                            url={url}
                            title={title}
                            setTitle={setTitle}
                            setPercentage={setPercentage}
                            setResponseId={setResponseId}
                            handleDownload={handleDownload}
                            yes={yes}
                            setYes={setYes}
                            yes2={yes2}
                            setYes2={setYes2}
                            responseId={responseId}

                        />
                    </div>
                </div>
                {
                loading &&
                <LoadingMarkup percentage={percentage} />
            }
            </section>
            
        </React.Fragment>
    )
}

export default Convert;