export const languageses = [
        {id: 4, language: 'Arabic', code: 'ar'},
        {id: 108, language: 'Chinese', code: 'cmn-Hans-CN'},
        {id: 17, language: 'English', code: 'en'},
        {id: 39, language: 'Japanese', code: 'ja'},
        {id: 77, language: 'Tamil', code: 'ta'},
    
    ];
    
    export const Targetlanguageses = [
        {id: 4, language: 'Arabic', code: 'ar'},
        {id: 108, language: 'Chinese', code: 'zh-CN'},
        {id: 17, language: 'English', code: 'en'},
        {id: 39, language: 'Japanese', code: 'ja'},
        {id: 77, language: 'Tamil', code: 'ta'},
        
    ];