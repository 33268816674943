import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TransbuilderComponent from '../components/transbuilder/TransbuilderComponent'
import '../assets/styles/transbuilder.css'

const Transbuilder = (props) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <TransbuilderComponent />
       
        </React.Fragment>
   
    )
}

export default Transbuilder;