import React, { useEffect, useState, useRef } from "react";
import FacebookLocalization from "./FacebookLocalization";
import InstagramLocalization from "./InstagramLocalization";
import LinkedinLocalization from "./LinkedinLocalization";
import TwitterLocalization from "./TwitterLocalization";
import { motion, AnimatePresence } from "framer-motion";

const SocialMediaList = (props) => {
  const {
    setSmlLocalizationTab,
    smlLocalizationTab,
    sourceLanguageOptions,
    targetLanguageOptions,
    setTargetLanguageOptions,
    setSourceLanguageOptions,
    SmlTargetlanguageses,
    languages,
    getDataFromTweetUrl,
    tweetUrl,
    setTweetUrl,
    translatedTweetTxt,
    postTweet,
    setTweetSourceTxt,
    tweetSourceTxt,
    getTweetSourceData,
    showAlertModal,
    setShowAlertModal,
    originalTxt,
    setOriginalTxt,
    translatedTxt,
    setTranslatedTxt,
    translatedTxtEdit,
    setTranslatedTxtEdit,
    resultLangTranslatedTxt,
    setResultLangTranslatedTxt,
  } = props;
  return (
    <React.Fragment>
      <div className="sml-link-localization-wrap">
        <ul className="sml-tab-links">
          <li
            className={
              "sml-tab-link-item " +
              (smlLocalizationTab === "twitter" ? "active" : "")
            }
            onClick={() => setSmlLocalizationTab("twitter")}
          >
            <img
              src={window.location.origin + "/media/icons/twitter-logo.svg"}
              alt="social-media"
            />
            Twitter
          </li>
          {/* <li className={"sml-tab-link-item " + (smlLocalizationTab === "instagram" ? "active" : "")} onClick={() => setSmlLocalizationTab("instagram")}>
                        <img src={window.location.origin + "/media/icons/instagram-logo.svg"} alt="social-media"/>
                        Instagram
                    </li>
                    <li className={"sml-tab-link-item " + (smlLocalizationTab === "facebook" ? "active" : "")} onClick={() => setSmlLocalizationTab("facebook")}>
                        <img src={window.location.origin + "/media/icons/facebook-logo.svg"} alt="social-media"/>
                        Facebook
                    </li>
                    <li className={"sml-tab-link-item " + (smlLocalizationTab === "linkedin" ? "active" : "")} onClick={() => setSmlLocalizationTab("linkedin")}>
                        <img src={window.location.origin + "/media/icons/linkedin-logo.svg"} alt="social-media"/>
                        Linkedin
                    </li> */}
        </ul>
        <div className="sml-localization-tab">
          <AnimatePresence exitBeforeEnter>
            {smlLocalizationTab === "twitter" && (
              <TwitterLocalization
                languages={languages}
                SmlTargetlanguageses={SmlTargetlanguageses}
                sourceLanguageOptions={sourceLanguageOptions}
                targetLanguageOptions={targetLanguageOptions}
                setSourceLanguageOptions={setSourceLanguageOptions}
                setTargetLanguageOptions={setTargetLanguageOptions}
                getDataFromTweetUrl={getDataFromTweetUrl}
                tweetUrl={tweetUrl}
                setTweetUrl={setTweetUrl}
                translatedTweetTxt={translatedTweetTxt}
                postTweet={postTweet}
                tweetSourceTxt={tweetSourceTxt}
                setTweetSourceTxt={setTweetSourceTxt}
                getTweetSourceData={getTweetSourceData}
                showAlertModal={showAlertModal}
                setShowAlertModal={setShowAlertModal}
                originalTxt={originalTxt}
                setOriginalTxt={setOriginalTxt}
                translatedTxt={translatedTxt}
                setTranslatedTxt={setTranslatedTxt}
                translatedTxtEdit={translatedTxtEdit}
                setTranslatedTxtEdit={setTranslatedTxtEdit}
                resultLangTranslatedTxt={resultLangTranslatedTxt}
                setResultLangTranslatedTxt={setResultLangTranslatedTxt}
              />
            )}
            {smlLocalizationTab === "instagram" && <InstagramLocalization />}
            {smlLocalizationTab === "facebook" && <FacebookLocalization />}
            {smlLocalizationTab === "linkedin" && <LinkedinLocalization />}
          </AnimatePresence>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SocialMediaList;
