import React, { useEffect, useState, useRef } from "react";
import { ButtonBase } from "@mui/material";
import { Link } from "react-router-dom";
import ailaysaLogo from '../assets/icons/ailaysaLogo.svg';
import back from '../assets/icons/back.svg';
import cloud from '../assets/icons/cloud.svg';
import saveAttention from '../assets/icons/saveAttention.gif';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Rodal from "rodal";
import { useNavigate } from "react-router-dom";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Popover } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Select, { components } from "react-select";
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreIcon from '@mui/icons-material/More';
import Slider from '@mui/material/Slider';

const useStyles = makeStyles(theme => ({
  popover: {
      pointerEvents: 'none',
  },
  popoverContent: {
      pointerEvents: 'auto',
  },
}));




const CanvasHeader = ({lastUpdated,fileSize,fileFormate,setFileSize, setFileFormate,setDownloadLangwise,setDownloadProject,postEditAccess,handleCloseAndOpen,anchorEl,setAnchorEl,name,setName,download,setDownload,setPop,setNavbar,setSwitchWorkspace,switchWorkspace,ailaysaWorkspace}) => {
   
     const options = [
        { value: 'JPEG', label: 'JPEG' },
        { value: 'PNG', label: 'PNG' },
        { value: 'SVG', label: 'SVG' },
        // { value: 'ta', label: 'Tamil' },
        // { value: 'en', label: 'English'}
      ]


      const selectFileFormate = (selected) =>{
        setFileFormate(selected.target)
      }
     
  const classes = useStyles();
    const  BasicMenu =()=> {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };

        const handleCloseAndOpen = (event) => {
          if (anchorEl == null) {
              setAnchorEl(event.currentTarget);
          }
          else {
              setAnchorEl(null);
          }
      }

      const [ si , setSi] = useState()
      const getFiletSize = (newValue) => {

        // let some = newValue
        setSi(newValue.target.value)
      }
      
      useEffect(() => {
        console.log(si);
      },[si])
     

    //   var slide = document.querySelectorAll('.slider-file');
    // // sliderDiv = document.getElementById("sliderAmount");

    //     slide.onchange = function(newValue) {
    //         console.log(newValue) 
    //     }

        return (
          <div>
            <Button
             
              onClick={handleCloseAndOpen}
              className="download-button"
            ><FileDownloadOutlinedIcon className="download-icon"  /> 
               Download
            </Button>
           
              <Popover
                open={open}
                // onClose={handleClose}
                className={classes.popover}
                classes={{
                    paper: classes.popoverContent,
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            // modifiers={[
            //     {
            //         name: 'arrow',
            //         enabled: true,
            //         options: {
            //           element: arrowRef,
            //         },
            //       },
            // ]}

            >
              <Accordion className="">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="download-headings"><MoreIcon className="more1-icon" /><span className="download-heading-names">current image</span></div>
        </AccordionSummary>
        <AccordionDetails>
               <div className="download-list-flex">
                <div className="file-size-div">
                  <label className="heading-list-down-lab">File Type</label>
                  <Select
                                classNamePrefix="select-lang"
                                // styles={customAssignStyles}
                                isSearchable={true}
                                menuPlacement="bottom"
                                options={options}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                hideSelectedOptions={false}
                                value={fileFormate}
                                // defaultValue={options[0].label}
                                // value={targetLanguageOptions}
                                onChange={selectFileFormate}
                                placeholder="JPEG"
                                // components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            />
                </div>
                <div className="file-size-div">
                <label className="heading-list-down-lab">File Size</label>
                <Slider
                  size="small"
                  defaultValue={1}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-label="Small"
                  onChange={getFiletSize}
                  valueLabelDisplay="auto"
                  className= "slider-file"
                />
               
                <div className="innerText">
                {/* <span>{currentCanvasWidth * si} X {currentCanvasHeight * si}</span> */}
                </div>
                </div>
                <ButtonBase className="innter-down">Download</ButtonBase>
               </div>

        </AccordionDetails>
      </Accordion>
              <MenuItem className="download-text" onClick={()=>{
                handleDownload()
                handleClose()
              }}>   Download</MenuItem>
              <MenuItem className="download-text" onClick={()=>{
                handleDownloadLan()
                handleClose()
              }}> language   </MenuItem>
              <MenuItem className="download-text" onClick={()=>{
                handleDownloadPro()
                handleClose()
              }}> project Export </MenuItem>
            </Popover>
          </div>
        );
      }

    // const handleCloseAndOpen = (event) => {
    //     if (anchorEl == null) {
    //         setAnchorEl(event.currentTarget);
    //     }
    //     else {
    //         setAnchorEl(null);

    //     }
    // }


    

    const navigate = useNavigate();
    
    const handleOpen = () => {

        setPop(true)

    }

    const handleDownload = () => {

        setDownload(true)

    }

    const handleDownloadPro = () => {

      setDownloadProject(true)

  }

  const handleDownloadLan = () => {

    setDownloadLangwise(true)

}
    // const contentref=useRef()
    //     const handleNameChange=()=>{
    //     setName(contentref.current.value)
    //     console.log(contentref.current.value)
    //     }  
   
        let start
        const onFocus = (e) => {
            start = e.target.innerHTML;
          };
          const onBlur = (e) => {
            if(start !== e.target.innerHTML) {
              //Whatever you put here will act just like an onChange event
              const html = e.target.innerHTML;
              setName(html);
            }
          };
          console.log(name);
    // console.log(switchWorkspace);



    // const switchWork = () => {

    //     if (switchWorkspace == 'workspace') {
    //         setSwitchWorkspace('workspacePostEditing');
            
    //       } else {
    //         setSwitchWorkspace('workspace')

    //       }
    // }

    // const openWorkspace = () => {
    //     setSwitchWorkspace('workspacePostEditing');
    // }
    // const closeWorkspace = () => {
    //     setSwitchWorkspace('workspace');
    // }
    // const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setSwitchWorkspace(event.target.checked);
    console.log(event.target.checked)
  };

    return (
        <React.Fragment>
            <nav className="lab-canvas-navbar">
                <div className="container-fluid h-100">
                    <div className="main-lab-wrapper">
                        <img src={ailaysaLogo} />
                        <div className="canvas-header-items-main-wrap">
                            <div className="canvas-header-items">
                                <div className="d-flex align-items-center" onClick={() => {navigate("/")
                                setNavbar(false)
                            }}>
                                    <img src={back} />
                                    <a className="canvas-header-text">Home</a>
                                </div>
                                <a className="canvas-header-text">File</a>
                                <a className="canvas-header-text" onClick={handleCloseAndOpen}>Resize</a>
                                
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <div contentEditable="true" onFocus={onFocus} onBlur={onBlur}  className="document-name">{name}</div>
                                <img src={cloud} alt='cloud'/>
                                {/* <img src={saveAttention} style={{ width:"22px", height: "24px"}} /> */}
                                <span className="last-updated">{lastUpdated}</span>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                            {ailaysaWorkspace == true && 
                            <div className="form-check form-switch">
                                {/* <input className="form-check-input" type="checkbox" role="switch"value={switchWorkspace}
                                onChange={switchWork} id="flexSwitchCheckDefault" />
                                <label className="form-check-label post-edit-view--label" for="flexSwitchCheckDefault">Post edit view</label> */}
                                 <FormGroup>
                                    <FormControlLabel control={<Switch onChange={handleChange} />} className="post-edit-view--label" label="Post Editing view " disabled={postEditAccess? false : true} />
                                    </FormGroup>
                                </div>
                                }
                                <BasicMenu/>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>


        </React.Fragment>
    )
}

export default CanvasHeader;