import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import AiWritingArea from "../components/ai-writing/AiWritingArea";
import { getPrompt, getSocialMedia } from "../components/ai-writing/GetPrompt";
import Config from "../config/Config";
import { LoadingMarkup } from "./SubtitleLocalization";
import { useNavigate } from "react-router-dom";

const AiWriting = (props) => {
    const navigate = useNavigate();
    const LABS_API = Config.LABS_API
    const [getPromptOptions, setGetPromptOptions] = useState([getPrompt[0]])
    const [getSocialMediaOptions, setGetSocialMediaOptions] = useState([getSocialMedia[0]])
    const [generatedTxt, setGeneratedTxt] = useState(false);
    const [headlineGeneratedTxt, setHeadlineGeneratedTxt] = useState(false);
    const [resetForm, setResetForm] = useState(false);
    const [resetHeadlineForm, setResetHeadlineForm] = useState(false);
    const [productDescription, setProductDescription] = useState({})
    const [headlineResults, setHeadlineResults] = useState({})
    const [loading, setLoading] = useState(false)
    const [productData, setProductData] = useState({
        productName: "",
        keywords: "",
        desc: "",
    })
    const [open, setOpen] = useState(false);
    const [headlineData, setHeadlineData] = useState({
        productName: "",
        keywords: "",
        desc: "",
    })

    const [headlineFormValidation, setHeadlineFormValidation] = useState({
        productName: false,
        keywords: false,
        desc: false,
    })

    const [formValidation, setFormValidation] = useState({
        productName: false,
        keywords: false,
        desc: false,
    })

    const [selectAiWritingOptions, setSelectAiWritingOptions] = useState([])

    const aiWritingOptions = [
        { id: 1, label: "Product Description", value: "prodDesc"},
        { id: 2, label: "Headline Generator", value: "headGen"},
    ];

    const headlineinputHandler = (e) => {
        var { name, value } = e.target;
        setHeadlineData({
          ...headlineData,
          [name]: value,
        });
        if(value === ""){
            setHeadlineFormValidation({
                ...headlineFormValidation,
                [name]: true 
            })
        }else{
            setHeadlineFormValidation({
                ...headlineFormValidation,
                [name]: false 
            })
        }
    };
    
    
    const inputHandler = (e) => {
        var { name, value } = e.target;
        setProductData({
          ...productData,
          [name]: value,
        });
        if(value === ""){
            setFormValidation({
                ...formValidation,
                [name]: true 
            })
        }else{
            setFormValidation({
                ...formValidation,
                [name]: false 
            })
        }
    };

    const handleResetForm = () => {
        setProductData({
            productName: "",
            keywords: "",
            desc: "",
        })
        setGeneratedTxt(false)
        setResetForm(false)
    }

    const handleHeadlineResetForm = () => {
        setHeadlineData({
            productName: "",
            keywords: "",
            desc: "",
        })
        setHeadlineGeneratedTxt(false)
        setResetHeadlineForm(false)
    }
    
    const handleFormData = (formdata) => {
        formdata.append("product_name", productData.productName);
        formdata.append("keyword", productData.keywords);
        formdata.append("product_description", productData.desc);
        formdata.append("target_language", "en");
        return formdata;
    };

    const handleHeadlineFormData = (formdata) => {
        formdata.append("product_name", headlineData.productName);
        formdata.append("keyword", headlineData.keywords);
        formdata.append("result_headline", headlineData.desc);
        formdata.append("target_language", "en");
        formdata.append("social_media", getSocialMediaOptions.value);
        return formdata;
    };


    // fetch the Headline Generate
    const fetchHeadlineGenerator = async() => {
        setLoading(true)
        var requestOptions = {
        method: 'POST',
        body: handleHeadlineFormData(new FormData()),
        redirect: 'follow'
        };

        let data = await fetch( LABS_API + "/social_media_headline_generator", requestOptions)
        if(data.status === 200){
            let response = await data.json()
            setHeadlineGeneratedTxt(true)
            setResetHeadlineForm(true)
            setHeadlineResults(response.result_headline)
            setLoading(false)
        }
    }
    
    // fetch the product description
    const fetchProductDescription = async() => {
        setLoading(true)
        var requestOptions = {
        method: 'POST',
        body: handleFormData(new FormData()),
        redirect: 'follow'
        };

        let data = await fetch( LABS_API + "/product_description", requestOptions)
        if(data.status === 200){
            let response = await data.json()
            setGeneratedTxt(true)
            setResetForm(true)
            setProductDescription(response["product-description"])
            setLoading(false)
        }
    }

    // download the generated description as text file
    const downloadTxtFile = () => {
        let descriptionBox = document.getElementById('output-description-box')
        const element = document.createElement("a");
        const file = new Blob([descriptionBox.textContent], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = `${productData.productName}.txt`
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }  

    const headlineFieldValidation = () => {
        if(headlineData.productName === "" && headlineData.keywords === "" && headlineData.desc === ""){
            setHeadlineFormValidation({
                productName: true,
                keywords: true,
                desc: true
            })
            return false
        }if(headlineData.productName === ""){
            setHeadlineFormValidation({
                ...headlineFormValidation,
                productName: true
            })
            return false
        }if(headlineData.keywords === ""){
            setHeadlineFormValidation({
                ...headlineFormValidation,
                keywords: true
            })
            return false
        }if(headlineData.desc === ""){
            setHeadlineFormValidation({
                ...headlineFormValidation,
                desc: true
            })
            return false
        }
        return true
    }

    const fieldValidation = () => {
        if(productData.productName === "" && productData.keywords === "" && productData.desc === ""){
            setFormValidation({
                productName: true,
                keywords: true,
                desc: true
            })
            return false
        }if(productData.productName === ""){
            setFormValidation({
                ...formValidation,
                productName: true
            })
            return false
        }if(productData.keywords === ""){
            setFormValidation({
                ...formValidation,
                keywords: true
            })
            return false
        }if(productData.desc === ""){
            setFormValidation({
                ...formValidation,
                desc: true
            })
            return false
        }
        return true
    }
    


    return (
        <React.Fragment>
            <section className="sublocal-section-wrapper">
                <div className="container">
                    <div className="sublocal-wrap">
                        <h1>
                            <div onClick={() => navigate("/")} className="back-icon">
                                <ArrowBackIosNewIcon className="arrow-icon"/>
                            </div>
                            AI Writing
                        </h1>
                        {/* <p>This is a graphical interface to easily try all our models without writing a single line of code. For more information about the API, please see the documentation, and if you're new please first register and get a free API token.</p> */}
                        <AiWritingArea 
                            getPromptOptions={getPromptOptions}
                            setGetPromptOptions={setGetPromptOptions}
                            generatedTxt={generatedTxt}
                            setGeneratedTxt={setGeneratedTxt}
                            inputHandler={inputHandler}
                            fetchProductDescription={fetchProductDescription}
                            productDescription={productDescription}
                            downloadTxtFile={downloadTxtFile}
                            fieldValidation={fieldValidation}
                            formValidation={formValidation}
                            productData={productData}
                            resetForm={resetForm}
                            resetHeadlineForm={resetHeadlineForm}
                            handleResetForm={handleResetForm}
                            aiWritingOptions={aiWritingOptions}
                            selectAiWritingOptions={selectAiWritingOptions}
                            setSelectAiWritingOptions={setSelectAiWritingOptions}
                            getSocialMediaOptions={getSocialMediaOptions}
                            setGetSocialMediaOptions={setGetSocialMediaOptions}
                            headlineData={headlineData}
                            headlineinputHandler={headlineinputHandler}
                            fetchHeadlineGenerator={fetchHeadlineGenerator}
                            headlineFieldValidation={headlineFieldValidation}
                            headlineFormValidation={headlineFormValidation}
                            headlineGeneratedTxt={headlineGeneratedTxt}
                            headlineResults={headlineResults}
                            handleHeadlineResetForm={handleHeadlineResetForm}
                            open={open}
                            setOpen={setOpen}
                        />
                    </div>
                </div>
            </section>
            {
                loading &&
                <LoadingMarkup />
            }
        </React.Fragment>
    )
}

export default AiWriting;