import { SketchPicker ,CompactPicker, TwitterPicker } from 'react-color'
import Draggable, { DraggableCore } from 'react-draggable';
import React, { useEffect, useLayoutEffect, useState, useRef ,useCallback} from "react";
import { Popover } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import useEyeDropper from 'use-eye-dropper'
import ColorizeIcon from '@mui/icons-material/Colorize';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { ReactComponent as Bordersvg } from '../../../assets/elements/border.svg'
import { ReactComponent as Borderinnersvg } from '../../../assets/elements/borderinner.svg'
import { ReactComponent as Borderouttersvg } from '../../../assets/elements/borderouter.svg'

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));



const ElementsProperties =({ editor,fabric}) =>{

const classes = useStyles();

//draggable
const [state, setState] = useState({
    activeDrags: 0,
    deltaPosition: {
        x: 0, y: 0
    },
    controlledPosition: {
        x: -400, y: 200
    }
});

const onStart = () => {
    const { activeDrags } = state;
    setState({ ...state, activeDrags: activeDrags + 1 });
};
const onStop = () => {
    const { activeDrags } = state;
    setState({ ...state, activeDrags: activeDrags - 1 });
};

const dragHandlers = { onStart, onStop };
//Elements StrokeStyle

const StrokeStyle = ({ dragHandlers ,editor,fabric}) => {

    const nonstroke = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().set({ stroke: null })
                editor.canvas.getActiveObject().set({ strokeWidth: null })
                editor.canvas.renderAll()

            }
        }
    }

    const stroke = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                if (!editor.canvas.getActiveObject()?.stroke) {
                    editor.canvas.getActiveObject().set({ stroke: '#111' })
                }
                if (!editor.canvas.getActiveObject()?.strokeWidth) {
                    editor.canvas.getActiveObject().set({ strokeWidth: 3 })
                }
                editor.canvas.getActiveObject().set({ strokeDashArray: '' })

                editor.canvas.renderAll()

            }
        }
    }

    const strokedash = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                if (!editor.canvas.getActiveObject()?.stroke) {
                    editor.canvas.getActiveObject().set({ stroke: '#111' })
                }
                if (!editor.canvas.getActiveObject()?.strokeWidth) {
                    editor.canvas.getActiveObject().set({ strokeWidth: 3 })
                }
                editor.canvas.getActiveObject().set({ strokeDashArray: [5, 10] })
                editor.canvas.renderAll()

            }
        }
    }
    const strokesmalldash = () => {
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                if (!editor.canvas.getActiveObject()?.stroke) {
                    editor.canvas.getActiveObject().set({ stroke: '#111' })
                }
                if (!editor.canvas.getActiveObject()?.strokeWidth) {
                    editor.canvas.getActiveObject().set({ strokeWidth: 3 })
                }
                editor.canvas.getActiveObject().set({ strokeDashArray: [5, 20, 20, 5] })
                editor.canvas.renderAll()

            }
        }
    }

    // const ChangeBordercolor = ({ dragHandlers }) => {

        const [bordercolor, setBordercolor] = useState(editor?.canvas.getActiveObject()?.stroke ? editor.canvas.getActiveObject().stroke : "#111")
        // useEffect(()=>{
        //  if(editor){
        //         if(editor.canvas.getActiveObject()){
        //             setColor(editor.canvas.getActiveObject().fill)
        //             editor.canvas.renderAll()
        //     }
        //  }
        // },[editor])

        const onchangestrokecolor = () => {
            if (editor) {
                if (editor.canvas.getActiveObject()) {
                    editor.canvas.getActiveObject().set({ stroke: bordercolor })
                    editor.canvas.renderAll()
                }
            }
        }
        const temp = useRef(true)
        useEffect(() => {
            if (temp.current) {
                temp.current = false
                return
            }
            if (editor) {
                if (editor.canvas.getActiveObject()) {

                    try {
                        editor.canvas.getActiveObject().set({ stroke: bordercolor })
                        editor.canvas.renderAll()
                        // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                        let activeObjects = editor.canvas.getActiveObjects();
                        if (activeObjects.length) {
                            activeObjects.forEach(function (object) {
                                object.set({ stroke: bordercolor })
                                editor.canvas.renderAll()
                            });
                        }
                    }
                    catch {
                        console.log('color not change');
                    }
                }
                else {
                    try {

                        editor.canvas.getActiveObject().set(({ stroke: bordercolor }))
                        editor.canvas.renderAll()
                    }
                    catch {
                        console.log('color not change');
                    }
                }

            }
        }, [bordercolor])


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [objectstrokewidth, setObjectstrokewidth] = useState(100)
    const strokewidthinputer = useRef(null)

    useEffect(() => {

        if (editor) {

            if (editor.canvas.getActiveObjects()) {
                let temp = 0
                editor.canvas.getActiveObjects().forEach((object) => {
                    if (object.strokeWidth > temp) {
                        // console.log('object',object.opacity);
                        temp = object.strokeWidth
                        // console.log('temp',temp);
                    }
                })
                // console.log('final temp',temp);
                setObjectstrokewidth(temp * 2)
                editor.canvas.renderAll()

            }
        }
    }, [editor])


    // if(editor){
    //     if(editor.canvas.getActiveObject()){
    //         console.log(editor.canvas.getActiveObject().opacity);
    //         // setOpacity(editor.canvas.getActiveObject().opacity)
    //         editor.canvas.renderAll()
    //     }
    // }


    const changestrokewidthvalue = () => {
        setObjectstrokewidth(strokewidthinputer.current.value)
    }


    const strokewidthtemp = useRef(true)
    useEffect(() => {
        if (strokewidthtemp.current) {
            strokewidthtemp.current = false
            return;
        }
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                // editor.canvas.getActiveObject().set({opacity : opacity/100})
                // editor.canvas.renderAll()

                try {
                    editor.canvas.getActiveObject().set({ strokeWidth: objectstrokewidth / 2 })
                    editor.canvas.renderAll()
                    // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                    let activeObjects = editor.canvas.getActiveObjects();
                    if (activeObjects.length) {
                        activeObjects.forEach(function (object) {
                            object.set({ strokeWidth: objectstrokewidth / 2 })
                            editor.canvas.renderAll()
                        });
                    }
                }
                catch {
                }
            }
        }
    })



    const [objectradius, setObjectradius] = useState(0)
    const objectradiusinputer = useRef(null)





    useEffect(() => {

        if (editor) {

            if (editor.canvas.getActiveObjects()) {
                let temp = 0
                if (editor.canvas.getActiveObject().type != 'ellipse') {
                    editor.canvas.getActiveObjects().forEach((object) => {
                        if (object.rx > temp) {
                            // console.log('object',object.opacity);
                            temp = object.rx
                            // console.log('temp',temp);
                        }
                    })
                }
                // console.log('final temp',temp);
                setObjectradius(temp * 2)
                editor.canvas.renderAll()

            }
        }
    }, [editor])


    // if(editor){
    //     if(editor.canvas.getActiveObject()){
    //         console.log(editor.canvas.getActiveObject().opacity);
    //         // setOpacity(editor.canvas.getActiveObject().opacity)
    //         editor.canvas.renderAll()
    //     }
    // }


    const changobjectradiusvalue = () => {
        setObjectradius(objectradiusinputer.current.value)
    }


    const objectradiustemp = useRef(true)
    useEffect(() => {
        if (objectradiustemp.current) {
            objectradiustemp.current = false
            return;
        }
        if (editor) {
            if (editor.canvas.getActiveObject()) {
                // editor.canvas.getActiveObject().set({opacity : opacity/100})
                // editor.canvas.renderAll()

                try {
                    if (editor) {
                        if (editor.canvas.getActiveObject().type != 'ellipse') {
                            editor.canvas.getActiveObject().set({ rx: objectradius / 2, ry: objectradius / 2 })
                            editor.canvas.renderAll()
                            // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                            let activeObjects = editor.canvas.getActiveObjects();
                            if (activeObjects.length) {
                                activeObjects.forEach(function (object) {
                                    object.set({ rx: objectradius / 2, ry: objectradius / 2 })
                                    editor.canvas.renderAll()
                                });
                            }
                        }
                    }
                }
                catch {
                }
            }
        }
    })

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [bordercolorOpen, setBordercolorOpen] = useState(false)

    const handleClickOp = () => {
        setBordercolorOpen(!bordercolorOpen)

    }

    const Eye = () => {
        const { open, close } = useEyeDropper()
        const [eyecolor, setEyecolor] = useState(bordercolor)
        const [eyeerror, setEyeerror] = useState()
        // useEyeDropper will reject/cleanup the open() promise on unmount,
        // so setState never fires when the component is unmounted.
        const pickColor = () => {
          open()
            .then(eyecolor => setEyecolor(eyecolor.sRGBHex))
            .catch(e => {
              console.log(e)
              // Ensures component is still mounted
              // before calling setState
              if (!e.canceled) setEyeerror(e)
            })
        }
        setBordercolor(eyecolor)
        return (
          <>
            <button onClick={pickColor} className='eyecplacer border' style={{border:'none',}}><ColorizeIcon/></button>
            {!!eyeerror && <span>{eyeerror.message}</span>}
          </>
        )
      }


    return (
        <div className="borderstyle-container " >
            <LineStyleIcon className={anchorEl ? "borderstyle-button toolbar-icons-active" : "borderstyle-button toolbar-icons"} style={{ visibility: (editor?.canvas.getActiveObject()?.type == 'textbox') || (editor?.canvas.getActiveObject()?.type == 'images') ? 'hidden' : 'visible' }} onClick={handleClick} />
            <Draggable handle="strong" {...dragHandlers}>
                <Popover
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}

                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="borderstyle-div no-cursor">
                        <strong className='cursor d-flex align-items-center  gap-2 mb-3'>
                            <div className="drag-me ">
                                Drag
                            </div>
                            <CloseIcon className="close-icon-pop" onClick={handleClose} />

                        </strong>
                        <div className="bordersize">
                            <label className="form-label text-effect-input-value">Stroke Width</label>
                            <div className="strokewidthcontainor">
                                <input type="range" className="form-range text-effects-slider-range" value={objectstrokewidth} min={0} max={100} ref={strokewidthinputer} onChange={() => {
                                    changestrokewidthvalue();
                                }} id="customRange1" />
                                <span className='spanOpacity'>{objectstrokewidth}</span>
                            </div>
                            <label className="form-label text-effect-input-value" style={{ display: (editor?.canvas.getActiveObject()?.type == 'circle' || editor?.canvas.getActiveObject()?.type == 'ellipse' ? 'none' : 'inline-block') }}>Stroke Radius</label>
                            <div className="strokewidthcontainor" style={{ display: (editor?.canvas.getActiveObject()?.type == 'circle' || editor?.canvas.getActiveObject()?.type == 'ellipse' ? 'none' : 'flex') }}>
                                <input type="range" className="form-range text-effects-slider-range" value={objectradius} min={0} max={100} ref={objectradiusinputer} onChange={() => {
                                    changobjectradiusvalue();
                                }} id="customRange1" />
                                <span className='spanOpacity'>{objectradius}</span>
                            </div>
                        </div>
                        <div class="text-stroke-effects-head-adjust"><span>Stroke</span></div>
                        <div className="borderdefault">
                            <div className="styleborder" onClick={handleClickOp}>
                                <div aria-describedby={id} color="white" size="sm" variant="outline" style={{ background: bordercolor }} className="bordercolor-btn " />
                            </div>
                            

                            <div className="styleborder" onClick={nonstroke}><DoNotDisturbAltIcon className='borderstyle4' /></div>
                            <div className="styleborder" onClick={stroke}><Borderouttersvg className='borderstyle4' /></div>
                            <div className="styleborder" onClick={strokesmalldash}><Bordersvg className='borderstyle4' /></div>
                            <div className="styleborder" onClick={strokedash}><Borderinnersvg className='borderstyle4' /></div>
                        </div>
                        <div className="d-flex justify-content-center  flex-column">
                        {bordercolorOpen && 
                            <>
                            <SketchPicker color={bordercolor} className='bordersketch' onChange={(color) => {
                                setBordercolor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")");
                                onchangestrokecolor();

                            }} />
                            <TwitterPicker className="twitterplacer" color={bordercolor} onChange={(e)=>{
                                setBordercolor("rgba(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")");
                                onchangestrokecolor();
                            }}/>
                            <Eye />
                            </>
                        }
                        </div>
                       
                    </div>
                </Popover>
            </Draggable>
        </div>

    )
}

const SingleElementPathColor = ({ dragHandlers,editor,fabric }) => {

    const [svgcolor, setSvgColor] = useState()


    // const onchangecolor = (e) => {
    //     setSvgColor(e.target.value)
    // }


    useEffect(() => {
        // if(opacitytemp.current){
        //     opacitytemp.current = false
        //     return;
        // }
        if (editor) {
            if ((editor.canvas.getActiveObject().type == 'path') || (editor.canvas.getActiveObject().type == 'rect') || (editor.canvas.getActiveObject().type == 'polygon') || (editor.canvas.getActiveObject().type == 'circle') || (editor.canvas.getActiveObject().type == 'ellipse') || (editor.canvas.getActiveObject().type == 'triangle') || (editor.canvas.getActiveObject().type == 'polyline')) {
                setSvgColor(editor.canvas.getActiveObject().fill)

                editor.canvas.renderAll()

            }

        }

    }, [editor])


    const tempSvgColor = useRef(true)


    useEffect(() => {
        if (tempSvgColor.current) {
            tempSvgColor.current = false
            return
        }
        if (editor) {
            if ((editor.canvas.getActiveObject()?.type == 'path') || (editor.canvas.getActiveObject()?.type == 'rect') || (editor.canvas.getActiveObject()?.type == 'polygon') || (editor.canvas.getActiveObject()?.type == 'circle') || (editor.canvas.getActiveObject()?.type == 'ellipse') || (editor.canvas.getActiveObject()?.type == 'triangle') || (editor.canvas.getActiveObject()?.type == 'polyline')) {
                editor.canvas.getActiveObject().set({
                    fill: svgcolor
                })
                editor.canvas.renderAll();

            }

        }
    }, [svgcolor])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const Eye = () => {
        const { open, close } = useEyeDropper()
        const [eyecolor, setEyecolor] = useState(svgcolor)
        const [eyeerror, setEyeerror] = useState()
        // useEyeDropper will reject/cleanup the open() promise on unmount,
        // so setState never fires when the component is unmounted.
        const pickColor = () => {
          open()
            .then(eyecolor => setEyecolor(eyecolor.sRGBHex))
            .catch(e => {
              console.log(e)
              // Ensures component is still mounted
              // before calling setState
              if (!e.canceled) setEyeerror(e)
            })
        }
        setSvgColor(eyecolor)
        return (
          <>
            <button onClick={pickColor} className="eyecplacer" style={{border:'none',}}><ColorizeIcon/></button>
            {!!eyeerror && <span>{eyeerror.message}</span>}
          </>
        )
      }


    return (

        <>
            <div aria-describedby={id} color="white" size="sm" variant="outline" style={{ background: svgcolor, width: '20px', height: '20px', border: ' 2px solid #ccc', display: editor?.canvas?.getActiveObject().type == 'group' ? 'none' : 'block' }} className="bordercolor-btn" onClick={handleClick}>

            </div>
            <Draggable handle="strong" {...dragHandlers}>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    classes={{
                        paper: classes.popoverContent,
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >

                    <div className="div-fontcolorsection box no-cursor">
                        <strong className='cursor d-flex align-items-center  gap-2 mb-3'>
                            <div className="drag-me ">
                                Drag
                            </div>
                            <CloseIcon className="close-icon-pop" onClick={handleClose} />

                        </strong>
                        <SketchPicker style={{ width: '20px', height: '20px', border: ' 2px solid #ccc' }} color={svgcolor} onChange={(color) => {
                            setSvgColor("rgba(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + "," + color.rgb.a + ")");

                        }} />
                        <TwitterPicker color={svgcolor} onChange={(e)=>{
                            setSvgColor("rgba(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")");
                        }}/>
                        <Eye />
                    </div>
                </Popover>
            </Draggable>
        </>
        // <input type='color' style={{  width: '20px' ,height: '20px', border: ' 2px solid #ccc'}} value={svgcolor} onChange={onchangecolor} />


    )
}


const ElementsColor = ({ editor, fabric}) => {
    const elementscolor = []

    const handleClickSvgColorInp = (e) => {
        const clicksvgColorinp = e.target.children[0];
        try {
            clicksvgColorinp.click();
        }
        catch {
            console.log('svginputcolor');
        }

    };



    if (editor) {
        if ( editor?.canvas.getActiveObject()?.type == 'group') {
            for (let i = 0; i < editor.canvas.getActiveObject()._objects.length; i++) {
                if (editor.canvas.getActiveObject()._objects[i].fill != '') {
                    // dummy.push({color:editor.canvas.getActiveObject()._objects[i].fill,paths:[i]})
                    if (elementscolor.length === 0) {
                        elementscolor.push({ color: editor.canvas.getActiveObject()._objects[i].fill, paths: [i] })
                        // console.log('one');

                    }
                    else {
                        if (elementscolor.find(o => o.color === editor.canvas.getActiveObject()._objects[i].fill)) {
                            let currentindexcolor = elementscolor.findIndex(x => x.color === editor.canvas.getActiveObject()._objects[i].fill)
                            elementscolor[currentindexcolor].paths.push(i)
                        }
                        else {
                            elementscolor.push({ color: editor.canvas.getActiveObject()._objects[i].fill, paths: [i] })
                            // console.log('add');
                        }
                    }
                }
            }



            // console.log(elementscolor);
            // console.log(elementscolor.length);

            return (
                <>
                    {elementscolor.map((c, i) => {
                        // console.log(c,i)
                        // console.log('path length :',c.paths.length)

                        return <div className="inpsvg-color-toolbar">
                            <div className={`hasPicked${i}`} onClick={handleClickSvgColorInp} style={{ backgroundColor: c.color, width: '20px', height: '20px', border: ' 2px solid #ccc' }} >
                                <input type='color' className={`color${i}`} v-model='elementsobjecarray[i]' style={{ width: '20px', height: '20px', visibility: 'hidden' }} value={c.color} onChange={(e) => {
                                    c.color = e.target.value
                                    for (let j = 0; j < c.paths.length; j++) {
                                        document.querySelector(`.hasPicked${i}`).style.backgroundColor = c.color
                                        editor.canvas.getActiveObject()._objects[c.paths[j]].set({ fill: e.target.value });
                                    }
                                    editor.canvas.renderAll()
                                }} />
                                </div>
                        </div>
                    })}

                </>
            )


        }
    }
}





return(
    <>
        <StrokeStyle editor={editor} fabric={fabric}/>
        <SingleElementPathColor editor={editor} fabric={fabric} />
        <ElementsColor editor={editor} fabric={fabric}/>
    </>
)

}
export default ElementsProperties;