import React, { useEffect, useState, useRef } from "react";
import { Collapse } from 'reactstrap';
// import YouTube from 'react-youtube';
import { ButtonBase } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Select, { components } from "react-select";
import { motion, AnimatePresence } from "framer-motion";


const SubtitleLocalizationArea = (props) => {

    const {
        sourceLanguageOptions,
        targetLanguageOptions,
        setTargetLanguageOptions,
        setSourceLanguageOptions,
        Targetlanguageses,
        languages,
        handleCheckSrt,
        urlLink,
        setUrlLink,
        loading,
        youtubeUrlId, 
        setYoutubeUrlId,
        youtubeEmbedBox, 
        setYoutubeEmbedBox,
        fieldValidation,
        formValidation,
        setFormValidation
    } = props

    const [invalidYTLink, setInvalidYTLink] = useState(false)
    const youtubePreview = useRef(null)

    const validationError = {
        color: 'red',
    } 
    
    const handleGetYoutubeUrl = (e) => {
        setUrlLink(e.target.value)
        setYoutubeUrlId(YouTubeGetID(e.target.value))
        youtubePreview.current?.scrollIntoView({block: "center"});
        setFormValidation(false)
    }

    // youtube link validation
    useEffect(() => {
      if(urlLink !== ""){
        if(urlLink.indexOf('https://www.youtube') !== -1){
            setYoutubeEmbedBox(true)
            setInvalidYTLink(false)
        }else{
            setYoutubeEmbedBox(false)
            setInvalidYTLink(true)
        }
      }else{
        setInvalidYTLink(false)
        setYoutubeEmbedBox(false)
      }
    }, [urlLink])
    

    function YouTubeGetID(url){
        url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    }

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownIcon className="icon" />
            </components.DropdownIndicator>
        );
    };

    const customAssignStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#9B9FA2",
            fontFamily: "Inter",
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "24px",
        }),
        menu: (provided, state) => ({
            ...provided,
            padding: "6px 0px",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #DADADA",
            borderRadius: "4px",
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: "0px solid #CED4DA",
            borderLeft: "2px solid transparent",
            color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
            background: state.isSelected ? "#F4F5F7" : "#ffffff",
            padding: "2px 8px",
            color: "#3C4043",
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            opacity: state.isDisabled ? 0.5 : 1,
            "&:hover": {
                background: "#F4F5F7",
                borderLeft: "2px solid #0074D3",
                cursor: "pointer",
            },
        }),
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? "2px solid #0074D3" : "2px solid #C0C8CE",
            borderRadius: 4,
            transtion: 0.3,
            color: state.isFocused ? "#0074D3" : "#3C4043",
            fontFamily: "Roboto",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "24px",
            width: "100%",
            minheigh: 51,
            padding: 0,
            height: state.isFocused ? 51 : 51,
            boxShadow: 0,
            "&:hover": {
                cursor: "pointer",
                fontWeight: "400",
                border: "2px solid #0074D3",
                height: 51,
            },
        }),
    };


    return (
        <React.Fragment>
            <div ref={youtubePreview} className="subtitle-localization-wrapper">
                <div className="subtitle-url-area">
                    <h2 className="title">Paste the youtube link here:</h2>
                    <input type="url" pattern="https?://.+" value={urlLink} placeholder="https://www.youtube.com/" onChange={(e) => handleGetYoutubeUrl(e)} className="youtube-link-form" />
                    {formValidation && <small style={validationError}>Required</small>}
                    {invalidYTLink && <small style={validationError}>Invalid URL</small>}
                </div>
                <AnimatePresence exitBeforeEnter>
                    {
                        youtubeEmbedBox &&
                        <motion.div
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: -20 }}
                            exit={{ opacity: 0, y: 20 }}
                            transition={{ duration: 0.4 }}
                        >
                            <div className="youtube-video-wrap">
                                {/* <YouTube videoId={youtubeUrlId} rel={0} className="youtube-wrap" opts={youtubePlayerOptions} /> */}
                                <object data={"https://www.youtube.com/embed/"+youtubeUrlId+"?autoplay=0"} width="100%" height="350px"></object>
                                {/* <img src={"http://img.youtube.com/vi/"+youtubeUrlId+"/maxresdefault.jpg"} alt="youtube-thumbnail" /> */}
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
                <div className="lang-select-row">
                    <div className="select-lang">
                        <label>Source Language</label>
                        <Select
                            classNamePrefix="select-lang"
                            styles={customAssignStyles}
                            menuPlacement="auto"
                            isSearchable={false}
                            options={languages}
                            getOptionLabel={(option) => option.language}
                            getOptionValue={(option) => option.code}
                            isOptionDisabled={(option) => option.disabled}
                            hideSelectedOptions={false}
                            value={sourceLanguageOptions}
                            onChange={setSourceLanguageOptions}
                            placeholder="Select Source Language"
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            
                        />
                    </div>
                    <div className="select-lang">
                        <label>Target Language(s)</label>
                        <Select
                            classNamePrefix="select-lang"
                            styles={customAssignStyles}
                            isSearchable={false}
                            isDisabled={urlLink === ""}
                            menuPlacement="auto"
                            options={Targetlanguageses}
                            getOptionLabel={(option) => option.language}
                            getOptionValue={(option) => option.code}
                            hideSelectedOptions={false}
                            value={targetLanguageOptions}
                            onChange={setTargetLanguageOptions}
                            placeholder="Select Target Language"
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            
                        />
                    </div>
                </div>
                <div className="publish-button-row">
                    <ButtonBase onClick={() => {fieldValidation() && handleCheckSrt()}} className="translate-btn">
                        <span>{loading ? "Processing" : "Translate & Download"}</span>
                    </ButtonBase>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SubtitleLocalizationArea;