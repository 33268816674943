import React, { useEffect, useState, useRef } from "react";
import { Collapse } from 'reactstrap';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Select, { components } from "react-select";
import { ButtonBase } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

const NerKeywordArea = (props) => {
    
    const {analysedValue,
        analysedValueRef, 
        setAnalysedValue, 
        AnalysedOptions, 
        setAnalysedOptionData, 
        analysedOptionData, 
        getNamedEntityValues,
        analysedTab,
        setAnalysedTab,
        handleAnalyse,
        namedEntitiesList,
        keywordsList,
        downloadNamedEntities,
        downloadKeywords
    } = props

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownIcon className="icon" />
            </components.DropdownIndicator>
        );
    };

    const customAssignStyles = {
        placeholder: (provided, state) => ({
            ...provided,
            color: "#3C4043",
            fontFamily: "Roboto",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "24px",
        }),
        menu: (provided, state) => ({
            ...provided,
            padding: "6px 0px",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #DADADA",
            borderRadius: "4px",
            width: "230px",
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: "0px solid #CED4DA",
            borderLeft: "2px solid transparent",
            color: state.isSelected ? "#ffffff" : state.isDisabled ? "#cccccc" : "#7E7E7E",
            background: state.isSelected ? "#F4F5F7" : "#ffffff",
            padding: "2px 8px",
            color: "#3C4043",
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            "&:hover": {
                background: "#F4F5F7",
                borderLeft: "2px solid #0074D3",
                cursor: "pointer",
            },
        }),
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? "2px solid #0074D3" : "2px solid #C0C8CE",
            borderRadius: 4,
            transtion: 0.3,
            color: state.isFocused ? "#0074D3" : "#3C4043",
            fontFamily: "Roboto",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "24px",
            width: "230px",
            minheigh: 44,
            padding: 0,
            height: state.isFocused ? 44 : 44,
            boxShadow: 0,
            "&:hover": {
                cursor: "pointer",
                fontWeight: "400",
                border: "2px solid #0074D3",
                height: 44,
            },
        }),
    };

    // Handle text formating and paste only plain text in the contenteditable
    var _onPaste_StripFormatting_IEPaste = false;

    function OnPaste_StripFormatting(elem, e) {

        if (e.originalEvent && e.originalEvent.clipboardData && e.originalEvent.clipboardData.getData) {
            e.preventDefault();
            var text = e.originalEvent.clipboardData.getData('text/plain');
            window.document.execCommand('insertText', false, text);
        }
        else if (e.clipboardData && e.clipboardData.getData) {
            e.preventDefault();
            var text = e.clipboardData.getData('text/plain');
            window.document.execCommand('insertText', false, text);
        }
        else if (window.clipboardData && window.clipboardData.getData) {
            // Stop stack overflow
            if (!_onPaste_StripFormatting_IEPaste) {
                _onPaste_StripFormatting_IEPaste = true;
                e.preventDefault();
                window.document.execCommand('ms-pasteTextOnly', false);
            }
            _onPaste_StripFormatting_IEPaste = false;
        }

    }




    return (
        <React.Fragment>
            <section className="ner-keyword-wrapper">
                <div className="ner-keyword-area">
                    <h2 className="title">Sentence to be Analysed</h2>
                    <div className="analysed-wrapper">
                        <ul className="analysed-tab-wrap">
                            <li onClick={() => setAnalysedTab(1)} className={"analysed-tab-link " + (analysedTab === 1 ? "active" : null)}>Find Names</li>
                            <li onClick={() => setAnalysedTab(2)} className={"analysed-tab-link " + (analysedTab === 2 ? "active" : null)}>Keywords</li>
                        </ul>
                        <div className="tab-switch-wrapper">
                            <AnimatePresence exitBeforeEnter>
                                {
                                    analysedTab === 1 &&
                                    <motion.div
                                        animate={{ opacity: 1, x: 0 }}
                                        initial={{ opacity: 0, x: -30 }}
                                        exit={{ opacity: 0, x: 30 }}
                                        transition={{ duration: 0.4 }}  
                                        className="description-wrapper"
                                    >
                                        <div
                                            contentEditable={true}
                                            ref={analysedValueRef}
                                            className="textarea-form"
                                            onPaste={(e) => OnPaste_StripFormatting(this, e)}
                                            onInput={(e) => setAnalysedValue(e.target.textContent)}
                                        >
                                        </div>
                                        <div className="btn-row">
                                            <ButtonBase className="translate-btn" 
                                                onClick={handleAnalyse}
                                                disabled={analysedValue === "" ? true : false}
                                            >
                                                Analyse
                                            </ButtonBase>
                                        </div>
                                    </motion.div>
                                }
                                {
                                    analysedTab === 2 &&
                                    <motion.div
                                        animate={{ opacity: 1, x: 0 }}
                                        initial={{ opacity: 0, x: -30 }}
                                        exit={{ opacity: 0, x: 30 }}
                                        transition={{ duration: 0.4 }}  
                                        className="description-wrapper"
                                    >
                                        <div
                                            contentEditable={true}
                                            ref={analysedValueRef}
                                            onPaste={(e) => OnPaste_StripFormatting(this, e)}
                                            className="textarea-form"
                                            onInput={(e) => setAnalysedValue(e.target.textContent)}
                                        >
                                        </div>
                                        <div className="btn-row">
                                            <ButtonBase className="translate-btn" 
                                                onClick={handleAnalyse}
                                                disabled={analysedValue === "" ? true : false}
                                            >
                                                Analyse
                                            </ButtonBase>
                                        </div>
                                    </motion.div>
                                }
                            </AnimatePresence>
                        </div>
                    </div>
                    <div className="btn-row">
                        {/* <Select
                            classNamePrefix="select-src-lang"
                            styles={customAssignStyles}
                            isSearchable={false}
                            options={AnalysedOptions}
                            hideSelectedOptions={false}
                            value={analysedOptionData}
                            onChange={setAnalysedOptionData}
                            isDisabled={analysedValue === "" ? true : false}
                            components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                            
                        /> */}
                        {
                            (namedEntitiesList || keywordsList) &&
                            <ButtonBase 
                                onClick={namedEntitiesList && downloadNamedEntities || keywordsList && downloadKeywords} 
                                className="download-btn"
                            >
                                <FileDownloadOutlinedIcon className="icon" />
                                <span>Download</span>
                            </ButtonBase>
                        }
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default NerKeywordArea;