import Config from "../config/Config";

export const Apis = {
    getData : Config.LABS_API + 'prompts/domains/',
    createDomain : Config.LABS_API + 'prompts/domains/',
    createUseCase : Config.LABS_API + 'prompts/use_cases/',
    createCategory : Config.LABS_API + 'prompts/categories/',
    createSubCategory: Config.LABS_API + 'prompts/sub_categories/',
    createPrompt: Config.LABS_API + 'prompts/prompts/',
    publishPrompt: Config.LABS_API + 'prompts/prompts/publish/',
    generatePromptOutput: Config.LABS_API + 'prompts/generate_prompt_output/',
    getGeneratePromptOutput: Config.LABS_API + 'prompts/prompts/outputs/',
    openiOutput: Config.LABS_API + 'prompts/run_edited_prompt/'



}