import React, { useEffect, useLayoutEffect, useState, useRef ,useCallback} from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from "fabric";
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ElementsProperties from "./ElementsProperties";
import ImageProperties from "./ImageProperties";
import BackgroundProperties from "./BackgroundProperties";
import GeneralProperties from "./GeneralProperties";
import TextProperties from "./TextProperties";
import ColorizeIcon from '@mui/icons-material/Colorize';
import useEyeDropper from 'use-eye-dropper'
import cloneiconbut from '../../../assets/icons/cloneiconbut.svg'
import closebutton from '../../../assets/icons/closebutton.svg'
import BrushIcon from '@mui/icons-material/Brush';
import { makeStyles } from '@material-ui/core/styles';
import RemoveIcon from '@mui/icons-material/Remove';


const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    popoverContent: {
        pointerEvents: 'auto',
    },
}));
const AddPage = ({setCanvasAdd,setCanvasDuplicateData,duplicate,canvasHeight, canvasWidth, canvasAdd, canvasDuplicateData}) => {

    const { editor, onReady } = useFabricJSEditor();

    
    useEffect(() => {
    if (editor) {

        editor.canvas.setWidth(600);
        editor.canvas.renderAll();
    }

    // height
    if (editor) {

        editor.canvas.setHeight(700);
        editor.canvas.renderAll();
    }
    },[editor,canvasAdd]);

    useEffect(() => {

        if (editor) {
            editor.canvas.preserveObjectStacking = true;
            editor.canvas.fireRightClick = true
            // editor.canvas.id = name
            // editor.canvas.canvasimg = canvasimgurl
            editor.canvas.backgroundColor = '#FFFFFF'


            editor.canvas.renderAll();
            if (editor.canvas.getActiveObject()) {
                editor.canvas.getActiveObject().borderColor = '#BD8AF5'
                editor.canvas.getActiveObject().borderScaleFactor = 2
                editor.canvas.getActiveObject().cornerStyle = "circle"
                editor.canvas.getActiveObject().cornerColor = '#FFFFFF'
                editor.canvas.getActiveObject().cornerSize = 9
                editor.canvas.getActiveObject().cornerStrokeColor = '#00000029'
                editor.canvas.getActiveObject().transparentCorners = false
                editor.canvas.renderAll();
            }

        }
    },[editor,canvasAdd,duplicate])


    const [renderAgain, setRenderAgain] = useState(0)

    //  useEffect(() => {

    //     if(canvasDuplicateData != null){
    //         if(editor){
    //             editor?.canvas.loadFromJSON(canvasDuplicateData, function() {
    //             editor?.canvas.renderAll.bind(editor?.canvas.renderAll());
    //         })

    //         }

    //     }
            
    //  },[duplicate,canvasDuplicateData])

    useEffect(() => {

    if(canvasDuplicateData != null){
     const timerId = setTimeout(() => {
        load()
        // setCanvasDuplicateData(null)
    },[100]);
    }


    },[canvasDuplicateData])


    
     const load = () => {
        editor?.canvas.loadFromJSON(canvasDuplicateData, function() {
            editor?.canvas.renderAll.bind(editor?.canvas.renderAll());
            console.log('1');

     })

    }


    const removeCanvas = () => {
        setCanvasAdd(false)
    }
    

    return(
        <div>
                                <Styles  editor={editor} fabric={fabric} />

            {/* <div className="canvas-scaler"  style={{ cursor: 'context-menu', transform: `scale(${scaleCanvas})`, border: backgroundEdit ? '3px solid #BD8AF5' : 'none', zIndex: 1 }}> */}
                                <div className="canvas-pages-toolbar" >
                                        <ContentCopyIcon style={{ visibility: 'hidden'}}/> 
                                     <AddIcon style={{ visibility: 'hidden'}}/>
                                     <RemoveIcon onClick={removeCanvas} />

                                     {/* <button  id='load' onClick={load}>load</button> */}
                                        
                                </div>
                                <FabricJSCanvas id="canvas" className="canvas-space" onReady={onReady} />
                            {/* </div> */}
        </div>
    )



}

const Styles = ({editor,fabric}) => {
    // const { editor, onReady } = useFabricJSEditor();

//toolbar
const [sideBarElementOpenClose, setSideBarElementOpenClose] = useState(false)

    const classes = useStyles();
    // const [arrowRef, setArrowRef] = useState(null);
    // const [visible, setVisible] = useState(false);
    // const [value, setValue] = React.useState(30);
    // 1920
    const [canvasWidth, setCanvaswidth] = useState(600)
    // 1080
    const [canvasHeight, setCanvasHeight] = useState(700)
    const [bold, setBold] = useState(false)
    const [italic, setItalic] = useState(false)
    const [underline, setUnderline] = useState(false)
    const [isdrawing, setisdrawing] = useState(false)
    const [counter, setCounter] = useState(32)
    const [searchInput, setSearchInput] = useState("");
    const [canvasSecondary, setCanvasSecondary] = useState(false)
    const [files, setFile] = useState([]);
    const [message, setMessage] = useState();
    const hiddenFileInput = React.useRef(null);
    const hiddenColorInput = React.useRef(null);
    const drawingicon = useRef(null)
    const [lockicon, setlockicon] = useState('LockIcon')
    const [disableLi, setDisableLi] = useState(false);


const [toolbar, setToolbar] = useState(false)

const [TextId, setTextId] = useState(1);
const [imgId, setImgId] = useState(1);



    var _wrapLine = function(_line, lineIndex, desiredWidth, reservedSpace) {
        var lineWidth = 0,
           splitByGrapheme = this.splitByGrapheme,
           graphemeLines = [],
           line = [],
           // spaces in different languges?
           words = splitByGrapheme ? fabric.util.string.graphemeSplit(_line) : _line.split(this._wordJoiners),
           word = '',
           offset = 0,
           infix = splitByGrapheme ? '' : ' ',
           wordWidth = 0,
           infixWidth = 0,
           largestWordWidth = 0,
           lineJustStarted = true,
           additionalSpace = splitByGrapheme ? 0 : this._getWidthOfCharSpacing();

       reservedSpace = reservedSpace || 0;
       desiredWidth -= reservedSpace;
       for (var i = 0; i < words.length; i++) {
           // i would avoid resplitting the graphemes
           word = fabric.util.string.graphemeSplit(words[i]);
           wordWidth = this._measureWord(word, lineIndex, offset);
           offset += word.length;

           // Break the line if a word is wider than the set width
           if (this.breakWords && wordWidth >= desiredWidth) {

               if (!lineJustStarted) {
                   line.push(infix);
                   lineJustStarted = true;
               }

               // Loop through each character in word
               for (var w = 0; w < word.length; w++) {
                   var letter = word[w];
                   var letterWidth = this.getMeasuringContext().measureText(letter).width * this.fontSize / this.CACHE_FONT_SIZE;
                   if (lineWidth + letterWidth > desiredWidth) {
                       graphemeLines.push(line);
                       line = [];
                       lineWidth = 0;
                   } else {
                       line.push(letter);
                       lineWidth += letterWidth;
                   }
               }
               word = [];
           } else {
               lineWidth += infixWidth + wordWidth - additionalSpace;
           }

           if (lineWidth >= desiredWidth && !lineJustStarted) {
               graphemeLines.push(line);
               line = [];
               lineWidth = wordWidth;
               lineJustStarted = true;
           } else {
               lineWidth += additionalSpace;
           }

           if (!lineJustStarted) {
               line.push(infix);
           }
           line = line.concat(word);

           infixWidth = this._measureWord([infix], lineIndex, offset);
           offset++;
           lineJustStarted = false;
           // keep track of largest word
           if (wordWidth > largestWordWidth && !this.breakWords) {
               largestWordWidth = wordWidth;
           }
       }

       i && graphemeLines.push(line);

       if (largestWordWidth + reservedSpace > this.dynamicMinWidth) {
           this.dynamicMinWidth = largestWordWidth - additionalSpace + reservedSpace;
       }

       return graphemeLines;
   };


   fabric.util.object.extend(fabric.Textbox.prototype, {
   _wrapLine: _wrapLine,
   });



const [leftToptext, setLeftTopText] = useState(100)
const addHeading = () => {

    setTextId(TextId + 1)
    setLeftTopText(leftToptext + 10)

    const txt = (new fabric.Textbox("Add a heading", {
        id: 'text-' + TextId,
        // textAlign: "center",
        left: leftToptext,
        top: leftToptext,
        fontFamily: 'Ariel',
        fill: '#111',
        fontSize: 32,
        opacity: 1,
        lockMovementX: false,
        lockMovementY: false,
        lineHeight: 1,
        width: 190,
        minScaleLimit: 0.5,
        // perPixelTargetFind: true,
        breakWords: true,
        uniformScaling: true,
        // scalingAffectsSizeProp: true

    }));


    editor.canvas.add(txt);
    editor.canvas.renderAll();
    setToolbar(true);

    editor.canvas.getObjects().forEach((e) => {
        if (e.id === 'text-' + TextId) {
            editor.canvas.setActiveObject(e)
            console.log(e.id);
        }
    })
}

const addHeading2 = () => {
    setTextId(TextId + 1)
    setLeftTopText(leftToptext + 10)

    const txt = (new fabric.Textbox("Add a Subheading", {
        id: 'text-' + TextId,
        // textAlign: "center",
        left: leftToptext,
        top: leftToptext,
        fontFamily: 'Ariel',
        fontWeight: '500',
        fill: '#111',
        fontSize: 28,
        lockMovementX: false,
        lockMovementY: false,
        lineHeight: 1,
        width: 167,
        minScaleLimit: 0.5,
        breakWords: true,
        // perPixelTargetFind: true,
        uniformScaling: true



    }));



    editor.canvas.add(txt);
    editor.canvas.renderAll();
    setToolbar(true);



    editor.canvas.getObjects().forEach((e) => {
        if (e.id === 'text-' + TextId) {
            editor.canvas.setActiveObject(e)
            console.log(e.id);
        }
    })



}

const addHeading3 = () => {

    setTextId(TextId + 1)
    setLeftTopText(leftToptext + 10)

    const txt = (new fabric.Textbox("Add a Text", {
        id: 'text-' + TextId,
        // textAlign: "center",
        left: leftToptext,
        top: leftToptext,
        fontFamily: 'Ariel',
        fontWeight: '400',
        fill: '#111',
        fontSize: 18,
        lockMovementX: false,
        lineHeight: 1,
        width: 110,
        minScaleLimit: 0.5,
        breakWords: true,
        // perPixelTargetFind: true,
        uniformScaling: true


    }));

    editor.canvas.add(txt);
    editor.canvas.renderAll();
    setToolbar(true);



    editor.canvas.getObjects().forEach((e) => {
        if (e.id === 'text-' + TextId) {
            editor.canvas.setActiveObject(e)
            console.log(e.id);
        }
    })

}

// console.log(editor.canvas.getActiveObject().getSelectedText()[1]);
// console.log(editor.canvas.getActiveObject().getSelectedText().length);
// // for(let i = 0;i<editor.canvas.getActiveObject().getSelectedText().length,i++){

// // }     
// if(editor.canvas.getActiveObject().getSelectedText()){
// editor.canvas.getActiveObject().setSelectionStyles(({ fontWeight: 'bold' }),editor.canvas.getActiveObject().selectionStart,editor.canvas.getActiveObject().selectionEnd - 5  )
// }
// editor.canvas.renderAll()
// //active.getActiveObject().setSelectionStyles(({ fontWeight: 'bold' }), 0, temp)

const firstupdate = useRef(true)
useEffect(() => {
    if (firstupdate.current) {
        firstupdate.current = false
        return;
    }
    if (editor) {
        // editor.canvas.backgroundColor = canvasbgcolor
        if (editor.canvas.getActiveObject()) {
            // bold
            // let boldcount = 0
            // if(editor.canvas.getActiveObject().getSelectedText()){
            //     let length = editor.canvas.getActiveObject().getSelectedText().length
            //     const spacecount = editor.canvas.getActiveObject().getSelectedText().length - editor.canvas.getActiveObject().getSelectedText().replaceAll(' ', '').length
            //     for(let i=0;i<length;i++){
            //        if( editor.canvas.getActiveObject().getSelectedText()[i] != ' '){
            //             if (editor.canvas.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold') {
            //                 boldcount++
            //             }
            //             else {

            //             }
            //             if(boldcount == length-spacecount){
            //                 setBold(true)
            //             }
            //             else{
            //                 setBold(false)
            //             }
            //         }
            //         else{
            //             console.log('space');
            //         }
            //     }

            // }



            // italic
            if ((editor.canvas.getActiveObject().fontWeight === 'bold')) {
                setBold(true)
                editor.canvas.renderAll()
            }

            else {
                setBold(false)
                editor.canvas.renderAll()
            }
            if ((editor.canvas.getActiveObject().fontStyle === 'italic')) {
                setItalic(true)
                editor.canvas.renderAll()
            }

            else {
                setItalic(false)
                editor.canvas.renderAll()
            }
            // underline

            if ((editor.canvas.getActiveObject().underline === true)) {
                setUnderline(true)
                editor.canvas.renderAll()
            }

            else {
                setUnderline(false)
                editor.canvas.renderAll()
            }

            if ((editor.canvas.getActiveObject().linethrough === true)) {
                setLinethrough(true)
                editor.canvas.renderAll()
            }

            else {
                setLinethrough(false)
                editor.canvas.renderAll()
            }

            if ((editor.canvas.getActiveObject().overline === true)) {
                setOverline(true)
                editor.canvas.renderAll()
            }

            else {
                setOverline(false)
                editor.canvas.renderAll()
            }

        }
    }
    if (fontSizeTemp.current) {
        fontSizeTemp.current = false
        return;
    }

    if (editor) {
        if (editor.canvas.getActiveObject()) {
            setTempCounter(editor.canvas.getActiveObject().fontSize)
            editor.canvas.renderAll()

        }
    }
    if (editor) {
        editor.canvas.preserveObjectStacking = true;
        editor.canvas.fireRightClick = true
        // editor.canvas.id = name


        editor.canvas.renderAll();
        if (editor.canvas.getActiveObject()) {
            editor.canvas.getActiveObject().borderColor = '#BD8AF5'
            editor.canvas.getActiveObject().borderScaleFactor = 2
            editor.canvas.getActiveObject().cornerStyle = "circle"
            editor.canvas.getActiveObject().cornerColor = '#FFFFFF'
            editor.canvas.getActiveObject().cornerSize = 9
            editor.canvas.getActiveObject().cornerStrokeColor = '#00000029'
            editor.canvas.getActiveObject().transparentCorners = false
            setBackgroundEdit(false)
            editor.canvas.renderAll();
        }

    }
    if (editor) {
        editor.canvas.backgroundColor = canvasbgcolor
        editor.canvas.renderAll()
    }
    if (editor) {
        // my template
        // if (editor.canvas.getObjects().length > 0 || editor.canvas.backgroundImage || editor.canvas.backgroundColor != 'rgba(255,255,255,1)' || editor.canvas.backgroundColor != '#ffffff') {
        //     setCanvasismodified(true)
        // }
        // else {
        //     setCanvasismodified(false)
        //     setReusedtempboolen(false)
        // }
        if (reusedtempboolen) {
            setButtontext('save')
            if (canvasismodified) {
                setButtontext('save')
            }

        }
        // if (editor.canvas.getObjects().length < 1 && !editor.canvas.backgroundImage && (editor.canvas.backgroundColor != 'rgba(255,255,255,1)' || editor.canvas.backgroundColor != '#ffffff')) {
        //     setCanvasismodified(false)
        //     setReusedtempboolen(false)
        // }
        // if (name === reusedtempname) {
        //     // console.log('yes');
        //     setButtontext('save')
        // }
        // else {
        //     // console.log('no');
        //     setButtontext('Save as Template');
        // }

        // my design
        // if (editor.canvas.getObjects().length > 0 || editor.canvas.backgroundImage || editor.canvas.backgroundColor != 'rgba(255,255,255,1)' || editor.canvas.backgroundColor != '#ffffff') {
        //     setCanvasismodified(true)
        // }
        // else {
        //     setCanvasismodified(false)
        //     setReuseddesignboolen(false)
        // }
        if (reusedtempboolen) {
            setButtontext('save')
            if (canvasismodified) {
                setButtontext('save')
            }

        }
        // if (editor.canvas.getObjects().length < 1 && !editor.canvas.backgroundImage && (editor.canvas.backgroundColor != 'rgba(255,255,255,1)' || editor.canvas.backgroundColor != '#ffffff')) {
        //     setCanvasismodified(false)
        //     setReuseddesignboolen(false)
        // }
        // if (name === reuseddesignname) {
        //     // console.log('yes');
        //     setButtontext2('save')
        // }
        else {
            // console.log('no');
            setButtontext2('Save as Design');
        }

    }
    if (editor) {
        if (editor.canvas.getActiveObject()) {
            setDisabled(true)
        }
        else {
            setDisabled(false)
        }
        // right click to get active object
        editor.canvas.on('mouse:up', (e) => {
            if (e.button == 3) {
                editor.canvas.setActiveObject(e.target)
            }
        })
        if (editor.canvas.getActiveObject()?.type === 'group') {
            setMultiselect(true)
            setgroupname('Ungroup')

        }
        else if ((editor.canvas.getActiveObject()?.type == 'activeSelection')) {
            setgroupname('Group')
            setMultiselect(true)
        }
        else {
            setMultiselect(false)
            setgroupname('Ungroup')
        }



        // if(editor.canvas.getActiveObject()?.type == 'group'){
        //     setgroupname('Ungroup')
        //     console.log('grouped');
        // }
        // else{
        //     setMultiselect(false)
        //     setgroupname('Group')
        //     console.log('ungrouped');
        // }
        if (editor.canvas.getActiveObjects().length > 1) {
            let total = editor.canvas.getActiveObjects().length
            let tvalue = 0;
            let ivalue = 0;
            let ovalue = 0;

            editor.canvas.getActiveObjects().forEach((e) => {
                if (e.type == 'textbox') {
                    tvalue++
                }
                else if (e.type == 'image') {
                    ivalue++
                }
                else if ((e.type != 'textbox') && (e.type != 'image')) {
                    ovalue++
                }

            })
            if (tvalue == total) {
                setAlltext(true)
                console.log('text toolbar ');
            }
            else if (ivalue == total) {
                console.log('image toolbar ');

            }
            else if (ovalue == total) {
                console.log('others toolbar ');

            }
            else {
                setAlltext(false)
                console.log('no more toolbar');
            }
            console.log('text:', tvalue);
            console.log('image:', ivalue);
            console.log('others:', ovalue);
        }
        else {

        }
        editor.canvas.requestRenderAll();
    }
}, [bold, italic, underline, editor])


// firstupdate



//    remove active object in canvas
const removeActiveobject = () => {
    if (editor) {
        // editor.canvas.remove(editor.canvas.getActiveObject())
        try {
            editor.canvas.remove(editor.canvas.getActiveObject())
            // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
            let activeObjects = editor.canvas.getActiveObjects();
            if (activeObjects.length) {
                activeObjects.forEach(function (object) {
                    editor.canvas.remove(object);
                    editor.canvas.renderAll();
                });
                editor.canvas.discardActiveObject().renderAll();
            }
        }
        catch {
        }

    }
}

// lock active object in canvas
const lockmovementActiveobject = () => {
    if (editor) {
        if (editor.canvas.getActiveObject().lockMovementX === true) {
            editor.canvas.getActiveObject().set('lockMovementX', false);
            editor.canvas.getActiveObject().set('lockMovementY', false);
            editor.canvas.getActiveObject().set('lockScalingX', false);
            editor.canvas.getActiveObject().set('lockScalingY', false);
            editor.canvas.getActiveObject().set('hasControls', true);
            editor.canvas.getActiveObject().set('editable', true);
            editor.canvas.renderAll();
        }
        else {
            editor.canvas.getActiveObject().set('lockMovementX', true);
            editor.canvas.getActiveObject().set('lockMovementY', true);
            editor.canvas.getActiveObject().set('lockScalingX', true);
            editor.canvas.getActiveObject().set('lockScalingY', true);
            editor.canvas.getActiveObject().set('hasControls', false);
            editor.canvas.getActiveObject().set('editable', false);
            editor.canvas.getActiveObject().set('hasBorders', true);
            editor.canvas.renderAll();
        }
    }
}


// if drawing mode is on toolbar is visiable

const drawingFunc = () => {
    if (editor) {
        if (editor.canvas.isDrawingMode === false) {
            editor.canvas.set('isDrawingMode', true)
            setisdrawing(true)
            setToolbar(false)
            editor.canvas.renderAll();

        }
        else {
            editor.canvas.set('isDrawingMode', false)
            setisdrawing(false)
            setToolbar(true)
            editor.canvas.renderAll();
        }
    }
}
// drawing function end

// position function start
const forward = () => {
    if (editor) {
        if (editor.canvas.getActiveObject()) {
            editor.canvas.bringForward(editor.canvas.getActiveObject())
            editor.canvas.renderAll();
        }
    }
}
const backward = () => {
    if (editor) {
        if (editor.canvas.getActiveObject()) {
            editor.canvas.sendBackwards(editor.canvas.getActiveObject())
            editor.canvas.renderAll();
        }
    }
}
const front = () => {
    if (editor) {
        if (editor.canvas.getActiveObject()) {
            editor.canvas.bringToFront(editor.canvas.getActiveObject())
            editor.canvas.renderAll();
        }
    }
}

const back = () => {
    if (editor) {
        if (editor.canvas.getActiveObject()) {
            editor.canvas.sendToBack(editor.canvas.getActiveObject())
            editor.canvas.renderAll();
        }
    }
}

// groupsection start

const group = () => {
    if (!editor.canvas.getActiveObject()) {
        return;
    }
    if (editor.canvas.getActiveObject().type !== 'activeSelection') {
        return;
    }
    editor.canvas.getActiveObject().toGroup();
    editor.canvas.requestRenderAll();
}
const ungroup = () => {
    if (!editor.canvas.getActiveObject()) {
        return;
    }
    if (editor.canvas.getActiveObject().type !== 'group') {
        return;
    }
    editor.canvas.getActiveObject().toActiveSelection();
    editor.canvas.requestRenderAll();
}



// font editing tool start
// bold 

// const onBold = () => {
//     editor.canvas.getActiveObject().setSelectionStyles({fontWeight:'bold'})
//     editor.canvas.renderAll();

// }

// const unBold = () => {

//     editor.canvas.getActiveObject().setSelectionStyles({fontWeight:'normal'})
//     editor.canvas.renderAll();
// }
// const tempbold = useRef(true)

// const handleBold = () => {
//     if( editor.canvas.getActiveObject().getSelectedText()){
//         // console.log( editor.canvas.getActiveObject().getSelectionStyles())
//         // editor.canvas.getActiveObject().setSelectionStyles({fontWeight:'bold'})
//         // editor.canvas.renderAll()
//         // console.log( editor.canvas.getActiveObject().getSelectionStyles())

//         // console.log( editor.canvas.getActiveObject().getSelectionStyles().fontWeight);
//         // if(!editor.canvas.getActiveObject().getSelectionStyles().fontWeight){
//         //     editor.canvas.getActiveObject().setSelectionStyles({fontWeight:'bold'})
//         //     setBold(true)
//         //     console.log('first');
//         //     editor.canvas.renderAll()
//         //     return; 
//         // }


//         if(tempbold.current){
//             editor.canvas.getActiveObject().setSelectionStyles({fontWeight:'bold'})
//             editor.canvas.renderAll()
//             console.log('second');
//             tempbold.current = false
//             return;
//         }
//        if(!tempbold.current){
//             editor.canvas.getActiveObject().setSelectionStyles({fontWeight:''})
//             editor.canvas.renderAll()
//             console.log('third');
//             tempbold.current = true
//             return;
//        }


//     // }
//     // const temp = useRef(true)
//     //     if(editor.canvas.getActiveObject()){
//     //         if(temp.current=true){
//     //             console.log('first');
//     //             temp.current  =false
//     //             return;

//     //         }
//     //         else if(bold){
//     //             console.log('second');
//     //             setBold(true)
//     //             return;
//     //         }
//     //     }
// }}
// italic
// const onItalic = () => {

//     editor.canvas.getActiveObject().set("fontStyle", "italic");
//     editor.canvas.renderAll();

// }

// const unItalic = () => {

//     editor.canvas.getActiveObject().set("fontStyle", "");
//     editor.canvas.renderAll();
// }


// const handleItalic = () => {
//     if (!italic) {
//         onItalic();
//         setItalic(true);

//     } else {
//         setItalic(false);
//         unItalic();

//     }
// };

// underline

// const onUnderline = () => {

//     editor.canvas.getActiveObject().setSelectionStyles({fontStyle: 'italic'});
//     console.log(editor.canvas.getActiveObject().fontStyle);
//     editor.canvas.renderAll();

// }

// const unUnderline = () => {

//     editor.canvas.getActiveObject().set("underline", false);
//     editor.canvas.renderAll();
// }


// const handleUnderline = () => {
//     if (!underline) {
//         onUnderline();
//         setUnderline(true);

//     } else {
//         unUnderline();
//         setUnderline(false);
//     }
// };

// font editio tool end

// canvas size function start
// width
if (editor) {

    editor.canvas.setWidth(canvasWidth);
    editor.canvas.renderAll();
}

// height
if (editor) {

    editor.canvas.setHeight(canvasHeight);
    editor.canvas.renderAll();
}

// console.log(canvasHeight, canvasWidth);

// canvas size function end

//simple-download
// const downloadImage = () => {

// };

const tempDownload = useRef(false)

// useEffect(() => {



//     if(download.){
//         // if(download != download){
//             downloadImage()
//     }

// },[download])
// const [data1, setData1] = useState(null)

// useEffect(() => {
//     if (download == true) {
//         const ext = "jpg";
//         const base64 = editor.canvas.toDataURL({
//             // multiplier: -3,
//             format: ext,
//             enableRetinaScaling: true
//         });
//         setData1(base64)

//         const link = document.createElement("a");
//         link.href = base64;
//         link.download = `${name}.${ext}`;
//         link.click();
//         setDownload(false)

//     }


// }, [download])

// console.log(data1);
//Drawing Mode
//   const drawingMode = () => {

//     canvas.isDrawingMode = !canvas.isDrawingMode;

//   }


// //Color Change
// const handleColorChange = () => {
//     setColor(e.target.value)
//     canvas.getActiveObject().set("fill", color);
//     canvas.renderAll();

// }

// const hiddenColorInput = React.useRef(null);

// const handleClickTwo = (event) => {
//     hiddenColorInput.current.click();

//   };



const onAddImage = (e) => {


    setToolbar(true)
    // console.log(e.target.src); 
    setImgId(imgId + 1)
    const stickers = e.target.src;
    console.log(e.target.fileType);

    function checkImage(url) {
        var request = new XMLHttpRequest();
        request.open("GET", url, true);
        request.send();
        request.onreadystatechange = function () {
            if (this.readyState == this.HEADERS_RECEIVED) {
                var contentType = request.getResponseHeader("Content-Type");
                console.log(contentType);
                if (contentType == 'image/svg+xml') {
                    setElementsobject(true)
                    fabric.loadSVGFromURL(stickers, function (objects, options) {
                        var loadedObjects = fabric.util.groupSVGElements(objects, options);
                        loadedObjects.set({ subTargetCheck: true })
                        loadedObjects.scaleToWidth(editor.canvas.width / 3);
                        loadedObjects.scaleToHeight(editor.canvas.height / 3);
                        editor.canvas.add(loadedObjects);
                        editor.canvas.setActiveObject(loadedObjects);

                        editor.canvas.renderAll();
                    });

                }
                else {
                    var filterBrightness = new fabric.Image.filters.Brightness({
                        brightness: 0,
                    });
                    var filterContrast = new fabric.Image.filters.Contrast({
                        contrast: 0,
                    });
                    var filterSaturation = new fabric.Image.filters.Saturation({
                        saturation: 0,
                    });
                    var filterBlur = new fabric.Image.filters.Blur({
                        blur: 0,
                    });
                    var filterVibrance = new fabric.Image.filters.Vibrance({
                        vibrance: 0,
                    });

                    var filterNoice = new fabric.Image.filters.Noise({
                        noise: 0,
                    });

                    var filterHue = new fabric.Image.filters.HueRotation({
                        rotation: 0,
                    });

                    fabric.Image.fromURL(stickers, (img) => {
                        img.scaleToWidth(200);
                        var oImg = img.set({
                            id: 'Image-' + imgId,
                            left: 100,
                            top: 60,


                        })

                        oImg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)

                        editor.canvas.add(oImg).sendBackwards();
                        editor.canvas.getObjects().forEach((e) => {
                            if (e.id === 'Image-' + imgId) {
                                editor.canvas.setActiveObject(e)
                                console.log(e.id);
                            }
                        })
                        editor.canvas.renderAll();


                    }, { crossOrigin: 'anonymous' });
                }

            }
        }
    }

    checkImage(e.target.src)

}


const handleUpload = (event) => {
    hiddenFileInput.current.click();
};



const [uploadimg,setUpload] = useState([])



// const removeImage = (i) => {
//     setFile(files.filter(x => x.name !== i));
// }


// console.log(canvasHeight, canvasWidth);
// console.log(color);


const [scaleCanvas, setScaleCanvas] = useState(1)

//Zoom
const handleScale = (e) => {

    setScaleCanvas(e.target.value);
}


// linethrough function start

const [linethrough, setLinethrough] = useState(false)



// overline function start
const [overline, setOverline] = useState(false)




if (editor) {
    if (editor.canvas.getActiveObject()) {
        if (editor.canvas.getActiveObject().uppercase) {
            editor.canvas.on("text:editing:exited", function (e) {
                editor.canvas.getActiveObject().set('uppercase', false)
                // console.log('updated text:', e.target.text);
                // console.log("text:editing:exited");
            });
        }
    }
}

// uppercase function end


//   ###################################################################################################################################################

// function duplicateObj() {
//     if(editor){
//         if(editor.canvas.getActiveObject()){
//                 editor.canvas.add(fabric.util.object.clone(editor.canvas.getActiveObject()))
//                 editor.canvas.renderAll();

//         }
//         }   
//     } 
// fabric.Cross = fabric.util.createClass(fabric.Object, {
//     type: 'cross',
//     initialize: function(options) {
//       this.callSuper('initialize', options);

//       this.width = 100;
//       this.height = 100;

//       this.w1 = this.h2 = 100;
//       this.h1 = this.w2 = 30;
//     },

//     _render: function(ctx) {
//       ctx.fillRect(-this.w1 / 2, -this.h1 / 2, this.w1, this.h1);
//       ctx.fillRect(-this.w2 / 2, -this.h2 / 2, this.w2, this.h2);
//     }

//   });
//   var TheClipboard
//   fabric.Cross.fromObject = function(object, callback) {
//     var cross = new fabric.Cross(object);
//     callback && callback(cross);
//     return cross;
//   };
//   function Copy() {
//     editor.canvas.getActiveObject().clone(function(cloned) {
//       TheClipboard = cloned;
//       console.log(TheClipboard)
//     });
//   }

//   function Paste() {
//     TheClipboard.clone(function(clonedObj) {
//         editor.canvas.discardActiveObject();
//       clonedObj.set({
//         left: clonedObj.left + 10,
//         top: clonedObj.top + 10,
//         evented: true,
//       });
//       if (clonedObj.type === 'activeSelection') {
//         // active selection needs a reference to the canvas
//         clonedObj.canvas = editor.canvas;
//         clonedObj.forEachObject(function(obj) {
//             editor.canvas.add(obj);
//         });
//         // this should solve the unselectability
//         clonedObj.setCoords();
//       } else {
//         editor.canvas.add(clonedObj);
//       }
//       TheClipboard.top += 10;
//       TheClipboard.left += 10;
//       editor.canvas.setActiveObject(clonedObj);
//       editor.canvas.requestRenderAll();
//     });
//   }

var _clipboard = null

function Copy() {
    if (editor) {
        if (editor.canvas.getActiveObject()) {
            if (editor.canvas.getActiveObject().isEditing == true) {
                return false;
            }
            editor.canvas.getActiveObject().clone(function (cloned) {
                _clipboard = cloned;
                if(editor.canvas.getActiveObject().type === 'activeSelection'){
                    _clipboard = cloned;
                }
            });
        }
    }
}

function Paste() {
    // clone again, so you can do multiple copies.
    if (editor) {
        _clipboard.clone(function (clonedObj) {
            editor.canvas.discardActiveObject();
            clonedObj.set({
                left: clonedObj.left + 10,
                top: clonedObj.top + 10,
                evented: true,
            });
            if (clonedObj.type === 'activeSelection') {
                // active selection needs a reference to the canvas.
                clonedObj.canvas = editor.canvas;
                clonedObj.forEachObject(function (obj) {
                    editor.canvas.add(obj);
                });
                // this should solve the unselectability
                clonedObj.setCoords();
            } else {
                editor.canvas.add(clonedObj);
            }
            console.log('paste');
            if (editor.canvas.getActiveObject()) {
                clonedObj.set({ top: editor.canvas.getActiveObject().get('top') + 10 })
                clonedObj.set({ top: editor.canvas.getActiveObject().get('left') + 10 })
            }
            else {
                _clipboard.top += 10;
                _clipboard.left += 10;
            }

            editor.canvas.setActiveObject(clonedObj);
            editor.canvas.requestRenderAll();
        });
    }
}
const STEP = 5;

var Direction = {
    LEFT: 0,
    UP: 1,
    RIGHT: 2,
    DOWN: 3
};

// useEffect(()=>{

window.addEventListener('keydown', function (options) {
    if (options.repeat) {
        return;
    }
    var key = options.which || options.keyCode; // key detection
    if (key === 37) { // handle Left key
        moveSelected(Direction.LEFT);
        console.log('move left');
    } else if (key === 38) { // handle Up key
        moveSelected(Direction.UP);
        console.log('move up');
    } else if (key === 39) { // handle Right key
        moveSelected(Direction.RIGHT);
        console.log('move right');
    } else if (key === 40) { // handle Down key
        moveSelected(Direction.DOWN);
        console.log('move down');
    }
    // if (key === 46) {
    //     // console.log('deleted');
    //     setBackgroundUpdate(false)
    //     try {
    //         editor.canvas.remove(editor.canvas.getActiveObject())
    //         // editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
    //         editor.canvas.renderAll()
    //         let activeObjects = editor.canvas.getActiveObjects();
    //         if (activeObjects.length) {
    //             activeObjects.forEach(function (object) {
    //                 editor.canvas.remove(object);
    //                 editor.canvas.renderAll()
    //             });
    //             editor.canvas.discardActiveObject().renderAll();
    //         }
    //     }
    //     catch {
    //     }
    // }
    //     if (e.which === 89 && e.ctrlKey) {
    //         // control + y
    //         //replay(redo, undo, '#undo', this);
    //     } else if (e.which === 90 && e.ctrlKey) {
    //         // control + z
    //         //replay(undo, redo, '#redo', this);
    //     } else 
    // if (options.which === 67 && options.ctrlKey) {
    //     // control + c
    //     options.preventDefault()
    //     Copy()
    //     console.log('copy');
    // } else if (options.which === 86 && options.ctrlKey) {
    //     // control + v
    //     options.preventDefault()
    //     Paste()
    //     console.log('paste');

    // }
});

useEffect(() => {
    window.addEventListener('keydown', e => {
        if (e.key === 'Delete') {
            console.log('delte');
            try {
                if(editor.canvas.getActiveObject()){
                    editor.canvas.remove(editor.canvas.getActiveObject())

                }
                // if(editor.canvas.getActiveObjects()){
                //     editor.canvas.remove(editor.canvas.getActiveObjects())
                //     editor.canvas.discardActiveObject()

                // }
                editor.canvas.discardActiveObject(editor.canvas.getActiveObject());
                let activeObjects = editor.canvas.getActiveObjects();
                if (activeObjects.length) {
                        activeObjects.forEach(function (object) {
                            editor.canvas.remove(object);
                        });
                }
            }
            catch {
            }
        }

    })


});


// },[])
function moveSelected(direction) {
    if (editor) {
        if (editor.canvas.getActiveObject()) {
            switch (direction) {
                case Direction.LEFT:
                    editor.canvas.getActiveObject().set({ left: editor.canvas.getActiveObject().get('left') - STEP });
                    break;
                case Direction.UP:
                    editor.canvas.getActiveObject().set({ top: editor.canvas.getActiveObject().get('top') - STEP });
                    break;
                case Direction.RIGHT:
                    editor.canvas.getActiveObject().set({ left: editor.canvas.getActiveObject().get('left') + STEP });
                    break;
                case Direction.DOWN:
                    editor.canvas.getActiveObject().set({ top: editor.canvas.getActiveObject().get('top') + STEP });
                    break;
            }
            editor.canvas.getActiveObject().setCoords();
            editor.canvas.renderAll();
            console.log('selected objects was moved');
        } else {
            console.log('no object selected');
        }
        editor.canvas.renderAll()
    }

}







const [counterTemp, setTempCounter] = useState(0)

//fontSize
// Function is called everytime increment button is clicked
const increaseFontSize = (e) => {
    // Counter state is incremented
    setCounter(parseInt(editor.canvas.getActiveObject().fontSize) + 1)
    console.log('cliked')
    setTempCounter(false)

    // handleSize()
}

// console.log(counter);
// console.log(counterTemp);


// Function is called everytime decrement button is clicked
const decreaseFontSize = (e) => {
    // Counter state is decremented
    setCounter(parseInt(editor.canvas.getActiveObject().fontSize) - 1)
    // handleSize()
    setTempCounter(true)

}
const fontSizeTemp = useRef(true)




// const handleFontSizeChange = (e) => {
//     setCounter(e.target.value)
// }
const fontsizeTEmp2 = useRef(true)
useEffect(() => {

    if (fontsizeTEmp2.current) {
        fontsizeTEmp2.current = false
        return;
    }

    if (editor) {
        if (editor.canvas.getActiveObject()) {
            editor.canvas.getActiveObject().set("fontSize", `${counter}`)
            editor.canvas.renderAll();


        }
    }
}, [counter])

// editor?.canvas.on('object:scaling', function (event) {
//     if (editor) {
//         setCounter((counter * editor.canvas.getActiveObject().scaleX).toFixed(0))
//     }
// })








// ############################# 20/08/2022 individual character styles formating  // dhananjezhian



//    const check2=()=>{
//         // console.log(editor.canvas.getActiveObject().get('text')[2]);
//         // let temp = editor.canvas.getActiveObject().get('text').length
//         // editor.canvas.getActiveObject().setSelectionStyles(({ fontWeight: 'bold' }), editor.canvas.getActiveObject().selectionStart+1, editor.canvas.getActiveObject().selectionEnd-1)
//         // editor.canvas.renderAll()
//         editor.canvas.getActiveObject().getSelectedText().forEach(element => {
//             console.log(element);
//         });

//    }
//     const check =()=>{
//         // console.log(editor.canvas.getActiveObject().getSelectedText()[1]);
//         let length = editor.canvas.getActiveObject().getSelectedText().length
//         // // for(let i = 0;i<editor.canvas.getActiveObject().getSelectedText().length,i++){

//         // // }     
//         // if(editor.canvas.getActiveObject().getSelectedText()){
//         //     editor.canvas.getActiveObject().setSelectionStyles(({ fontWeight: 'bold' }),editor.canvas.getActiveObject().selectionStart,editor.canvas.getActiveObject().selectionEnd - 5  )
//         // }
//         // editor.canvas.renderAll()
//          // //     active.getActiveObject().setSelectionStyles(({ fontWeight: 'bold' }), 0, temp)
//          let wordcount = 0 
//          let space =0
//          let bold =0
//          if(editor){
//              if(editor.canvas.getActiveObject().getSelectedText()){
//                  for (let i = 0; i < editor.canvas.getActiveObject().getSelectedText().length; i++) {
//                      if (editor.canvas.getActiveObject().getSelectedText()[i] != ' '){
//                         wordcount++
//                         console.log(editor.canvas.getActiveObject().getSelectedText()[i]);
//                          if(editor.canvas.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold'){
//                             bold++
//                         }
//                      }
//                      else {
//                          space++
//                      }
//                  }
//                  console.log('bold',bold+", word:"+ wordcount,'space:'+space);
//              }
//          }

//         //  console.log(editor.canvas.getActiveObject().getSelectionStyles());
//     }


// editor?.canvas.on('text:selection:changed', () => {
//     let wordcount = 0
//     let space = 0
//     let bold = 0
//     // if(editor.canvas.getActiveObject().getSelectedText()){
//     // console.log('veri 2');
//     for (let i = 0; i < editor.canvas.getActiveObject().getSelectedText().length; i++) {
//         if (editor.canvas.getActiveObject().getSelectedText()[i] != ' ') {
//             // console.log('veri 3');
//             wordcount++
//             //    console.log(editor.canvas.getActiveObject().getSelectedText()[i]);
//             if (editor.canvas.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold') {
//                 bold++
//                 //    console.log('veri 4 ');
//             }
//         }
//         else {
//             space++
//         }
//     }
//     console.log('bold', bold + ", word:" + wordcount, 'space:' + space);
//     if (bold == wordcount) {
//         // console.log('veri 4');
//         setBold(true)
//     }
//     else {
//         setBold(false)
//     }
//     // }

// })

//  if(editor){
//     if(editor.canvas.getActiveObject()?.type=='textbox'){
//         console.log('yes textbox');

//     }
//  }
// useEffect(()=>{
//         editor?.canvas.on('text:selection:changed', ()=>{
//             let wordcount = 0 
//             let space =0
//              let bold =0
//             if(editor.canvas.getActiveObject().getSelectedText()){
//                 console.log('veri 2');
//                 for (let i = 0; i < editor.canvas.getActiveObject().getSelectedText().length; i++) {
//                     if (editor.canvas.getActiveObject().getSelectedText()[i] != ' '){
//                         console.log('veri 3');
//                        wordcount++
//                        console.log(editor.canvas.getActiveObject().getSelectedText()[i]);
//                         if(editor.canvas.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold'){
//                            bold++
//                            console.log('veri 4 ');
//                        }
//                     }
//                     else {
//                         space++
//                     }
//                 }
//                 console.log('bold',bold+", word:"+ wordcount,'space:'+space);
//                 if(bold==wordcount){
//                     console.log('veri 4');
//                     setBold(true)
//                 }
//                 else{
//                    setBold(false)
//                 }
//             }
//             else{
//                 let temp = editor.canvas.getActiveObject().get('text').length  
//                 // for(let i=0;i<temp;i++){
//                 //     if(editor.canvas.getActiveObject().get('text')[i] != ' '){
//                 //             console.log('yes');
//                 //     }
//                 // } 
//                 setBold(false)
//             }
//          })
//          if(editor){
//             if(editor.canvas.getActiveObject()?.type=='textbox'){
//                 console.log('yes textbox');

//             }
//          }

// },[editor])

const handleBold = () => {
    const active = editor.canvas
    if (editor.canvas.getActiveObject().getSelectedText()) {
        let boldcount = 0
        for (let i = 0; i < active.getActiveObject().getSelectedText().length; i++) {
            if (active.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold') {
                // console.log('yes  ');
                boldcount++
            }
            else {
                // console.log('yes  ');
            }
        }

        console.log('bold-count', boldcount);
        let temp = active.getActiveObject().get('text').length

        if (boldcount === (active.getActiveObject().getSelectedText().length)) {
            console.log('unbold')
            active.getActiveObject().setSelectionStyles({ fontWeight: 'normal' })
            active.renderAll()

        }
        else if (boldcount === 0) {
            console.log('bold');
            active.getActiveObject().setSelectionStyles({ fontWeight: 'bold' })

            active.renderAll()

        }
        else if (boldcount >= active.getActiveObject().getSelectedText().length / 2) {
            console.log('bold')
            active.getActiveObject().setSelectionStyles({ fontWeight: 'bold' })


        }
        else if (boldcount <= active.getActiveObject().getSelectedText().length / 2) {
            console.log('unbold')
            active.getActiveObject().setSelectionStyles({ fontWeight: 'normal' })

        }
        //     let wordcount = 0 
        //  let space =0
        //  let bold =0
        //  if(editor){
        //      if(editor.canvas.getActiveObject().getSelectedText()){
        //          for (let i = 0; i < editor.canvas.getActiveObject().getSelectedText().length; i++) {
        //              if (editor.canvas.getActiveObject().getSelectedText()[i] != ' '){
        //                 wordcount++
        //                 console.log(editor.canvas.getActiveObject().getSelectedText()[i]);
        //                  if(editor.canvas.getActiveObject().getSelectionStyles()[i].fontWeight === 'bold'){
        //                     bold++
        //                 }
        //              }
        //              else {
        //                  space++
        //              }
        //          }
        //          console.log('bold',bold+", word:"+ wordcount,'space:'+space);
        //         //  if(bold==wordcount){
        //         //     setBold(true)
        //         //  }
        //         //  else{
        //         //     setBold(false)
        //         //  }
        //         editor.canvas.on('text:selection', function(e) {
        //             //this will only work using the dev build
        //               if(bold == wordcount){
        //                 setBold(true)
        //                 }
        //             else{
        //                 setBold(false)
        //                 }
        //         });
        //      }
        //  }

    }
    else {
        if (active.getActiveObject().fontWeight == 'normal') {
            let temp = active.getActiveObject().get('text').length
            active.getActiveObject().setSelectionStyles(({ fontWeight: 'bold' }), 0, temp)
            active.getActiveObject().set({ fontWeight: 'bold' })
            setBold(true)

        }
        else {
            let temp = active.getActiveObject().get('text').length
            active.getActiveObject().setSelectionStyles(({ fontWeight: 'normal' }), 0, temp)
            active.getActiveObject().set({ fontWeight: 'normal' })
            setBold(false)
        }
    }
    active.renderAll()
}
const handleItalic = () => {
    const active = editor.canvas
    if (editor.canvas.getActiveObject().getSelectedText()) {
        let italiccount = 0
        for (let i = 0; i < active.getActiveObject().getSelectedText().length; i++) {
            if (active.getActiveObject().getSelectionStyles()[i].fontStyle === 'italic') {
                // console.log('yes im true');
                italiccount++
            }
            else {
                // console.log('yes im false');
            }
        }
        console.log('italic-count', italiccount);
        let temp = active.getActiveObject().get('text').length
        if (italiccount === (active.getActiveObject().getSelectedText().length)) {
            console.log('unitalic')
            console.log(active.getActiveObject().getSelectedText().length / 2)
            active.getActiveObject().setSelectionStyles({ fontStyle: 'normal' })
            if (italiccount === temp) {
                active.getActiveObject().set({ fontStyle: 'normal' })
                setItalic(false)
            }
            setItalic(false)
            active.renderAll()
        }
        else if (italiccount === 0) {
            console.log('italic');
            active.getActiveObject().setSelectionStyles({ fontStyle: 'italic' })
            if (italiccount === temp) {
                setItalic(true)
                active.getActiveObject().set({ fontStyle: 'italic' })
            }
            setItalic(false)
        }
        else if (italiccount >= active.getActiveObject().getSelectedText().length / 2) {
            console.log('italic')
            active.getActiveObject().setSelectionStyles({ fontStyle: 'italic' })
            setItalic(false)
            active.getActiveObject().set({ fontStyle: 'normal' })
        }
        else if (italiccount <= active.getActiveObject().getSelectedText().length / 2) {
            console.log('unitalic')
            active.getActiveObject().setSelectionStyles({ fontStyle: 'normal' })
            setItalic(false)
            active.getActiveObject().set({ fontStyle: 'normal' })
        }

    }
    else {
        if (active.getActiveObject().fontStyle == 'normal') {
            let temp = active.getActiveObject().get('text').length
            active.getActiveObject().setSelectionStyles(({ fontStyle: 'italic' }), 0, temp)
            active.getActiveObject().set({ fontStyle: 'italic' })
            setItalic(true)
        }
        else {
            let temp = active.getActiveObject().get('text').length
            active.getActiveObject().setSelectionStyles(({ fontStyle: 'normal' }), 0, temp)
            active.getActiveObject().set({ fontStyle: 'normal' })
            setItalic(false)

        }
    }
    active.renderAll()
}
const handleUnderline = () => {
    const active = editor.canvas
    if (editor.canvas.getActiveObject().getSelectedText()) {
        let Underlinecount = 0
        for (let i = 0; i < active.getActiveObject().getSelectedText().length; i++) {
            if (active.getActiveObject().getSelectionStyles()[i].underline === true) {
                // console.log('yes im true');
                Underlinecount++
            }
            else {
                // console.log('yes im false');
            }
        }
        console.log('Underlinecount', Underlinecount);
        let temp = active.getActiveObject().get('text').length
        if (Underlinecount === (active.getActiveObject().getSelectedText().length)) {
            console.log('un-Underline')
            console.log(active.getActiveObject().getSelectedText().length / 2)
            active.getActiveObject().setSelectionStyles({ underline: false })
            if (Underlinecount === temp) {
                active.getActiveObject().set({ underline: false })
                setUnderline(false)
            }
            setUnderline(false)
            active.renderAll()
        }
        else if (Underlinecount === 0) {
            console.log('underline');
            active.getActiveObject().setSelectionStyles({ underline: true })
            if (Underlinecount === temp) {
                setUnderline(true)
                active.getActiveObject().set({ underline: true })
            }
            setUnderline(false)
        }
        else if (Underlinecount >= active.getActiveObject().getSelectedText().length / 2) {
            console.log('underline')
            active.getActiveObject().setSelectionStyles({ underline: true })
            setUnderline(false)
            active.getActiveObject().set({ underline: false })
        }
        else if (Underlinecount <= active.getActiveObject().getSelectedText().length / 2) {
            console.log('un-underline')
            active.getActiveObject().setSelectionStyles({ underline: false })
            setUnderline(false)
            active.getActiveObject().set({ underline: false })
        }

    }
    else {
        if (active.getActiveObject().underline == false) {
            let temp = active.getActiveObject().get('text').length
            active.getActiveObject().setSelectionStyles(({ underline: true }), 0, temp)
            active.getActiveObject().set({ underline: true })
            setUnderline(true)
        }
        else {
            let temp = active.getActiveObject().get('text').length
            active.getActiveObject().setSelectionStyles(({ underline: false }), 0, temp)
            active.getActiveObject().set({ underline: false })
            setUnderline(false)

        }
    }
    active.renderAll()
}
const handlinethrough = () => {
    const active = editor.canvas
    if (editor.canvas.getActiveObject().getSelectedText()) {
        let linethroughcount = 0
        for (let i = 0; i < active.getActiveObject().getSelectedText().length; i++) {
            if (active.getActiveObject().getSelectionStyles()[i].linethrough === true) {
                // console.log('yes im true');
                linethroughcount++
            }
            else {
                // console.log('yes im false');
            }
        }
        console.log('linethroughcount', linethroughcount);
        let temp = active.getActiveObject().get('text').length
        if (linethroughcount === (active.getActiveObject().getSelectedText().length)) {
            console.log('un-linethrough')
            console.log(active.getActiveObject().getSelectedText().length / 2)
            active.getActiveObject().setSelectionStyles({ linethrough: false })
            if (linethroughcount === temp) {
                active.getActiveObject().set({ linethrough: false })
                setLinethrough(false)
            }
            setLinethrough(false)
            active.renderAll()
        }
        else if (linethroughcount === 0) {
            console.log('linethrough');
            active.getActiveObject().setSelectionStyles({ linethrough: true })
            if (linethroughcount === temp) {
                setLinethrough(true)
                active.getActiveObject().set({ linethrough: true })
            }
            setLinethrough(false)
        }
        else if (linethroughcount >= active.getActiveObject().getSelectedText().length / 2) {
            console.log('linethrough')
            active.getActiveObject().setSelectionStyles({ linethrough: true })
            setLinethrough(false)
            active.getActiveObject().set({ linethrough: false })
        }
        else if (linethroughcount <= active.getActiveObject().getSelectedText().length / 2) {
            console.log('un-linethrough')
            active.getActiveObject().setSelectionStyles({ linethrough: false })
            active.getActiveObject().set({ linethrough: false })
            setLinethrough(false)
        }

    }
    else {
        if (active.getActiveObject().linethrough == false) {
            let temp = active.getActiveObject().get('text').length
            active.getActiveObject().setSelectionStyles(({ linethrough: true }), 0, temp)
            active.getActiveObject().set({ linethrough: true })
            setLinethrough(true)
        }
        else {
            let temp = active.getActiveObject().get('text').length
            active.getActiveObject().setSelectionStyles(({ linethrough: false }), 0, temp)
            active.getActiveObject().set({ linethrough: false })
            setLinethrough(false)

        }
    }
    active.renderAll()
}
const handoverline = () => {
    const active = editor.canvas
    if (editor.canvas.getActiveObject().getSelectedText()) {
        let overlinecount = 0
        for (let i = 0; i < active.getActiveObject().getSelectedText().length; i++) {
            if (active.getActiveObject().getSelectionStyles()[i].overline === true) {
                // console.log('yes im true');
                overlinecount++
            }
            else {
                // console.log('yes im false');
            }
        }
        console.log('overlinecount', overlinecount);
        let temp = active.getActiveObject().get('text').length
        if (overlinecount === (active.getActiveObject().getSelectedText().length)) {
            console.log('un-overline')
            console.log(active.getActiveObject().getSelectedText().length / 2)
            active.getActiveObject().setSelectionStyles({ overline: false })
            if (overlinecount === temp) {
                active.getActiveObject().set({ overline: false })
                setOverline(false)
            }
            setOverline(false)
            active.renderAll()
        }
        else if (overlinecount === 0) {
            console.log('overline');
            active.getActiveObject().setSelectionStyles({ overline: true })
            if (overlinecount === temp) {
                setOverline(true)
                active.getActiveObject().set({ overline: true })
            }
            setOverline(false)
        }
        else if (overlinecount >= active.getActiveObject().getSelectedText().length / 2) {
            console.log('overline')
            active.getActiveObject().setSelectionStyles({ overline: true })
            setOverline(false)
            active.getActiveObject().set({ overline: false })
        }
        else if (overlinecount <= active.getActiveObject().getSelectedText().length / 2) {
            console.log('un-overline')
            active.getActiveObject().setSelectionStyles({ overline: false })
            active.getActiveObject().set({ overline: false })
            setOverline(false)
        }

    }
    else {
        if (active.getActiveObject().overline == false) {
            let temp = active.getActiveObject().get('text').length
            active.getActiveObject().setSelectionStyles(({ overline: true }), 0, temp)
            active.getActiveObject().set({ overline: true })
            setOverline(true)
        }
        else {
            let temp = active.getActiveObject().get('text').length
            active.getActiveObject().setSelectionStyles(({ overline: false }), 0, temp)
            active.getActiveObject().set({ overline: false })
            setOverline(false)

        }
    }
    active.renderAll()
}

const [elementsobject, setElementsobject] = useState(false)
const ele = (e) => {

    setElementsobject(true)
    fabric.loadSVGFromURL(e.target.src, function (objects, options) {
        var loadedObjects = fabric.util.groupSVGElements(objects, options);
        loadedObjects.set({ subTargetCheck: true })
        loadedObjects.scaleToWidth(editor.canvas.width / 3);
        loadedObjects.scaleToHeight(editor.canvas.height / 3);
        editor.canvas.add(loadedObjects);
        editor.canvas.setActiveObject(loadedObjects);

        editor.canvas.renderAll();
    });

}


if (editor) {
    if (editor.canvas.getActiveObject()?.type == 'group') {
        editor.canvas.on('mouse:dblclick', function (e) {
            // clicked item will be
            //   console.log()  
            if (editor.canvas.getActiveObject()?.type == 'group') {
                editor.canvas.setActiveObject(e.subTargets[0]);
            }
            //   editor.canvas.renderAll()
        });
    }
}



// function deleteObject(eventData, transform) {
//     var target = transform.target;
//     var canvas = target.canvas;
//     canvas.remove(target);
//     canvas.requestRenderAll();
// }

function cloneObject(eventData, transform) {
    var target = transform.target;
    var canvas = target.canvas;
    target.clone(function (cloned) {
        cloned.left += 10;
        cloned.top += 10;
        canvas.add(cloned);
        canvas.renderAll()
    });
}



function renderIcon(icon) {
    return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(icon, -size / 2, -size / 2, size, size);
        ctx.restore();
    }
}


let deleteIcon = closebutton.toString()

let cloneIcon = cloneiconbut.toString()

let deleteImg = document.createElement('img');
deleteImg.src = deleteIcon;

let cloneImg = document.createElement('img');
cloneImg.src = cloneIcon;


fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: 16,
    cursorStyle: 'pointer',
    mouseUpHandler: removeActiveobject,
    render: renderIcon(deleteImg),
    cornerSize: 24
});

fabric.Object.prototype.controls.clone = new fabric.Control({
    x: -0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: -16,
    cursorStyle: 'pointer',
    mouseUpHandler: cloneObject,
    render: renderIcon(cloneImg),
    cornerSize: 24
});

fabric.Textbox.prototype.controls.clone = new fabric.Control({
    x: -0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: -16,
    cursorStyle: 'pointer',
    mouseUpHandler: cloneObject,
    render: renderIcon(cloneImg),
    cornerSize: 24
});

fabric.Textbox.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: 16,
    cursorStyle: 'pointer',
    mouseUpHandler: removeActiveobject,
    render: renderIcon(deleteImg),
    cornerSize: 24
});


const [canvasbgcolor, setCanvasbgcolor] = useState('rgba(255,255,255,1)')

const Eye = () => {
    const { open, close } = useEyeDropper()
    const [eyecolor, setEyecolor] = useState(canvasbgcolor)
    const [eyeerror, setEyeerror] = useState()
    // useEyeDropper will reject/cleanup the open() promise on unmount,
    // so setState never fires when the component is unmounted.
    const pickColor = () => {
        open()
            .then(eyecolor => setEyecolor(eyecolor.sRGBHex))
            .catch(e => {
                console.log(e)
                // Ensures component is still mounted
                // before calling setState
                if (!e.canceled) setEyeerror(e)
            })
    }
    setCanvasbgcolor(eyecolor)
    return (
        <>
            <button onClick={pickColor} className="bgeye" style={{ border: 'none', }}><ColorizeIcon /></button>
            {!!eyeerror && <span>{eyeerror.message}</span>}
        </>
    )
}


const addbgimg = (e) => {
    // fabric.Image.fromURL(
    //     e.target.src,
    //     function (bg) {
    //         editor.canvas.setBackgroundImage(bg);
    //         editor.canvas.renderAll();

    //     }
    // );
    var filterBrightness = new fabric.Image.filters.Brightness({
        brightness: 0,
    });
    var filterContrast = new fabric.Image.filters.Contrast({
        contrast: 0,
    });
    var filterSaturation = new fabric.Image.filters.Saturation({
        saturation: 0,
    });
    var filterBlur = new fabric.Image.filters.Blur({
        blur: 0,
    });
    var filterVibrance = new fabric.Image.filters.Vibrance({
        vibrance: 0,
    });

    var filterNoice = new fabric.Image.filters.Noise({
        noise: 0,
    });

    var filterHue = new fabric.Image.filters.HueRotation({
        rotation: 0,
    });
    // var filterPixalate = new fabric.Image.filters.Pixelate({
    //     blocksize: 0,
    // });



    fabric.Image.fromURL(e.target.src, (bg) => {
        // bg.scaleToWidth(200);

        var obg = bg.set({

            top: 0,
            lef: 0,
         
        })


        obg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)
        // editor.canvas.setBackgroundImage(obg);
        editor.canvas.add(obg)
        editor.canvas.setActiveObject(obg)
        editor.canvas.getActiveObject().set({
            
            id: 'background',
            top: 0,
            left: 0,
            originX: 'left',
            originY: 'top',
            crossOrigin: 'anonymous',
            scaleX: editor.canvas.getWidth()/obg.width,
            scaleY: editor.canvas.getHeight()/obg.height,

        })
        // editor.canvas.setBackgroundImage(editor.canvas.getActiveObject());

        editor.canvas.renderAll();
        setToolbar(true);
        setBackgroundUpdate(true);
        setDisableLi(true)

    }, { crossOrigin: 'anonymous' });

};

// const [yes, setYes] = useState(false)
const [backgroundEdit, setBackgroundEdit] = useState(false)


if (editor) {
    if (editor.canvas) {
        editor.canvas.on('mouse:dblclick', function (e) {
            // clicked item will be
            //   console.log()  
            if (!editor.canvas.getActiveObject()) {
                if (editor.canvas.backgroundImage.id == 'background') {
                    console.log(editor.canvas.backgroundImage.id);
                    // editor.canvas.hoverCursor = 'move'
                    // editor.canvas.setActiveObject(editor.canvas.backgroundImage)
                    setBackgroundEdit(true)
                    editor.canvas.renderAll()
                }
            }
            //   editor.canvas.renderAll()
        });
    }
}






const clearAll = () => {
    editor.canvas.clear();

}





//   const addasbackground = (file) => {

//     var filterBrightness = new fabric.Image.filters.Brightness({
//         brightness: 0,
//     });
//     var filterContrast = new fabric.Image.filters.Contrast({
//         contrast: 0,
//     });
//     var filterSaturation = new fabric.Image.filters.Saturation({
//         saturation: 0,
//     });
//     var filterBlur = new fabric.Image.filters.Blur({
//         blur: 0,
//     });
//     var filterVibrance = new fabric.Image.filters.Vibrance({
//         vibrance: 0,
//     });

//     var filterNoice = new fabric.Image.filters.Noise({
//         noise: 0,
//     });

//     var filterHue = new fabric.Image.filters.HueRotation({
//         rotation: 0,
//     });
//     // var filterPixalate = new fabric.Image.filters.Pixelate({
//     //     blocksize: 0,
//     // });





//     fabric.Image.fromURL(URL.createObjectURL(file), (bg) => {
//         var obg = bg.set({
//             id: 'background',

//         })

//         obg.filters.push(filterBrightness, filterContrast, filterSaturation, filterBlur, filterVibrance, filterNoice, filterHue)
//         editor.canvas.setBackgroundImage(obg);
//         editor.canvas.renderAll();


//     });

//   }

//   const addaselement = (file) => {
//     setElementsobject(true)
//     fabric.loadSVGFromURL(URL.createObjectURL(file), function (objects, options) {
//         var loadedObjects = fabric.util.groupSVGElements(objects, options);
//         loadedObjects.set({ subTargetCheck: true })
//         loadedObjects.scaleToWidth(editor.canvas.width / 3);
//         loadedObjects.scaleToHeight(editor.canvas.height / 3);
//         editor.canvas.add(loadedObjects);
//         editor.canvas.setActiveObject(loadedObjects);

//         editor.canvas.renderAll();
//     });
// }

const [backgroundUpdate, setBackgroundUpdate] = useState(false)

const setAsbackgroundIm = () => {

    setDisableLi(true)
    if (editor) {
        if (editor.canvas.getActiveObject()) {

            // editor.canvas.setBackgroundImage(editor.canvas.getActiveObject(),editor.canvas.renderAll.bind(editor.canvas), {
            editor.canvas.getActiveObject().set({
                top: 0,
                left: 0,
                originX: 'left',
                originY: 'top',
                scaleX: editor.canvas.getWidth() / editor.canvas.getActiveObject().width,
                scaleY: editor.canvas.getHeight() / editor.canvas.getActiveObject().height,
                crossOrigin: 'anonymous',
                // id: 'background'

            });
            // editor.canvas.remove(editor.canvas.getActiveObject())
            editor.canvas.renderAll();
            console.log('clicked');
            setBackgroundUpdate(true)
        }
    }

}

const handleConfirmBg = () => {
    editor.canvas.setBackgroundImage(editor.canvas.getActiveObject(), editor.canvas.renderAll.bind(editor.canvas), {
        id: 'background',
        scaleX: (editor.canvas.getWidth() / editor.canvas.getActiveObject().width)+0.01,
        scaleY: (editor.canvas.getHeight() / editor.canvas.getActiveObject().height)+0.01,

    })
    editor.canvas.remove(editor.canvas.getActiveObject())
    editor.canvas.renderAll()
    setBackgroundUpdate(false)
    setBackgroundEdit(false)
    setDisableLi(false)



}

const handleCancelBg = () => {
    setBackgroundUpdate(false)
    setDisableLi(false)

    editor.canvas.getActiveObject().set({
        top: 0,
        left: 0,
        scaleX: 0.4,
        scaleY: 0.4
    })
}


const removeBackground = () => {

    editor.canvas.setBackgroundImage(null, editor.canvas.renderAll.bind(editor.canvas));
    setCanvasbgcolor("#fff")
    setBackgroundEdit(false)

    editor.canvas.renderAll();

}
const [images, setImages] = useState()
const [bgimages, setBgimages] = useState()
const Auth = '563492ad6f917000010000012ccdde633dac40949cc7797a1528cf8b'


// async function api() {
//     // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
//     const response = await fetch(`https://pixabay.com/api/?key=29795073-12ae1a787134bd2ba0260ea8f&q=${photosearchword}&image_type=photo&per_page=100`);
//     const jsonifiedResponse = await response.json();
//     // console.log(jsonifiedResponse);
//     setImages(jsonifiedResponse.hits)
// }
// async function bgapi() {
//     // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
//     const response = await fetch(`https://pixabay.com/api/?key=29795073-12ae1a787134bd2ba0260ea8f&q=pattern&image_type=photo&per_page=200`);
//     const jsonifiedResponse = await response.json();
//     // console.log(jsonifiedResponse);
//     setBgimages(jsonifiedResponse.hits)
// }

async function api() {
    // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
    const response = await fetch(`https://api.pexels.com/v1/search?query=${photosearchword}&per_page=100`,{
        method:"GET",
        headers:{
            Accept:"application/json",
            Authorization: Auth
        }
    });
    const jsonifiedResponse = await response.json();
    console.log(jsonifiedResponse.photos);
    setImages(jsonifiedResponse.photos)
}
async function bgapi() {
    // const response = await fetch(`https://picsum.photos/v2/list?page=2&limit=100`);
    const response = await fetch(`https://api.pexels.com/v1/search?query=background&per_page=100`,{
        method:"GET",
        headers:{
            Accept:"application/json",
            Authorization: Auth
        }
    });
    const jsonifiedResponse = await response.json();
    // console.log(jsonifiedResponse);
    setBgimages(jsonifiedResponse.photos)
}

// //   api()

useEffect(() => {
    api()
    bgapi()
},[])

// console.log(images);

const filters = {
    imageWidth: 4000,
    imageHeight:4000 ,
};

const result = images?.filter((o) =>
    Object.keys(filters).every((k) => filters[k] >= o[k])
);
//   console.log(result)

const photosearchinputer = useRef()
const [photosearchword,setPhotosearchword] = useState('random')










const [call, setCall] = useState(1)

const [tempid,setTempid] = useState(1) 



// const [thumbData , setThumbData] = useState(null)

const [temp,setTemp] = useState([])
const [design,setDesign] = useState([])



//   const setJsonData = () => {

// }

// useEffect(() => {
//     if(apiData != null){
//       setThumbData((JSON.parse(apiData)))

//     }
// },[apiData])
const [buttontext,setButtontext] = useState('Save as Template')
const [reusedtempboolen,setReusedtempboolen] = useState(false) 
const [reusedtempname,setReusedtempname ] = useState(null)
const [buttontext2,setButtontext2] = useState('Save as Design')
const [designid,setDesignid] = useState(null)
const [reuseddesignname,setReuseddesignname] = useState(null)
const [reuseddesignboolen,setReuseddesignboolen] = useState(false)




// if(editor){
//     if(!reuseddesignboolen){
//         editor.canvas.on('object:added', saveAsDesign);
//         editor.canvas.on('object:removed', saveAsDesign);
//         editor.canvas.on('object:modified', saveAsDesign);
//     }
// }

// const [runonetime,setrunonetime] =useState(1)
// if(runonetime == 1){
//     if(editor){
//         loaddesignJsonCan()
//         editor.canvas.renderAll()
//         setrunonetime(runonetime+1)
//     }
// }





// const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
//     editor?.canvas.on('mouse:down', function(options) {
//         // console.log(options.e.layerX, options.e.layerY);
//         setAnchorPoint({ x: options.e.layerX, y: options.e.layerY });
//     });

//     const [show, setShow] = useState(false); // hide menu

//     const handleContextMenu = useCallback(
//       (event) => {
//         event.preventDefault();  
//         // setAnchorPoint({ x: event.pageX, y: event.pageY });
//         setShow(true);
//         console.log(anchorPoint);
//       },
//       [setAnchorPoint]
//     );

//     const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

//     useEffect(() => {
//       document.addEventListener("click", handleClick);
//       document.addEventListener("contextmenu", handleContextMenu);
//       return () => {
//         document.removeEventListener("click", handleClick);
//         document.removeEventListener("contextmenu", handleContextMenu);
//       };
//     });


const [contextMenu, setContextMenu] = React.useState(null);

const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
        contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
            }
            : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
    );
};

const handleClose = () => {
    setContextMenu(null);
};



const [canvasismodified,setCanvasismodified] = useState(false)
const [canvasstatechange,setCanvasstatechange] = useState(1)

const canvasModifiedCallback = function() {
    setCanvasismodified(true)
    setCanvasstatechange(canvasstatechange+1)

};

if(editor){
    editor.canvas.on('object:added', canvasModifiedCallback);
    editor.canvas.on('object:removed', canvasModifiedCallback);
    editor.canvas.on('object:modified', canvasModifiedCallback);
    // editor.canvas.on('text:changed', canvasModifiedCallback);
    editor.canvas.on('text:editing',canvasModifiedCallback)
    editor.canvas.on('text:editing:exited', canvasModifiedCallback);
}








const reusedesigntem = useRef(true)

// useEffect(()=>{
//     if(reusedesigntem){
//         reusedesigntem.current = false
//         return
//     }
//     (async function () {
//         // var formdata = new FormData();
//         // formdata.append("canvas_json", tempdata);
//         // formdata.append("languages", result);

//         var requestOptions = {
//             method: 'GET',
//             headers: handleAuthTrue(new Headers()),
//             // body: formdata,
//             redirect: 'follow'
//         };

//         let data = await fetch(LABS_API + "/canvas/canvas-designs/", requestOptions)
//         if (data.status === 200) {
//             let response = await data.json()
//             setName(response[response.length-1].file_name)
//             setDesignid(response[response.length-1].id)
//             setReuseddesignname(response[response.length-1].file_name)
//             setReuseddesignboolen(true)
//             setReusedtempboolen(false)
//             setCanvaswidth(response[response.length-1].width)
//             setCanvasHeight(response[response.length-1].height)
//             setApiData(response[response.length-1].template_json)
//             // if(name === reuseddesignname){
//             //     console.log('yes');
//             //     setButtontext2('save')
//             // }
//             // else{
//             //     console.log('no');
//             //     setButtontext2('Save as Design');

//             // }
//             // setMultilingualCanvasData(JSON.parse(response.result_canvas))
//             loadJsonCan()

//         }
//         else {

//             console.log('error');
//         }
//     })();
// },[call])











// const check=()=>{
//     // console.log(reusedtempname);
//     // console.log(name);
//     console.log(temp)
//     // console.log(JSON.parse(reusedtemp).objects);
//     // console.log(JSON.stringify(editor.canvas).objects);
//     // let data = JSON.stringify(editor.canvas)
//     // if(reusedtemp.objects == data.objects){
//     //     console.log('yes');
//     // }
//     // else{
//     //     console.log('no');
//     // }
// }






  const check=()=>{
    // console.log(editor.canvas.getActiveObject().type);
    // console.log(editor.canvas.getActiveObjects().length);
    console.log(editor.canvas.getObjects()[1]);
}


const [disabled, setDisabled] = useState(false)
const [alltext, setAlltext] = useState(false)
const [multiselect, setMultiselect] = useState(false)
const [groupname, setgroupname] = useState('Group')




// editor?.canvas.on('object:added', getJsonData);
// // editor?.canvas.on('object:removed', getJsonData);
// editor?.canvas.on('object:modified', getJsonData);
    const win = window.localStorage;
    //    useEffect(()=>{
    //     if(win.name == null){
    //         setName('Document name')
    //     }
    //     else{
    //         setName(win.getItem('name'))
    //     }
    //     if(win.name == null){
    //         editor.canvas.loadFromJSON(JSON.parse(win.getItem('json')))
    //     }
    //     else{
    //         // editor.canvas.loadFromJSON(JSON.parse(win.getItem('json')))
    //     }
    //    },[])
        // const session = useRef(true)
            // if(session.current){
            //     session.current = false
            //     return
            // }
            // if(editor){
            // }
            // useEffect(()=>{
            //     win.setItem('name',name)
            //     win.setItem('json', JSON.stringify(jsdata));
            //     // console.log('step3');
            // },[editor,name])

            // reusedtempboolen
            // reuseddesignboolen

            // editor?.canvas.on('object:added',()=>{
            //     if(!reuseddesignboolen){
            //         editor?.canvas.on('object:added', saveAsDesign);
            //         editor?.canvas.on('object:removed', saveAsDesign);
            //         editor?.canvas.on('object:modified', saveAsDesign);
            //     }
            // })
       
                // editor?.canvas.on('object:added', ()=>{
                //     if(!reuseddesignboolen && canvasismodified){
                //         saveAsDesign()
                //     }
                // })

    useEffect(()=>{
       


        // if(!reusedtempboolen && !reuseddesignboolen){
        //     // if(editor?.canvas.getObjects().length == 1){
        //     //     console.log('step 2');
        //     //     saveAsDesign()
        //     //     editor.canvas.renderAll()
        //     // }
        //     console.log('yes temp false and design false');
        // }
        // else if(reusedtempboolen && !reuseddesignboolen){
        //     console.log('yes temp true and design false');
        // }
        // else if(reusedtempboolen && reuseddesignboolen){
        //     console.log('yes temp true and design true');
        //     return;
        // }
        // else{
        //     return
        // }

        // if(reusedtempboolen){
        //     console.log('yes temp is strue');
        // }
        // else{
        //     console.log('nope temp is false');
        // }
        // if(reuseddesignboolen){
        //     console.log('yes design is true');
        // }
        // else{
        //     console.log('design is false');
        // }
        
    
        


    },[canvasstatechange])

    
    const [canvasAdd , setCanvasAdd] = useState(false)
    const [duplicate , setDuplicate] = useState(0)

    // const canvasAdd = []
    const addCanvas = () => {


        setCanvasAdd(true)

    }

    const [canvasDuplicateData, setCanvasDuplicateData] = useState(null)
    const duplicateCanvas = () => {
        setCanvasDuplicateData(editor.canvas.toJSON(['id', 'height', 'width', 'subTargetCheck', 'canvasimg']))
        setDuplicate(duplicate +1)

    }

    useEffect(() => {
        if(canvasDuplicateData != null){
            setCanvasAdd(true)

        }
    },[canvasDuplicateData])


    return (
        <div>
            <div className='new-tool' style={{top: 7 ,  left: 17,  width: "98%" }}>
            <div className="toolbars " style={{display: editor?.canvas.getActiveObject()? 'flex' : 'none'}} >
                {(editor?.canvas.getActiveObject()?.type == 'textbox') &&
                    <TextProperties editor={editor} fabric={fabric} counter={counter} counterTemp={counterTemp} Copy={Copy} Paste={Paste} decreaseFontSize={decreaseFontSize} increaseFontSize={increaseFontSize} handleBold={handleBold} handleItalic={handleItalic} handleUnderline={handleUnderline} handoverline={handoverline} handlinethrough={handlinethrough} bold={bold} italic={italic} underline={underline} overline={overline} linethrough={linethrough} />
                }
                {sideBarElementOpenClose &&
                    <div className="shapes-prop-container" >
                        {/* <Shapes editor={editor} fabric={fabric} /> */}
                    </div>
                }
                {(editor?.canvas.getActiveObject()?.type == 'path' || editor?.canvas.getActiveObject()?.type == 'rect' || editor?.canvas.getActiveObject()?.type == 'polygon' || editor?.canvas.getActiveObject()?.type == 'circle' || editor?.canvas.getActiveObject()?.type == 'ellipse' || editor?.canvas.getActiveObject()?.type == 'triangle' || editor?.canvas.getActiveObject()?.type == 'polyline' || editor?.canvas.getActiveObject()?.type == 'group') &&
                    <div className="shapes-prop-container">
                        <BrushIcon className={isdrawing ? "toolbar-icons-active" : "toolbar-icons"} onClick={drawingFunc} />
                        <button className='set-as-background-but toolbar-icons' onClick={setAsbackgroundIm}>Set as Background</button>
                        <ElementsProperties editor={editor} fabric={fabric} />
                    </div>
                }
                {(editor?.canvas.getActiveObject()?.type == 'image') &&
                    <ImageProperties editor={editor} fabric={fabric} setAsbackgroundIm={setAsbackgroundIm} />
                }
                {backgroundEdit &&
                    <BackgroundProperties editor={editor} fabric={fabric} removeBackground={removeBackground} />

                }
            </div>
            <GeneralProperties editor={editor} fabric={fabric} lockmovementActiveobject={lockmovementActiveobject} canvasWidth={canvasWidth} canvasHeight={canvasHeight} clearAll={clearAll} />
            </div>
        </div>
    )
}


export {AddPage, Styles}