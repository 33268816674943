import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../layouts/Header";
import Config from "../config/Config";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ButtonLoader from "./ButtonLoader";
import { Tooltip } from "@mui/material";

const Digitalization = (props) => {
    const navigate = useNavigate();

    const [innerText, setInnerText] = useState('');
    const [updatedContent, setUpdatedContent] = useState('');
    const [isUpdating, setIsUpdating] = useState(false)
    const contentRef = useRef(null);

    // const [wordsToHighlight, setWordsToHightlight] = useState([])

    const handleInput = (event) => {
        setInnerText(event.target.innerText);
    };

    const getSpellCheck = async () => {
        const formdata = new FormData();
        formdata.append("text", innerText);
        formdata.append("lang_code", "ta");

        Config.axios({
            url: Config.LABS_API + 'synonyms/tamil-spell-check',
            method: "POST",
            data: formdata,
            auth: true,
            success: async (response) => {

                let words = []
                console.log(response)
                response?.data?.result?.forEach((each) => {
                    words.push(each.word)
                })
                // setWordsToHightlight(words)
                highlightWords(innerText, words)
            },
            error: (err) => {
                Config.showToast('Somthing went wrong', 'error')

            }
        })
    }

    const handleUpdate = () => {
        setIsUpdating(true)
        setTimeout(() => {
            setIsUpdating(false)
        }, 2000);
    }



    const paste = (e) => {
        e.preventDefault()
        var text = e.clipboardData.getData('text/plain')
        document.execCommand('insertText', false, text)
    }

    const highlightWords = (text,wordsToHighlight) => {
        if (!wordsToHighlight || wordsToHighlight.length === 0) return text;
    
        let highlightedText = text;
        wordsToHighlight.forEach((word) => {
          const regex = new RegExp(`(${word})`, 'gi');
          highlightedText = highlightedText.replace(regex, '<mark class="mistake">$1</mark>');
        });
    
        contentRef.current.innerHTML = highlightedText;
      };


    return (
        <React.Fragment>
            <section className="ac-section-wrapper" style={{ top: 0, height: '100%' }}>
                <Header />
                <div className="digitalization-initial-wrap">
                    <div className="digitalization-main-wrap">
                        <div className="digitalization-content-wrapper">
                            <div className="content-editable-div" placeholder="Paste or Enter text here..."onPaste={paste}  ref={contentRef}  dangerouslySetInnerHTML={{ __html: innerText }} onInput={handleInput} contentEditable={true} />
                        </div>
                        <div className="digitalization-options">
                            <button onClick={() => getSpellCheck()}>Spell check</button>
                        </div>
                    </div>
                    <Tooltip title="Click to correct">
                    <button onClick={() => !isUpdating && handleUpdate()} className="update-button">
                       {isUpdating ? 
                       (
                        <div class="jumping-dots-loader"> <span></span> <span></span> <span></span> </div>
                       ) : ( <ArrowForwardIcon style={{
                            color:'#FFFFFF'
                        }} />)}
                    </button>
                    </Tooltip>
                    <div className="digitalization-main-wrap" style={{borderLeft:'none'}}>
                        <div className="digitalization-content-wrapper">
                        <div className="content-editable-div"    dangerouslySetInnerHTML={{ __html: updatedContent }}   />
                        </div>
                        <div className="digitalization-options" style={{visibility:'hidden'}}>
                            <button>Spell check</button>
                        </div>
                    </div>
                </div>
               
            </section>
        </React.Fragment>
    )
}

export default Digitalization;