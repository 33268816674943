import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import {toast,Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastComponent from "../pages/ToastComponent";


let userSelectionCallTimer = null;

const userState = JSON.parse(
  typeof Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME) != "undefined" ? Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME) : null
);
const userStateSub = JSON.parse(
  typeof Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME_SUB) != "undefined" ? Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME_SUB) : null
);
const Config = {
    userState: userState,
    userStateSub: userStateSub,
    HOST_URL: "/",
    LABS_API: process.env.REACT_APP_LABS_API,
    LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
    MT_URL: process.env.REACT_APP_MT_LOGIN_URL,
    AI_CANVAS_STAGING_API:process.env.REACT_APP_AI_CANVAS_STAGING_API,
    axios: (params) => {
      //Common axios handling
      let userCacheData = JSON.parse(
        typeof Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME) != "undefined" ? Cookies.get(process.env.REACT_APP_USER_COOKIE_KEY_NAME) : null
      );
      // refresh the page if the access token does not match
      if (userState?.token !== userCacheData?.token) {
        window.location.reload()
      }
      let token = userCacheData != null ? userCacheData?.token : "";
      let headers = {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      };
      if (params.headers != null) headers = params.headers; //Setting headers
      if (params.auth != null) {
        //If auth needs
        if (params.auth === true) headers["Authorization"] = `Bearer ${token}`;
      }
      let axiosObj = {
        method: params.method,
        url: params.url,
        headers: headers,
      };
      if (params.method == null)
        //Define method
        params["method"] = "GET";
      if (params?.data != null || params?.formData != null)
        //If data, set the data
        axiosObj["data"] = params?.data != null ? params.data : params?.formData != null && params.formData;
      if (params?.timeout != null)
        // If timeout need
        params["timeout"] = params?.timeout;
      axios(axiosObj)
        .then((response) => {
          if (params?.success != null) params.success(response); //Callback to the param's success function
        })
        .catch((error) => {
          /*Create Refresh Token if access_token expires - start*/
          if (error.response?.data?.messages !== undefined && error.response?.data?.messages.length !== 0 && error.response?.data?.messages[0]?.token_class === "AccessToken") {
            let refreshToken = userState != null ? userState.refresh_token : "";
            let formData = new FormData();
            formData.append("refresh", refreshToken);
            Config.axios({
              url: Config.BASE_URL + "/auth/dj-rest-auth/token/refresh/",
              method: "POST",
              data: formData,
              auth: true,
              success: (response) => {
                // console.log(response.data)
                let userData = Config.userStateStagingDesigner;
                userData.token = response.data.access;
                Cookies.remove(process.env.REACT_APP_USER_COOKIE_KEY_NAME, { domain: Config.COOKIE_DOMAIN });
                Cookies.set(process.env.REACT_APP_USER_COOKIE_KEY_NAME, JSON.stringify(userData), { domain: Config.COOKIE_DOMAIN });
                setTimeout(() => {
                  window.location.reload(false);
                }, 500);
                Config.axios(params);
              },
              error: (error) => {
                // console.log(error.response.status);
                if (Config.userStateStagingDesigner !== null) Cookies.remove(process.env.REACT_APP_USER_COOKIE_KEY_NAME, { domain: Config.COOKIE_DOMAIN });
                window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL;
              },
            });
          } else if (error.response?.data?.msg === "Unauthorised" || error.response?.data?.code === "bad_authorization_header") {
            //Logout if unauthorized response came
            Config.logout();
          } else if (params.error != null) params.error(error);
          else {
            console.log(params.url);
            console.log(error);
            console.showToast(Config.DEFAULT_ERROR_MESSAGE, "error");
          }
        })
        .finally(() => {
          if (params.finally != null) params.finally();
        });
    },  
     debounceApiCall: (callback, time) => {
      if (userSelectionCallTimer) {
        clearTimeout(userSelectionCallTimer);
      }
      userSelectionCallTimer = setTimeout(() => {
          callback();
          userSelectionCallTimer = null;
      }, time); // Adjust the debounce delay as needed
  }, 
  showToast: (label, type) => {
      toast.dismiss();
      toast.clearWaitingQueue();
      toast(<ToastComponent type={type} content={label} />)
      
  },

  };
  export default Config;