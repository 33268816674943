
export const sizeList = [
    "8px",
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "72px"
  ];
  
  export const fontList = [
    "Arial",
    "Poppins",
    "Courier Prime",
    "Chilanka",
    "Roboto",
    "Splash",
    "Dancing Script"

  ];