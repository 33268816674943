export const languages = [
  { id: 4, language: "Arabic", code: "ar", disabled: true },
  { id: 108, language: "Chinese", code: "cmn-Hans-CN", disabled: true },
  { id: 17, language: "English", code: "en" },
  { id: 39, language: "Japanese", code: "ja", disabled: true },
  { id: 77, language: "Tamil", code: "ta", disabled: true },
];

export const Targetlanguageses = [
  { id: 4, language: "Arabic", code: "ar" },
  { id: 108, language: "Chinese", code: "zh-CN" },
  {id: 17, language: 'English', code: 'en'},
  { id: 39, language: "Japanese", code: "ja" },
  { id: 77, language: "Tamil", code: "ta" },
];

export const Tlanguageses = [
  {id: 1, language: 'Afrikaans', code: 'af'},
  {id: 2, language: 'Albanian', code: 'sq'},
  {id: 3, language: 'Amharic', code: 'am'},
  {id: 4, language: 'Arabic', code: 'ar'},
  {id: 5, language: 'Armenian', code: 'hy'},
  {id: 6, language: 'Azerbaijani', code: 'az'},
  {id: 7, language: 'Basque', code: 'eu'},
  {id: 8, language: 'Belarusian', code: 'be'},
  {id: 91, language: 'Bengali', code: 'bn'},
  {id: 9, language: 'Bosnian', code: 'bs'},
  {id: 10, language: 'Bulgarian', code: 'bg'},
  {id: 11, language: 'Catalan', code: 'ca'},
  {id: 92, language: 'Cebuano', code: 'ceb'},
  {id: 108, language: 'Chinese (Simplified)', code: 'zh-CN'},
  {id: 107, language: 'Chinese (Traditional)', code: 'zh-TW'},
  {id: 13, language: 'Croatian', code: 'hr'},
  {id: 14, language: 'Czech', code: 'cs'},
  {id: 15, language: 'Danish', code: 'da'},
  {id: 16, language: 'Dutch', code: 'nl'},
  {id: 17, language: 'English', code: 'en'},
  {id: 19, language: 'Estonian', code: 'et'},
  {id: 106, language: 'Farsi / Persian', code: ''},
  {id: 20, language: 'Filipino', code: ''},
  {id: 21, language: 'Finnish', code: 'fi'},
  {id: 22, language: 'French', code: 'fr'},
  {id: 25, language: 'Georgian', code: 'ka'},
  {id: 26, language: 'German', code: 'de'},
  {id: 27, language: 'Greek', code: 'el'},
  {id: 28, language: 'Gujarati', code: 'gu'},
  {id: 94, language: 'Haitian Creole', code: 'ht'},
  {id: 29, language: 'Hausa', code: 'ha'},
  {id: 30, language: 'Hawaiian', code: 'haw'},
  {id: 31, language: 'Hebrew', code: 'he'},
  {id: 32, language: 'Hindi', code: 'hi'},
  {id: 95, language: 'Hmong', code: 'hmn'},
  {id: 33, language: 'Hungarian', code: 'hu'},
  {id: 34, language: 'Icelandic', code: 'is'},
  {id: 35, language: 'Igbo', code: 'ig'},
  {id: 36, language: 'Indonesian', code: 'id'},
  {id: 37, language: 'Irish', code: 'ga'},
  {id: 38, language: 'Italian', code: 'it'},
  {id: 39, language: 'Japanese', code: 'ja'},
  {id: 96, language: 'Javanese', code: 'jv'},
  {id: 40, language: 'Kannada', code: 'kn'},
  {id: 41, language: 'Kazakh', code: 'kk'},
  {id: 42, language: 'Khmer', code: 'km'},
  {id: 43, language: 'Kinyarwanda', code: 'rw'},
  {id: 44, language: 'Korean', code: 'ko'},
  {id: 45, language: 'Kyrgyz', code: 'ky'},
  {id: 46, language: 'Lao', code: 'lo'},
  {id: 47, language: 'Latvian', code: 'lv'},
  {id: 48, language: 'Lithuanian', code: 'lt'},
  {id: 50, language: 'Macedonian', code: 'mk'},
  {id: 51, language: 'Malagasy', code: 'mg'},
  {id: 52, language: 'Malay', code: 'ms'},
  {id: 53, language: 'Malayalam', code: 'ml'},
  {id: 54, language: 'Maltese', code: 'mt'},
  {id: 98, language: 'Maori', code: 'mi'},
  {id: 55, language: 'Marathi', code: 'mr'},
  {id: 56, language: 'Mongolian', code: 'mn'},
  {id: 57, language: 'Myanmar (Burmese)', code: 'my'},
  {id: 58, language: 'Nepali', code: 'ne'},
  {id: 99, language: 'Norwegian', code: 'no'},
  {id: 59, language: 'Odia', code: 'or'},
  {id: 60, language: 'Pashto', code: 'ps'},
  {id: 62, language: 'Polish', code: 'pl'},
  {id: 63, language: 'Portuguese', code: 'pt'},
  {id: 64, language: 'Punjabi', code: 'pa'},
  {id: 65, language: 'Romanian', code: 'ro'},
  {id: 66, language: 'Russian', code: 'ru'},
  {id: 100, language: 'Samoan', code: 'sm'},
  {id: 67, language: 'Serbian', code: 'sr'},
  {id: 68, language: 'Shona', code: 'sn'},
  {id: 101, language: 'Sindhi', code: 'sd'},
  {id: 69, language: 'Sinhala', code: 'si'},
  {id: 70, language: 'Slovak', code: 'sk'},
  {id: 71, language: 'Slovenian', code: 'sl'},
  {id: 72, language: 'Somali', code: 'so'},
  {id: 73, language: 'Spanish', code: 'es'},
  {id: 102, language: 'Sundanese', code: 'su'},
  {id: 74, language: 'Swahili', code: 'sw'},
  {id: 75, language: 'Swedish', code: 'sv'},
  {id: 76, language: 'Tajik', code: 'tg'},
  {id: 77, language: 'Tamil', code: 'ta'},
  {id: 78, language: 'Tatar', code: 'tt'},
  {id: 79, language: 'Telugu', code: 'te'},
  {id: 80, language: 'Thai', code: 'th'},
  {id: 81, language: 'Turkish', code: 'tr'},
  {id: 103, language: 'Turkmen', code: 'tk'},
  {id: 82, language: 'Ukrainian', code: 'uk'},
  {id: 83, language: 'Urdu', code: 'ur'},
  {id: 84, language: 'Uyghur', code: 'ug'},
  {id: 85, language: 'Uzbek', code: 'uz'},
  {id: 86, language: 'Vietnamese', code: 'vi'},
  {id: 87, language: 'Welsh', code: 'cy'},
  {id: 104, language: 'Xhosa', code: 'xh'},
  {id: 88, language: 'Yiddish', code: 'yi'},
  {id: 89, language: 'Yoruba', code: 'yo'},
  {id: 90, language: 'Zulu', code: 'zu'},
  
  ];
  

export const SmlTargetlanguageses = [
  { id: 4, language: "Arabic", code: "ar" },
  { id: 108, language: "Chinese", code: "zh-Hans" },
  // {id: 17, language: 'English', code: 'en'},
  { id: 39, language: "Japanese", code: "ja" },
  { id: 77, language: "Tamil", code: "ta" },
];
