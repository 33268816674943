export const FontFamilyList = [
  {name : "Archivo Black" },
  {name : "Abhaya Libre" },
  {name : "Aboreto" },
  {name : "Alumni Sans Collegiate One" },
  {name : "Alumni Sans Pinstripe" },
  {name : "Anek Bangla" },
  {name : "Anek Odia" },
  {name : "Cairo" },
  {name : "Catamaran" },
  {name : "Caveat" },
  {name : "Chilanka" },
  {name : "Clicker Script" },
  {name : "Dancing Script" },
  {name : "DynaPuff" },
  {name : "Gayathri" },
  {name : "Hanuman" },
  {name : "Hind Madurai" },
  {name : "Hind Siliguri" },
  {name : "Hind Vadodara" },
  {name : "Indie Flower" },
  {name : "Jomolhari" },
  {name : "Kanit" },
  {name : "Katibeh" },
  {name : "M PLUS Rounded 1c" },
  {name : "Mandali" },
  {name : "Manjari" },
  {name : "Monofett" },
  {name : "Montserrat" },
  {name : "Montserrat Alternates" },
  {name : "Montserrat Subrayada" },
  {name : "Mukta" },
  {name : "Mukta Mahee" },
  {name : "Mukta Malar" },
  {name : "Nanum Gothic" },
  {name : "Nanum Gothic Coding" },
  {name : "Nokora" },
  {name : "Noto Kufi Arabic" },
  {name : "Noto Naskh Arabic" },
  {name : "Noto Sans" },
  {name : "Noto Sans Arabic" },
  {name : "Noto Sans Bengali" },
  {name : "Noto Sans Devanagari" },
  {name : "Noto Sans Gujarati" },
  {name : "Noto Sans Gurmukhi" },
  {name : "Noto Sans Hebrew" },
  {name : "Noto Sans JP" },
  {name : "Noto Sans Kannada" },
  {name : "Noto Sans Khmer" },
  {name : "Noto Sans KR" },
  {name : "Noto Sans Malayalam" },
  {name : "Noto Sans Myanmar" },
  {name : "Noto Sans SC" },
  {name : "Noto Sans Sinhala" },
  {name : "Noto Sans Tamil" },
  {name : "Noto Sans Tamil Supplement" },
  {name : "Noto Sans TC" },
  {name : "Noto Sans Telugu" },
  {name : "Noto Sans Thai" },
  {name : "Noto Sans Thai Looped" },
  {name : "Noto Serif Bengali" },
  {name : "Noto Serif Devanagari" },
  {name : "Noto Serif Gujarati" },
  {name : "Noto Serif Gurmukhi" },
  {name : "Noto Serif Hebrew" },
  {name : "Noto Serif JP" },
  {name : "Noto Serif Kannada" },
  {name : "Noto Serif Khmer" },
  {name : "Noto Serif KR" },
  {name : "Noto Serif Malayalam" },
  {name : "Noto Serif Sinhala" },
  {name : "Noto Serif Tamil" },
  {name : "Noto Serif TC" },
  {name : "Noto Serif Thai" },
  {name : "Noto Serif Tibetan" },
  {name : "Open Sans" },
  {name : "Oswald" },
  {name : "Pacifico" },
  {name : "Padauk" },
  {name : "Permanent Marker" },
  {name: "Peralta"},
  {name : "Poppins" },
  {name : "Prompt" },
  {name : "Raleway" },
  {name : "Raleway Dots" },
  {name : "Roboto" },
  {name : "Rubik" },
  {name : "Rubik Beastly" },
  {name : "Rubik Bubbles" },
  {name : "Rubik Burned" },
  {name : "Rubik Dirt" },
  {name : "Rubik Distressed" },
  {name : "Rubik Glitch" },
  {name : "Rubik Iso" },
  {name : "Rubik Marker Hatch" },
  {name : "Rubik Maze" },
  {name : "Rubik Microbe" },
  {name : "Rubik Mono One" },
  {name : "Rubik Moonrocks" },
  {name : "Rubik Puddles" },
  {name : "Rubik Wet Paint" },
  {name : "Satisfy" },
  {name : "Sawarabi Gothic" },
  {name : "Silkscreen" },
  {name : "Space Mono" },
  {name : "Teko" },
  {name : "Uchen" },
  ];


  export const ArabicFontFamilyList = [

    {name: "Alkalami"},
    {name: "Almarai"},
    {name: "Amiri"},
    {name: "Amiri Quran"},
    {name: "Aref Ruqaa Ink"},
    {name: "Baloo Bhaijaan 2"},
    {name: "Cairo"},
    {name: "Cairo Play"},
    {name: "Changa"},
    {name: "El Messiri"},
    {name: "IBM Plex Sans Arabic"},
    {name: "Mada"},
    {name: "Reem Kufi Fun"},
    {name: "Reem Kufi Ink"},
    {name: "Tajawal"},
    
    ];
  
    export const BengaliFontFamilyList = [

      {name: "Anek Bangla"},
      {name: "Atma"},
      {name: "Baloo Da 2"},
      {name: "Galada"},
      {name: "Hind Siliguri"},
      {name: "Mina"},
      {name: "Noto Sans Bengali"},
      {name: "Noto Serif Bengali"},
      {name: "Tiro Bangla"},

    ];

    export const ChineseHkFontFamilyList = [

      {name: "Noto Sans HK"},
      {name: "Noto Serif HK"},

    ];

    export const ChineseSimpFontFamilyList = [

      {name: "Liu Jian Mao Cao"},
      {name: "Long Cang"},
      {name: "Ma Shan Zheng"},
      {name: "Noto Sans SC"}, 
      {name: "Noto Serif SC"}, 
      {name: "ZCOOL KuaiLe"}, 
      {name: "ZCOOL QingKe HuangYou"}, 
      {name: "ZCOOL XiaoWei"}, 
      {name: "Zhi Mang Xing"}, 

    ];

    export const ChineseTrFontFamilyList = [

      {name: "Noto Sans TC"},
      {name: "Noto Serif TC"},

    ];

    export const GujaratiFontFamilyList = [
      {name: "Anek Gujarati"},
      {name: "Baloo Bhai 2"},
      {name: "Farsan"},
      {name: "Hind Vadodara"},
      {name: "Kumar One"},
      {name: "Kumar One Outline"},
      {name: "Mogra"},
      {name: "Mukta Vaani"},
      {name: "Noto Sans Gujarati"},
      {name: "Noto Serif Gujarati"},
      {name: "Rasa"},
      {name: "Shrikhand"},

    ];

    export const GurmukhiFontFamilyList = [
      {name: "Anek Gurmukhi"},
      {name: "Baloo Paaji 2"},
      {name: "BhuTuka Expanded One"},
      {name: "Langar"},
      {name: "Mukta Mahee"},
      {name: "Noto Sans Gurmukhi"},
      {name: "Noto Serif Gurmukhi"},
      {name: "Tiro Gurmukhi"},
      

    ];

    export const KanadaFontFamilyList = [
      {name: "Akaya Kanadaka"},
      {name: "Anek Kannada"},
      {name: "Baloo Tamma 2"},
      {name: "Benne"}, 
      {name: "Hubballi"}, 
      {name: "Noto Sans Kannada"},
      {name: "Noto Serif Kannada"},
      {name: "Tiro Kannada"}, 
      
    ];

    export const MalayalamFontFamilyList = [
      {name: "Anek Malayalam"}, 
      {name: "Arima"}, 
      {name: "Baloo Chettan 2"}, 
      {name: "Chilanka"}, 
      {name: "Gayathri"}, 
      {name: "Manjari"}, 
      {name: "Noto Sans Malayalam"},
      {name: "Noto Serif Malayalam"},
      
    ];

    export const MyanmarFontFamilyList = [
      {name: "Noto Sans Myanmar"},
      {name: "Noto Serif Myanmar"},
      {name: "Padauk"},
      
    ];

    export const OriyaFontFamilyList = [
      {name: "Anek Odia"}, 
      {name: "Baloo Bhaina 2"},
      {name: "Noto Sans Oriya"}, 
      
      
    ];

    export const SinhalaFontFamilyList = [

      {name: "Abhaya Libre"}, 
      {name: "Gemunu Libre"}, 
      {name: "Noto Sans Sinhala"}, 
      {name: "Noto Serif Sinhala"}, 
      {name: "Stick No Bills"},
      {name: "Yaldevi"},
      
    ];

    export const TamilFontFamilyList = [
      
      {name: "Anek Tamil"},
      {name: "Arima"},
      {name: "Arima Madurai"},
      {name: "Baloo Thambi 2"},
      {name: "Catamaran"},
      {name: "Coiny"},
      {name: "Hind Madurai"},
      {name: "Kavivanar"},
      {name: "Meera Inimai"},
      {name: "Mukta Malar"},
      {name: "Noto Sans Tamil"},
      {name: "Noto Serif Tamil"},
      {name: "Oi"},
      {name: "Pavanam"},
      {name: "Tiro Tamil"},
      
    ];

    
    export const TeluguFontFamilyList = [
      
      {name: 'Anek Telugu'},
      {name: 'Baloo Tammudu 2'},
      {name: 'Gurajada'},
      {name: 'Hind Guntur'},
      {name: 'Mallanna'},
      {name: 'Mandali'},
      {name: 'Noto Sans Telugu'},
      {name: 'Noto Serif Telugu'},
      {name: 'NTR'},
      {name: 'Ramabhadra'},
      {name: 'Ramaraja'},
      {name: 'Suranna'},
      {name: 'Tenali Ramakrishna'},
      {name: 'Timmana'},
      {name: 'Tiro Telugu'},

      
    ];

    export const TibetanFontFamilyList = [
      
      {name: "Jomolhari"},
      {name: "Noto Serif Tibetan"},
      {name: "Uchen"},
      
    ];





    
  