import React, { useEffect, useState, useRef } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useNavigate } from "react-router-dom";
import Canvas from "../components/canvas-workspace/Canvas";


const CanvasWorkspace = (props) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <section className="canvas-section-wrapper">
                    <div className="canvas-wrap-header">
                        <Canvas />
                    </div>
            </section>
        </React.Fragment>
    )
}

export default CanvasWorkspace;