import { ButtonBase } from "@mui/material";
import React, { useEffect, useState } from "react";

function AlertModal(props) {

    const {setShowAlertModal, showAlertModal, noNamedEntities, noKeywords, setNoNamedEntities, setNoKeywords, showYoutubeAlertModal, setShowYoutubeAlertModal} = props


    return(
        <React.Fragment>
            <div className="confirmation-wrapper">
                <img src={window.location.origin + "/media/icons/confirm-icon.svg"} alt="confirm-icon"/>
                {(noNamedEntities && <h2>No name entities found</h2>) || (noKeywords && <h2>No keywords found</h2>) || (showYoutubeAlertModal && <h2>No Subtitle found for this video</h2>) || <h2>Your Tweet needs to be shorter</h2>}
                <div className="button-row">
                    <ButtonBase onClick={()=>{showAlertModal && setShowAlertModal(false); noNamedEntities && setNoNamedEntities(false); noKeywords && setNoKeywords(false); showYoutubeAlertModal && setShowYoutubeAlertModal(false)}} className="submit-txt">
                        <span>OK</span>
                    </ButtonBase>
                </div> 
            </div>
        </React.Fragment>
    )

}


export default AlertModal;