import React, { useEffect, useState, useRef } from "react";
import { ButtonBase } from "@mui/material";
import { Link } from "react-router-dom";
import Config from "../config/Config";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
    const logout = () => {
        if (Config.userState !== null) Cookies.remove(process.env.REACT_APP_USER_COOKIE_KEY_NAME, { domain: Config.COOKIE_DOMAIN });
        if (Config.planState !== null) Cookies.remove(process.env.REACT_APP_PLAN_COOKIE_KEY_NAME, { domain: Config.COOKIE_DOMAIN });
        if (typeof Cookies.get("redirection_domain") != "undefined") Cookies.remove("redirection_domain", { domain: Config.COOKIE_DOMAIN });
        setTimeout(() => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
        }, 1000);

    }
    const navigate = useNavigate();

    const back = () => {
      navigate('/')

    }

    return (
        <React.Fragment>
            <nav className="lab-main-navbar">
                <div className="container h-100">
                    <div className="main-nav-wrapper">
                        <img onClick={back} src={window.location.pathname.includes('digitalization') ?  window.location.origin + "/media/icons/logoBlack.svg" :  window.location.origin + "/media/icons/logo.svg"} />
                        {/* <ButtonBase component={Link} to="/"> */}
                        <ButtonBase onClick={logout}>
                            <span className="main-btn">Logout</span>
                        </ButtonBase>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    )
}

export default Header;