import { EditorState, Modifier,ContentState } from "draft-js";
import React, { useState,useEffect } from "react";
import { ButtonBase } from "@mui/material";
import MicIcon from '@mui/icons-material/Mic';
import MicOff from "@mui/icons-material/MicOff";

const SpeechRecognition = window.SpeechRecognition
      || window.webkitSpeechRecognition
      || window.mozSpeechRecognition
      || window.msSpeechRecognition
      || window.oSpeechRecognition;

      
const recognition = SpeechRecognition ? new SpeechRecognition() : {};
recognition.continuous = true;

export default function VoiceEditor(props) {

  let{speechSourceLanguageOption,editorState, onChange} = props

  const [isListening, setIsListening] = useState(false);
  recognition.lang = speechSourceLanguageOption.code;

  console.log(speechSourceLanguageOption.code);

  const handleListening = () => {
    if (!isListening) {

      try{
      recognition.start();
      setIsListening(true);
      }
      catch{
        recognition.stop();
        setIsListening(false);
      }
    } else {
      recognition.stop();
      setIsListening(false);
    }
  };

  recognition.onresult = (e) => {
    const current = e.resultIndex;
    const transcript = e.results[current][0].transcript;

    // console.log(transcript);

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      transcript,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));

  };


  return (

    <div>
      <ButtonBase className="listen-button" onClick={handleListening}>
        <div className="d-flex gap-2 align-items-center">
          {isListening ? <MicOff className="icon"/> : <MicIcon className="icon"/> }
          {isListening ? <span className="txt">Stop Listening</span> : <span className="txt">Start Listening</span>}
        </div>
      </ButtonBase>
    </div>
  );
}
